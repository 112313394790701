import Auth from "./auth_reducer";
import App from "./app_reducer";
import Admin from "./admin_reducer";
import Dumpster from "./dumpster_reducer";
import Lorry from "./lorry_reducer";
import DumpsterPrice from "./dumpster_price_reducer";
import General from "./general_reducer";
import UserOrganization from "./user_organization_reducer";
import User from "./user_reducer";
import Driver from "./driver_reducer";
import Order from "./order_reducer";
import Notification from "./notification_reducer";
import GoogleMap from "./google_map_reducer";
import Recycling from "./recycling_reducer";
import FirebaseDB from "./firebase_db_reducer";
import Payment from "./payment_reducer";
import Whatsapp from "./whatsapp_reducer";
import ScheduledWaste from "./scheduled_waste_reducer";
import Grader from "./grader_reducer";
import Express from "./express_reducer";

export default {
  Auth,
  App,
  Admin,
  Dumpster,
  Lorry,
  DumpsterPrice,
  General,
  UserOrganization,
  User,
  Driver,
  Order,
  Notification,
  GoogleMap,
  Recycling,
  FirebaseDB,
  Payment,
  Whatsapp,
  ScheduledWaste,
  Grader,
  Express
};