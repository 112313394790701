import * as actionTypes from "./actionTypes";


export const loadNotificationList = data => {
  return {
    type: actionTypes.LOAD_NOTIFICATION_LIST,
    data
  };
};

export const loadNotificationListSuccess = data => {
  return {
    type: actionTypes.LOAD_NOTIFICATION_LIST_SUCCESS,
    data
  };
};

export const loadNotificationListFail = () => {
  return {
    type: actionTypes.LOAD_NOTIFICATION_LIST_FAIL
  };
};



export const loadNotificationDetail = (id, data) => {
  
  return {
    type: actionTypes.LOAD_NOTIFICATION_DETAIL,
    id,
    data
  };
};

export const loadNotificationDetailSuccess = data => {
  
  return {
    type: actionTypes.LOAD_NOTIFICATION_DETAIL_SUCCESS,
    data
  };
};

export const loadNotificationDetailFail = () => {
  return {
    type: actionTypes.LOAD_NOTIFICATION_DETAIL_FAIL
  };
};


export const createNotification = (data) => {
  return {
    type: actionTypes.CREATE_NOTIFICATION,
    data
  };
};

export const createNotificationSuccess = () => {
  return {
    type: actionTypes.CREATE_NOTIFICATION_SUCCESS
  };
};

export const createNotificationFail = () => {
  return {
    type: actionTypes.CREATE_NOTIFICATION_FAIL
  };
};



export const editNotification = (id,data) => {
  return {
    type: actionTypes.EDIT_NOTIFICATION,
    id,
    data
  };
};

export const editNotificationSuccess = () => {
  return {
    type: actionTypes.EDIT_NOTIFICATION_SUCCESS
  };
};

export const editNotificationFail = () => {
  return {
    type: actionTypes.EDIT_NOTIFICATION_FAIL
  };
};



export const deleteNotification = (id, data) => {
  return {
    type: actionTypes.DELETE_NOTIFICATION,
    id,
    data
  };
};

export const deleteNotificationSuccess = () => {
  return {
    type: actionTypes.DELETE_NOTIFICATION_SUCCESS
  };
};

export const deleteNotificationFail = () => {
  return {
    type: actionTypes.DELETE_NOTIFICATION_FAIL
  };
};