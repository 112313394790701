import * as actionTypes from "./actionTypes";

export const loadGraderList = data => {
  
  return {
    type: actionTypes.LOAD_GRADER_LIST,
    data
  };
};

export const loadGraderListSuccess = data => {
  return {
    type: actionTypes.LOAD_GRADER_LIST_SUCCESS,
    data
  };
};

export const loadGraderListFail = () => {
  return {
    type: actionTypes.LOAD_GRADER_LIST_FAIL
  };
};



export const graderCount = (data) => {
  
  return {
    type: actionTypes.GRADER_COUNT,
    data
  };
};

export const graderCountSuccess = data => {
  return {
    type: actionTypes.GRADER_COUNT_SUCCESS,
    data
  };
};

export const graderCountFail = () => {
  return {
    type: actionTypes.GRADER_COUNT_FAIL
  };
};


export const editGraderDetail = (id,data) => {
  return {
    type: actionTypes.EDIT_GRADER_DETAIL,
    id,
    data
  };
};

export const editGraderDetailSuccess = () => {
  return {
    type: actionTypes.EDIT_GRADER_DETAIL_SUCCESS
  };
};

export const editGraderDetailFail = () => {
  return {
    type: actionTypes.EDIT_GRADER_DETAIL_FAIL
  };
};


export const removeGrader = (id,data) => {
  return {
    type: actionTypes.REMOVE_GRADER,
    id,
    data
  };
};

export const removeGraderSuccess = (data) => {
  return {
    type: actionTypes.REMOVE_GRADER_SUCCESS,
    data
  };
};

export const removeGraderFail = () => {
  return {
    type: actionTypes.REMOVE_GRADER_FAIL
  };
};


export const recoveryGrader = (id, data) => {
  
  return {
    type: actionTypes.RECOVERY_GRADER,
    id,
    data
  };
};

export const recoveryGraderSuccess = () => {
  
  return {
    type: actionTypes.RECOVERY_GRADER_SUCCESS
  };
};

export const recoveryGraderFail = () => {
  return {
    type: actionTypes.RECOVERY_GRADER_FAIL
  };
};


export const editGraderStatus = (id,data, listParams) => {
  return {
    type: actionTypes.EDIT_GRADER_STATUS,
    id,
    data,
    listParams
  };
};

export const editGraderStatusSuccess = () => {
  return {
    type: actionTypes.EDIT_GRADER_STATUS_SUCCESS
  };
};


export const proceedDeactivateConfirm = (data) => {
  return {
    type: actionTypes.PROCEED_DEACTIVATE_CONFIRM,
    data
  };
};

export const proceedUpdateOrderLandfill = (data) => {
  return {
    type: actionTypes.PROCEED_UPDATE_ORDER_LANDFILL,
    data
  };
};

export const dismissUpdateOrderLandfill = () => {
  return {
    type: actionTypes.DIMISS_UPDATE_ORDER_LANDFILL
  };
};

export const editGraderStatusFail = () => {
  return {
    type: actionTypes.EDIT_GRADER_STATUS_FAIL
  };
};




export const editGraderVerifyStatus = (id,data, listParams) => {
  return {
    type: actionTypes.EDIT_GRADER_VERIFY_STATUS,
    id,
    data,
    listParams
  };
};

export const editGraderVerifyStatusSuccess = () => {
  return {
    type: actionTypes.EDIT_GRADER_VERIFY_STATUS_SUCCESS
  };
};

export const editGraderVerifyStatusFail = () => {
  return {
    type: actionTypes.EDIT_GRADER_VERIFY_STATUS_FAIL
  };
};


export const rejectGraderVerifyStatus = (id,data, listParams) => {
  return {
    type: actionTypes.REJECT_GRADER_VERIFY_STATUS,
    id,
    data,
    listParams
  };
};

export const rejectGraderVerifyStatusSuccess = () => {
  return {
    type: actionTypes.REJECT_GRADER_VERIFY_STATUS_SUCCESS
  };
};

export const rejectGraderVerifyStatusFail = () => {
  return {
    type: actionTypes.REJECT_GRADER_VERIFY_STATUS_FAIL
  };
};


export const loadGraderDetail = (id, data) => {
  
  return {
    type: actionTypes.LOAD_GRADER_DETAIL,
    id,
    data
  };
};

export const loadGraderDetailSuccess = data => {
  
  return {
    type: actionTypes.LOAD_GRADER_DETAIL_SUCCESS,
    data
  };
};

export const loadGraderDetailFail = () => {
  return {
    type: actionTypes.LOAD_GRADER_DETAIL_FAIL
  };
};


export const uploadGraderDoc = (id, data) => {
  
  return {
    type: actionTypes.UPLOAD_GRADER_DOC,
    id,
    data
  };
};

export const uploadGraderDocSuccess = () => {
  
  return {
    type: actionTypes.UPLOAD_GRADER_DOC_SUCCESS
  };
};

export const uploadGraderDocFail = () => {
  return {
    type: actionTypes.UPLOAD_GRADER_DOC_FAIL
  };
};


export const loadGraderDocumentationCount = (data) => {
  
  return {
    type: actionTypes.GRADER_DOCUMENTATION_COUNT,
    data
  };
};

export const loadGraderDocumentationCountSuccess = (data) => {
  
  return {
    type: actionTypes.GRADER_DOCUMENTATION_COUNT_SUCCESS,
    data
  };
};

export const loadGraderDocumentationCountFail = () => {
  return {
    type: actionTypes.GRADER_DOCUMENTATION_COUNT_FAIL
  };
};

export const loadGraderDocumentationList = (data) => {
  
  return {
    type: actionTypes.GRADER_DOCUMENTATION_LIST,
    data
  };
};

export const loadGraderDocumentationListSuccess = (data) => {
  
  return {
    type: actionTypes.GRADER_DOCUMENTATION_LIST_SUCCESS,
    data
  };
};

export const loadGraderDocumentationListFail = () => {
  return {
    type: actionTypes.GRADER_DOCUMENTATION_LIST_FAIL
  };
};

export const resetGraderDocumentationList = () => {
  return {
    type: actionTypes.RESET_GRADER_DOCUMENTATION_LIST
  };
};

export const approveGraderDocumentation = (data) => {
  
  return {
    type: actionTypes.APPROVE_GRADER_DOCUMENTATION,
    data
  };
};

export const approveGraderDocumentationSuccess = (data) => {
  
  return {
    type: actionTypes.APPROVE_GRADER_DOCUMENTATION_SUCCESS,
    data
  };
};

export const approveGraderDocumentationFail = () => {
  return {
    type: actionTypes.APPROVE_GRADER_DOCUMENTATION_FAIL
  };
};


export const rejectGraderDocumentation = (data) => {
  
  return {
    type: actionTypes.REJECT_GRADER_DOCUMENTATION,
    data
  };
};

export const rejectGraderDocumentationSuccess = (data) => {
  
  return {
    type: actionTypes.REJECT_GRADER_DOCUMENTATION_SUCCESS,
    data
  };
};

export const rejectGraderDocumentationFail = () => {
  return {
    type: actionTypes.REJECT_GRADER_DOCUMENTATION_FAIL
  };
};


/* ===== GRADER ZONE ===== */

export const loadGraderZoneList = data => {
  
  return {
    type: actionTypes.LOAD_GRADER_ZONE_LIST,
    data
  };
};

export const loadGraderZoneListSuccess = data => {
  return {
    type: actionTypes.LOAD_GRADER_ZONE_LIST_SUCCESS,
    data
  };
};

export const loadGraderZoneListFail = () => {
  return {
    type: actionTypes.LOAD_GRADER_ZONE_LIST_FAIL
  };
};



export const graderZoneCount = (data) => {
  
  return {
    type: actionTypes.GRADER_ZONE_COUNT,
    data
  };
};

export const graderZoneCountSuccess = data => {
  return {
    type: actionTypes.GRADER_ZONE_COUNT_SUCCESS,
    data
  };
};

export const graderZoneCountFail = () => {
  return {
    type: actionTypes.GRADER_ZONE_COUNT_FAIL
  };
};



export const expressPackingHistoryList = data => {
  
  return {
    type: actionTypes.LOAD_EXPRESS_PACKING_HISTORY_LIST,
    data
  };
};

export const expressPackingHistoryListSuccess = data => {
  return {
    type: actionTypes.LOAD_EXPRESS_PACKING_HISTORY_LIST_SUCCESS,
    data
  };
};

export const expressPackingHistoryListFail = () => {
  return {
    type: actionTypes.LOAD_EXPRESS_PACKING_HISTORY_LIST_FAIL
  };
};



export const expressPackingListDetail = (id, data) => {
  
  return {
    type: actionTypes.LOAD_EXPRESS_PACKING_LIST_DETAIL,
    id,
    data
  };
};

export const expressPackingListDetailSuccess = data => {
  return {
    type: actionTypes.LOAD_EXPRESS_PACKING_LIST_DETAIL_SUCCESS,
    data
  };
};

export const expressPackingListDetailFail = () => {
  return {
    type: actionTypes.LOAD_EXPRESS_PACKING_LIST_DETAIL_FAIL
  };
};



export const getExpressPackingListPDF = (id, data) => {
  
  return {
    type: actionTypes.GET_EXPRESS_PACKING_LIST_PDF,
    id,
    data
  };
};

export const getExpressPackingListPDFSuccess = data => {
  return {
    type: actionTypes.GET_EXPRESS_PACKING_LIST_PDF_SUCCESS,
    data
  };
};

export const getExpressPackingListPDFFail = () => {
  return {
    type: actionTypes.GET_EXPRESS_PACKING_LIST_PDF_FAIL
  };
};


export const getExpressPackingListStack = (data) => {
  
  return {
    type: actionTypes.GET_EXPRESS_PACKING_LIST_STACK,
    data
  };
};

export const getExpressPackingListStackSuccess = data => {
  return {
    type: actionTypes.GET_EXPRESS_PACKING_LIST_STACK_SUCCESS,
    data
  };
};

export const getExpressPackingListStackFail = () => {
  return {
    type: actionTypes.GET_EXPRESS_PACKING_LIST_STACK_FAIL
  };
};


export const recyclingPackingHistoryList = data => {
  
  return {
    type: actionTypes.LOAD_RECYCLING_PACKING_HISTORY_LIST,
    data
  };
};

export const recyclingPackingHistoryListSuccess = data => {
  return {
    type: actionTypes.LOAD_RECYCLING_PACKING_HISTORY_LIST_SUCCESS,
    data
  };
};

export const recyclingPackingHistoryListFail = () => {
  return {
    type: actionTypes.LOAD_RECYCLING_PACKING_HISTORY_LIST_FAIL
  };
};



export const recyclingPackingListDetail = (id, data) => {
  
  return {
    type: actionTypes.LOAD_RECYCLING_PACKING_LIST_DETAIL,
    id,
    data
  };
};

export const recyclingPackingListDetailSuccess = data => {
  
  return {
    type: actionTypes.LOAD_RECYCLING_PACKING_LIST_DETAIL_SUCCESS,
    data
  };
};

export const recyclingPackingListDetailFail = () => {
  return {
    type: actionTypes.LOAD_RECYCLING_PACKING_LIST_DETAIL_FAIL
  };
};


export const getRecyclingPackingListPDF = (id, data) => {
  
  return {
    type: actionTypes.GET_RECYCLING_PACKING_LIST_PDF,
    id,
    data
  };
};

export const getRecyclingPackingListPDFSuccess = data => {
  
  return {
    type: actionTypes.GET_RECYCLING_PACKING_LIST_PDF_SUCCESS,
    data
  };
};

export const getRecyclingPackingListPDFFail = () => {
  return {
    type: actionTypes.GET_RECYCLING_PACKING_LIST_PDF_FAIL
  };
};


export const getRecyclingPackingListStack = (data) => {
  
  return {
    type: actionTypes.GET_RECYCLING_PACKING_LIST_STACK,
    data
  };
};

export const getRecyclingPackingListStackSuccess = data => {
  return {
    type: actionTypes.GET_RECYCLING_PACKING_LIST_STACK_SUCCESS,
    data
  };
};

export const getRecyclingPackingListStackFail = () => {
  return {
    type: actionTypes.GET_RECYCLING_PACKING_LIST_STACK_FAIL
  };
};
/* ===== GRADER ZONE ===== */