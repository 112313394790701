import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
import Helper from "../../helpers/helpers";

export function* loadScheduledWasteList() {
  
  yield takeEvery(actionTypes.LOAD_SCHEDULED_WASTE_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SCHEDULEDWASTELIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadScheduledWasteList(action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.loadScheduledWasteListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadScheduledWasteListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadScheduledWasteListFail());
    }
  });
}


/** WASTE CATEGORY */
export function* addScheduledWasteCategory() {
  yield takeEvery(actionTypes.SCHEDULED_WASTE_CATEGORY_CREATE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ADDSCHEDULEDWASTECATEGORY");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.addScheduledWasteCategory(action.data);
      if (response.data.success) {
        yield put(actions.addScheduledWasteCategorySuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.addScheduledWasteCategoryFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.addScheduledWasteCategoryFail());
    }
  });
}

export function* editScheduledWasteCategory() {
  yield takeEvery(actionTypes.SCHEDULED_WASTE_CATEGORY_UPDATE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDSCHEDULEDWASTECATEGORY");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editScheduledWasteCategory(action.id,action.data);
      if (response.data.success) {
        yield put(actions.editScheduledWasteCategorySuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.editScheduledWasteCategoryFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editScheduledWasteCategoryFail());
    }
  });
}

export function* deleteScheduledWasteCategory() {
  yield takeEvery(actionTypes.SCHEDULED_WASTE_CATEGORY_DELETE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DELSCHEDULEDWASTECATEGORY");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.deleteScheduledWasteCategory(action.id, action.data);
      if (response.data.success) {
        yield put(actions.deleteScheduledWasteCategorySuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.deleteScheduledWasteCategoryFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.deleteScheduledWasteCategoryFail());
    }
  });
}


export function* scheduledWasteCategorySetItem() {
  yield takeEvery(actionTypes.SCHEDULED_WASTE_CATEGORY_SET_ITEM, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETSCHEDULEDWASTECATEGORYITEM");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.scheduledWasteCategorySetItem(action.id, action.data);
      if (response.data.success) {
        yield put(actions.scheduledWasteCategorySetItemSuccess());
        yield put(actions.toastSuccess(response.data.message));        
      } else {
        alert(response.data.message);
        yield put(actions.scheduledWasteCategorySetItemFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.scheduledWasteCategorySetItemFail());
    }
  });
}

/** WASTE CATEGORY END*/


/** WASTE PRICE */


export function* loadScheduledWastePriceEffectiveDateList() {
  
  yield takeEvery(actionTypes.SCHEDULED_WASTE_PRICE_EFFECTIVE_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SCHEDULEDWASTEPRICEEFFECTIVELIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadScheduledWastePriceEffectiveDateList(action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.loadScheduledWastePriceEffectiveDateListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadScheduledWastePriceEffectiveDateListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadScheduledWastePriceEffectiveDateListFail());
    }
  });
}


export function* loadScheduledWastePriceList() {
  
  yield takeEvery(actionTypes.SCHEDULED_WASTE_PRICE_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SCHEDULEDWASTEPRICELIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadScheduledWastePriceList(action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.loadScheduledWastePriceListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadScheduledWastePriceListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadScheduledWastePriceListFail());
    }
  });
}


export function* loadScheduledWastePricePDF() {
  
  yield takeEvery(actionTypes.SCHEDULED_WASTE_PRICE_LIST_PDF, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SCHEDULEDWASTEPRICEPDF");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadScheduledWastePricePDF(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadScheduledWastePricePDFSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadScheduledWastePricePDFFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadScheduledWastePricePDFFail());
    }
  });
}

export function* scheduledWastePriceSet() {
  yield takeEvery(actionTypes.SCHEDULED_WASTE_PRICE_SET, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETSCHEDULEDWASTEPRICE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.scheduledWastePriceSet(action.data);
      if (response.data.success) {
        yield put(actions.scheduledWastePriceSetSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.scheduledWastePriceSetFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.scheduledWastePriceSetFail());
    }
  });
}

/** WASTE CATEGORY END*/


/** WASTE LORRY PRICE */
export function* loadScheduledWasteLorryPriceEffectiveDateList() {
  
  yield takeEvery(actionTypes.SCHEDULED_WASTE_LORRY_PRICE_EFFECTIVE_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SCHEDULEDWASTELORRYPRICEEFFECTIVELIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadScheduledWasteLorryPriceEffectiveDateList(action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.loadScheduledWasteLorryPriceEffectiveDateListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadScheduledWasteLorryPriceEffectiveDateListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadScheduledWasteLorryPriceEffectiveDateListFail());
    }
  });
}


export function* loadScheduledWasteLorryPriceList() {
  
  yield takeEvery(actionTypes.SCHEDULED_WASTE_LORRY_PRICE_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SCHEDULEDWASTELORRYPRICELIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadScheduledWasteLorryPriceList(action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.loadScheduledWasteLorryPriceListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadScheduledWasteLorryPriceListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadScheduledWasteLorryPriceListFail());
    }
  });
}


export function* loadScheduledWasteLorryPricePDF() {
  
  yield takeEvery(actionTypes.SCHEDULED_WASTE_LORRY_PRICE_LIST_PDF, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SCHEDULEDWASTELORRYPRICEPDF");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadScheduledWasteLorryPricePDF(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadScheduledWasteLorryPricePDFSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadScheduledWasteLorryPricePDFFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadScheduledWasteLorryPricePDFFail());
    }
  });
}

export function* scheduledWasteLorryPriceSet() {
  yield takeEvery(actionTypes.SCHEDULED_WASTE_LORRY_PRICE_SET, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETSCHEDULEDWASTELORRYPRICE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.scheduledWasteLorryPriceSet(action.data);
      if (response.data.success) {
        yield put(actions.scheduledWasteLorryPriceSetSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.scheduledWasteLorryPriceSetFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.scheduledWasteLorryPriceSetFail());
    }
  });
}

export function* scheduledWasteProcessCostSet() {
  yield takeEvery(actionTypes.SCHEDULED_PROCESS_COST_SET, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETSCHEDULEDPROCESSCOST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.scheduledWasteProcessCostSet(action.id, action.data);
      if (response.data.success) {
        yield put(actions.scheduledWasteProcessCostSetSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.scheduledWasteProcessCostSetFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.scheduledWasteProcessCostSetFail());
    }
  });
}


/** WASTE LORRY PRICE END*/


/** RECOVERY COMPANY */
export function* loadRecoveryCompanyList() {
  
  yield takeEvery(actionTypes.LOAD_RECOVERY_COMPANY_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECOVERYCOMPANYLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadRecoveryCompanyList(action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.loadRecoveryCompanyListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadRecoveryCompanyListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadRecoveryCompanyListFail());
    }
  });
}


export function* loadRecoveryCompanyDetail() {
  
  yield takeEvery(actionTypes.LOAD_RECOVERY_COMPANY_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECOVERYCOMPANYDETAIL");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadRecoveryCompanyDetail(action.id, action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.loadRecoveryCompanyDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadRecoveryCompanyDetailFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadRecoveryCompanyDetailFail());
    }
  });
}

export function* addRecoveryCompany() {
  
  yield takeEvery(actionTypes.RECOVERY_COMPANY_CREATE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ADDRECOVERYCOMPANY");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.addRecoveryCompany(action.data);
      if (response.data.success) {
   
        yield put(actions.addRecoveryCompanySuccess());
        yield put(actions.toastSuccess(response.data.message));    
      } else {
        alert(response.data.message);
        yield put(actions.addRecoveryCompanyFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.addRecoveryCompanyFail());
    }
  });
}


export function* editRecoveryCompanyDetail() {
  
  yield takeEvery(actionTypes.RECOVERY_COMPANY_UPDATE_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDRECOVERYCOMPANYDETAIL");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editRecoveryCompanyDetail(action.id, action.data);
      if (response.data.success) {
   
        yield put(actions.editRecoveryCompanyDetailSuccess());
        yield put(actions.toastSuccess(response.data.message));    
      } else {
        alert(response.data.message);
        yield put(actions.editRecoveryCompanyDetailFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editRecoveryCompanyDetailFail());
    }
  });
}



export function* editRecoveryCompanyLicense() {
  
  yield takeEvery(actionTypes.RECOVERY_COMPANY_UPDATE_LICENSE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDRECOVERYCOMPANYLICENSE");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editRecoveryCompanyLicense(action.id, action.data);
      if (response.data.success) {
   
        yield put(actions.editRecoveryCompanyLicenseSuccess());
        yield put(actions.toastSuccess(response.data.message));    
      } else {
        alert(response.data.message);
        yield put(actions.editRecoveryCompanyLicenseFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editRecoveryCompanyLicenseFail());
    }
  });
}

export function* deleteRecoveryCompany() {
  
  yield takeEvery(actionTypes.RECOVERY_COMPANY_DELETE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DELRECOVERYCOMPANY");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.deleteRecoveryCompany(action.id, action.data);
      if (response.data.success) {
   
        yield put(actions.deleteRecoveryCompanySuccess());
        yield put(actions.toastSuccess(response.data.message));    
      } else {
        alert(response.data.message);
        yield put(actions.deleteRecoveryCompanyFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.deleteRecoveryCompanyFail());
    }
  });
}
/** RECOVERY COMPANY END*/

/* ===== SCHEDULED DOE REGISTRATION ===== */
export function* loadDOERegistrationList() {
  
  yield takeEvery(actionTypes.LOAD_DOE_REGISTRATION_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let status = (action.data.status) ? action.data.status : ""

    let track_no = Helper.generateTrackNo(`DOEREGISTRATIONLIST${status}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDOERegistrationList(action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.loadDOERegistrationListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDOERegistrationListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDOERegistrationListFail());
    }
  });
}

export function* DOERegistrationCount() {
  
  yield takeEvery(actionTypes.LOAD_DOE_REGISTRATION_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DOEREGISTRATIONCOUNT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.DOERegistrationCount(action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.DOERegistrationCountSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.DOERegistrationCountFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.DOERegistrationCountFail());
    }
  });
}

export function* loadDOERegistrationDetail() {
  
  yield takeEvery(actionTypes.LOAD_DOE_REGISTRATION_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DOEREGISTRATIONDETAIL");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDOERegistrationDetail(action.id, action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.loadDOERegistrationDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDOERegistrationDetailFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDOERegistrationDetailFail());
    }
  });
}

export function* DOERegistrationApprove() {
  
  yield takeEvery(actionTypes.DOE_REGISTRATION_APPROVE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("APPRDOEREGISTRATION");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.DOERegistrationApprove(action.id, action.data);
      if (response.data.success) {
   
        yield put(actions.DOERegistrationApproveSuccess());
        yield put(actions.toastSuccess(response.data.message));    
      } else {
        alert(response.data.message);
        yield put(actions.DOERegistrationApproveFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.DOERegistrationApproveFail());
    }
  });
}

export function* DOERegistrationReject() {
  
  yield takeEvery(actionTypes.DOE_REGISTRATION_REJECT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REJECTDOEREGISTRATION");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.DOERegistrationReject(action.id, action.data);
      if (response.data.success) {
   
        yield put(actions.DOERegistrationRejectSuccess());
        yield put(actions.toastSuccess(response.data.message));    
      } else {
        alert(response.data.message);
        yield put(actions.DOERegistrationRejectFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.DOERegistrationRejectFail());
    }
  });
}
/* ===== END SCHEDULED DOE REGISTRATION ===== */

/* ===== SCHEDULED WASTE CONTAINER ===== */
export function* loadScheduledWasteContainerList() {
  
  yield takeEvery(actionTypes.LOAD_SCHEDULED_WASTE_CONTAINER_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SWCONTAINERLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadScheduledWasteContainerList(action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.loadScheduledWasteContainerListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadScheduledWasteContainerListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadScheduledWasteContainerListFail());
    }
  });
}


export function* createScheduledWasteContainer() {
  
  yield takeEvery(actionTypes.CREATE_SCHEDULED_WASTE_CONTAINER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ADDSWCONTAINER");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.createScheduledWasteContainer(action.data);
      if (response.data.success) {
   
        yield put(actions.createScheduledWasteContainerSuccess());
        yield put(actions.toastSuccess(response.data.message));    
      } else {
        alert(response.data.message);
        yield put(actions.createScheduledWasteContainerFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.createScheduledWasteContainerFail());
    }
  });
}


export function* updateScheduledWasteContainer() {
  
  yield takeEvery(actionTypes.UPDATE_SCHEDULED_WASTE_CONTAINER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDSWCONTAINER");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.updateScheduledWasteContainer(action.id, action.data);
      if (response.data.success) {
   
        yield put(actions.updateScheduledWasteContainerSuccess());
        yield put(actions.toastSuccess(response.data.message));    
      } else {
        alert(response.data.message);
        yield put(actions.updateScheduledWasteContainerFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.updateScheduledWasteContainerFail());
    }
  });
}

export function* removeScheduledWasteContainer() {
  
  yield takeEvery(actionTypes.REMOVE_SCHEDULED_WASTE_CONTAINER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DELSWCONTAINER");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.removeScheduledWasteContainer(action.id, action.data);
      if (response.data.success) {
   
        yield put(actions.removeScheduledWasteContainerSuccess());
        yield put(actions.toastSuccess(response.data.message));    
      } else {
        alert(response.data.message);
        yield put(actions.removeScheduledWasteContainerFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.removeScheduledWasteContainerFail());
    }
  });
}
/* ===== END SCHEDULED WASTE CONTAINER ===== */

export default function* scheduledWasteSaga() {
  
  yield all([fork(loadScheduledWasteList)]);
  
  yield all([fork(addScheduledWasteCategory)]);  
  yield all([fork(editScheduledWasteCategory)]);  
  yield all([fork(deleteScheduledWasteCategory)]);  
  yield all([fork(scheduledWasteCategorySetItem)]);  

  yield all([fork(loadScheduledWastePriceEffectiveDateList)]);
  yield all([fork(loadScheduledWastePriceList)]);
  yield all([fork(loadScheduledWastePricePDF)]);  
  yield all([fork(scheduledWastePriceSet)]);

  yield all([fork(loadScheduledWasteLorryPriceEffectiveDateList)]);
  yield all([fork(loadScheduledWasteLorryPriceList)]);
  yield all([fork(loadScheduledWasteLorryPricePDF)]);  
  yield all([fork(scheduledWasteLorryPriceSet)]);
  yield all([fork(scheduledWasteProcessCostSet)]);
  
  
  yield all([fork(loadRecoveryCompanyList)]);
  yield all([fork(loadRecoveryCompanyDetail)]);
  yield all([fork(addRecoveryCompany)]);
  yield all([fork(editRecoveryCompanyDetail)]);
  yield all([fork(editRecoveryCompanyLicense)]);
  yield all([fork(deleteRecoveryCompany)]);
  
  yield all([fork(loadDOERegistrationList)]);
  yield all([fork(DOERegistrationCount)]);
  yield all([fork(loadDOERegistrationDetail)]);
  yield all([fork(DOERegistrationApprove)]);
  yield all([fork(DOERegistrationReject)]);

  yield all([fork(loadScheduledWasteContainerList)]);
  yield all([fork(createScheduledWasteContainer)]);
  yield all([fork(updateScheduledWasteContainer)]);
  yield all([fork(removeScheduledWasteContainer)]);
  
}
