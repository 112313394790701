import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/admin";
};


export const adminChangePassword = data => {
  const url = `${baseURL()}/changePass`;
  return axios.post(url, data);
};

export const adminUpdateDetail = data => {
  const url = `${baseURL()}/detail/update`;
  return axios.post(url, data);
};

export const loadAdminList = data => {
  const url = `${baseURL()}/load`;
  return axios.post(url, data);
};
export const loadAdminDetail = (id, data) => {
  const url = `${baseURL()}/detail/${id}`;
  return axios.post(url,data);
};

export const createAdmin = (data) => {
  const url = `${baseURL()}/create`;
  return axios.post(url, data);
};

export const editAdminDetail = (id, data) => {
  const url = `${baseURL()}/update/${id}`;
  return axios.post(url, data);
};

export const editAdminStatus = (id, data) => {
  const url = `${baseURL()}/update/${id}/status`;
  return axios.post(url, data);
};

export const deleteAdmin = (id, data) => {
  const url = `${baseURL()}/remove/${id}`;
  return axios.post(url, data);
};

export const resetAdminPassword = (id, data) => {
  const url = `${baseURL()}/resetPass/${id}`;
  return axios.post(url, data);
};

export const setAdminEmergencyContact = (id, data) => {
  const url = `${baseURL()}/emergency/set/${id}`;
  return axios.post(url, data);
};




/* ===== ADMIN PRIVILEGES ===== */


export const loadAdminPrivileges = (data) => {
  const url = `${baseURL()}/privileges/load`;
  return axios.post(url, data);
};

export const createAdminRole = (data) => {
  const url = `${baseURL()}/privileges/role/create`;
  return axios.post(url, data);
};

export const deleteAdminRole = (id,data) => {
  const url = `${baseURL()}/privileges/role/remove/${id}`;
  return axios.post(url, data);
};

export const updateAdminRole = (id,data) => {
  const url = `${baseURL()}/privileges/role/update/${id}`;
  return axios.post(url, data);
};

export const setAdminPrivileges = (data) => {
  const url = `${baseURL()}/privileges/set`;
  return axios.post(url, data);
};


export const loadAdminRole = (data) => {
  const url = `${baseURL()}/privileges/role/load`;
  return axios.post(url, data);
};
