import * as actionTypes from "./actionTypes";


export const updatePendingCount = (key,data) => {
  
  return {
    type: actionTypes.UPDATE_PENDING_COUNT,
    key,
    data
  };
};

export const updatePendingCountSuccess = (key,data) => {
  return {
    type: actionTypes.UPDATE_PENDING_COUNT_SUCCESS,
    key,
    data
  };
};

export const updatePendingCountFail = () => {
  return {
    type: actionTypes.UPDATE_PENDING_COUNT_FAIL
  };
};


export const updateLatestTime = data => {
  return {
    type: actionTypes.UPDATE_LATEST_TIME,
    data
  };
};

export const updateLatestTimeSuccess = data => {
  return {
    type: actionTypes.UPDATE_LATEST_TIME_SUCCESS,
    data
  };
};

export const updateLatestTimeFail = () => {
  return {
    type: actionTypes.UPDATE_LATEST_TIME_FAIL
  };
};




export const updateOrderLog = data => {
  return {
    type: actionTypes.UPDATE_ORDER_LOG,
    data
  };
};

export const updateOrderLogSuccess = data => {
  return {
    type: actionTypes.UPDATE_ORDER_LOG_SUCCESS,
    data
  };
};

export const updateOrderLogFail = () => {
  return {
    type: actionTypes.UPDATE_ORDER_LOG_FAIL
  };
};



export const updateNewOrderCount = data => {
  return {
    type: actionTypes.UPDATE_NEW_ORDER_COUNT,
    data
  };
};

export const updateNewOrderCountSuccess = data => {
  return {
    type: actionTypes.UPDATE_NEW_ORDER_COUNT_SUCCESS,
    data
  };
};

export const updateNewOrderCountFail = () => {
  return {
    type: actionTypes.UPDATE_NEW_ORDER_COUNT_FAIL
  };
};



export const updateNoDriverAcceptCount = data => {
    return {
        type: actionTypes.UPDATE_NO_DRIVER_ACCEPT_COUNT,
        data
    };
};

export const updateNoDriverAcceptCountSuccess = data => {
    return {
        type: actionTypes.UPDATE_NO_DRIVER_ACCEPT_COUNT_SUCCESS,
        data
    };
};

export const updateNoDriverAcceptCountFail = () => {
    return {
        type: actionTypes.UPDATE_NO_DRIVER_ACCEPT_COUNT_FAIL
    };
};




export const updateTripLog = data => {
  return {
    type: actionTypes.UPDATE_TRIP_LOG,
    data
  };
};

export const updateTripLogSuccess = data => {
  return {
    type: actionTypes.UPDATE_TRIP_LOG_SUCCESS,
    data
  };
};

export const updateTripLogFail = () => {
  return {
    type: actionTypes.UPDATE_TRIP_LOG_FAIL
  };
};



export const updateUpcomingTrip = data => {
  return {
    type: actionTypes.UPDATE_UPCOMING_TRIP,
    data
  };
};

export const updateUpcomingTripSuccess = data => {
  return {
    type: actionTypes.UPDATE_UPCOMING_TRIP_SUCCESS,
    data
  };
};

export const updateUpcomingTripFail = () => {
  return {
    type: actionTypes.UPDATE_UPCOMING_TRIP_FAIL
  };
};

  
export const updateDriverNoComingCount = data => {
    return {
        type: actionTypes.UPDATE_DRIVER_NO_COMING_COUNT,
        data
    };
};

export const updateDriverNoComingCountSuccess = data => {
    return {
        type: actionTypes.UPDATE_DRIVER_NO_COMING_COUNT_SUCCESS,
        data
    };
};

export const updateDriverNoComingCountFail = () => {
    return {
        type: actionTypes.UPDATE_DRIVER_NO_COMING_COUNT_FAIL
    };
};

