import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
import { push } from "react-router-redux";
import Helper from "../../helpers/helpers";

export function* loadDumpsterPriceList() {
  
  yield takeEvery(actionTypes.LOAD_DUMPSTER_PRICE_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DUMPPRICELIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDumpsterPriceList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDumpsterPriceListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDumpsterPriceListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDumpsterPriceListFail());
    }
  });
}

export function* loadDumpsterPriceEffectiveDateList() {
  
  yield takeEvery(actionTypes.LOAD_DUMPSTER_PRICE_EFFECTIVE_DATE_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DUMPPRICEEFFECTIVE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDumpsterPriceEffectiveDateList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDumpsterPriceEffectiveDateListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDumpsterPriceEffectiveDateListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDumpsterPriceEffectiveDateListFail());
    }
  });
}

export function* getDumpsterPricePDF() {
  
  yield takeEvery(actionTypes.GET_DUMPSTER_PRICE_PDF, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DUMPPRICEPDF");
    action.data.track_no = track_no

    yield put(actions.enableLoading());
    try {
      const response = yield API.getDumpsterPricePDF(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.getDumpsterPricePDFSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getDumpsterPricePDFFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getDumpsterPricePDFFail());
    }
  });
}

export function* setDumpsterPrice() {
  yield takeEvery(actionTypes.SET_DUMPSTER_PRICE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETDUMPPRICE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());
    
    try {
      const response = yield API.setDumpsterPrice(action.data);
      if (response.data.success) {
        yield put(actions.setDumpsterPriceSuccess());
        yield put(actions.toastSuccess("Dumpster Price Set Successfully!"));
                  
      } else {
        alert(response.data.message);
        yield put(actions.setDumpsterPriceFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.setDumpsterPriceFail());
    }
  });
}


export function* addDumpsterPrice() {
  yield takeEvery(actionTypes.ADD_DUMPSTER_PRICE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ADDDUMPPRICE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.addDumpsterPrice(action.data);
      if (response.data.success) {
        yield put(actions.addDumpsterPriceSuccess());
        yield put(
          actions.toastSuccess("New dumpster price created successfully!")
        );

        if(action.data.filter_dumpster)
          yield put(push(`/dumpster/waste/price/${action.data.filter_dumpster}`));
        else
          yield put(push("/dumpster/waste/price"));
      } else {
        alert(response.data.message);
        yield put(actions.addDumpsterPriceFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.addDumpsterPriceFail());
    }
  });
}

export function* editDumpsterPrice() {
  yield takeEvery(actionTypes.EDIT_DUMPSTER_PRICE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITDUMPPRICE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());
    
    try {
      const response = yield API.editDumpsterPrice(action.id, action.data);
      if (response.data.success) {
        yield put(actions.editDumpsterPriceSuccess());
        yield put(actions.toastSuccess("Dumpster price updated successfully!"));
        
        // if(action.data.filter_dumpster)
        //   yield put(push(`/dumpster/price/${action.data.filter_dumpster}`));
        // else
        //   yield put(push("/dumpster/price"));
          
      } else {
        alert(response.data.message);
        yield put(actions.editDumpsterPriceFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editDumpsterPriceFail());
    }
  });
}

export function* loadDumpsterPriceRecyclingList() {
  
  yield takeEvery(actionTypes.LOAD_DUMPSTER_PRICE_RECYCLING_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DUMPPRICERECYCLING");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDumpsterPriceRecyclingList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDumpsterPriceRecyclingListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDumpsterPriceRecyclingListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDumpsterPriceRecyclingListFail());
    }
  });
}

export function* loadDumpsterPriceRecyclingEffectiveDateList() {
  
  yield takeEvery(actionTypes.LOAD_DUMPSTER_PRICE_RECYCLING_EFFECTIVE_DATE_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DUMPPRICERECYCLINGEFFECTIVE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDumpsterPriceRecyclingEffectiveDateList(action.data);
      if (response.data.success && response.data.data) {
       
        yield put(actions.loadDumpsterPriceRecyclingEffectiveDateListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDumpsterPriceRecyclingEffectiveDateListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDumpsterPriceRecyclingEffectiveDateListFail());
    }
  });
}

export function* getDumpsterPriceRecyclingPDF() {
  
  yield takeEvery(actionTypes.GET_DUMPSTER_PRICE_RECYCLING_PDF, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DUMPPRICERECYCLINGPDF");
    action.data.track_no = track_no

    yield put(actions.enableLoading());
    try {
      const response = yield API.getDumpsterPriceRecyclingPDF(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.getDumpsterPriceRecyclingPDFSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getDumpsterPriceRecyclingPDFFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getDumpsterPriceRecyclingPDFFail());
    }
  });
}

export function* setDumpsterPriceRecycling() {
  yield takeEvery(actionTypes.SET_DUMPSTER_PRICE_RECYCLING, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETDUMPPRICERECYCLING");
    action.data.track_no = track_no

    yield put(actions.enableLoading());
    
    try {
      const response = yield API.setDumpsterPriceRecycling(action.data);
      if (response.data.success) {
        yield put(actions.setDumpsterPriceRecyclingSuccess());
        yield put(actions.toastSuccess("Dumpster Price Set Successfully!"));
                  
      } else {
        alert(response.data.message);
        yield put(actions.setDumpsterPriceRecyclingFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.setDumpsterPriceRecyclingFail());
    }
  });
}

export default function* dumpsterPriceSaga() {
  yield all([fork(loadDumpsterPriceEffectiveDateList)]);
  yield all([fork(loadDumpsterPriceList)]);
  yield all([fork(getDumpsterPricePDF)]);
  yield all([fork(setDumpsterPrice)]);
  yield all([fork(addDumpsterPrice)]);
  yield all([fork(editDumpsterPrice)]);
  yield all([fork(loadDumpsterPriceRecyclingEffectiveDateList)]);
  yield all([fork(loadDumpsterPriceRecyclingList)]);
  yield all([fork(getDumpsterPriceRecyclingPDF)]);
  yield all([fork(setDumpsterPriceRecycling)]);
  
}
