import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  recyclingList: [],
  recyclingListAll: [],
  recyclingDetail: {},
  recyclingMixList: [],
  isLoadingRecycling: false,
  isLoadingRecyclingMix: false,
  isRecyclingAddSuccess: false,
  isRecyclingEditSuccess: false,
  isRecyclingDragSuccess: false,
  isRecyclingPriceControlSetSuccess: false,
  isRecyclingPriceControlSetFail: false,
  recyclingPriceControlList: [],
  isRecyclingRemoveSuccess: false,
  recyclingRemoveConfirm: null,
  recyclingPricePDF: "",
  recyclingPricePostPDF: "",
  recyclingEffectiveDateList: {},
  expressRecyclingSalesPriceUpdateHistoryList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  expressRecyclingSalesPriceUpdateHistoryCount: {},
  expressRecyclingSalesPriceUpdateHistoryDetail: [],
};

export default function expressReducer(state = initState, action) {

  switch (action.type) {

    
    case actionTypes.LOAD_EXPRESS_RECYCLING_LIST: {
      
      return {
        ...state,
        recyclingList:[],
        isLoadingRecycling: true,
      };
    }

    case actionTypes.LOAD_EXPRESS_RECYCLING_LIST_SUCCESS: {
  
      return {
        ...state,
        recyclingList: action.data,
        isLoadingRecycling: false,
      };
    }

    case actionTypes.LOAD_EXPRESS_RECYCLING_LIST_FAIL: {
      return {
        ...state,
        isLoadingRecycling: false,
      };
    }
    
    
    case actionTypes.LOAD_EXPRESS_RECYCLING_LIST_ALL: {
      
      return {
        ...state,
        recyclingListAll: []
      };
    }

    case actionTypes.LOAD_EXPRESS_RECYCLING_LIST_ALL_SUCCESS: {
  
      return {
        ...state,
        recyclingListAll: action.data
      };
    }

    case actionTypes.LOAD_EXPRESS_RECYCLING_LIST_ALL_FAIL: {
      return {
        ...state,
      };
    }
    
    case actionTypes.LOAD_EXPRESS_RECYCLING_DETAIL: {
      
      return {
        ...state,
        recyclingDetail:{},
      };
    }

    case actionTypes.LOAD_EXPRESS_RECYCLING_DETAIL_SUCCESS: {
  
      return {
        ...state,
        recyclingDetail: action.data,
      };
    }

    case actionTypes.LOAD_EXPRESS_RECYCLING_DETAIL_FAIL: {
      return {
        ...state,
      };
    }

    
    case actionTypes.LOAD_EXPRESS_RECYCLING_EFFECTIVE_DATE_LIST: {
    
      return {
        ...state,
        recyclingEffectiveDateList: {},
      };
    }

    case actionTypes.LOAD_EXPRESS_RECYCLING_EFFECTIVE_DATE_LIST_SUCCESS: {
      
      return {
        ...state,
        recyclingEffectiveDateList: action.data,
      };
    }

    case actionTypes.LOAD_EXPRESS_RECYCLING_EFFECTIVE_DATE_LIST_FAIL: {
      return {
        ...state,
        recyclingEffectiveDateList: {},
      };
    }
    
    
    case actionTypes.LOAD_EXPRESS_RECYCLING_MIX_LIST: {
      
      return {
        ...state,
        recyclingMixList:[],
        isLoadingRecyclingMix: true,
      };
    }

    case actionTypes.LOAD_EXPRESS_RECYCLING_MIX_LIST_SUCCESS: {
  
      return {
        ...state,
        recyclingMixList: action.data,
        isLoadingRecyclingMix: false,
      };
    }

    case actionTypes.LOAD_EXPRESS_RECYCLING_MIX_LIST_FAIL: {
      return {
        ...state,
        isLoadingRecyclingMix: false,
      };
    }

    

    case actionTypes.EXPRESS_RECYCLING_PRICE_CONTROL_LOAD: {
      return {
        ...state,
        recyclingPriceControlList:[],
      };
    }

    case actionTypes.EXPRESS_RECYCLING_PRICE_CONTROL_LOAD_SUCCESS: {
      return {
        ...state,
        recyclingPriceControlList: action.data,
      };
    }

    case actionTypes.EXPRESS_RECYCLING_PRICE_CONTROL_LOAD_FAIL: {
      return {
        ...state
      };
    }


    case actionTypes.EXPRESS_RECYCLING_PRICE_CONTROL_SET: {
      return {
        ...state,
        isRecyclingPriceControlSetSuccess: false,
        isRecyclingPriceControlSetFail: false
      };
    }

    case actionTypes.EXPRESS_RECYCLING_PRICE_CONTROL_SET_SUCCESS: {
      return {
        ...state,
        isRecyclingPriceControlSetSuccess: true
      };
    }

    case actionTypes.EXPRESS_RECYCLING_PRICE_CONTROL_SET_FAIL: {
      return {
        ...state,
        isRecyclingPriceControlSetFail: true
      };
    }
    

    case actionTypes.DELETE_EXPRESS_RECYCLING: {
      return {
        ...state,
        isRecyclingRemoveSuccess: false,
        recyclingRemoveConfirm: null
      };
    }

    case actionTypes.DELETE_EXPRESS_RECYCLING_SUCCESS: {
      
      return {
        ...state,
        isRecyclingRemoveSuccess: (action.data) ? false : true,
        recyclingRemoveConfirm: (action.data) ? action.data : null
      };
    }

    case actionTypes.DELETE_EXPRESS_RECYCLING_FAIL: {
      return {
        ...state,
        isRecyclingRemoveSuccess: false
      };
    }
    


    case actionTypes.SET_EXPRESS_RECYCLING_SEQUENCE: {
      return {
        ...state,
        isRecyclingDragSuccess: false
      };
    }

    case actionTypes.SET_EXPRESS_RECYCLING_SEQUENCE_SUCCESS: {
      return {
        ...state,
        isRecyclingDragSuccess: true
      };
    }

    case actionTypes.SET_EXPRESS_RECYCLING_SEQUENCE_FAIL: {
      return {
        ...state,
        isRecyclingDragSuccess: false
      };
    }
    
    
    case actionTypes.GET_EXPRESS_RECYCLING_PRICE_PDF: {
      
      return {
        ...state,
        recyclingPricePDF: ""
      };
    }

    case actionTypes.GET_EXPRESS_RECYCLING_PRICE_PDF_SUCCESS: {
      
      return {
        ...state,
        recyclingPricePDF: action.data
      };
    }

    case actionTypes.GET_EXPRESS_RECYCLING_PRICE_PDF_FAIL: {
      return {
        ...state
      };
    }
    
    
    case actionTypes.GET_EXPRESS_RECYCLING_PRICE_POST_PDF: {
      
      return {
        ...state,
        recyclingPricePostPDF: ""
      };
    }

    case actionTypes.GET_EXPRESS_RECYCLING_PRICE_POST_PDF_SUCCESS: {
      
      return {
        ...state,
        recyclingPricePostPDF: action.data
      };
    }

    case actionTypes.GET_EXPRESS_RECYCLING_PRICE_POST_PDF_FAIL: {
      return {
        ...state
      };
    }

    


    case actionTypes.EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST: {
      let expressRecyclingSalesPriceUpdateHistoryList = cloneDeep(state.expressRecyclingSalesPriceUpdateHistoryList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        expressRecyclingSalesPriceUpdateHistoryList.offset = action.data.offset;
        expressRecyclingSalesPriceUpdateHistoryList.limit = action.data.limit;
      }

      return {
        ...state,
        expressRecyclingSalesPriceUpdateHistoryList
      };
    }

    case actionTypes.EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST_SUCCESS: {
     
      let expressRecyclingSalesPriceUpdateHistoryList = cloneDeep(state.expressRecyclingSalesPriceUpdateHistoryList);
      expressRecyclingSalesPriceUpdateHistoryList.data = action.data.data;
      expressRecyclingSalesPriceUpdateHistoryList.meta = {
        page: 1 + state.expressRecyclingSalesPriceUpdateHistoryList.offset / state.expressRecyclingSalesPriceUpdateHistoryList.limit,
        pageSize: state.expressRecyclingSalesPriceUpdateHistoryList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.expressRecyclingSalesPriceUpdateHistoryList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        expressRecyclingSalesPriceUpdateHistoryList
      };
    }

    case actionTypes.EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST_FAIL: {
      return {
        ...state
      };
    }
    
    
    case actionTypes.EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT: {
      
      return {
        ...state,
        expressRecyclingSalesPriceUpdateHistoryCount: {}
      };
    }

    case actionTypes.EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT_SUCCESS: {
      
      return {
        ...state,
        expressRecyclingSalesPriceUpdateHistoryCount: action.data
      };
    }

    case actionTypes.RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT_FAIL: {
      return {
        ...state
      };
    }
    
    case actionTypes.EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL: {
      
      return {
        ...state,
        expressRecyclingSalesPriceUpdateHistoryDetail: []
      };
    }

    case actionTypes.EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL_SUCCESS: {
      
      return {
        ...state,
        expressRecyclingSalesPriceUpdateHistoryDetail: action.data
      };
    }

    case actionTypes.EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL_FAIL: {
      return {
        ...state
      };
    }

    default:
      return state;
  }
}
