import React from "react";
import ImageCellView from "../../../components/tables/imageCell";
import DeleteCell from "../../../components/tables/deleteCell";
import EditableCell from "../../../components/tables/editableCell";
import FilterDropdown from "../../../components/tables/filterDropdown";
import Tags from "../../../assets/components/Tags";
import Color from "../../Color";

const DateCell = data => <p>{data.toLocaleString()}</p>;
const ImageCell = src => <ImageCellView src={src} />;
const LinkCell = (link, href, target = "") => (
  <a href={href ? href : "#"} target={target}>
    {link}
  </a>
);
const TextCell = (text, align = null, disableClick = false) => {
  let classname = "";
  switch (align) {
    case "right":
      classname = "text-right";
      break;
    case "center":
      classname = "text-center";
      break;
    default:
      break;
  }
  return disableClick ? (
    <p
      className={classname}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      {text}
    </p>
  ) : (
    <p className={classname}>{text}</p>
  );
};
const TagCell = (text, type = "") => {
  return <Tags type={type}>{text}</Tags>;
};

const IconCell = (icon, type = null) => {
  let color = "";

  switch (type) {
    case "success":
      color = Color.Caribbean;
      break;
    case "danger":
      color = Color.Carnation;
      break;
    default:
      color = Color.Primary;
  }

  let iconStyle = {
    color: color,
    fontSize: 20
  };
  return (
    <p className={"text-center"}>
      <i className={icon} style={iconStyle} />
    </p>
  );
};
const ClickableCell = (link, align, onClick) => {
  let textAlign = "";
  switch (align) {
    case "right":
      textAlign = "text-right";
      break;
    case "center":
      textAlign = "text-center";
      break;
    default:
      break;
  }
  return (
    <p className={`clickable ${textAlign}`} onClick={onClick}>
      {link}
    </p>
  );
};

export {
  DateCell,
  ImageCell,
  LinkCell,
  TextCell,
  TagCell,
  IconCell,
  ClickableCell,
  EditableCell,
  DeleteCell,
  FilterDropdown
};
