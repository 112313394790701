import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Popover from "../../components/uielements/popover";
import IntlMessages from "../../components/utility/intlMessages";
import TopbarDropdownWrapper from "./topbarDropdown.style";
import Divider from "antd/es/divider";
import * as actions from "../../redux/actions";

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false
    };
  }
  hide() {
    this.setState({ visible: false });
  }
  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  render() {

    const { profileData } = this.props;
    
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <Link to={"/admin/profile"} className="isoDropdownLink">
          Profile
        </Link>
        <Divider />
        <a className="isoDropdownLink" onClick={this.props.onLogout} href="/#">
          <IntlMessages id="topbar.logout" />
        </a>
      </TopbarDropdownWrapper>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        arrowPointAtCenter={true}
        placement="bottomLeft"
      >
        <div className="isoImgWrapper">
          <img alt="user" src={profileData.profile_thumbnail} />
          <span className="userActivity online" />
        </div>
      </Popover>
    );
  }
}


const mapStateToProps = state => {
  const { profileData } = state.Auth;

  return { profileData };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => dispatch(actions.logoutConfirmation())
  };
};

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(TopbarUser);
