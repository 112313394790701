import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  jwtToken: null,
  jwtTokenRefresh: null,
  profileData: null,
  toggleChangePass: false,
  forgotPasswordSuccess: false,
  lastPath: null,
  newContentAvailable: false,
  FPXUnderMaintenance: false,
  versionUpdateList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
    }
  },
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        jwtToken: action.token,
        jwtTokenRefresh: action.refresh_token,
        profileData: action.profile
      };

    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        jwtToken: null,
        jwtTokenRefresh: null,
        profileData: null
      };

    // Relogin
    case actionTypes.REFRESH_JWT_TOKEN_SUCCESS:
      return {
        ...state,
        jwtToken: action.token,
        jwtTokenRefresh: action.refresh_token
      };

    // Force Logout
    case actionTypes.FORCE_LOGOUT:
      return {
        ...state
      };
    case actionTypes.FORCE_LOGOUT_SUCCESS: {
      const lastPath = window.location.pathname;
      return {
        ...state,
        jwtToken: null,
        profileData: null,
        lastPath: state.lastPath === null ? lastPath : state.lastPath
      };
    }

    case actionTypes.GET_PROFILE_SUCCESS:
      return {
        ...state,
        profileData: action.data
      };

    case actionTypes.CHANGE_PASSWORD:
      return {
        ...state,
        toggleChangePass: false
      };

    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        toggleChangePass: true
      };

      
    case actionTypes.CHECK_VERSION: {
      
      return {
        ...state,
        newContentAvailable: false,
        FPXUnderMaintenance: false
      };
    }

    case actionTypes.CHECK_VERSION_SUCCESS: {
      return {
        ...state,
        newContentAvailable: action.data.new_version_available,
        FPXUnderMaintenance: action.data.fpx_under_maintenance
      };
    }

    case actionTypes.CHECK_VERSION_FAIL: {
      return {
        ...state,
        newContentAvailable: true
      };
    }

    case actionTypes.VERSION_UPDATE_LIST: {
      let versionUpdateList = cloneDeep(state.versionUpdateList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        versionUpdateList.offset = action.data.offset;
        versionUpdateList.limit = action.data.limit;
      }

      return {
        ...state,
        versionUpdateList
      };
    }

    case actionTypes.VERSION_UPDATE_LIST_SUCCESS: {
      let versionUpdateList = cloneDeep(state.versionUpdateList);
      action.data.data.map(row => versionUpdateList.data.push(row))
      versionUpdateList.meta = {
        page: 1 + state.versionUpdateList.offset / state.versionUpdateList.limit,
        pageSize: state.versionUpdateList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.versionUpdateList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        versionUpdateList
      };
    }

    case actionTypes.VERSION_UPDATE_LIST_FAIL: {
      return {
        ...state
      };
    }

    default:
      return state;
  }
}
