import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import TopbarUser from "./topbarUser";
import TopbarWrapper from "./topbar.style";
import themes from "../../settings/themes";
import { THEME_CONFIG } from "../../settings";
import * as actions from "../../redux/actions";

const { Header } = Layout;
const customizedTheme = themes[THEME_CONFIG.theme];

class Topbar extends Component {
  render() {
    const { profile, environment } = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    const styling = {
      background: customizedTheme.backgroundColor,
      position: "fixed",
      width: "100%",
      height: 70
    };

    const textstyle = {
      marginBottom: 0,
      marginLeft: 10,
      lineHeight: 1
    };

    const server = {
      Live: "LIVE",
      Stage: "STAGING",
      Dev: "DEV"
    };

    return (
      <TopbarWrapper>
        <Header
          style={styling}
          className={
            collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"
          }
        >
          <div className="isoLeft">
            <button
              className={
                collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"
              }
              style={{ color: customizedTheme.textColor }}
              onClick={this.props.onToggleCollapsed}
            />
            <h3 style={textstyle}>SERVER: {server[environment]}</h3>
          </div>

          <ul className="isoRight">
            <li className="hidden">Welcome, {profile.profile_name}</li>
            <li
              onClick={() => this.setState({ selectedItem: "user" })}
              className="isoUser"
            >
              <TopbarUser />
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.Auth.profileData,
    environment: state.App.env,
    collapsed: state.App.collapsed,
    openDrawer: state.App.openDrawer
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onToggleCollapsed: () => dispatch(actions.toggleCollapsed())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Topbar);
