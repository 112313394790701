import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";


export function* updateLatestTime() {
  
  yield takeEvery(actionTypes.UPDATE_LATEST_TIME, function*(action) {
    //yield put(actions.enableLoading());

    try {
      yield put(
        actions.updateLatestTimeSuccess(action.data)
      );
    } catch (error) {
      console.log(error, "Update New Order Latest Time Failed.");
      yield put(actions.updateLatestTimeFail());
    }
  });
}


export function* updatePendingCount() {
  
  yield takeEvery(actionTypes.UPDATE_PENDING_COUNT, function*(action) {
   
    try {
      yield put(
        actions.updatePendingCountSuccess(action.key, action.data)
      );
    } catch (error) {
      console.log(error, "Update Pending Count Failed.");
      yield put(actions.updatePendingCountFail());
    }
  });
}

export function* updateOrderLog() {
  
  yield takeEvery(actionTypes.UPDATE_ORDER_LOG, function*(action) {
    //yield put(actions.enableLoading());

    try {
      yield put(
        actions.updateOrderLogSuccess(action.data)
      );
    } catch (error) {
      console.log(error, "Update Order Log Failed.");
      yield put(actions.updateOrderLogFail());
    }
  });
}

export function* updateNewOrderCount() {
  
  yield takeEvery(actionTypes.UPDATE_NEW_ORDER_COUNT, function*(action) {
    //yield put(actions.enableLoading());

    try {
      yield put(
        actions.updateNewOrderCountSuccess(action.data)
      );
    } catch (error) {
      console.log(error, "Update New Order Count Failed.");
      yield put(actions.updateNewOrderCountFail());
    }
  });
}

export function* updateNoDriverAcceptCount() {
  
  yield takeEvery(actionTypes.UPDATE_NO_DRIVER_ACCEPT_COUNT, function*(action) {
    //yield put(actions.enableLoading());

    try {
      yield put(
        actions.updateNoDriverAcceptCountSuccess(action.data)
      );
    } catch (error) {
      console.log(error, "Update No Driver Accept Count Failed.");
      yield put(actions.updateNoDriverAcceptCountFail());
    }
  });
}



export function* updateTripLog() {
  
  yield takeEvery(actionTypes.UPDATE_TRIP_LOG, function*(action) {
    //yield put(actions.enableLoading());

    try {
      yield put(
        actions.updateTripLogSuccess(action.data)
      );
    } catch (error) {
      console.log(error, "Update Trip Log Failed.");
      yield put(actions.updateTripLogFail());
    }
  });
}

export function* updateUpcomingTrip() {
  
  yield takeEvery(actionTypes.UPDATE_UPCOMING_TRIP, function*(action) {
    //yield put(actions.enableLoading());

    try {
      yield put(
        actions.updateUpcomingTripSuccess(action.data)
      );
    } catch (error) {
      console.log(error, "Update Upcoming Trip Failed.");
      yield put(actions.updateUpcomingTripFail());
    }
  });
}

export function* updateDriverNoComingCount() {
  
  yield takeEvery(actionTypes.UPDATE_DRIVER_NO_COMING_COUNT, function*(action) {
    //yield put(actions.enableLoading());

    try {
      yield put(
        actions.updateDriverNoComingCountSuccess(action.data)
      );
    } catch (error) {
      console.log(error, "Update Driver No Coming Count Failed.");
      yield put(actions.updateDriverNoComingCountFail());
    }
  });
}

export default function* firebaseDBSaga() {
  yield all([fork(updateLatestTime)]);
  yield all([fork(updatePendingCount)]);
  yield all([fork(updateOrderLog)]);
  yield all([fork(updateNewOrderCount)]);
  yield all([fork(updateNoDriverAcceptCount)]);
  yield all([fork(updateTripLog)]);
  yield all([fork(updateUpcomingTrip)]);
  yield all([fork(updateDriverNoComingCount)]);
}
