import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import { Debounce } from "react-throttle";
import WindowResizeListener from "react-window-size-listener";
import { ThemeProvider } from "styled-components";
import Sidebar from "../Sidebar/Sidebar";
import Topbar from "../Topbar/Topbar";
import AppRouter from "./AppRouter";
import themes from "../../settings/themes";
import { THEME_CONFIG } from "../../settings";
import AppHolder from "./commonStyle";
import Spin from "../../components/uielements/spin";
import * as actions from "../../redux/actions";
import { SetAxiosDefaultConfig } from "../../redux/api";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./global.css";

import Firebase from '../../helpers/firebase/index';
// import firebase from "firebase/app";
// import { userFirebaseConfig } from '../../settings';


const { Content } = Layout;
 
export class App extends Component {

  componentDidMount() {
    const { app } = this.props
    let isLive = (app.env === "Live") ? true : false
    
    SetAxiosDefaultConfig();
    
    // this._onRelogin();
    // this._onReloginInterval();
    this.props.onLoadAMyProfile();

    //Firebase.requestFirebaseNotificationPermission();

    Firebase.individual_driver_waiting_approve(isLive).on("value", snap =>{
      
      console.log("Received pending approve individual driver", snap.val())
      this.props.onUpdatePendingCount("individual_driver_waiting_approve",snap.val());
    })
    
    Firebase.organization_driver_waiting_approve(isLive).on("value", snap =>{
      
      console.log("Received pending approve organization driver", snap.val())
      this.props.onUpdatePendingCount("organization_driver_waiting_approve",snap.val());
    })
    
    Firebase.user_branch_waiting_approve(isLive).on("value", snap =>{
      
      console.log("Received pending approve user branch", snap.val())
      this.props.onUpdatePendingCount("user_branch_waiting_approve",snap.val());
    })
    
    Firebase.lorry_waiting_approve(isLive).on("value", snap =>{
      
      console.log("Received pending approve lorry", snap.val())
      this.props.onUpdatePendingCount("lorry_waiting_approve",snap.val());
    })
    
    Firebase.user_credit_term_waiting_approve(isLive).on("value", snap =>{
      
      console.log("Received pending approve user credit term", snap.val())
      this.props.onUpdatePendingCount("user_credit_term_waiting_approve",snap.val());
    })

    Firebase.order_pending_accept(isLive).on("value", snap =>{
      
      console.log("Received pending accept order", snap.val())
      this.props.onUpdatePendingCount("order_pending_accept",snap.val());
    })

    Firebase.grading_waiting_approve(isLive).on("value", snap =>{
      
      console.log("Received pending approve grading", snap.val())
      this.props.onUpdatePendingCount("grading_waiting_approve",snap.val());
    })

    Firebase.withdraw_waiting_approve(isLive).on("value", snap =>{
      
      console.log("Received pending approve withdraw", snap.val())
      this.props.onUpdatePendingCount("withdraw_waiting_approve",snap.val());
    })

    Firebase.order_refund_waiting_approve(isLive).on("value", snap =>{
      
      console.log("Received pending approve order refund", snap.val())
      this.props.onUpdatePendingCount("order_refund_waiting_approve",snap.val());
    })

    Firebase.driver_payment_waiting_approve(isLive).on("value", snap =>{
      
      console.log("Received pending approve driver payment", snap.val())
      this.props.onUpdatePendingCount("driver_payment_waiting_approve",snap.val());
    })

    Firebase.reimburse_cut_off_waiting_approve(isLive).on("value", snap =>{
      
      console.log("Received pending approve reimburse cut off", snap.val())
      this.props.onUpdatePendingCount("reimburse_cut_off_waiting_approve",snap.val());
    })

    Firebase.feeback_waiting_resolve(isLive).on("value", snap =>{
      
      console.log("Received pending resolve feedback", snap.val())

      let val = snap.val()
      if(val)      
        val = val.filter(element => element)
      
      this.props.onUpdatePendingCount("feeback_waiting_resolve",val);
    })

    Firebase.partner_manager_waiting_verify(isLive).on("value", snap =>{
      
      console.log("Received pending verify partner manager", snap.val())
      this.props.onUpdatePendingCount("partner_manager_waiting_verify",snap.val());
    })

    Firebase.transport_license_waiting_verify(isLive).on("value", snap =>{
      
      console.log("Received pending verify transport License", snap.val())
      this.props.onUpdatePendingCount("transport_license_waiting_verify",snap.val());
    })

    Firebase.waiting_set_recovery_company(isLive).on("value", snap =>{
      
      console.log("Received pending set recovery company", snap.val())
      this.props.onUpdatePendingCount("waiting_set_recovery_company",snap.val());
    })

    Firebase.DOE_Registration_waiting_approve(isLive).on("value", snap =>{
      
      console.log("Received pending verify DOE Registration", snap.val())
      this.props.onUpdatePendingCount("DOE_Registration_waiting_approve",snap.val());
    })
    
    
    // Firebase.order_log(isLive).on("value", snap =>{
      
    //   console.log("Received Order Log", snap.val())
    //   this.props.onUpdateOrderLog(snap.val());
    // })
    
    // Firebase.new_order(isLive).on("value", snap =>{
      
    //   console.log("Received New Order Update", snap.val())
    //   this.props.onUpdateNewOrderCount(snap.val());
    // })
    
    // Firebase.trip_log(isLive).on("value", snap =>{
      
    //   console.log("Received Trip Log", snap.val())
    //   this.props.onUpdateTripLog(snap.val());
    // })
    
    // Firebase.upcoming_trip(isLive).on("value", snap =>{
      
    //   console.log("Received Upcoming Trip", snap.val())
    //   this.props.onUpdateUpcomingTrip(snap.val());
    // })
    
    // Firebase.waiting_accept_order(isLive).on("value", snap =>{
      
    //   console.log(snap.val())
    //   this.props.onUpdateNoDriverAcceptCount(snap.val());
    // })
    
    // Firebase.waiting_going_now(isLive).on("value", snap =>{
      
    //   console.log(snap.val())
    //   this.props.onUpdateDriverNoComingCount(snap.val());
    // })
  }

  componentWillUnmount() {
    clearInterval(this.reloginInterval);
    
  }

  componentDidUpdate(prevProps) {
    
    // const { profileData, app } = this.props
    // let isLive = (app.env === "Live") ? true : false
    
    if (prevProps.location.pathname !== this.props.location.pathname) {
      //this.props.onLoadAMyProfile();
    }

    // if (JSON.stringify(prevProps.profileData) !== JSON.stringify(profileData) &&
    //   JSON.stringify(profileData) !== "{}"
    // ) {
    //     Firebase.latest_time(profileData.admin_id, isLive).on("value", snap =>{
        
    //       console.log("Received New Order Latest Time", snap.val())
    //       this.props.onUpdateLatestTime(snap.val());
    //     })
    // }
  }

  _onReloginInterval = () => {
    // Call relogin every one hour
    this.reloginInterval = setInterval(() => {
      this._onRelogin();
    }, 3600000);
  };

  _onRelogin = () => {
    this.props.onRefreshJWTToken();
  };

  render() {
    const { url } = this.props.match;
    const { height, profileData } = this.props;
    const appHeight = window.innerHeight;

    const adminPrivileges = (profileData.AdminPrivileges) ? profileData.AdminPrivileges : {}

    // Firebase.onMessageListener()
    // .then((payload) => {
    //     const { title, body } = payload.data;
    //     toast.info(`${title}; ${body}`);
    //   })
    //   .catch((err) => {
    //     toast.error(JSON.stringify(err));
    //   });

    return (
      <ThemeProvider theme={themes[THEME_CONFIG.theme]}>
        <AppHolder>
          <Layout style={{ height: appHeight }}>
            <Debounce time="1000" handler="onResize">
              <WindowResizeListener
                onResize={windowSize =>
                  this.props.onToggleAll(
                    windowSize.windowWidth,
                    windowSize.windowHeight
                  )
                }
              />
            </Debounce>
            <Topbar url={url} />
            <Layout style={{ flexDirection: "row", overflowX: "hidden" }}>
              <Sidebar url={url} />
              <Layout
                className="isoContentMainLayout"
                style={{
                  height: height
                }}
              >
                <Content
                  className="isomorphicContent"
                  style={{
                    padding: "70px 0 0",
                    flexShrink: "0",
                    background: "#f1f3f6",
                    position: "relative"
                  }}
                >
                  <Spin className="test" spinning={this.props.app.isLoading}>
                    <AppRouter url={url} adminPrivileges={adminPrivileges} />
                  </Spin>
                  <ToastContainer autoClose={2000} />
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </AppHolder>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    app: state.App,
    auth: state.Auth,
    height: state.App.height,
    profileData: state.Auth.profileData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onToggleAll: (width, height) => dispatch(actions.toggleAll(width, height)),
    onRefreshJWTToken: () => dispatch(actions.refreshJWTToken()),
    onLoadAMyProfile: () => dispatch(actions.getProfile()),

    onUpdatePendingCount: (key,param) => dispatch(actions.updatePendingCount(key,param)),
    // onUpdateLatestTime: param => dispatch(actions.updateLatestTime(param)),

    // onUpdateOrderLog: param => dispatch(actions.updateOrderLog(param)),
    // onUpdateNewOrderCount: param => dispatch(actions.updateNewOrderCount(param)),
    // onUpdateNoDriverAcceptCount: param => dispatch(actions.updateNoDriverAcceptCount(param)),

    // onUpdateTripLog: param => dispatch(actions.updateTripLog(param)),
    // onUpdateUpcomingTrip: param => dispatch(actions.updateUpcomingTrip(param)),
    // onUpdateDriverNoComingCount: param => dispatch(actions.updateDriverNoComingCount(param)),

    
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
