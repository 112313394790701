import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
import Helper from "../../helpers/helpers";


export function* loadStateDistrictList() {

  yield takeEvery(actionTypes.LOAD_STATE_DISTRICT_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("STATEDISTRICTLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadStateDistrictList( action.state, action.district, action.data );
      
      if (response.data.success) {
        yield put(actions.loadStateDistrictListSuccess(response.data.data));
      } else {
        alert(response.data.message);
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
    }
  });
}


export function* loadLanguages() {

  yield takeEvery(actionTypes.LOAD_LANGUAGUES, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("LANGUAGELIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadLanguages(action.data);
      
      if (response.data.success) {
        yield put(actions.loadLanguagesSuccess(response.data.data));
      } else {
        alert(response.data.message);
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
    }
  });
}

export function* loadVerificationCode() {

  yield takeEvery(actionTypes.LOAD_VERIFICATION_CODE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("VERIFICATIONCODELIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadVerificationCode(action.data);
     
      if (response.data.success && response.data.data) {
        yield put(actions.loadVerificationCodeSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadVerificationCodeFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadVerificationCodeFail());
    }
  });
}


export function* loadOrderType() {

  yield takeEvery(actionTypes.LOAD_ORDER_TYPE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ORDERTYPELIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadOrderType(action.data);
     
      if (response.data.success && response.data.data) {
        yield put(actions.loadOrderTypeSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadOrderTypeFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadOrderTypeFail());
    }
  });
}


export function* setOrderType() {

  yield takeEvery(actionTypes.SET_ORDER_TYPE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETORDERTYPE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.setOrderType(action.id, action.data);
     
      if (response.data.success) {
        yield put(actions.setOrderTypeSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.setOrderTypeFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.setOrderTypeFail());
    }
  });
}


export function* removeOrderType() {

  yield takeEvery(actionTypes.REMOVE_ORDER_TYPE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DELORDERTYPE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.removeOrderType(action.id, action.data);
     
      if (response.data.success) {
        yield put(actions.removeOrderTypeSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.removeOrderTypeFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.removeOrderTypeFail());
    }
  });
}


export function* setDistance() {

  yield takeEvery(actionTypes.SET_DISTANCE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETDISTANCE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.setDistance(action.id, action.data);
     
      if (response.data.success) {
        yield put(actions.setDistanceSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.setDistanceFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.setDistanceFail());
    }
  });
}


export function* removeDistance() {

  yield takeEvery(actionTypes.REMOVE_DISTANCE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DELDISTANCE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.removeDistance(action.id, action.data);
     
      if (response.data.success) {
        yield put(actions.removeDistanceSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.removeDistanceFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.removeDistanceFail());
    }
  });
}

export function* loadLandfillCostType() {

  yield takeEvery(actionTypes.LOAD_LANDFILL_COST_TYPE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("LANDFILLCOSTTYPELIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadLandfillCostType(action.data);
     
      if (response.data.success && response.data.data) {
        yield put(actions.loadLandfillCostTypeSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadLandfillCostTypeFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadLandfillCostTypeFail());
    }
  });
}


export function* loadTimeSlot() {

  yield takeEvery(actionTypes.LOAD_TIME_SLOT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("TIMESLOTLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadTimeSlot(action.data);
     
      if (response.data.success && response.data.data) {
        yield put(actions.loadTimeSlotSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadTimeSlotFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadTimeSlotFail());
    }
  });
}

export function* loadDistanceList() {

  yield takeEvery(actionTypes.LOAD_DISTANCE_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DISTANCELIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDistanceList(action.data);
     
      if (response.data.success && response.data.data) {
        yield put(actions.loadDistanceListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDistanceListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDistanceListFail());
    }
  });
}


export function* loadLandfillPoint() {

  yield takeEvery(actionTypes.LANDFILL_POINT_LOAD, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("LANDFILLLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadLandfillPoint(action.data);
     
      if (response.data.success && response.data.data) {
        yield put(actions.loadLandfillPointSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadLandfillPointFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadLandfillPointFail());
    }
  });
}

export function* loadLandfillPointDetail() {

  yield takeEvery(actionTypes.LANDFILL_POINT_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("LANDFILLDETAIL");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadLandfillPointDetail(action.id, action.data);
     
      if (response.data.success && response.data.data) {
        yield put(actions.loadLandfillPointDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadLandfillPointDetailFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadLandfillPointDetailFail());
    }
  });
}

export function* addLandfillPoint() {

  yield takeEvery(actionTypes.LANDFILL_POINT_ADD, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ADDLANDFILL");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.addLandfillPoint(action.data);
     
      if (response.data.success) {
        yield put(actions.addLandfillPointSuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.addLandfillPointFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.addLandfillPointFail());
    }
  });
}

export function* editLandfillPoint() {

  yield takeEvery(actionTypes.LANDFILL_POINT_EDIT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITLANDFILL");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editLandfillPoint(action.id, action.data);
     
      if (response.data.success) {
        yield put(actions.editLandfillPointSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.editLandfillPointFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editLandfillPointFail());
    }
  });
}


export function* removeLandfillPoint() {

  yield takeEvery(actionTypes.LANDFILL_POINT_REMOVE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DELLANDFILL");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.removeLandfillPoint(action.id, action.data);
     
      if (response.data.success) {
        yield put(actions.removeLandfillPointSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.removeLandfillPointFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.removeLandfillPointFail());
    }
  });
}

export function* editRecyclingSalesPrice() {

  yield takeEvery(actionTypes.EDIT_RECYCLING_SALES_PRICE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITRECYCLINGSALESPRICE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editRecyclingSalesPrice(action.id, action.data);
     
      if (response.data.success) {
        yield put(actions.editRecyclingSalesPriceSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.editRecyclingSalesPriceFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editRecyclingSalesPriceFail());
    }
  });
}


export function* setLandfillAdjustment() {

  yield takeEvery(actionTypes.LANDFILL_ADJUSTMENT_SET, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETLANDFILLADJ");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.setLandfillAdjustment(action.id, action.data);
     
      if (response.data.success) {
        yield put(actions.setLandfillAdjustmentSuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.setLandfillAdjustmentFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.setLandfillAdjustmentFail());
    }
  });
}


export function* removeLandfillAdjustment() {

  yield takeEvery(actionTypes.LANDFILL_ADJUSTMENT_REMOVE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DELLANDFILLADJ");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.removeLandfillAdjustment(action.id, action.data);
     
      if (response.data.success) {
        yield put(actions.removeLandfillAdjustmentSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.removeLandfillAdjustmentFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.removeLandfillAdjustmentFail());
    }
  });
}



export function* loadLandfillAdjustment() {

  yield takeEvery(actionTypes.LANDFILL_ADJUSTMENT_LOAD, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("LANDFILLADJLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadLandfillAdjustment(action.data);
     
      if (response.data.success && response.data.data) {
        yield put(actions.loadLandfillAdjustmentSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadLandfillAdjustmentFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadLandfillAdjustmentFail());
    }
  });
}

export function* loadLandfillAdjustmentDetail() {

  yield takeEvery(actionTypes.LANDFILL_ADJUSTMENT_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("LANDFILLADJDETAIL");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadLandfillAdjustmentDetail(action.id, action.data);
     
      if (response.data.success && response.data.data) {
        yield put(actions.loadLandfillAdjustmentDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadLandfillAdjustmentDetailFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadLandfillAdjustmentDetailFail());
    }
  });
}


export function* loadWeighingPoint() {

  yield takeEvery(actionTypes.WEIGHING_POINT_LOAD, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WEIGHINGPOINTLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadWeighingPoint(action.data);
     
      if (response.data.success && response.data.data) {
        yield put(actions.loadWeighingPointSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadWeighingPointFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadWeighingPointFail());
    }
  });
}

export function* addWeighingPoint() {

  yield takeEvery(actionTypes.WEIGHING_POINT_ADD, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ADDWEIGHINGPOINT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.addWeighingPoint(action.data);
     
      if (response.data.success) {
        yield put(actions.addWeighingPointSuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.addWeighingPointFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.addWeighingPointFail());
    }
  });
}

export function* editWeighingPoint() {

  yield takeEvery(actionTypes.WEIGHING_POINT_EDIT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITWEIGHINGPOINT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editWeighingPoint(action.id, action.data);
     
      if (response.data.success) {
        yield put(actions.editWeighingPointSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.editWeighingPointFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editWeighingPointFail());
    }
  });
}


export function* removeWeighingPoint() {

  yield takeEvery(actionTypes.WEIGHING_POINT_REMOVE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DELWEIGHINGPOINT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.removeWeighingPoint(action.id, action.data);
     
      if (response.data.success) {
        yield put(actions.removeWeighingPointSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.removeWeighingPointFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.removeWeighingPointFail());
    }
  });
}


export function* loadSettings() {

  yield takeEvery(actionTypes.SETTING_LOAD, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETTINGLOAD");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadSettings(action.data);
     
      if (response.data.success && response.data.data) {
        yield put(actions.loadSettingsSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadSettingsFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadSettingsFail());
    }
  });
}

export function* getSettingValue() {

  yield takeEvery(actionTypes.GET_SETTING_VALUE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GETSETTINGVALUE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.getSettingValue(action.data);
     
      if (response.data.success && response.data.data) {
        yield put(actions.getSettingValueSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getSettingValueFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getSettingValueFail());
    }
  });
}

export function* settingUpdate() {

  yield takeEvery(actionTypes.SETTING_UPDATE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDSETTING");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.settingUpdate(action.data);
     
      if (response.data.success) {
        yield put(actions.settingUpdateSuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.settingUpdateFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.settingUpdateFail());
    }
  });
}

/** FEEDBACK **/
export function* loadFeedbackSubjectList() {
  
  yield takeEvery(actionTypes.LOAD_FEEDBACK_SUBJECT_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("FEEDBACKSUBLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadFeedbackSubjectList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadFeedbackSubjectListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadFeedbackSubjectListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadFeedbackSubjectListFail());
    }
  });
}
export function* loadFeedbackList() {
  
  yield takeEvery(actionTypes.LOAD_FEEDBACK_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let status = (action.data.status) ? action.data.status : ""
    
    let track_no = Helper.generateTrackNo(`FEEBACKLIST${status}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadFeedbackList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadFeedbackListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadFeedbackListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadFeedbackListFail());
    }
  });
}

export function* loadFeedbackDetail() {
  
  yield takeEvery(actionTypes.LOAD_FEEDBACK_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("FEEDBACKDETAIL");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadFeedbackDetail(action.id, action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadFeedbackDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadFeedbackDetailFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadFeedbackDetailFail());
    }
  });
}


export function* getFeedbackCount() {
  
  yield takeEvery(actionTypes.GET_FEEDBACK_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("FEEDBACKCOUNT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.getFeedbackCount(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.getFeedbackCountSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getFeedbackCountFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getFeedbackCountFail());
    }
  });
}


export function* resolveFeedback() {
  yield takeEvery(actionTypes.RESOLVE_FEEDBACK, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RESOLVEFEEDBACK");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.resolveFeedback(action.id,action.data);
      if (response.data.success) {
        yield put(actions.resolveFeedbackSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.resolveFeedbackFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.resolveFeedbackFail());
    }
  });
}
/** End FEEDBACK **/

/** MERCHANT REGISTRATION **/
export function* loadMerchantRegistrationList() {
  
  yield takeEvery(actionTypes.LOAD_MERCHANT_REGISTRATION_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let status = (action.data.status) ? action.data.status : ""
    
    let track_no = Helper.generateTrackNo(`MERREGISTRATIONLIST${status}`);
    
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadMerchantRegistrationList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadMerchantRegistrationListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadMerchantRegistrationListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadMerchantRegistrationListFail());
    }
  });
}

export function* loadMerchantRegistrationDetail() {
  
  yield takeEvery(actionTypes.LOAD_MERCHANT_REGISTRATION_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("MERREGISTRATIONDETAIL");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadMerchantRegistrationDetail(action.id, action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadMerchantRegistrationDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadMerchantRegistrationDetailFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadMerchantRegistrationDetailFail());
    }
  });
}



export function* getMerchantRegistrationCount() {
  
  yield takeEvery(actionTypes.GET_MERCHANT_REGISTRATION_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("MERREGISTRATIONCOUNT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.getMerchantRegistrationCount(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.getMerchantRegistrationCountSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getMerchantRegistrationCountFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getMerchantRegistrationCountFail());
    }
  });
}


export function* verifyMerchantRegistration() {
  yield takeEvery(actionTypes.VERIFY_MERCHANT_REGISTRATION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("VRFMERREGISTRATION");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.verifyMerchantRegistration(action.id, action.data);
      if (response.data.success) {
        yield put(actions.verifyMerchantRegistrationSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.verifyMerchantRegistrationFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.verifyMerchantRegistrationFail());
    }
  });
}

export function* rejectMerchantRegistration() {
  yield takeEvery(actionTypes.REJECT_MERCHANT_REGISTRATION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REJECTMERREGISTRATION");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.rejectMerchantRegistration(action.id, action.data);
      if (response.data.success) {
        yield put(actions.rejectMerchantRegistrationSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.rejectMerchantRegistrationFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.rejectMerchantRegistrationFail());
    }
  });
}
/** End MERCHANT REGISTRATION **/

/** Promotion **/
export function* loadPromotionList() {
  
  yield takeEvery(actionTypes.LOAD_PROMOTION_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("PROMOTIONLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadPromotionList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadPromotionListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadPromotionListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadPromotionListFail());
    }
  });
}

export function* addPromotion() {
  yield takeEvery(actionTypes.ADD_PROMOTION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ADDPROMOTIOM");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.addPromotion(action.data);
      if (response.data.success) {
        yield put(actions.addPromotionSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.addPromotionFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.addPromotionFail());
    }
  });
}

export function* editPromotion() {
  yield takeEvery(actionTypes.EDIT_PROMOTION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITPROMOTION");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editPromotion(action.id,action.data);
      if (response.data.success) {
        yield put(actions.editPromotionSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.editPromotionFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editPromotionFail());
    }
  });
}

export function* deletePromotion() {
  yield takeEvery(actionTypes.DELETE_PROMOTION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DELPROMOTION");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.deletePromotion(action.id, action.data);
      if (response.data.success) {
        yield put(actions.deletePromotionSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.deletePromotionFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.deletePromotionFail());
    }
  });
}
/** End Promotion **/


export function* loadCarbonFootprint() {

  yield takeEvery(actionTypes.LOAD_CARBON_FOOTPRINT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("CARBONFOOTPRINTLOAD");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadCarbonFootprint(action.data);
     
      if (response.data.success && response.data.data) {
        yield put(actions.loadCarbonFootprintSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadCarbonFootprintFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadCarbonFootprintFail());
    }
  });
}


export function* setCarbonFootprint() {

  yield takeEvery(actionTypes.SET_CARBON_FOOTPRINT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETCARBONFOOTPRINT");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.setCarbonFootprint(action.key, action.data);
     
      if (response.data.success) {
        yield put(actions.setCarbonFootprintSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.setCarbonFootprintFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.setCarbonFootprintFail());
    }
  });
}

export function* overallImpactLoad() {

  yield takeEvery(actionTypes.LOAD_OVERALL_IMPACT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("OVERALLIMPACTLOAD");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.overallImpactLoad(action.data);
     
      if (response.data.success && response.data.data) {
        yield put(actions.overallImpactLoadSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.overallImpactLoadFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.overallImpactLoadFail());
    }
  });
}



export function* loadReferralCommission() {

  yield takeEvery(actionTypes.LOAD_REFERRAL_COMMISSION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REFERRALCOMMISSIONLOAD");
    action.data.track_no = track_no

    yield put(actions.enableLoading());
    

    try {
      const response = yield API.loadReferralCommission(action.data);
     
      if (response.data.success && response.data.data) {
        yield put(actions.loadReferralCommissionSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadReferralCommissionFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadReferralCommissionFail());
    }
  });
}


export function* setReferralCommission() {

  yield takeEvery(actionTypes.SET_REFERRAL_COMMISSION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETREFERRALCOMMISSION");    
    action.data.track_no = track_no

    yield put(actions.enableLoading());
    
    try {
      const response = yield API.setReferralCommission(action.data);
     
      if (response.data.success) {
        yield put(actions.setReferralCommissionSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.setReferralCommissionFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.setReferralCommissionFail());
    }
  });
}


export function* removeReferralLevel() {

  yield takeEvery(actionTypes.REMOVE_REFERRAL_LEVEL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REMOVEREFERRALLEVEL");    
    action.data.track_no = track_no

    yield put(actions.enableLoading());
    
    try {
      const response = yield API.removeReferralLevel(action.id, action.data);
     
      if (response.data.success) {
        yield put(actions.removeReferralLevelSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.removeReferralLevelFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.removeReferralLevelFail());
    }
  });
}


export default function* generalSaga() {
  
  yield all([fork(loadStateDistrictList)]);
  yield all([fork(loadLanguages)]);
  yield all([fork(loadVerificationCode)]);
  yield all([fork(loadDistanceList)]);
  yield all([fork(loadTimeSlot)]);
  yield all([fork(loadOrderType)]);
  yield all([fork(setOrderType)]);
  yield all([fork(removeOrderType)]);
  yield all([fork(setDistance)]);
  yield all([fork(removeDistance)]);
  yield all([fork(loadLandfillCostType)]);
  yield all([fork(loadLandfillPoint)]);
  yield all([fork(loadLandfillPointDetail)]);
  yield all([fork(addLandfillPoint)]);
  yield all([fork(editLandfillPoint)]);
  yield all([fork(removeLandfillPoint)]);
  yield all([fork(editRecyclingSalesPrice)]);
  yield all([fork(loadSettings)]);
  yield all([fork(getSettingValue)]);  
  yield all([fork(settingUpdate)]);
  yield all([fork(setLandfillAdjustment)]);
  yield all([fork(removeLandfillAdjustment)]);
  yield all([fork(loadLandfillAdjustment)]);
  yield all([fork(loadLandfillAdjustmentDetail)]);
  
  yield all([fork(loadWeighingPoint)]);
  yield all([fork(addWeighingPoint)]);
  yield all([fork(editWeighingPoint)]);
  yield all([fork(removeWeighingPoint)]);

  yield all([fork(loadFeedbackSubjectList)]);
  yield all([fork(loadFeedbackList)]);
  yield all([fork(loadFeedbackDetail)]);
  yield all([fork(getFeedbackCount)]);
  yield all([fork(resolveFeedback)]);
  yield all([fork(loadMerchantRegistrationList)]);
  yield all([fork(loadMerchantRegistrationDetail)]);
  yield all([fork(getMerchantRegistrationCount)]);
  yield all([fork(verifyMerchantRegistration)]);
  yield all([fork(rejectMerchantRegistration)]);
  
  yield all([fork(loadPromotionList)]);
  yield all([fork(addPromotion)]);
  yield all([fork(editPromotion)]);
  yield all([fork(deletePromotion)]);
  
  yield all([fork(loadCarbonFootprint)]);
  yield all([fork(setCarbonFootprint)]);
  yield all([fork(overallImpactLoad)]);

  yield all([fork(loadReferralCommission)]);
  yield all([fork(setReferralCommission)]);  
  yield all([fork(removeReferralLevel)]);
}
