import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";
import moment from "moment";

const INITIAL_STATE = {
  pending_count: {},
  latest_time: null,
  order_log: {},
  new_order: {},
  no_driver_accept: [],
  trip_log: {},
  upcoming_log: {},
  driver_no_coming: []
};

export default function firebaseDBReducer(state = INITIAL_STATE, action) {
  switch (action.type) {

    // Update Pending Count
    case actionTypes.UPDATE_PENDING_COUNT: {
        return {
            ...state,
            pending_count: cloneDeep(state.pending_count)
        };
    }

    case actionTypes.UPDATE_PENDING_COUNT_SUCCESS: {
    
        let pending_count = cloneDeep(state.pending_count)
        
        pending_count[action.key] = action.data
        
        return {
            ...state,
            pending_count
        };
    }

    case actionTypes.UPDATE_PENDING_COUNT_FAIL: {
        return {
            ...state
        };
    }


    // Update Latest Time
    case actionTypes.UPDATE_LATEST_TIME: {
        return {
            ...state,
            latest_time: cloneDeep(state.latest_time)
        };
    }

    case actionTypes.UPDATE_LATEST_TIME_SUCCESS: {
        
        let time = (action.data && moment(action.data).isValid()) ? action.data : moment().format("YYYY-MM-DD HH:mm:ss")

        return {
            ...state,
            latest_time: time
        };
    }

    case actionTypes.UPDATE_LATEST_TIME_FAIL: {
        return {
            ...state
        };
    }


    // Update Order Log
    case actionTypes.UPDATE_ORDER_LOG: {
        return {
            ...state,
            order_log: {}
        };
    }

    case actionTypes.UPDATE_ORDER_LOG_SUCCESS: {
    
        return {
            ...state,
            order_log: action.data
        };
    }

    case actionTypes.UPDATE_ORDER_LOG_FAIL: {
        return {
            ...state,
            order_log: {}
        };
    }

    // Update New Order Count
    case actionTypes.UPDATE_NEW_ORDER_COUNT: {
        return {
            ...state,
            new_order: cloneDeep(state.new_order)
        };
    }

    case actionTypes.UPDATE_NEW_ORDER_COUNT_SUCCESS: {
    
        return {
            ...state,
            new_order: action.data
        };
    }

    case actionTypes.UPDATE_NEW_ORDER_COUNT_FAIL: {
        return {
            ...state
        };
    }
    // Update New Order Count END

    // Update No Driver Accept Count
    case actionTypes.UPDATE_NO_DRIVER_ACCEPT_COUNT: {
        return {
            ...state,
            no_driver_accept: []
        };
    }

    case actionTypes.UPDATE_NO_DRIVER_ACCEPT_COUNT_SUCCESS: {
        return {
            ...state,
            no_driver_accept: action.data
        };
    }

    case actionTypes.UPDATE_NO_DRIVER_ACCEPT_COUNT_FAIL: {
        return {
            ...state
        };
    }
    // Update No Driver Accept Count END



    // Update Trip Log
    case actionTypes.UPDATE_TRIP_LOG: {
        return {
            ...state,
            trip_log: {}
        };
    }

    case actionTypes.UPDATE_TRIP_LOG_SUCCESS: {
    
        return {
            ...state,
            trip_log: action.data
        };
    }

    case actionTypes.UPDATE_TRIP_LOG_FAIL: {
        return {
            ...state,
            trip_log: {}
        };
    }
    
    // Update Upcoming Trip
    case actionTypes.UPDATE_UPCOMING_TRIP: {
        return {
            ...state,
            upcoming_log: {}
        };
    }

    case actionTypes.UPDATE_UPCOMING_TRIP_SUCCESS: {
    
        return {
            ...state,
            upcoming_log: action.data
        };
    }

    case actionTypes.UPDATE_UPCOMING_TRIP_FAIL: {
        return {
            ...state,
            upcoming_log: {}
        };
    }


    // Update Driver No Coming Count
    case actionTypes.UPDATE_DRIVER_NO_COMING_COUNT: {
        return {
            ...state,
            driver_no_coming: []
        };
    }

    case actionTypes.UPDATE_DRIVER_NO_COMING_COUNT_SUCCESS: {
        return {
            ...state,
            driver_no_coming: action.data
        };
    }

    case actionTypes.UPDATE_DRIVER_NO_COMING_COUNT_FAIL: {
        return {
            ...state
        };
    }
    // Update Driver No Coming Count END

    default:
      return state;
  }
}
