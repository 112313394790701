const Color = {
  Primary: "#009246",

  //  orange
  Flamingo: "#f05b22",
  FlushOrange: "#ff7300",
  SoftOrange: "#fec193",
  PaleOrange: "#ffeddf",
  Seashell: "#fff5ee",
  GreyOrange: "#c1906a",

  // Yellow
  Amber: "#FFC200",
  LightYellow: "#eee1b1",
  Khaki: "#bdad73",
  GreyYellow: "#c5c47a",
  
  // Green
  Camarone: "#005B28",
  Salem: "#0A8146",
  FunGreen: "#009627",
  Jade: "#00B24D",
  Malachite: "#00C028",
  Caribbean: "#02c498",
  Green: "#008000",
  LightGreen: "#b1eee1",
  GreyGreen: "#75a99e",
  MacaronGreen: "#dcf8c6",

  // red
  Carnation: "#ef4c66",
  BrinkPink: "#F75D81",
  LightPink: "#eeb1bf",
  PinkPearl: "#a86b79",
  GreyRed: "#c18f9b",
  RoseRed: '#ef183b',
  GreyPink: "#c191ba",

  // grey
  Black: "#000000",
  Thunder: "#2f2528",
  MineShaft: "#333333",
  Scorpion: "#595959",
  Boulderapprox: "#777777",
  Dusty: "#979797",
  DustyGrey: "#949494",
  Nobel: "#B4B4B4",
  Alto: "#DCDCDC",
  Mercury: "#E9E9E9",
  Sand: "#F5F5F5",
  TransparentGrey: "#8080801f",
  Grey: "#ededed",

  // white
  White: "#ffffff",

  // blue
  PrussianBlue: "#003859",
  CongressBlue: "#024099",
  Lochmara: "#007DD5",
  Viking: "#5BC0DE",
  DarkBlue: "#108ee9",
  LinghtBlue: "#72c2e0",
  BlueLink: "#1890ff",
  GreyBlue: "#6790ad",

  // purple
  Plum: "#70266E",
  DaisyBush: "#42299a",
  PurpleBlue: "#aa9ae4",
  Purple: "#7266BA",
  GreyPurple: "#8680b1",
};

export default Color;
