import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/general/admin/recycling";
  // return "http://192.168.1.12:3003/general/admin/recycling";
};


export const countRecyclingNewPrice = data => {
  const url = `${baseURL()}/newPrice/count`;
  return axios.post(url, data);
};

export const loadRecyclingList = data => {
  const url = `${baseURL()}/load`;
  return axios.post(url, data);
};

export const loadRecyclingDetail = (id, data) => {
  const url = `${baseURL()}/load/${id}`;
  return axios.post(url, data);
};

export const loadRecyclingEffectiveDateList = (data) => {
  const url = `${baseURL()}/price/effectiveDate`;
  return axios.post(url, data);
};

export const loadRecyclingCategory = data => {
  const url = `${baseURL()}/category/load`;
  return axios.post(url, data);
};

export const addRecyclingCategory = data => {
  const url = `${baseURL()}/category/create`;
  return axios.post(url, data);
};

export const editRecyclingCategory = (id, data) => {
  const url = `${baseURL()}/category/update/${id}`;
  return axios.post(url, data);
};

export const deleteRecyclingCategory = (id, data) => {
  const url = `${baseURL()}/category/remove/${id}`;
  return axios.post(url, data);
};

export const setRecyclingCategorySequence = data => {
  const url = `${baseURL()}/category/sequence/set`;
  return axios.post(url, data);
};


export const recyclingPriceControlLoad = (data) => {
  const url = `${baseURL()}/priceControl/load`;
  return axios.post(url, data);
};

export const recyclingPriceControlSet = (data, id = null) => {
  let url = `${baseURL()}/priceControl/set`;
  if(id) url += `/${id}`
  
  return axios.post(url, data);
};

export const deleteRecycling = (id, data) => {
  const url = `${baseURL()}/remove/${id}`;
  return axios.post(url, data);
};

export const setRecyclingSequence = (id, data) => {
  const url = `${baseURL()}/sequence/set/${id}`;
  return axios.post(url, data);
};



/** RECYCLING EXTRA FEE **/
export const loadRecyclingExtraFeeList = data => {
  const url = `${baseURL()}/extraFee/load`;
  return axios.post(url, data);
};

export const addRecyclingExtraFee = data => {
  const url = `${baseURL()}/extraFee/create`;
  return axios.post(url, data);
};

export const editRecyclingExtraFeeItem =  (id, data) => {
  const url = `${baseURL()}/extraFee/item/update/${id}`;
  return axios.post(url, data);
};

export const updateRecyclingExtraFeeWasteType = (id, data) => {
  const url = `${baseURL()}/extraFee/wasteType/set/${id}`;
  return axios.post(url, data);
};

export const deleteRecyclingExtraFee = (id,data) => {
  const url = `${baseURL()}/extraFee/remove/${id}`;
  return axios.post(url, data);
};

export const loadRecyclingLoadingFeeList = data => {
  const url = `${baseURL()}/extraFee/loadingFee`;
  return axios.post(url, data);
};

export const setRecyclingLoadingFee = data => {
  const url = `${baseURL()}/extraFee/loadingFee/set`;
  return axios.post(url, data);
};

/** END RECYCLING EXTRA FEE **/


export const getRecyclingPricePDF = data => {
  const url = `${baseURL()}/pdf `;
  return axios.post(url, data);
};

export const getRecyclingPricePostPDF = data => {
  const url = `${baseURL()}/post/pdf `;
  return axios.post(url, data);
};

export const loadRecyclingSalesPriceList = data => {
  const url = `${baseURL()}/salesPrice/load `;
  return axios.post(url, data);
};

export const getRecyclingSalesPricePDF = data => {
  const url = `${baseURL()}/salesPrice/pdf `;
  return axios.post(url, data);
};


export const loadRecyclingSalesPriceUpdHistory = data => {
  const url = `${baseURL()}/priceUpdateHistory/load`;
  return axios.post(url, data);
};

export const countRecyclingSalesPriceUpdHistory = data => {
  const url = `${baseURL()}/priceUpdateHistory/count`;
  return axios.post(url, data);
};

export const getRecyclingSalesPriceUpdHistoryDetail = (id, data) => {
  const url = `${baseURL()}/priceUpdateHistory/detail/${id}`;
  return axios.post(url, data);
};
