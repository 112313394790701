import { all } from "redux-saga/effects";
import authSagas from "./auth_saga";
import appSagas from "./app_saga";
import adminSagas from "./admin_sage";
import dumpsterSagas from "./dumpster_saga";
import lorrySagas from "./lorry_saga";
import dumpsterPriceSagas from "./dumpster_price_saga";
import generalSagas from "./general_saga";
import userOrganizationSagas from "./user_organization_saga";
import userSagas from "./user_saga";
import driverSagas from "./driver_saga";
import orderSagas from "./order_saga";
import notificationSaga from "./notification_saga";
import googleMapSaga from "./google_map_saga";
import recyclingSaga from "./recycling_saga";
import firebaseDBSaga from "./firebase_db_saga";
import paymentSaga from "./payment_saga";
import whatsappSaga from "./whatsapp_saga";
import scheduledWasteSaga from "./scheduled_waste_saga";
import graderSaga from "./grader_saga";
import expressSaga from "./express_saga";

export default function* rootSaga() {
  yield all([
    appSagas(), 
    authSagas(), 
    adminSagas(),
    dumpsterSagas(), 
    lorrySagas(), 
    dumpsterPriceSagas(), 
    generalSagas(), 
    userOrganizationSagas(), 
    userSagas(), 
    driverSagas(), 
    orderSagas(),
    notificationSaga(),
    googleMapSaga(),
    recyclingSaga(),
    firebaseDBSaga(),
    paymentSaga(),
    whatsappSaga(),
    scheduledWasteSaga(),
    graderSaga(),
    expressSaga()
  ]);
}
