import * as actionTypes from "./actionTypes";

export const loadOrderList = data => {
  return {
    type: actionTypes.LOAD_ORDER_LIST,
    data
  };
};

export const loadOrderListSuccess = data => {
  return {
    type: actionTypes.LOAD_ORDER_LIST_SUCCESS,
    data
  };
};

export const loadOrderListFail = () => {
  return {
    type: actionTypes.LOAD_ORDER_LIST_FAIL
  };
};



export const orderCount = (data) => {
  
  return {
    type: actionTypes.ORDER_COUNT,
    data
  };
};

export const orderCountSuccess = data => {
  return {
    type: actionTypes.ORDER_COUNT_SUCCESS,
    data
  };
};

export const orderCountFail = () => {
  return {
    type: actionTypes.ORDER_COUNT_FAIL
  };
};

export const createOrder = data => {
  return {
    type: actionTypes.CREATE_ORDER,
    data
  };
};

export const createOrderSuccess = () => {
  return {
    type: actionTypes.CREATE_ORDER_SUCCESS
  };
};

export const createOrderFail = () => {
  return {
    type: actionTypes.CREATE_ORDER_FAIL
  };
};


export const cancelOrder = (id,data) => {
  return {
    type: actionTypes.CANCEL_ORDER,
    id,
    data
  };
};

export const cancelOrderSuccess = (data, params) => {
  return {
    type: actionTypes.CANCEL_ORDER_SUCCESS,
    data,
    params
  };
};

export const cancelOrderFail = () => {
  return {
    type: actionTypes.CANCEL_ORDER_FAIL
  };
};


export const getOrderCancellationDetail = (id, data) => {
  return {
    type: actionTypes.GET_ORDER_CANCELLATION_DETAIL,
    id,
    data
  };
};

export const getOrderCancellationDetailSuccess = (data) => {
  return {
    type: actionTypes.GET_ORDER_CANCELLATION_DETAIL_SUCCESS,
    data
  };
};

export const getOrderCancellationDetailFail = () => {
  return {
    type: actionTypes.GET_ORDER_CANCELLATION_DETAIL_FAIL
  };
};


export const orderAssignDriver = (id,data) => {
  return {
    type: actionTypes.ORDER_ASSIGN_DRIVER,
    id,
    data
  };
};

export const orderAssignDriverSuccess = (data, params) => {
  return {
    type: actionTypes.ORDER_ASSIGN_DRIVER_SUCCESS,
    data,
    params
  };
};

export const orderAssignDriverFail = () => {
  return {
    type: actionTypes.ORDER_ASSIGN_DRIVER_FAIL
  };
};


export const loadOrderDetail = (id, data) => {
  
  return {
    type: actionTypes.LOAD_ORDER_DETAIL,
    id,
    data
  };
};

export const loadOrderDetailSuccess = data => {
  
  return {
    type: actionTypes.LOAD_ORDER_DETAIL_SUCCESS,
    data
  };
};

export const loadOrderDetailFail = () => {
  return {
    type: actionTypes.LOAD_ORDER_DETAIL_FAIL
  };
};


export const loadOrderDetailTripGroup = (id, data) => {
  
  return {
    type: actionTypes.LOAD_ORDER_DETAIL_TRIP_GROUP,
    id,
    data
  };
};

export const loadOrderDetailTripGroupSuccess = data => {
  
  return {
    type: actionTypes.LOAD_ORDER_DETAIL_TRIP_GROUP_SUCCESS,
    data
  };
};

export const loadOrderDetailTripGroupFail = () => {
  return {
    type: actionTypes.LOAD_ORDER_DETAIL_TRIP_GROUP_FAIL
  };
};



export const loadOrderDetailTripList = (id, data) => {
  
  return {
    type: actionTypes.LOAD_ORDER_DETAIL_TRIP_LIST,
    id,
    data
  };
};

export const loadOrderDetailTripListSuccess = data => {
  
  return {
    type: actionTypes.LOAD_ORDER_DETAIL_TRIP_LIST_SUCCESS,
    data
  };
};

export const loadOrderDetailTripListFail = () => {
  return {
    type: actionTypes.LOAD_ORDER_DETAIL_TRIP_LIST_FAIL
  };
};




export const loadOrderTripList = data => {
  return {
    type: actionTypes.LOAD_ORDER_TRIP_LIST,
    data
  };
};

export const loadOrderTripListSuccess = data => {
  return {
    type: actionTypes.LOAD_ORDER_TRIP_LIST_SUCCESS,
    data
  };
};

export const loadOrderTripListFail = () => {
  return {
    type: actionTypes.LOAD_ORDER_TRIP_LIST_FAIL
  };
};

export const orderTripCount = data => {
  return {
    type: actionTypes.ORDER_TRIP_COUNT,
    data
  };
};

export const orderTripCountSuccess = data => {
  return {
    type: actionTypes.ORDER_TRIP_COUNT_SUCCESS,
    data
  };
};

export const orderTripCountFail = () => {
  return {
    type: actionTypes.ORDER_TRIP_COUNT_FAIL
  };
};


export const loadDriverTripList = (id, data) => {
  return {
    type: actionTypes.LOAD_DRIVER_TRIP_LIST,
    id,
    data
  };
};

export const loadDriverTripListSuccess = data => {
  return {
    type: actionTypes.LOAD_DRIVER_TRIP_LIST_SUCCESS,
    data
  };
};

export const loadDriverTripListFail = () => {
  return {
    type: actionTypes.LOAD_DRIVER_TRIP_LIST_FAIL
  };
};


export const updateTripPlacement = (id,data) => {
  return {
    type: actionTypes.UPDATE_TRIP_PLACEMENT,
    id,
    data
  };
};

export const updateTripPlacementSuccess = () => {
  return {
    type: actionTypes.UPDATE_TRIP_PLACEMENT_SUCCESS
  };
};

export const updateTripPlacementFail = () => {
  return {
    type: actionTypes.UPDATE_TRIP_PLACEMENT_FAIL
  };
};

export const updateTripFilling = (id,data) => {
  return {
    type: actionTypes.UPDATE_TRIP_FILLING,
    id,
    data
  };
};

export const updateTripFillingSuccess = () => {
  return {
    type: actionTypes.UPDATE_TRIP_FILLING_SUCCESS
  };
};

export const updateTripFillingFail = () => {
  return {
    type: actionTypes.UPDATE_TRIP_FILLING_FAIL
  };
};

export const rollbackTripFilling = (id,data) => {
  return {
    type: actionTypes.ROLLBACK_TRIP_FILLING,
    id,
    data
  };
};

export const rollbackTripFillingSuccess = () => {
  return {
    type: actionTypes.ROLLBACK_TRIP_FILLING_SUCCESS
  };
};

export const rollbackTripFillingFail = () => {
  return {
    type: actionTypes.ROLLBACK_TRIP_FILLING_FAIL
  };
};

export const updateTripCollection = (id,data) => {
  return {
    type: actionTypes.UPDATE_TRIP_COLLECTION,
    id,
    data
  };
};

export const updateTripCollectionSuccess = (id,params,data) => {
  return {
    type: actionTypes.UPDATE_TRIP_COLLECTION_SUCCESS,
    id,
    params,
    data
  };
};

export const updateTripCollectionFail = () => {
  return {
    type: actionTypes.UPDATE_TRIP_COLLECTION_FAIL
  };
};

export const updateTripDisposal = (id,data) => {
  return {
    type: actionTypes.UPDATE_TRIP_DISPOSAL,
    id,
    data
  };
};

export const updateTripDisposalSuccess = () => {
  return {
    type: actionTypes.UPDATE_TRIP_DISPOSAL_SUCCESS
  };
};

export const updateTripDisposalFail = () => {
  return {
    type: actionTypes.UPDATE_TRIP_DISPOSAL_FAIL
  };
};


export const reuploadDisposalSlip = (id,data) => {
  return {
    type: actionTypes.REUPLOAD_DISPOSAL_SLIP,
    id,
    data
  };
};

export const reuploadDisposalSlipSuccess = () => {
  return {
    type: actionTypes.REUPLOAD_DISPOSAL_SLIP_SUCCESS
  };
};

export const reuploadDisposalSlipFail = () => {
  return {
    type: actionTypes.REUPLOAD_DISPOSAL_SLIP_FAIL
  };
};


export const reuploadTripProofImg = (id,data) => {
  return {
    type: actionTypes.REUPLOAD_TRIP_PROOF,
    id,
    data
  };
};

export const reuploadTripProofImgSuccess = () => {
  return {
    type: actionTypes.REUPLOAD_TRIP_PROOF_SUCCESS
  };
};

export const reuploadTripProofImgFail = () => {
  return {
    type: actionTypes.REUPLOAD_TRIP_PROOF_FAIL
  };
};


export const reuploadTripWeighingSlip = (id,data) => {
  return {
    type: actionTypes.REUPLOAD_TRIP_WEIGHING_SLIP,
    id,
    data
  };
};

export const reuploadTripWeighingSlipSuccess = () => {
  return {
    type: actionTypes.REUPLOAD_TRIP_WEIGHING_SLIP_SUCCESS
  };
};

export const reuploadTripWeighingSlipFail = () => {
  return {
    type: actionTypes.REUPLOAD_TRIP_WEIGHING_SLIP_FAIL
  };
};


export const skipSignature = (id,data) => {
  return {
    type: actionTypes.SKIP_SIGNATURE,
    id,
    data
  };
};

export const skipSignatureSuccess = () => {
  return {
    type: actionTypes.SKIP_SIGNATURE_SUCCESS
  };
};

export const skipSignatureFail = () => {
  return {
    type: actionTypes.SKIP_SIGNATURE_FAIL
  };
};


export const updateDumpsterEmpty = (id,data) => {
  return {
    type: actionTypes.UPDATE_DUMPSTER_EMPTY,
    id,
    data
  };
};

export const updateDumpsterEmptySuccess = () => {
  return {
    type: actionTypes.UPDATE_DUMPSTER_EMPTY_SUCCESS
  };
};

export const updateDumpsterEmptyFail = () => {
  return {
    type: actionTypes.UPDATE_DUMPSTER_EMPTY_FAIL
  };
};




export const updateTripLoadingCollection = (id,data) => {
  return {
    type: actionTypes.UPDATE_TRIP_LOADING_COLLECTION,
    id,
    data
  };
};

export const updateTripLoadingCollectionSuccess = () => {
  return {
    type: actionTypes.UPDATE_TRIP_LOADING_COLLECTION_SUCCESS
  };
};

export const updateTripLoadingCollectionFail = () => {
  return {
    type: actionTypes.UPDATE_TRIP_LOADING_COLLECTION_FAIL
  };
};

/* ===== TRACK DRIVER  ===== */
export const trackDriver = (id, data) => {
  return {
    type: actionTypes.TRACK_DRIVER,
    id,
    data
  };
};

export const trackDriverSuccess = data => {
  return {
    type: actionTypes.TRACK_DRIVER_SUCCESS,
    data
  };
};

export const trackDriverFail = () => {
  return {
    type: actionTypes.TRACK_DRIVER_FAIL
  };
};
/* ===== END TRACK DRIVER ===== */

/* ===== TRIP_SET_ARRIVED  ===== */
export const tripSetArrived = (id, data) => {
  return {
    type: actionTypes.TRIP_SET_ARRIVED,
    id,
    data
  };
};

export const tripSetArrivedSuccess = () => {
  return {
    type: actionTypes.TRIP_SET_ARRIVED_SUCCESS
  };
};

export const tripSetArrivedFail = () => {
  return {
    type: actionTypes.TRIP_SET_ARRIVED_FAIL
  };
};
/* ===== END TRIP SET ARRIVED ===== */

/* ===== TRIP UPDATE GOING NOW  ===== */
export const tripUpdateGoingNow = (id, data) => {
  return {
    type: actionTypes.TRIP_UPDATE_GOING_NOW,
    id,
    data
  };
};

export const tripUpdateGoingNowSuccess = (data) => {
  return {
    type: actionTypes.TRIP_UPDATE_GOING_NOW_SUCCESS,
    data
  };
};

export const tripUpdateGoingNowFail = () => {
  return {
    type: actionTypes.TRIP_UPDATE_GOING_NOW_FAIL
  };
};

export const tripAllowGoingNowAdvance = (id, data) => {
  return {
    type: actionTypes.TRIP_UPDATE_GOING_NOW_ADVANCE,
    id,
    data
  };
};

export const tripAllowGoingNowAdvanceSuccess = () => {
  return {
    type: actionTypes.TRIP_UPDATE_GOING_NOW_ADVANCE_SUCCESS
  };
};

export const tripAllowGoingNowAdvanceFail = () => {
  return {
    type: actionTypes.TRIP_UPDATE_GOING_NOW_ADVANCE_FAIL
  };
};


export const tripRollbackGoingNow = (id, data) => {
  return {
    type: actionTypes.TRIP_ROLLBACK_GOING_NOW,
    id,
    data
  };
};

export const tripRollbackGoingNowSuccess = () => {
  return {
    type: actionTypes.TRIP_ROLLBACK_GOING_NOW_SUCCESS
  };
};

export const tripRollbackGoingNowFail = () => {
  return {
    type: actionTypes.TRIP_ROLLBACK_GOING_NOW_FAIL
  };
};

/* ===== END TRIP UPDATE GOING NOW ===== */

/* ===== TRIP SET STARTED  ===== */
export const tripSetStarted = (orderID, tripID, data) => {
  return {
    type: actionTypes.TRIP_SET_STARTED,
    orderID,
    tripID,
    data
  };
};

export const tripSetStartedSuccess = () => {
  return {
    type: actionTypes.TRIP_SET_STARTED_SUCCESS
  };
};

export const tripSetStartedFail = () => {
  return {
    type: actionTypes.TRIP_SET_STARTED_FAIL
  };
};
/* ===== END TRIP SET STARTED ===== */

/* ===== EDIT TRIP WEIGHING FIGURE  ===== */
export const editTripWeighingFigure = (id, data) => {
  return {
    type: actionTypes.EDIT_TRIP_WEIGHING_FIGURE,
    id,
    data
  };
};

export const editTripWeighingFigureSuccess = (data) => {
  return {
    type: actionTypes.EDIT_TRIP_WEIGHING_FIGURE_SUCCESS,
    data
  };
};

export const editTripWeighingFigureFail = () => {
  return {
    type: actionTypes.EDIT_TRIP_WEIGHING_FIGURE_FAIL
  };
};
/* ===== END EDIT TRIP WEIGHING FIGURE ===== */

/* ===== RESET COLLECTION TRIP  ===== */
export const resetCollectionTrip = (id, data) => {
  return {
    type: actionTypes.RESET_COLLECTION_TRIP,
    id,
    data
  };
};

export const resetCollectionTripSuccess = data => {
  return {
    type: actionTypes.RESET_COLLECTION_TRIP_SUCCESS, 
    data
  };
};

export const resetCollectionTripFail = () => {
  return {
    type: actionTypes.RESET_COLLECTION_TRIP_FAIL
  };
};
/* ===== END RESET COLLECTION TRIP ===== */


/* ===== ORDER INVOICE  ===== */
export const billingList = (data) => {
  return {
    type: actionTypes.BILLING_LIST,
    data,
  };
};

export const billingListSuccess = data => {
  return {
    type: actionTypes.BILLING_LIST_SUCCESS,
    data
  };
};

export const billingListFail = () => {
  return {
    type: actionTypes.BILLING_LIST_FAIL
  };
};


export const orderInvoice = (id, data) => {
  return {
    type: actionTypes.ORDER_INVOICE,
    id,
    data
  };
};

export const orderInvoiceSuccess = (data, amount_updated)  => {
  return {
    type: actionTypes.ORDER_INVOICE_SUCCESS,
    data,
    amount_updated
  };
};

export const orderInvoiceFail = () => {
  return {
    type: actionTypes.ORDER_INVOICE_FAIL
  };
};


export const orderInvoiceStack = (data) => {
  return {
    type: actionTypes.ORDER_INVOICE_STACK,
    data
  };
};

export const orderInvoiceStackSuccess = (data)  => {
  return {
    type: actionTypes.ORDER_INVOICE_STACK_SUCCESS,
    data
  };
};

export const orderInvoiceStackFail = () => {
  return {
    type: actionTypes.ORDER_INVOICE_STACK_FAIL
  };
};


export const agreementDebitCreditNoteLoad = (id, data) => {
  return {
    type: actionTypes.AGREEMENT_DEBIT_CREDIT_NOTE_LOAD,
    id,
    data
  };
};

export const agreementDebitCreditNoteLoadSuccess = data => {
  return {
    type: actionTypes.AGREEMENT_DEBIT_CREDIT_NOTE_LOAD_SUCCESS,
    data
  };
};

export const agreementDebitCreditNoteLoadFail = () => {
  return {
    type: actionTypes.AGREEMENT_DEBIT_CREDIT_NOTE_LOAD_FAIL
  };
};


export const agreementDebitCreditNoteStack = (data) => {
  return {
    type: actionTypes.AGREEMENT_DEBIT_CREDIT_NOTE_STACK,
    data
  };
};

export const agreementDebitCreditNoteStackSuccess = data => {
  return {
    type: actionTypes.AGREEMENT_DEBIT_CREDIT_NOTE_STACK_SUCCESS,
    data
  };
};

export const agreementDebitCreditNoteStackFail = () => {
  return {
    type: actionTypes.AGREEMENT_DEBIT_CREDIT_NOTE_STACK_FAIL
  };
};

/* ===== END ORDER INVOICE ===== */





/* ===== SPECIAL INVOICE  ===== */
export const specialInvoiceList = (data) => {
  return {
    type: actionTypes.SPECIAL_INVOICE_LIST,
    data,
  };
};

export const specialInvoiceListSuccess = data => {
  return {
    type: actionTypes.SPECIAL_INVOICE_LIST_SUCCESS,
    data
  };
};

export const specialInvoiceListFail = () => {
  return {
    type: actionTypes.SPECIAL_INVOICE_LIST_FAIL
  };
};


export const getSpecialInvoice = (id, data) => {
  return {
    type: actionTypes.GET_SPECIAL_INVOICE,
    id,
    data
  };
};

export const getSpecialInvoiceSuccess = data => {
  return {
    type: actionTypes.GET_SPECIAL_INVOICE_SUCCESS,
    data
  };
};

export const getSpecialInvoiceFail = () => {
  return {
    type: actionTypes.GET_SPECIAL_INVOICE_FAIL
  };
};


export const getSpecialInvoiceStack = (data) => {
  return {
    type: actionTypes.GET_SPECIAL_INVOICE_STACK,
    data
  };
};

export const getSpecialInvoiceStackSuccess = data => {
  return {
    type: actionTypes.GET_SPECIAL_INVOICE_STACK_SUCCESS,
    data
  };
};

export const getSpecialInvoiceStackFail = () => {
  return {
    type: actionTypes.GET_SPECIAL_INVOICE_STACK_FAIL
  };
};
/* ===== SPECIAL INVOICE  ===== */


/* ===== PURCHASE BILL  ===== */
export const purchaseBillList = (data) => {
  return {
    type: actionTypes.PURCHASE_BILL_LIST,
    data,
  };
};

export const purchaseBillListSuccess = data => {
  return {
    type: actionTypes.PURCHASE_BILL_LIST_SUCCESS,
    data
  };
};

export const purchaseBillListFail = () => {
  return {
    type: actionTypes.PURCHASE_BILL_LIST_FAIL
  };
};


export const purchaseBillDumpRecycling = (id, data) => {
  return {
    type: actionTypes.PURCHASE_BILL_DUMP_RECYCLING,
    id,
    data
  };
};

export const purchaseBillDumpRecyclingSuccess = data => {
  return {
    type: actionTypes.PURCHASE_BILL_DUMP_RECYCLING_SUCCESS,
    data
  };
};

export const purchaseBillDumpRecyclingFail = () => {
  return {
    type: actionTypes.PURCHASE_BILL_DUMP_RECYCLING_FAIL
  };
};


export const purchaseBillDumpRecyclingStack = (data) => {
  return {
    type: actionTypes.PURCHASE_BILL_DUMP_RECYCLING_STACK,
    data
  };
};

export const purchaseBillDumpRecyclingStackSuccess = data => {
  return {
    type: actionTypes.PURCHASE_BILL_DUMP_RECYCLING_STACK_SUCCESS,
    data
  };
};

export const purchaseBillDumpRecyclingStackFail = () => {
  return {
    type: actionTypes.PURCHASE_BILL_DUMP_RECYCLING_STACK_FAIL
  };
};


export const purchaseBillDumpExpress = (id, data) => {
  return {
    type: actionTypes.PURCHASE_BILL_DUMP_EXPRESS,
    id,
    data
  };
};

export const purchaseBillDumpExpressSuccess = data => {
  return {
    type: actionTypes.PURCHASE_BILL_DUMP_EXPRESS_SUCCESS,
    data
  };
};

export const purchaseBillDumpExpressFail = () => {
  return {
    type: actionTypes.PURCHASE_BILL_DUMP_EXPRESS_FAIL
  };
};

export const purchaseBillDumpExpressStack = (data) => {
  return {
    type: actionTypes.PURCHASE_BILL_DUMP_EXPRESS_STACK,
    data
  };
};

export const purchaseBillDumpExpressStackSuccess = data => {
  return {
    type: actionTypes.PURCHASE_BILL_DUMP_EXPRESS_STACK_SUCCESS,
    data
  };
};

export const purchaseBillDumpExpressStackFail = () => {
  return {
    type: actionTypes.PURCHASE_BILL_DUMP_EXPRESS_STACK_FAIL
  };
};



export const purchaseBillStatement = (data) => {
  return {
    type: actionTypes.PURCHASE_BILL_STATEMENT,
    data,
  };
};

export const purchaseBillStatementSuccess = data => {
  return {
    type: actionTypes.PURCHASE_BILL_STATEMENT_SUCCESS,
    data
  };
};

export const purchaseBillStatementFail = () => {
  return {
    type: actionTypes.PURCHASE_BILL_STATEMENT_FAIL
  };
};


/* ===== END PURCHASE BILL ===== */

/* ===== STORE RECEIPT  ===== */
export const storeReceiptList = (data) => {
  return {
    type: actionTypes.STORE_RECEIPT_LIST,
    data,
  };
};

export const storeReceiptListSuccess = data => {
  return {
    type: actionTypes.STORE_RECEIPT_LIST_SUCCESS,
    data
  };
};

export const storeReceiptListFail = () => {
  return {
    type: actionTypes.STORE_RECEIPT_LIST_FAIL
  };
};


export const getStoreReceiptDumpExpressStack = (data) => {
  return {
    type: actionTypes.GET_STORE_RECEIPT_DUMP_EXPRESS_STACK,
    data
  };
};

export const getStoreReceiptDumpExpressStackSuccess = data => {
  return {
    type: actionTypes.GET_STORE_RECEIPT_DUMP_EXPRESS_STACK_SUCCESS,
    data
  };
};

export const getStoreReceiptDumpExpressStackFail = () => {
  return {
    type: actionTypes.GET_STORE_RECEIPT_DUMP_EXPRESS_STACK_FAIL
  };
};


export const getStoreReceiptDumpRecyclingStack = (data) => {
  return {
    type: actionTypes.GET_STORE_RECEIPT_DUMP_RECYCLING_STACK,
    data
  };
};

export const getStoreReceiptDumpRecyclingStackSuccess = data => {
  return {
    type: actionTypes.GET_STORE_RECEIPT_DUMP_RECYCLING_STACK_SUCCESS,
    data
  };
};

export const getStoreReceiptDumpRecyclingStackFail = () => {
  return {
    type: actionTypes.GET_STORE_RECEIPT_DUMP_RECYCLING_STACK_FAIL
  };
};


/* ===== END STORE RECEIPT ===== */

/* ===== STORE RECEIPT  ===== */
export const wasteReceiptList = (data) => {
  return {
    type: actionTypes.WASTE_RECEIPT_LIST,
    data,
  };
};

export const wasteReceiptListSuccess = data => {
  return {
    type: actionTypes.WASTE_RECEIPT_LIST_SUCCESS,
    data
  };
};

export const wasteReceiptListFail = () => {
  return {
    type: actionTypes.WASTE_RECEIPT_LIST_FAIL
  };
};


export const getWasteReceiptStack = (data) => {
  return {
    type: actionTypes.GET_WASTE_RECEIPT_STACK,
    data
  };
};

export const getWasteReceiptStackSuccess = data => {
  return {
    type: actionTypes.GET_WASTE_RECEIPT_STACK_SUCCESS,
    data
  };
};

export const getWasteReceiptStackFail = () => {
  return {
    type: actionTypes.GET_WASTE_RECEIPT_STACK_FAIL
  };
};
/* ===== END STORE RECEIPT ===== */


/* ===== RECYCLABLE SALES INVOICE  ===== */
export const recyclableSalesInvoiceList = (data) => {
  return {
    type: actionTypes.RECYCLABLE_SALES_INVOICE_LIST,
    data,
  };
};

export const recyclableSalesInvoiceListSuccess = data => {
  return {
    type: actionTypes.RECYCLABLE_SALES_INVOICE_LIST_SUCCESS,
    data
  };
};

export const recyclableSalesInvoiceListFail = () => {
  return {
    type: actionTypes.RECYCLABLE_SALES_INVOICE_LIST_FAIL
  };
};


export const recyclableSalesInvoice = (id, data) => {
  return {
    type: actionTypes.RECYCLABLE_SALES_INVOICE,
    id,
    data
  };
};

export const recyclableSalesInvoiceSuccess = data => {
  return {
    type: actionTypes.RECYCLABLE_SALES_INVOICE_SUCCESS,
    data
  };
};

export const recyclableSalesInvoiceFail = () => {
  return {
    type: actionTypes.RECYCLABLE_SALES_INVOICE_FAIL
  };
};


export const recyclableSalesInvoiceStack = (data) => {
  return {
    type: actionTypes.RECYCLABLE_SALES_INVOICE_STACK,
    data
  };
};

export const recyclableSalesInvoiceStackSuccess = data => {
  return {
    type: actionTypes.RECYCLABLE_SALES_INVOICE_STACK_SUCCESS,
    data
  };
};

export const recyclableSalesInvoiceStackFail = () => {
  return {
    type: actionTypes.RECYCLABLE_SALES_INVOICE_STACK_FAIL
  };
};

/* ===== END RECYCLABLE SALES INVOICE ===== */


/* ===== BOOKING FEE  ===== */
export const bookingFeeList = (data) => {
  return {
    type: actionTypes.BOOKING_FEE_LIST,
    data,
  };
};

export const bookingFeeListSuccess = data => {
  return {
    type: actionTypes.BOOKING_FEE_LIST_SUCCESS,
    data
  };
};

export const bookingFeeListFail = () => {
  return {
    type: actionTypes.BOOKING_FEE_LIST_FAIL
  };
};
/* ===== END BOOKING FEE ===== */


/* ===== PROCESS FEE  ===== */
export const processFeeList = (data) => {
  return {
    type: actionTypes.PROCESS_FEE_LIST,
    data,
  };
};

export const processFeeListSuccess = data => {
  return {
    type: actionTypes.PROCESS_FEE_LIST_SUCCESS,
    data
  };
};

export const processFeeListFail = () => {
  return {
    type: actionTypes.PROCESS_FEE_LIST_FAIL
  };
};
/* ===== END PROCESS FEE ===== */


/* ===== LANDFILL FEE  ===== */
export const landfillFeeList = (data) => {
  return {
    type: actionTypes.LANDFILL_FEE_LIST,
    data,
  };
};

export const landfillFeeListSuccess = data => {
  return {
    type: actionTypes.LANDFILL_FEE_LIST_SUCCESS,
    data
  };
};

export const landfillFeeListFail = () => {
  return {
    type: actionTypes.LANDFILL_FEE_LIST_FAIL
  };
};
/* ===== END LANDFILL FEE ===== */


/* ===== USER LOADING FEE LIST  ===== */
export const userLoadingFeeList = (data) => {
  return {
    type: actionTypes.USER_LOADING_FEE_LIST,
    data,
  };
};

export const userLoadingFeeListSuccess = data => {
  return {
    type: actionTypes.USER_LOADING_FEE_LIST_SUCCESS,
    data
  };
};

export const userLoadingFeeListFail = () => {
  return {
    type: actionTypes.USER_LOADING_FEE_LIST_FAIL
  };
};
/* ===== END USER LOADING FEE LIST ===== */


/* ===== DRIVER LOADING PAYMENT LIST  ===== */
export const driverLoadingPaymentList = (data) => {
  return {
    type: actionTypes.DRIVER_LOADING_PAYMENT_LIST,
    data,
  };
};

export const driverLoadingPaymentListSuccess = data => {
  return {
    type: actionTypes.DRIVER_LOADING_PAYMENT_LIST_SUCCESS,
    data
  };
};

export const driverLoadingPaymentListFail = () => {
  return {
    type: actionTypes.DRIVER_LOADING_PAYMENT_LIST_FAIL
  };
};
/* ===== END DRIVER LOADING PAYMENT LIST ===== */


/* ===== SORTING FEE LIST  ===== */
export const sortingFeeList = (data) => {
  return {
    type: actionTypes.SORTING_FEE_LIST,
    data,
  };
};

export const sortingFeeListSuccess = data => {
  return {
    type: actionTypes.SORTING_FEE_LIST_SUCCESS,
    data
  };
};

export const sortingFeeListFail = () => {
  return {
    type: actionTypes.SORTING_FEE_LIST_FAIL
  };
};
/* ===== END SORTING FEE LIST ===== */


/* ===== OVERSIZE FEE LIST  ===== */
export const oversizeFeeList = (data) => {
  return {
    type: actionTypes.OVERSIZE_FEE_LIST,
    data,
  };
};

export const oversizeFeeListSuccess = data => {
  return {
    type: actionTypes.OVERSIZE_FEE_LIST_SUCCESS,
    data
  };
};

export const oversizeFeeListFail = () => {
  return {
    type: actionTypes.OVERSIZE_FEE_LIST_FAIL
  };
};
/* ===== END OVERSIZE FEE LIST ===== */


export const recyclablePurchaseList = (data) => {
  return {
    type: actionTypes.RECYCLABLE_PURCHASE_SUMMARY_LIST,
    data,
  };
};

export const recyclablePurchaseListSuccess = data => {
  return {
    type: actionTypes.RECYCLABLE_PURCHASE_SUMMARY_LIST_SUCCESS,
    data
  };
};

export const recyclablePurchaseListFail = () => {
  return {
    type: actionTypes.RECYCLABLE_PURCHASE_SUMMARY_LIST_FAIL
  };
}

/* ===== TRACK ORDER SUMMARY REPORT  ===== */

export const orderTrackReport = (id, data) => {
  return {
    type: actionTypes.ORDER_TRACK_REPORT,
    id,
    data
  };
};

export const orderTrackReportSuccess = data => {
  return {
    type: actionTypes.ORDER_TRACK_REPORT_SUCCESS,
    data
  };
};

export const orderTrackReportFail = () => {
  return {
    type: actionTypes.ORDER_TRACK_REPORT_FAIL
  };
};

/* ===== END TRACK ORDER SUMMARY REPORT ===== */


/* ===== ORDER TRIP STORE RECEIPT  ===== */

export const orderTripStoreReceipt = (id, data) => {
  return {
    type: actionTypes.ORDER_TRIP_STORE_RECEIPT,
    id,
    data
  };
};

export const orderTripStoreReceiptSuccess = data => {
  return {
    type: actionTypes.ORDER_TRIP_STORE_RECEIPT_SUCCESS,
    data
  };
};

export const orderTripStoreReceiptFail = () => {
  return {
    type: actionTypes.ORDER_TRIP_STORE_RECEIPT_FAIL
  };
};

/* ===== END ORDER TRIP STORE RECEIPT ===== */

/* ===== ORDER TRIP WASTE RECEIPT  ===== */

export const orderTripWasteReceipt = (id, data) => {
  return {
    type: actionTypes.ORDER_TRIP_WASTE_RECEIPT,
    id,
    data
  };
};

export const orderTripWasteReceiptSuccess = data => {
  return {
    type: actionTypes.ORDER_TRIP_WASTE_RECEIPT_SUCCESS,
    data
  };
};

export const orderTripWasteReceiptFail = () => {
  return {
    type: actionTypes.ORDER_TRIP_WASTE_RECEIPT_FAIL
  };
};

/* ===== END ORDER TRIP WASTE RECEIPT ===== */

/* ===== ORDER WASTE COLLECTION SUMMARY REPORT  ===== */

export const orderWasteSummary = (id, data) => {
  return {
    type: actionTypes.ORDER_WASTE_SUMMARY,
    id,
    data
  };
};

export const orderWasteSummarySuccess = data => {
  return {
    type: actionTypes.ORDER_WASTE_SUMMARY_SUCCESS,
    data
  };
};

export const orderWasteSummaryFail = () => {
  return {
    type: actionTypes.ORDER_WASTE_SUMMARY_FAIL
  };
};

/* ===== END ORDER WASTE COLLECTION SUMMARY REPORT ===== */

/* ===== ORDER TRANSACTION ===== */
export const loadTransactionList = data => {
  return {
    type: actionTypes.LOAD_TRANSACTION_LIST,
    data
  };
};

export const loadTransactionListSuccess = data => {
  return {
    type: actionTypes.LOAD_TRANSACTION_LIST_SUCCESS,
    data
  };
};

export const loadTransactionListFail = () => {
  return {
    type: actionTypes.LOAD_TRANSACTION_LIST_FAIL
  };
};

export const loadDuitNowTransactionList = data => {
  return {
    type: actionTypes.LOAD_DUITNOW_TRANSACTION_LIST,
    data
  };
};

export const loadDuitNowTransactionListSuccess = data => {
  return {
    type: actionTypes.LOAD_DUITNOW_TRANSACTION_LIST_SUCCESS,
    data
  };
};

export const loadDuitNowTransactionListFail = () => {
  return {
    type: actionTypes.LOAD_DUITNOW_TRANSACTION_LIST_FAIL
  };
};
/* ===== END ORDER TRANSACTION ===== */


/* ===== ORDER SUMMARY ===== */
export const loadOrderSummary = data => {
  return {
    type: actionTypes.LOAD_ORDER_SUMMARY,
    data
  };
};

export const loadOrderSummarySuccess = data => {
  return {
    type: actionTypes.LOAD_ORDER_SUMMARY_SUCCESS,
    data
  };
};

export const loadOrderSummaryFail = () => {
  return {
    type: actionTypes.LOAD_ORDER_SUMMARY_FAIL
  };
};


export const loadOrderSummaryChart = data => {
  return {
    type: actionTypes.LOAD_ORDER_SUMMARY_CHART,
    data
  };
};

export const loadOrderSummaryChartSuccess = data => {
  return {
    type: actionTypes.LOAD_ORDER_SUMMARY_CHART_SUCCESS,
    data
  };
};

export const loadOrderSummaryChartFail = () => {
  return {
    type: actionTypes.LOAD_ORDER_SUMMARY_CHART_FAIL
  };
};


export const loadOrderSaleSummary = data => {
  return {
    type: actionTypes.LOAD_ORDER_SALE_SUMMARY,
    data
  };
};

export const loadOrderSaleSummarySuccess = data => {
  return {
    type: actionTypes.LOAD_ORDER_SALE_SUMMARY_SUCCESS,
    data
  };
};

export const loadOrderSaleSummaryFail = () => {
  return {
    type: actionTypes.LOAD_ORDER_SALE_SUMMARY_FAIL
  };
};
/* ===== END ORDER SUMMARY ===== */




/* ===== DRIVER INCENTIVE ===== */
export const loadDriverIncentive = (id, data) => {

  return {
    type: actionTypes.LOAD_DRIVER_INCENTIVE,
    id,
    data
  };
};

export const loadDriverIncentiveSuccess = data => {
  return {
    type: actionTypes.LOAD_DRIVER_INCENTIVE_SUCCESS,
    data
  };
};

export const loadDriverIncentiveFail = () => {
  return {
    type: actionTypes.LOAD_DRIVER_INCENTIVE_FAIL
  };
};
/* ===== END DRIVER INCENTIVE ===== */


/* ===== USER ORDER HISTORY ===== */
export const loadUserOrderHistory = (id, data) => {

  return {
    type: actionTypes.LOAD_USER_ORDER_HISTORY,
    id,
    data
  };
};

export const loadUserOrderHistorySuccess = data => {
  return {
    type: actionTypes.LOAD_USER_ORDER_HISTORY_SUCCESS,
    data
  };
};

export const loadUserOrderHistoryFail = () => {
  return {
    type: actionTypes.LOAD_USER_ORDER_HISTORY_FAIL
  };
};
/* ===== END USER ORDER HISTORY ===== */


/* ===== MONTHLY WASTE COLLECTION SUMMARY REPORT  ===== */

export const monthlyWasteSummary = (user,branch, params) => {
  
  return {
    type: actionTypes.MONTHLY_WASTE_SUMMARY,
    user: (user) ? user : null,
    branch: (branch) ? branch : null,
    params,
  };
};

export const monthlyWasteSummarySuccess = data => {
  return {
    type: actionTypes.MONTHLY_WASTE_SUMMARY_SUCCESS,
    data
  };
};

export const monthlyWasteSummaryFail = () => {
  return {
    type: actionTypes.MONTHLY_WASTE_SUMMARY_FAIL
  };
};

export const monthlyWasteSummaryLoad = (user,branch, params) => {
  
  return {
    type: actionTypes.MONTHLY_WASTE_SUMMARY_LOAD,
    user: (user) ? user : null,
    branch: (branch) ? branch : null,
    params,
  };
};

export const monthlyWasteSummaryLoadSuccess = data => {
  return {
    type: actionTypes.MONTHLY_WASTE_SUMMARY_LOAD_SUCCESS,
    data
  };
};

export const monthlyWasteSummaryLoadFail = () => {
  return {
    type: actionTypes.MONTHLY_WASTE_SUMMARY_LOAD_FAIL
  };
};
/* ===== END MONTHLY WASTE COLLECTION SUMMARY REPORT ===== */


export const monthlyDriverTripSummary = (data) => {
  
  return {
    type: actionTypes.MONTHLY_DRIVER_TRIP_SUMMARY,
    data
  };
};

export const monthlyDriverTripSummarySuccess = data => {
  return {
    type: actionTypes.MONTHLY_DRIVER_TRIP_SUMMARY_SUCCESS,
    data
  };
};

export const monthlyDriverTripSummaryFail = () => {
  return {
    type: actionTypes.MONTHLY_DRIVER_TRIP_SUMMARY_FAIL
  };
};


export const resetDriverTripSummary = () => {
  
  return {
    type: actionTypes.RESET_DRIVER_TRIP_SUMMARY
  };
};


export const resetDriverTripSummarySuccess = () => {
  
  return {
    type: actionTypes.RESET_DRIVER_TRIP_SUMMARY_SUCCESS
  };
};



export const orderDistanceSummary = (data) => {
  
  return {
    type: actionTypes.ORDER_DISTANCE_SUMMARY,
    data
  };
};

export const orderDistanceSummarySuccess = data => {
  return {
    type: actionTypes.ORDER_DISTANCE_SUMMARY_SUCCESS,
    data
  };
};

export const orderDistanceSummaryFail = () => {
  return {
    type: actionTypes.ORDER_DISTANCE_SUMMARY_FAIL
  };
};




export const referralCommissionSummary = (data) => {
  return {
    type: actionTypes.LOAD_REFERRAL_COMMISSION_SUMMARY,
    data
  };
};

export const referralCommissionSummarySuccess = data => {

  return {
    type: actionTypes.LOAD_REFERRAL_COMMISSION_SUMMARY_SUCCESS,
    data
  };
};


export const referralCommissionSummaryFail = () => {
  return {
    type: actionTypes.LOAD_REFERRAL_COMMISSION_SUMMARY_FAIL
  };
};


/* ===== MONTHLY LANDFILL SUMMARY REPORT===== */

export const monthlyLandfillSummary = (id, params) => {
  return {
    type: actionTypes.MONTHLY_LANDFILL_SUMMARY,
    id: (id) ? id : null,
    params,
  };
};

export const monthlyLandfillSummarySuccess = data => {
  return {
    type: actionTypes.MONTHLY_LANDFILL_SUMMARY_SUCCESS,
    data
  };
};

export const monthlyLandfillSummaryFail = () => {
  return {
    type: actionTypes.MONTHLY_LANDFILL_SUMMARY_FAIL
  };
};

export const monthlyLandfillSummaryLoad = (id, params) => {
  
  return {
    type: actionTypes.MONTHLY_LANDFILL_SUMMARY_LOAD,
    id: (id) ? id : null,
    params,
  };
};

export const monthlyLandfillSummaryLoadSuccess = data => {
  return {
    type: actionTypes.MONTHLY_LANDFILL_SUMMARY_LOAD_SUCCESS,
    data
  };
};

export const monthlyLandfillSummaryLoadFail = () => {
  return {
    type: actionTypes.MONTHLY_LANDFILL_SUMMARY_LOAD_FAIL
  };
};

/* ===== END MONTHLY LANDFILL SUMMARY REPORT ===== */

/* ===== MONTHLY STOCK SUMMARY REPORT  ===== */

export const monthlyStockSummary = (data) => {
  return {
    type: actionTypes.MONTHLY_STOCK_SUMMARY,
    data,
  };
};

export const monthlyStockSummarySuccess = data => {
  return {
    type: actionTypes.MONTHLY_STOCK_SUMMARY_SUCCESS,
    data
  };
};

export const monthlyStockSummaryFail = () => {
  return {
    type: actionTypes.MONTHLY_STOCK_SUMMARY_FAIL
  };
};

export const monthlyStockSummaryLoad = (data) => {
  
  return {
    type: actionTypes.MONTHLY_STOCK_SUMMARY_LOAD,
    data
  };
};

export const monthlyStockSummaryLoadSuccess = data => {
  return {
    type: actionTypes.MONTHLY_STOCK_SUMMARY_LOAD_SUCCESS,
    data
  };
};

export const monthlyStockSummaryLoadFail = () => {
  return {
    type: actionTypes.MONTHLY_STOCK_SUMMARY_LOAD_FAIL
  };
};



export const monthlyWasteStockSummary = (data) => {
  return {
    type: actionTypes.MONTHLY_WASTE_STOCK_SUMMARY,
    data,
  };
};

export const monthlyWasteStockSummarySuccess = data => {
  return {
    type: actionTypes.MONTHLY_WASTE_STOCK_SUMMARY_SUCCESS,
    data
  };
};

export const monthlyWasteStockSummaryFail = () => {
  return {
    type: actionTypes.MONTHLY_WASTE_STOCK_SUMMARY_FAIL
  };
};

export const monthlyWasteStockSummaryLoad = (data) => {
  
  return {
    type: actionTypes.MONTHLY_WASTE_STOCK_SUMMARY_LOAD,
    data
  };
};

export const monthlyWasteStockSummaryLoadSuccess = data => {
  return {
    type: actionTypes.MONTHLY_WASTE_STOCK_SUMMARY_LOAD_SUCCESS,
    data
  };
};

export const monthlyWasteStockSummaryLoadFail = () => {
  return {
    type: actionTypes.MONTHLY_WASTE_STOCK_SUMMARY_LOAD_FAIL
  };
};
/* ===== END MONTHLY STOCK SUMMARY REPORT ===== */


/* ===== ADJUSTMENT AGREEMENT  ===== */
export const loadAdjAgreementList = data => {
  return {
    type: actionTypes.LOAD_ADJUSTMENT_AGREEMENT_LIST,
    data
  };
};

export const loadAdjAgreementListSuccess = data => {
  return {
    type: actionTypes.LOAD_ADJUSTMENT_AGREEMENT_LIST_SUCCESS,
    data
  };
};

export const loadAdjAgreementListFail = () => {
  return {
    type: actionTypes.LOAD_ADJUSTMENT_AGREEMENT_LIST_FAIL
  };
};

export const loadAdjAgreementDetail = (id, data) => {
  return {
    type: actionTypes.LOAD_ADJUSTMENT_AGREEMENT_DETAIL,
    id,
    data
  };
};

export const loadAdjAgreementDetailSuccess = data => {
  return {
    type: actionTypes.LOAD_ADJUSTMENT_AGREEMENT_DETAIL_SUCCESS,
    data
  };
};

export const loadAdjAgreementDetailFail = () => {
  return {
    type: actionTypes.LOAD_ADJUSTMENT_AGREEMENT_DETAIL_FAIL
  };
};



export const agreementProceeRefund = data => {
  return {
    type: actionTypes.AGREEMENT_PROCESS_REFUND,
    data
  };
};

export const agreementProceeRefundSuccess = data => {
  return {
    type: actionTypes.AGREEMENT_PROCESS_REFUND_SUCCESS,
    data
  };
};

export const agreementProceeRefundFail = () => {
  return {
    type: actionTypes.AGREEMENT_PROCESS_REFUND_FAIL
  };
};

export const loadAdjAffectedOrderList = (id, data) => {
  return {
    type: actionTypes.LOAD_ADJUSTMENT_AFFECTED_ORDER_LIST,
    data,
    id
  };
};

export const loadAdjAffectedOrderListSuccess = data => {
  return {
    type: actionTypes.LOAD_ADJUSTMENT_AFFECTED_ORDER_LIST_SUCCESS,
    data
  };
};

export const loadAdjAffectedOrderListFail = () => {
  return {
    type: actionTypes.LOAD_ADJUSTMENT_AFFECTED_ORDER_LIST_FAIL
  };
};

/* ===== END ADJUSTMENT AGREEMENT ===== */



/* ===== CANCEL ORDER REFUND ===== */

export const cancelOrderRefundList = (data) => {
  return {
    type: actionTypes.CANCEL_ORDER_REFUND_LIST,
    data
  };
};

export const cancelOrderRefundListSuccess = data => {
  return {
    type: actionTypes.CANCEL_ORDER_REFUND_LIST_SUCCESS,
    data
  };
};

export const cancelOrderRefundListFail = () => {
  return {
    type: actionTypes.CANCEL_ORDER_REFUND_LIST_FAIL
  };
};



export const cancelOrderRefundDetail = (id, data) => {
  return {
    type: actionTypes.CANCEL_ORDER_REFUND_DETAIL,
    id,
    data
  };
};

export const cancelOrderRefundDetailSuccess = data => {
  return {
    type: actionTypes.CANCEL_ORDER_REFUND_DETAIL_SUCCESS,
    data
  };
};

export const cancelOrderRefundDetailFail = () => {
  return {
    type: actionTypes.CANCEL_ORDER_REFUND_DETAIL_FAIL
  };
};



export const cancelOrderProcessRefund = (id, data) => {
  return {
    type: actionTypes.CANCEL_ORDER_PROCESS_REFUND,
    id,
    data
  };
};

export const cancelOrderProcessRefundSuccess = () => {
  return {
    type: actionTypes.CANCEL_ORDER_PROCESS_REFUND_SUCCESS
  };
};

export const cancelOrderProcessRefundFail = () => {
  return {
    type: actionTypes.CANCEL_ORDER_PROCESS_REFUND_FAIL
  };
};

/* ===== END CANCEL ORDER REFUND ===== */




/* ===== REASSIGN TRIP ===== */
export const reassignTrip = (id, data) => {
  return {
    type: actionTypes.REASSIGN_TRIP,
    id,
    data
  };
};

export const reassignTripSuccess = (data) => {
  return {
    type: actionTypes.REASSIGN_TRIP_SUCCESS,
    data
  };
};

export const reassignTripFail = () => {
  return {
    type: actionTypes.REASSIGN_TRIP_FAIL
  };
};
/* ===== END REASSIGN TRIP ===== */




/* ===== MAKE IT TODAY TRIP ===== */
export const makeItTodayTrip = (id, data) => {
  return {
    type: actionTypes.MAKE_IT_TODAY_TRIP,
    id,
    data
  };
};

export const makeItTodayTripSuccess = () => {
  return {
    type: actionTypes.MAKE_IT_TODAY_TRIP_SUCCESS
  };
};

export const makeItTodayTripFail = () => {
  return {
    type: actionTypes.MAKE_IT_TODAY_TRIP_FAIL
  };
};
/* ===== END MAKE IT TODAY TRIP ===== */


/* ===== COLLECTION REQUEST ===== */

export const collectionRequestCount = (data) => {
  return {
    type: actionTypes.COLLECTION_REQUEST_COUNT,
    data
  };
};

export const collectionRequestCountSuccess = (data) => {
  return {
    type: actionTypes.COLLECTION_REQUEST_COUNT_SUCCESS,
    data
  };
};

export const collectionRequestCountFail = () => {
  return {
    type: actionTypes.COLLECTION_REQUEST_COUNT_FAIL
  };
};

export const collectionRequestLoad = (data) => {
  return {
    type: actionTypes.COLLECTION_REQUEST_LOAD,
    data
  };
};

export const collectionRequestLoadSuccess = (data) => {
  return {
    type: actionTypes.COLLECTION_REQUEST_LOAD_SUCCESS,
    data
  };
};

export const collectionRequestLoadFail = () => {
  return {
    type: actionTypes.COLLECTION_REQUEST_LOAD_FAIL
  };
};



export const collectionRequestApprove = (id, data) => {
  return {
    type: actionTypes.COLLECTION_REQUEST_APPROVE,
    id,
    data
  };
};

export const collectionRequestApproveSuccess = () => {
  return {
    type: actionTypes.COLLECTION_REQUEST_APPROVE_SUCCESS
  };
};

export const collectionRequestApproveFail = () => {
  return {
    type: actionTypes.COLLECTION_REQUEST_APPROVE_FAIL
  };
};


export const collectionRequestReject = (id, data) => {
  return {
    type: actionTypes.COLLECTION_REQUEST_REJECT,
    id,
    data
  };
};

export const collectionRequestRejectSuccess = () => {
  return {
    type: actionTypes.COLLECTION_REQUEST_REJECT_SUCCESS
  };
};

export const collectionRequestRejectFail = () => {
  return {
    type: actionTypes.COLLECTION_REQUEST_REJECT_FAIL
  };
};
/* ===== END COLLECTION REQUEST ===== */



/* ===== FILLING_EXTENSION REQUEST ===== */

export const fillingExtensionRequestCount = (data) => {
  return {
    type: actionTypes.FILLING_EXTENSION_REQUEST_COUNT,
    data
  };
};

export const fillingExtensionRequestCountSuccess = (data) => {
  return {
    type: actionTypes.FILLING_EXTENSION_REQUEST_COUNT_SUCCESS,
    data
  };
};

export const fillingExtensionRequestCountFail = () => {
  return {
    type: actionTypes.FILLING_EXTENSION_REQUEST_COUNT_FAIL
  };
};

export const fillingExtensionRequestLoad = (data) => {
  return {
    type: actionTypes.FILLING_EXTENSION_REQUEST_LOAD,
    data
  };
};

export const fillingExtensionRequestLoadSuccess = (data) => {
  return {
    type: actionTypes.FILLING_EXTENSION_REQUEST_LOAD_SUCCESS,
    data
  };
};

export const fillingExtensionRequestLoadFail = () => {
  return {
    type: actionTypes.FILLING_EXTENSION_REQUEST_LOAD_FAIL
  };
};


export const fillingExtensionRequestDetail = (id, data) => {
  return {
    type: actionTypes.FILLING_EXTENSION_REQUEST_DETAIL,
    id,
    data
  };
};

export const fillingExtensionRequestDetailSuccess = (data) => {
  return {
    type: actionTypes.FILLING_EXTENSION_REQUEST_DETAIL_SUCCESS,
    data
  };
};

export const fillingExtensionRequestDetailFail = () => {
  return {
    type: actionTypes.FILLING_EXTENSION_REQUEST_DETAIL_FAIL
  };
};


export const fillingExtensionRequestApprove = (id, data) => {
  return {
    type: actionTypes.FILLING_EXTENSION_REQUEST_APPROVE,
    id,
    data
  };
};

export const fillingExtensionRequestApproveSuccess = () => {
  return {
    type: actionTypes.FILLING_EXTENSION_REQUEST_APPROVE_SUCCESS
  };
};

export const fillingExtensionRequestApproveFail = () => {
  return {
    type: actionTypes.FILLING_EXTENSION_REQUEST_APPROVE_FAIL
  };
};


export const fillingExtensionRequestReject = (id, data) => {
  return {
    type: actionTypes.FILLING_EXTENSION_REQUEST_REJECT,
    id,
    data
  };
};

export const fillingExtensionRequestRejectSuccess = () => {
  return {
    type: actionTypes.FILLING_EXTENSION_REQUEST_REJECT_SUCCESS
  };
};

export const fillingExtensionRequestRejectFail = () => {
  return {
    type: actionTypes.FILLING_EXTENSION_REQUEST_REJECT_FAIL
  };
};
/* ===== END FILLING_EXTENSION REQUEST ===== */


/* ===== MADE_IT_TODAY REQUEST ===== */

export const madeItTodayRequestCount = (data) => {
  return {
    type: actionTypes.MADE_IT_TODAY_REQUEST_COUNT,
    data
  };
};

export const madeItTodayRequestCountSuccess = (data) => {
  return {
    type: actionTypes.MADE_IT_TODAY_REQUEST_COUNT_SUCCESS,
    data
  };
};

export const madeItTodayRequestCountFail = () => {
  return {
    type: actionTypes.MADE_IT_TODAY_REQUEST_COUNT_FAIL
  };
};

export const madeItTodayRequestLoad = (data) => {
  return {
    type: actionTypes.MADE_IT_TODAY_REQUEST_LOAD,
    data
  };
};

export const madeItTodayRequestLoadSuccess = (data) => {
  return {
    type: actionTypes.MADE_IT_TODAY_REQUEST_LOAD_SUCCESS,
    data
  };
};

export const madeItTodayRequestLoadFail = () => {
  return {
    type: actionTypes.MADE_IT_TODAY_REQUEST_LOAD_FAIL
  };
};


export const madeItTodayRequestDetail = (id, data) => {
  return {
    type: actionTypes.MADE_IT_TODAY_REQUEST_DETAIL,
    id,
    data
  };
};

export const madeItTodayRequestDetailSuccess = (data) => {
  return {
    type: actionTypes.MADE_IT_TODAY_REQUEST_DETAIL_SUCCESS,
    data
  };
};

export const madeItTodayRequestDetailFail = () => {
  return {
    type: actionTypes.MADE_IT_TODAY_REQUEST_DETAIL_FAIL
  };
};


export const madeItTodayRequestApprove = (id, data) => {
  return {
    type: actionTypes.MADE_IT_TODAY_REQUEST_APPROVE,
    id,
    data
  };
};

export const madeItTodayRequestApproveSuccess = () => {
  return {
    type: actionTypes.MADE_IT_TODAY_REQUEST_APPROVE_SUCCESS
  };
};

export const madeItTodayRequestApproveFail = () => {
  return {
    type: actionTypes.MADE_IT_TODAY_REQUEST_APPROVE_FAIL
  };
};


export const madeItTodayRequestReject = (id, data) => {
  return {
    type: actionTypes.MADE_IT_TODAY_REQUEST_REJECT,
    id,
    data
  };
};

export const madeItTodayRequestRejectSuccess = () => {
  return {
    type: actionTypes.MADE_IT_TODAY_REQUEST_REJECT_SUCCESS
  };
};

export const madeItTodayRequestRejectFail = () => {
  return {
    type: actionTypes.MADE_IT_TODAY_REQUEST_REJECT_FAIL
  };
};
/* ===== END MADE_IT_TODAY REQUEST ===== */


/* ===== ADD_ON_TRIP REQUEST ===== */

export const addonTripRequestCount = (data) => {
  return {
    type: actionTypes.ADD_ON_TRIP_REQUEST_COUNT,
    data
  };
};

export const addonTripRequestCountSuccess = (data) => {
  return {
    type: actionTypes.ADD_ON_TRIP_REQUEST_COUNT_SUCCESS,
    data
  };
};

export const addonTripRequestCountFail = () => {
  return {
    type: actionTypes.ADD_ON_TRIP_REQUEST_COUNT_FAIL
  };
};

export const addonTripRequestLoad = (data) => {
  return {
    type: actionTypes.ADD_ON_TRIP_REQUEST_LOAD,
    data
  };
};

export const addonTripRequestLoadSuccess = (data) => {
  return {
    type: actionTypes.ADD_ON_TRIP_REQUEST_LOAD_SUCCESS,
    data
  };
};

export const addonTripRequestLoadFail = () => {
  return {
    type: actionTypes.ADD_ON_TRIP_REQUEST_LOAD_FAIL
  };
};


export const addonTripRequestDetail = (id, data) => {
  return {
    type: actionTypes.ADD_ON_TRIP_REQUEST_DETAIL,
    id,
    data
  };
};

export const addonTripRequestDetailSuccess = (data) => {
  return {
    type: actionTypes.ADD_ON_TRIP_REQUEST_DETAIL_SUCCESS,
    data
  };
};

export const addonTripRequestDetailFail = () => {
  return {
    type: actionTypes.ADD_ON_TRIP_REQUEST_DETAIL_FAIL
  };
};


export const addonTripRequestApprove = (id, data) => {
  return {
    type: actionTypes.ADD_ON_TRIP_REQUEST_APPROVE,
    id,
    data
  };
};

export const addonTripRequestApproveSuccess = () => {
  return {
    type: actionTypes.ADD_ON_TRIP_REQUEST_APPROVE_SUCCESS
  };
};

export const addonTripRequestApproveFail = () => {
  return {
    type: actionTypes.ADD_ON_TRIP_REQUEST_APPROVE_FAIL
  };
};


export const addonTripRequestReject = (id, data) => {
  return {
    type: actionTypes.ADD_ON_TRIP_REQUEST_REJECT,
    id,
    data
  };
};

export const addonTripRequestRejectSuccess = () => {
  return {
    type: actionTypes.ADD_ON_TRIP_REQUEST_REJECT_SUCCESS
  };
};

export const addonTripRequestRejectFail = () => {
  return {
    type: actionTypes.ADD_ON_TRIP_REQUEST_REJECT_FAIL
  };
};
/* ===== END ADD_ON_TRIP REQUEST ===== */


export const findDriverSimulate = (id, data) => {
  return {
    type: actionTypes.FIND_DRIVER_SIMULATE,
    id,
    data
  };
};

export const findDriverSimulateSuccess = (data) => {
  return {
    type: actionTypes.FIND_DRIVER_SIMULATE_SUCCESS,
    data
  };
};

export const findDriverSimulateFail = () => {
  return {
    type: actionTypes.FIND_DRIVER_SIMULATE_FAIL
  };
};

/* ===== GRADING  ===== */
export const gradingListLoad = (data) => {
  return {
    type: actionTypes.LOAD_GRADING_LIST,
    data
  };
};

export const gradingListLoadSuccess = (data) => {
  return {
    type: actionTypes.LOAD_GRADING_LIST_SUCCESS,
    data
  };
};

export const gradingListLoadFail = () => {
  return {
    type: actionTypes.LOAD_GRADING_LIST_FAIL
  };
};

export const gradingCount = (data) => {
  return {
    type: actionTypes.LOAD_GRADING_COUNT,
    data
  };
};

export const gradingCountSuccess = (data) => {
  return {
    type: actionTypes.LOAD_GRADING_COUNT_SUCCESS,
    data
  };
};

export const gradingCountFail = () => {
  return {
    type: actionTypes.LOAD_GRADING_COUNT_FAIL
  };
};

export const pendingGradingListLoad = (data) => {
  return {
    type: actionTypes.LOAD_PENDING_GRADING_LIST,
    data
  };
};

export const pendingGradingListLoadSuccess = (data) => {
  return {
    type: actionTypes.LOAD_PENDING_GRADING_LIST_SUCCESS,
    data
  };
};

export const pendingGradingListLoadFail = () => {
  return {
    type: actionTypes.LOAD_PENDING_GRADING_LIST_FAIL
  };
};

export const pendingGradingCount = (data) => {
  return {
    type: actionTypes.LOAD_PENDING_GRADING_COUNT,
    data
  };
};

export const pendingGradingCountSuccess = (data) => {
  return {
    type: actionTypes.LOAD_PENDING_GRADING_COUNT_SUCCESS,
    data
  };
};

export const pendingGradingCountFail = () => {
  return {
    type: actionTypes.LOAD_PENDING_GRADING_COUNT_FAIL
  };
};



export const pendingWasteGradingListLoad = (data) => {
  return {
    type: actionTypes.LOAD_PENDING_WASTE_GRADING_LIST,
    data
  };
};

export const pendingWasteGradingListLoadSuccess = (data) => {
  return {
    type: actionTypes.LOAD_PENDING_WASTE_GRADING_LIST_SUCCESS,
    data
  };
};

export const pendingWasteGradingListLoadFail = () => {
  return {
    type: actionTypes.LOAD_PENDING_WASTE_GRADING_LIST_FAIL
  };
};

export const pendingWasteGradingCount = (data) => {
  return {
    type: actionTypes.LOAD_PENDING_WASTE_GRADING_COUNT,
    data
  };
};

export const pendingWasteGradingCountSuccess = (data) => {
  return {
    type: actionTypes.LOAD_PENDING_WASTE_GRADING_COUNT_SUCCESS,
    data
  };
};

export const pendingWasteGradingCountFail = () => {
  return {
    type: actionTypes.LOAD_PENDING_WASTE_GRADING_COUNT_FAIL
  };
};


/* ===== END GRADING  ===== */


/* ===== MPRM Process  ===== */
export const MPRMProcessList = (data) => {
  return {
    type: actionTypes.MPRM_PROCESS_LIST,
    data
  };
};

export const MPRMProcessListSuccess = (data) => {
  return {
    type: actionTypes.MPRM_PROCESS_LIST_SUCCESS,
    data
  };
};

export const MPRMProcessListFail = () => {
  return {
    type: actionTypes.MPRM_PROCESS_LIST_FAIL
  };
};

export const MPRMProcessDetail = (id,data) => {
  return {
    type: actionTypes.MPRM_PROCESS_DETAIL,
    id,
    data
  };
};

export const MPRMProcessDetailSuccess = (data) => {
  return {
    type: actionTypes.MPRM_PROCESS_DETAIL_SUCCESS,
    data
  };
};

export const MPRMProcessDetailFail = () => {
  return {
    type: actionTypes.MPRM_PROCESS_DETAIL_FAIL
  };
};

export const MPRMProcessRetry = (id, data) => {
  return {
    type: actionTypes.MPRM_PROCESS_RETRY,
    id,
    data
  };
};

export const MPRMProcessRetrySuccess = () => {
  return {
    type: actionTypes.MPRM_PROCESS_RETRY_SUCCESS
  };
};

export const MPRMProcessRetryFail = () => {
  return {
    type: actionTypes.MPRM_PROCESS_RETRY_FAIL
  };
};

export const MPRMProcessReset = (id, data) => {
  return {
    type: actionTypes.MPRM_PROCESS_RESET,
    id,
    data
  };
};

export const MPRMProcessResetSuccess = () => {
  return {
    type: actionTypes.MPRM_PROCESS_RESET_SUCCESS
  };
};

export const MPRMProcessResetFail = () => {
  return {
    type: actionTypes.MPRM_PROCESS_RESET_FAIL
  };
};

/* ===== END MPRM Process  ===== */



/* ===== EXPRESS ===== */
export const expressOrderCount = (data) => {
  
  return {
    type: actionTypes.LOAD_EXPRESS_ORDER_COUNT,
    data
  };
};

export const expressOrderCountSuccess = data => {
  return {
    type: actionTypes.LOAD_EXPRESS_ORDER_COUNT_SUCCESS,
    data
  };
};

export const expressOrderCountFail = () => {
  return {
    type: actionTypes.LOAD_EXPRESS_ORDER_COUNT_FAIL
  };
};

export const loadExpressOrderList = data => {
  return {
    type: actionTypes.LOAD_EXPRESS_ORDER_LIST,
    data
  };
};

export const loadExpressOrderListSuccess = data => {
  return {
    type: actionTypes.LOAD_EXPRESS_ORDER_LIST_SUCCESS,
    data
  };
};

export const loadExpressOrderListFail = () => {
  return {
    type: actionTypes.LOAD_EXPRESS_ORDER_LIST_FAIL
  };
};


export const loadExpressOrderDetail = (id, data) => {
  
  return {
    type: actionTypes.LOAD_EXPRESS_ORDER_DETAIL,
    id,
    data
  };
};

export const loadExpressOrderDetailSuccess = data => {
  
  return {
    type: actionTypes.LOAD_EXPRESS_ORDER_DETAIL_SUCCESS,
    data
  };
};

export const loadExpressOrderDetailFail = () => {
  return {
    type: actionTypes.LOAD_EXPRESS_ORDER_DETAIL_FAIL
  };
};


export const cancelExpressOrder = (id, data) => {
  
  return {
    type: actionTypes.CANCEL_EXPRESS_ORDER,
    id,
    data
  };
};

export const cancelExpressOrderSuccess = () => {
  
  return {
    type: actionTypes.CANCEL_EXPRESS_ORDER_SUCCESS
  };
};

export const cancelExpressOrderFail = () => {
  return {
    type: actionTypes.CANCEL_EXPRESS_ORDER_FAIL
  };
};


export const expressStoreReceipt = (id, data) => {
  return {
    type: actionTypes.EXPRESS_STORE_RECEIPT,
    id,
    data
  };
};

export const expressStoreReceiptSuccess = data => {
  return {
    type: actionTypes.EXPRESS_STORE_RECEIPT_SUCCESS,
    data
  };
};

export const expressStoreReceiptFail = () => {
  return {
    type: actionTypes.EXPRESS_STORE_RECEIPT_FAIL
  };
};


export const loadExpressOrderSummary = data => {
  return {
    type: actionTypes.LOAD_EXPRESS_SUMMARY,
    data
  };
};

export const loadExpressOrderSummarySuccess = data => {
  return {
    type: actionTypes.LOAD_EXPRESS_SUMMARY_SUCCESS,
    data
  };
};

export const loadExpressOrderSummaryFail = () => {
  return {
    type: actionTypes.LOAD_EXPRESS_SUMMARY_FAIL
  };
};


export const loadExpressOrderSummaryChart = data => {
  return {
    type: actionTypes.LOAD_EXPRESS_SUMMARY_CHART,
    data
  };
};

export const loadExpressOrderSummaryChartSuccess = data => {
  return {
    type: actionTypes.LOAD_EXPRESS_SUMMARY_CHART_SUCCESS,
    data
  };
};

export const loadExpressOrderSummaryChartFail = () => {
  return {
    type: actionTypes.LOAD_EXPRESS_SUMMARY_CHART_FAIL
  };
};


export const loadExpressOrderSaleSummary = data => {
  return {
    type: actionTypes.LOAD_EXPRESS_SALE_SUMMARY,
    data
  };
};

export const loadExpressOrderSaleSummarySuccess = data => {
  return {
    type: actionTypes.LOAD_EXPRESS_SALE_SUMMARY_SUCCESS,
    data
  };
};

export const loadExpressOrderSaleSummaryFail = () => {
  return {
    type: actionTypes.LOAD_EXPRESS_SALE_SUMMARY_FAIL
  };
};

export const graderDailySettlement = data => {
  return {
    type: actionTypes.LOAD_GRADER_DAILY_SETTLEMENT,
    data
  };
};

export const graderDailySettlementSuccess = data => {
  return {
    type: actionTypes.LOAD_GRADER_DAILY_SETTLEMENT_SUCCESS,
    data
  };
};

export const graderDailySettlementFail = () => {
  return {
    type: actionTypes.LOAD_GRADER_DAILY_SETTLEMENT_FAIL
  };
};

/* ===== END EXPRESS ===== */