import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
import Helper from "../../helpers/helpers";

export function* loadDumpsterMeasurementList() {
  
  yield takeEvery(actionTypes.LOAD_DUMPSTER_MEASUREMENT_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DUMPMEASUREMENTLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDumpsterMeasurementList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDumpsterMeasurementListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDumpsterMeasurementListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDumpsterMeasurementListFail());
    }
  });
}

export function* addDumpsterMeasurement() {
  yield takeEvery(actionTypes.ADD_DUMPSTER_MEASUREMENT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ADDDUMPMEASUREMENT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.addDumpsterMeasurement(action.data);
      if (response.data.success) {
        yield put(actions.addDumpsterMeasurementSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.addDumpsterMeasurementFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.addDumpsterMeasurementFail());
    }
  });
}

export function* editDumpsterMeasurement() {
  yield takeEvery(actionTypes.EDIT_DUMPSTER_MEASUREMENT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITDUMPMEASUREMENT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editDumpsterMeasurement(action.id,action.data);
      if (response.data.success) {
        yield put(actions.editDumpsterMeasurementSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.editDumpsterMeasurementFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editDumpsterMeasurementFail());
    }
  });
}

export function* deleteDumpsterMeasurement() {
  yield takeEvery(actionTypes.DELETE_DUMPSTER_MEASUREMENT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DELDUMPMEASUREMENT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.deleteDumpsterMeasurement(action.id, action.data);
      if (response.data.success) {
        yield put(actions.deleteDumpsterMeasurementSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.deleteDumpsterMeasurementFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.deleteDumpsterMeasurementFail());
    }
  });
}

export function* loadDumpsterServiceList() {
  
  yield takeEvery(actionTypes.LOAD_DUMPSTER_SERVICE_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DUMPSERVICELIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDumpsterServiceList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDumpsterServiceListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDumpsterServiceListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDumpsterServiceListFail());
    }
  });
}

export function* updateDumpsterService() {
  
  yield takeEvery(actionTypes.UPDATE_DUMPSTER_SERVICE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDDUMPSERVICE");

    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.updateDumpsterService(action.id, action.data);
      if (response.data.success) {
        yield put(actions.updateDumpsterServiceSuccess());
      } else {
        alert(response.data.message);
        yield put(actions.updateDumpsterServiceFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.updateDumpsterServiceFail());
    }
  });
}



export function* loadDumpsterList() {
  
  yield takeEvery(actionTypes.LOAD_DUMPSTER_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DUMPLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDumpsterList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDumpsterListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDumpsterListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDumpsterListFail());
    }
  });
}

export function* addDumpster() {
  yield takeEvery(actionTypes.ADD_DUMPSTER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ADDDUMP");

    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.addDumpster(action.data);
      if (response.data.success) {
        yield put(actions.addDumpsterSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.addDumpsterFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.addDumpsterFail());
    }
  });
}

export function* editDumpster() {
  yield takeEvery(actionTypes.EDIT_DUMPSTER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDIDUMP");

    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editDumpster(action.id,action.data);
      if (response.data.success) {
        yield put(actions.editDumpsterSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.editDumpsterFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editDumpsterFail());
    }
  });
}


export function* updateDumpsterWasteProcessCost() {
  yield takeEvery(actionTypes.UPDATE_DUMPSTER_WASTE_PROCESS_COST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDDUMWASTEPPROCESSCOST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.updateDumpsterWasteProcessCost(action.id,action.data);
      if (response.data.success) {
        yield put(actions.updateDumpsterWasteProcessCostSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.updateDumpsterWasteProcessCostFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.updateDumpsterWasteProcessCostFail());
    }
  });
}


export function* updateDumpsterRecyclingProcessCost() {
  yield takeEvery(actionTypes.UPDATE_DUMPSTER_RECYCLING_PROCESS_COST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDDUMPRECYCLINGPROCESSCOST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.updateDumpsterRecyclingProcessCost(action.id,action.data);
      if (response.data.success) {
        yield put(actions.updateDumpsterRecyclingProcessCostSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.updateDumpsterRecyclingProcessCostFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.updateDumpsterRecyclingProcessCostFail());
    }
  });
}

export function* deleteDumpster() {
  yield takeEvery(actionTypes.DELETE_DUMPSTER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DELDUMP");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.deleteDumpster(action.id, action.data);
      if (response.data.success) {
        yield put(actions.deleteDumpsterSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.deleteDumpsterFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.deleteDumpsterFail());
    }
  });
}


export function* loadDumpsterDetail() {
  
  // call loadDumpsterDetail function at actions/dumpster_action.js
  yield takeEvery(actionTypes.LOAD_DUMPSTER_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DUMPDETAIL");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      // call loadDumpsterDetail API at api/dumpster_api.js
      const response = yield API.loadDumpsterDetail(action.id, action.data);
      
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadDumpsterDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDumpsterDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDumpsterDetailFail());
    }
  });
}


export function* loadDumpsterExtraFee() {
  
  yield takeEvery(actionTypes.LOAD_DUMPSTER_EXTRA_FEE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DUMPEXTRAFEELIST");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadDumpsterExtraFee(action.id,action.data);
      
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadDumpsterExtraFeeSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDumpsterExtraFeeFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDumpsterExtraFeeFail());
    }
  });
}



export function* driverDumpsterCount() {
  yield takeEvery(actionTypes.DRIVER_DUMPSTER_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DRIVERDUMPCOUNT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.driverDumpsterCount(action.id, action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.driverDumpsterCountSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.driverDumpsterCountFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.driverDumpsterCountFail());
    }
  });
}


export function* loadDriverDumpsterList() {
  yield takeEvery(actionTypes.DRIVER_DUMPSTER_LIST, function*(action) {

    if(!action.data) action.data = {}

    let status = (action.data.status) ? action.data.status : ""

    let track_no = Helper.generateTrackNo(`DRIVERDUMPLIST${status}`);
    
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDriverDumpsterList(action.id, action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDriverDumpsterListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDriverDumpsterListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDriverDumpsterListFail());
    }
  });
}




export function* loadDriverDumpsterSummary() {
  
  yield takeEvery(actionTypes.LOAD_DRIVER_DUMPSTER_SUMMARY, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DRIVERDUMPSUMMARY");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDriverDumpsterSummary(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDriverDumpsterSummarySuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDriverDumpsterSummaryFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDriverDumpsterSummaryFail());
    }
  });
}

/* ===== RECYCLING DUMPSTER ===== */
export function* loadRecyclingDumpsterList() {
  
  yield takeEvery(actionTypes.LOAD_RECYCLING_DUMPSTER_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECYCLINGDUMPLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadRecyclingDumpsterList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadRecyclingDumpsterListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadRecyclingDumpsterListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadRecyclingDumpsterListFail());
    }
  });
}


export function* loadRecyclingDumpsterDetail() {
  
  // call loadDumpsterDetail function at actions/dumpster_action.js
  yield takeEvery(actionTypes.LOAD_RECYCLING_DUMPSTER_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECYCLINGDUMPDETAIL");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      // call loadDumpsterDetail API at api/dumpster_api.js
      const response = yield API.loadRecyclingDumpsterDetail(action.id, action.data);
      
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadRecyclingDumpsterDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadRecyclingDumpsterDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadRecyclingDumpsterDetailFail());
    }
  });
}

export function* addRecyclingDumpster() {
  yield takeEvery(actionTypes.ADD_RECYCLING_DUMPSTER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ADDRECYCLINGDUMP");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.addRecyclingDumpster(action.data);
      if (response.data.success) {
        yield put(actions.addRecyclingDumpsterSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.addRecyclingDumpsterFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.addRecyclingDumpsterFail());
    }
  });
}

export function* editRecyclingDumpster() {
  yield takeEvery(actionTypes.EDIT_RECYCLING_DUMPSTER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITRECYCLINGDUMP");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editRecyclingDumpster(action.id,action.data);
      if (response.data.success) {
        yield put(actions.editRecyclingDumpsterSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.editRecyclingDumpsterFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editRecyclingDumpsterFail());
    }
  });
}

export function* deleteRecyclingDumpster() {
  yield takeEvery(actionTypes.DELETE_RECYCLING_DUMPSTER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DELRECYCLINGDUMP");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.deleteRecyclingDumpster(action.id, action.data);
      if (response.data.success) {
        yield put(actions.deleteRecyclingDumpsterSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.deleteRecyclingDumpsterFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.deleteRecyclingDumpsterFail());
    }
  });
}
/* ===== RECYCLING DUMPSTER END ===== */


export default function* dumpsterSaga() {
  
  yield all([fork(loadDumpsterMeasurementList)]);
  yield all([fork(loadDumpsterServiceList)]);
  yield all([fork(updateDumpsterService)]);
  yield all([fork(loadDumpsterList)]);
  yield all([fork(addDumpster)]);
  yield all([fork(editDumpster)]);
  yield all([fork(updateDumpsterWasteProcessCost)]);
  yield all([fork(updateDumpsterRecyclingProcessCost)]);
  yield all([fork(deleteDumpster)]);
  yield all([fork(loadDumpsterDetail)]);
  yield all([fork(loadDumpsterExtraFee)]);
  yield all([fork(addDumpsterMeasurement)]);
  yield all([fork(editDumpsterMeasurement)]);
  yield all([fork(deleteDumpsterMeasurement)]);
  yield all([fork(driverDumpsterCount)]);
  yield all([fork(loadDriverDumpsterList)]);
  yield all([fork(loadDriverDumpsterSummary)]);
  yield all([fork(loadRecyclingDumpsterList)]);
  yield all([fork(loadRecyclingDumpsterDetail)]);
  yield all([fork(addRecyclingDumpster)]);
  yield all([fork(editRecyclingDumpster)]);
  yield all([fork(deleteRecyclingDumpster)]);
}
