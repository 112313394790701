import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
import { push } from "react-router-redux";
import Helper from "../../helpers/helpers";

export function* loadGraderList() {
  
  yield takeEvery(actionTypes.LOAD_GRADER_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let status = (action.data.verifyStatus) ? action.data.verifyStatus : ""
    let service_type = (action.data.service_type) ? action.data.service_type : ""
    let search = (action.data.search) ? action.data.search : ""
    
    let track_no = Helper.generateTrackNo(`GRADERLIST${status}${service_type}${search}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadGraderList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadGraderListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadGraderListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadGraderListFail());
    }
  });
}
  


export function* graderCount() {
  
  yield takeEvery(actionTypes.GRADER_COUNT, function*(action) {

    let track_no = Helper.generateTrackNo("GRADERCOUNT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.graderCount(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.graderCountSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.graderCountFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.graderCountFail());
    }
  });
}

export function* editGraderDetail() {
  yield takeEvery(actionTypes.EDIT_GRADER_DETAIL, function*(action) {

    let track_no = Helper.generateTrackNo("EDITGRADERDETAIL");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editGraderDetail(action.id, action.data);
      if (response.data.success) {
        yield put(actions.editGraderDetailSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.editGraderDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editGraderDetailFail());
    }
  });
}

export function* removeGrader() {
  yield takeEvery(actionTypes.REMOVE_GRADER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REMOVEGRADER");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.removeGrader(action.id, action.data);
      if (response.data.success) {
        if(response.data.data)
        {
          yield put(actions.removeGraderSuccess(response.data.data));

        }else{
          yield put(actions.removeGraderSuccess());
          yield put(actions.toastSuccess(response.data.message));
          yield put(push("/grader"));

        }
      } else {
        alert(response.data.message);
        yield put(actions.removeGraderFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.removeGraderFail());
    }
  });
}

export function* recoveryGrader() {
  yield takeEvery(actionTypes.RECOVERY_GRADER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECOVERYGRADER");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.recoveryGrader(action.id, action.data);
      if (response.data.success) {
        
        yield put(actions.recoveryGraderSuccess());
        yield put(actions.toastSuccess(response.data.message));
        
      } else {
        alert(response.data.message);
        yield put(actions.recoveryGraderFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.recoveryGraderFail());
    }
  });
}

export function* editGraderStatus() {
  yield takeEvery(actionTypes.EDIT_GRADER_STATUS, function*(action) {

    let track_no = Helper.generateTrackNo("EDITGRADERSTATUS");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editGraderStatus(action.id, action.data);
      if (response.data.success) {

        if(response.data.data && response.data.data.updateOrder)
        {
          yield put(actions.proceedUpdateOrderLandfill(response.data.data));

        }else if(response.data.data && response.data.data.confirmDeactivate)
        {
          yield put(actions.proceedDeactivateConfirm(response.data.data));

        }else{
          yield put(actions.editGraderStatusSuccess());
          yield put(actions.toastSuccess(response.data.message));
        }
        // if(action.listParams)
        //   yield put(actions.loadGraderList(action.listParams));
        // else
        //   yield put(actions.loadGraderDetail(action.id));
      } else {
        alert(response.data.message);
        yield put(actions.editGraderStatusFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editGraderStatusFail());
    }
  });
}

export function* editGraderVerifyStatus() {
  yield takeEvery(actionTypes.EDIT_GRADER_VERIFY_STATUS, function*(action) {

    let track_no = Helper.generateTrackNo("EDITGRADERVERIFYSTATUS");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editGraderVerifyStatus(action.id, action.data);
      if (response.data.success) {
        yield put(actions.editGraderVerifyStatusSuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.editGraderVerifyStatusFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editGraderVerifyStatusFail());
    }
  });
}


export function* rejectGraderVerifyStatus() {
  yield takeEvery(actionTypes.REJECT_GRADER_VERIFY_STATUS, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REJECTGRADERVERIFYSTATUS");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.rejectGraderVerifyStatus(action.id, action.data);
      if (response.data.success) {
        yield put(actions.rejectGraderVerifyStatusSuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.rejectGraderVerifyStatusFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.rejectGraderVerifyStatusFail());
    }
  });
}

export function* loadGraderDetail() {
  
  yield takeEvery(actionTypes.LOAD_GRADER_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GRADERDETAIL");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadGraderDetail(action.id, action.data);
      
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadGraderDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadGraderDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadGraderDetailFail());
    }
  });
}



export function* uploadGraderDoc() {
  yield takeEvery(actionTypes.UPLOAD_GRADER_DOC, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPLOADGRADERDOC");

    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.uploadGraderDoc(action.id, action.data);
      if (response.data.success) {
        
        yield put(actions.uploadGraderDocSuccess());
        
      } else {
        alert(response.data.message);
        yield put(actions.uploadGraderDocFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.uploadGraderDocFail());
    }
  });
}
  


export function* loadGraderDocumentationCount() {
  
  yield takeEvery(actionTypes.GRADER_DOCUMENTATION_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GRADERDOCUMENTATIONCOUNT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadGraderDocumentationCount(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadGraderDocumentationCountSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadGraderDocumentationCountFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadGraderDocumentationCountFail());
    }
  });
}


export function* loadGraderDocumentationList() {
  
  yield takeEvery(actionTypes.GRADER_DOCUMENTATION_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let graderID = (action.data.grader_id) ? action.data.grader_id : ""
    let search = (action.data.search) ? action.data.search : ""
    let acc_verified = (action.data.acc_verified) ? "acc_verified" : ""
    let pending_verified = (action.data.pending_verified) ? "pending_verified" : ""
    
    let track_no = Helper.generateTrackNo(`GRADERDOCLIST${graderID}${search}${acc_verified}${pending_verified}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadGraderDocumentationList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadGraderDocumentationListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadGraderDocumentationListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadGraderDocumentationListFail());
    }
  });
}


export function* resetGraderDocumentationList() {
  
  yield takeEvery(actionTypes.RESET_GRADER_DOCUMENTATION_LIST, function*(action) {
    
    yield put(actions.loadGraderDocumentationListSuccess([]));
  });
}


export function* approveGraderDocumentation() {
  
  yield takeEvery(actionTypes.APPROVE_GRADER_DOCUMENTATION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("APPROVEGRADERDOCUMENTATION");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.approveGraderDocumentation(action.data);
      if (response.data.success) {
        yield put(actions.approveGraderDocumentationSuccess());
      } else {
        alert(response.data.message);
        yield put(actions.approveGraderDocumentationFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.approveGraderDocumentationFail());
    }
  });
}



export function* rejectGraderDocumentation() {
  
  yield takeEvery(actionTypes.REJECT_GRADER_DOCUMENTATION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REJECTGRADERDOCUMENTATION");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.rejectGraderDocumentation(action.data);
      if (response.data.success) {
        yield put(actions.rejectGraderDocumentationSuccess());
      } else {
        alert(response.data.message);
        yield put(actions.rejectGraderDocumentationFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.rejectGraderDocumentationFail());
    }
  });
}



/* ===== GRADER ZONE ===== */
export function* loadGraderZoneList() {
  
  yield takeEvery(actionTypes.LOAD_GRADER_ZONE_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let status = (action.data.verifyStatus) ? action.data.verifyStatus : ""
    let search = (action.data.search) ? action.data.search : ""
    
    let track_no = Helper.generateTrackNo(`GRADERZONELIST${status}${search}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadGraderZoneList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadGraderZoneListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadGraderZoneListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadGraderZoneListFail());
    }
  });
}

export function* graderZoneCount() {
  
  yield takeEvery(actionTypes.GRADER_ZONE_COUNT, function*(action) {

    let track_no = Helper.generateTrackNo("GRADERZONECOUNT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.graderZoneCount(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.graderZoneCountSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.graderZoneCountFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.graderZoneCountFail());
    }
  });
}


export function* expressPackingHistoryList() {
  
  yield takeEvery(actionTypes.LOAD_EXPRESS_PACKING_HISTORY_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let grader_id = (action.data.grader_id) ? action.data.grader_id : ""
    let search = (action.data.search) ? action.data.search : ""
    let dateFrom = (action.data.dateFrom) ? action.data.dateFrom : ""
    let dateTo = (action.data.dateTo) ? action.data.dateTo : ""
    
    let track_no = Helper.generateTrackNo(`EXPRESSPACKINGHISTORYLIST${grader_id}${search}${dateFrom}${dateTo}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.expressPackingHistoryList(action.data);
      if (response.data.success && response.data.data) {
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }
        
        yield put(actions.expressPackingHistoryListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.expressPackingHistoryListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.expressPackingHistoryListFail());
    }
  });
} 

export function* expressPackingListDetail() {
  
  yield takeEvery(actionTypes.LOAD_EXPRESS_PACKING_LIST_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EXPRESSPACKINGDETAIL");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.expressPackingListDetail(action.id, action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.expressPackingListDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.expressPackingListDetailFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.expressPackingListDetailFail());
    }
  });
}
  
export function* getExpressPackingListPDF() {
  
  yield takeEvery(actionTypes.GET_EXPRESS_PACKING_LIST_PDF, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GETEXPRESSPACKINGLISTPDF");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.getExpressPackingListPDF(action.id, action.data);
      if (response.data.success && response.data.data) {
        
        
        yield put(actions.getExpressPackingListPDFSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getExpressPackingListPDFFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getExpressPackingListPDFFail());
    }
  });
}
  
export function* getExpressPackingListStack() {
  
  yield takeEvery(actionTypes.GET_EXPRESS_PACKING_LIST_STACK, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GETEXPRESSPACKINGLISTSTACK");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.getExpressPackingListStack(action.data);
      if (response.data.success && response.data.data) {
        
        yield put(actions.getExpressPackingListStackSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getExpressPackingListStackFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getExpressPackingListStackFail());
    }
  });
}


export function* recyclingPackingHistoryList() {
  
  yield takeEvery(actionTypes.LOAD_RECYCLING_PACKING_HISTORY_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let grader_id = (action.data.grader_id) ? action.data.grader_id : ""
    let search = (action.data.search) ? action.data.search : ""
    let dateFrom = (action.data.dateFrom) ? action.data.dateFrom : ""
    let dateTo = (action.data.dateTo) ? action.data.dateTo : ""
    
    let track_no = Helper.generateTrackNo(`RECYCLINGPACKINGHISTORYLIST${grader_id}${search}${dateFrom}${dateTo}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.recyclingPackingHistoryList(action.data);
      if (response.data.success && response.data.data) {
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }
        yield put(actions.recyclingPackingHistoryListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.recyclingPackingHistoryListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.recyclingPackingHistoryListFail());
    }
  });
}
  
export function* recyclingPackingListDetail() {
  
  yield takeEvery(actionTypes.LOAD_RECYCLING_PACKING_LIST_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECYCLINGPACKINGDETAIL");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.recyclingPackingListDetail(action.id, action.data);
      if (response.data.success && response.data.data) {
        
        yield put(actions.recyclingPackingListDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.recyclingPackingListDetailFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.recyclingPackingListDetailFail());
    }
  });
}
  
export function* getRecyclingPackingListPDF() {
  
  yield takeEvery(actionTypes.GET_RECYCLING_PACKING_LIST_PDF, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GETRECYCLINGPACKINGLISTPDF");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.getRecyclingPackingListPDF(action.id, action.data);
      if (response.data.success && response.data.data) {
        
        yield put(actions.getRecyclingPackingListPDFSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getRecyclingPackingListPDFFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getRecyclingPackingListPDFFail());
    }
  });
}
  
export function* getRecyclingPackingListStack() {
  
  yield takeEvery(actionTypes.GET_RECYCLING_PACKING_LIST_STACK, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GETRECYCLINGPACKINGLISTSTACK");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.getRecyclingPackingListStack(action.data);
      if (response.data.success && response.data.data) {
        
        yield put(actions.getRecyclingPackingListStackSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getRecyclingPackingListStackFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getRecyclingPackingListStackFail());
    }
  });
}

/* ===== GRADER ZONE ===== */




export default function* graderSaga() {
  yield all([fork(loadGraderList)]);
  yield all([fork(graderCount)]);
  yield all([fork(editGraderDetail)]);
  yield all([fork(removeGrader)]); 
  yield all([fork(recoveryGrader)]);
  yield all([fork(editGraderStatus)]);
  yield all([fork(editGraderVerifyStatus)]);
  yield all([fork(rejectGraderVerifyStatus)]);
  yield all([fork(loadGraderDetail)]);
  yield all([fork(uploadGraderDoc)]);
  yield all([fork(loadGraderDocumentationCount)]);
  yield all([fork(loadGraderDocumentationList)]);
  yield all([fork(resetGraderDocumentationList)]);
  yield all([fork(approveGraderDocumentation)]);
  yield all([fork(rejectGraderDocumentation)]);
  
  yield all([fork(loadGraderZoneList)]);
  yield all([fork(graderZoneCount)]);
  yield all([fork(expressPackingHistoryList)]);
  yield all([fork(expressPackingListDetail)]);
  yield all([fork(getExpressPackingListPDF)]);
  yield all([fork(getExpressPackingListStack)]);
  yield all([fork(recyclingPackingHistoryList)]);
  yield all([fork(recyclingPackingListDetail)]);
  yield all([fork(getRecyclingPackingListPDF)]);
  yield all([fork(getRecyclingPackingListStack)]);
  
}
