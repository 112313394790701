import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
import { push } from "react-router-redux";
import Helper from "../../helpers/helpers";


export function* loadNotificationList() {
  
  yield takeEvery(actionTypes.LOAD_NOTIFICATION_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("NOTIFLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadNotificationList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadNotificationListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadNotificationListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadNotificationListFail());
    }
  });
}



export function* loadNotificationDetail() {
  
  yield takeEvery(actionTypes.LOAD_NOTIFICATION_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("NOTIFDETAIL");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadNotificationDetail(action.id, action.data);
      
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadNotificationDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadNotificationDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadNotificationDetailFail());
    }
  });
}


export function* createNotification() {
  yield takeEvery(actionTypes.CREATE_NOTIFICATION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("CREATENOTIF");

    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.createNotification(action.data);
      if (response.data.success) {
        yield put(actions.createNotificationSuccess());
        yield put(actions.toastSuccess(response.data.message));
        yield put(push(`/notification/edit/${response.data.data}`));
      } else {
        alert(response.data.message);
        yield put(actions.createNotificationFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.createNotificationFail());
    }
  });
}

export function* editNotification() {
  yield takeEvery(actionTypes.EDIT_NOTIFICATION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITNOTIF");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editNotification(action.id, action.data);
      if (response.data.success) {
        yield put(actions.editNotificationSuccess());
        yield put(actions.toastSuccess(response.data.message));
        
      } else {
        alert(response.data.message);
        yield put(actions.editNotificationFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editNotificationFail());
    }
  });
}


export function* deleteNotification() {
  yield takeEvery(actionTypes.DELETE_NOTIFICATION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DELNOTIF");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.deleteNotification(action.id, action.data);
      if (response.data.success) {
        yield put(actions.deleteNotificationSuccess());
        yield put(actions.toastSuccess("Notification deleted."));
      } else {
        alert(response.data.message);
        yield put(actions.deleteNotificationFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.deleteNotificationFail());
    }
  });
}

export default function* notificationSaga() {
  
  yield all([
    fork(loadNotificationList),
    fork(createNotification),
    fork(editNotification),
    fork(deleteNotification),
    fork(loadNotificationDetail)
    
  ]);
  
}
