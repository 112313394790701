import * as actionTypes from "./actionTypes";

export const loadLorryList = data => {
  return {
    type: actionTypes.LOAD_LORRY_LIST,
    data
  };
};

export const loadLorryListSuccess = data => {
  return {
    type: actionTypes.LOAD_LORRY_LIST_SUCCESS,
    data
  };
};

export const loadLorryListFail = () => {
  return {
    type: actionTypes.LOAD_LORRY_LIST_FAIL
  };
};

export const addLorry = data => {
  return {
    type: actionTypes.ADD_LORRY,
    data
  };
};

export const addLorrySuccess = () => {
  return {
    type: actionTypes.ADD_LORRY_SUCCESS
  };
};

export const addLorryFail = () => {
  return {
    type: actionTypes.ADD_LORRY_FAIL
  };
};


export const editLorry = (id,data) => {
  return {
    type: actionTypes.EDIT_LORRY,
    id,
    data
  };
};

export const editLorrySuccess = () => {
  return {
    type: actionTypes.EDIT_LORRY_SUCCESS
  };
};

export const editLorryFail = () => {
  return {
    type: actionTypes.EDIT_LORRY_FAIL
  };
};


export const deleteLorry = (id, data) => {
  return {
    type: actionTypes.DELETE_LORRY,
    id,
    data
  };
};

export const deleteLorrySuccess = () => {
  return {
    type: actionTypes.DELETE_LORRY_SUCCESS
  };
};

export const deleteLorryFail = () => {
  return {
    type: actionTypes.DELETE_LORRY_FAIL
  };
};


export const driverLorryCount = (id, data) => {
  return {
    type: actionTypes.DRIVER_LORRY_COUNT,
    id,
    data
  };
};

export const driverLorryCountSuccess = data => {
  return {
    type: actionTypes.DRIVER_LORRY_COUNT_SUCCESS,
    data
  };
};

export const driverLorryCountFail = () => {
  return {
    type: actionTypes.DRIVER_LORRY_COUNT_FAIL
  };
};

export const loadDriverLorryList = (id = null,data) => {
  return {
    type: actionTypes.DRIVER_LORRY_LIST,
    data,
    id
  };
};

export const loadDriverLorryListSuccess = data => {
  return {
    type: actionTypes.DRIVER_LORRY_LIST_SUCCESS,
    data
  };
};

export const loadDriverLorryListFail = () => {
  return {
    type: actionTypes.DRIVER_LORRY_LIST_FAIL
  };
};

export const driverLorryDetail = (id,data) => {
  return {
    type: actionTypes.DRIVER_LORRY_DETAIL,
    data,
    id
  };
};

export const driverLorryDetailSuccess = data => {
  return {
    type: actionTypes.DRIVER_LORRY_DETAIL_SUCCESS,
    data
  };
};

export const driverLorryDetailFail = () => {
  return {
    type: actionTypes.DRIVER_LORRY_DETAIL_FAIL
  };
};


export const approveDriverLorry = (id, data) => {
  return {
    type: actionTypes.DRIVER_LORRY_APPROVE,
    id,
    data
  };
};

export const approveDriverLorrySuccess = () => {
  return {
    type: actionTypes.DRIVER_LORRY_APPROVE_SUCCESS
  };
};

export const approveDriverLorryFail = () => {
  return {
    type: actionTypes.DRIVER_LORRY_APPROVE_FAIL
  };
};


export const rejectDriverLorry = (id, data) => {
  return {
    type: actionTypes.DRIVER_LORRY_REJECT,
    id,
    data
  };
};

export const rejectDriverLorrySuccess = () => {
  return {
    type: actionTypes.DRIVER_LORRY_REJECT_SUCCESS
  };
};

export const rejectDriverLorryFail = () => {
  return {
    type: actionTypes.DRIVER_LORRY_REJECT_FAIL
  };
};


export const createDriverLorry = (id, data) => {
  return {
    type: actionTypes.DRIVER_LORRY_CREATE,
    id,
    data
  };
};

export const createDriverLorrySuccess = () => {
  return {
    type: actionTypes.DRIVER_LORRY_CREATE_SUCCESS
  };
};

export const createDriverLorryFail = () => {
  return {
    type: actionTypes.DRIVER_LORRY_CREATE_FAIL
  };
};


export const removeDriverLorry = (id, data) => {
  return {
    type: actionTypes.DRIVER_LORRY_REMOVE,
    id,
    data
  };
};

export const removeDriverLorrySuccess = () => {
  return {
    type: actionTypes.DRIVER_LORRY_REMOVE_SUCCESS
  };
};

export const removeDriverLorryFail = () => {
  return {
    type: actionTypes.DRIVER_LORRY_REMOVE_FAIL
  };
};


/* ===== DRIVER LORRY ===== */
export const loadDriverLorrySummary = data => {
  return {
    type: actionTypes.LOAD_DRIVER_LORRY_SUMMARY,
    data
  };
};

export const loadDriverLorrySummarySuccess = data => {
  return {
    type: actionTypes.LOAD_DRIVER_LORRY_SUMMARY_SUCCESS,
    data
  };
};

export const loadDriverLorrySummaryFail = () => {
  return {
    type: actionTypes.LOAD_DRIVER_LORRY_SUMMARY_FAIL
  };
};

/* ===== END DRIVER LORRY SUMMARY ===== */