import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
import { push } from "react-router-redux";
import Helper from "../../helpers/helpers";

export function* loadUserList() {
  
  yield takeEvery(actionTypes.LOAD_USER_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`USERLIST${search}`);
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadUserList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadUserListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadUserListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadUserListFail());
    }
  });
}

export function* userCount() {
  
  yield takeEvery(actionTypes.USER_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("USERCOUNT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.userCount(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.userCountSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.userCountFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.userCountFail());
    }
  });
}

export function* switchUserToOrganizationType() {
  yield takeEvery(actionTypes.SWITCH_USER_TO_ORGANIZATION_TYPE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SWITCHUSERTYPE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.switchUserToOrganizationType(action.id, action.data);
      if (response.data.success) {
        yield put(actions.switchUserToOrganizationTypeSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.switchUserToOrganizationTypeFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.switchUserToOrganizationTypeFail());
    }
  });
}


export function* editUserDetail() {
  yield takeEvery(actionTypes.EDIT_USER_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITUSERDETAIL");

    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editUserDetail(action.id, action.data);
      if (response.data.success) {
        yield put(actions.editUserDetailSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.editUserDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editUserDetailFail());
    }
  });
}

export function* removeUser() {
  yield takeEvery(actionTypes.REMOVE_USER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DELUSER");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.removeUser(action.id, action.data);
      if (response.data.success) {
        yield put(actions.removeUserSuccess());
        yield put(actions.toastSuccess(response.data.message));
        yield put(push("/organization/user"));
      } else {
        alert(response.data.message);
        yield put(actions.removeUserFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.removeUserFail());
    }
  });
}

export function* recoveryUser() {
  yield takeEvery(actionTypes.RECOVERY_USER, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECOVERYUSER");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.recoveryUser(action.id, action.data);
      if (response.data.success) {
        
        yield put(actions.recoveryUserSuccess());
        yield put(actions.toastSuccess(response.data.message));
        
      } else {
        alert(response.data.message);
        yield put(actions.recoveryUserFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.recoveryUserFail());
    }
  });
}

export function* editUserStatus() {
  yield takeEvery(actionTypes.EDIT_USER_STATUS, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITUSERSTATUS");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editUserStatus(action.id, action.data);
      if (response.data.success) {
        yield put(actions.editUserStatusSuccess());
        yield put(actions.toastSuccess(response.data.message));

        // if(action.listParams)
        //   yield put(actions.loadUserList(action.listParams));
        // else
        //   yield put(actions.loadUserDetail(action.id));

      } else {
        alert(response.data.message);
        yield put(actions.editUserStatusFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editUserStatusFail());
    }
  });
}

export function* editUserLoginShowVeriCodeStatus() {
  yield takeEvery(actionTypes.EDIT_USER_SHOW_VERI_CODE_STATUS, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITUSERSHOWVERICODESTATUS");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editUserLoginShowVeriCodeStatus(action.id, action.data);
      if (response.data.success) {
        yield put(actions.editUserLoginShowVeriCodeStatusSuccess());
        yield put(actions.toastSuccess(response.data.message));

        // if(action.listParams)
        //   yield put(actions.loadDriverList(action.listParams));
        // else
        //   yield put(actions.loadDriverDetail(action.id));
      } else {
        alert(response.data.message);
        yield put(actions.editUserLoginShowVeriCodeStatusFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editUserLoginShowVeriCodeStatusFail());
    }
  });
}

export function* editUserVerifyStatus() {
  yield takeEvery(actionTypes.EDIT_USER_VERIFY_STATUS, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITUSERVERIFYSTATUS");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editUserVerifyStatus(action.id, action.data);
      if (response.data.success) {
        yield put(actions.editUserVerifyStatusSuccess());
        yield put(actions.toastSuccess(response.data.message));

        // if(action.listParams)
        //   yield put(actions.loadUserList(action.listParams));
        // else
        //   yield put(actions.loadUserDetail(action.id));
      } else {
        alert(response.data.message);
        yield put(actions.editUserVerifyStatusFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editUserVerifyStatusFail());
    }
  });
}


export function* loadUserDetail() {
  
  yield takeEvery(actionTypes.LOAD_USER_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("USERDETAIL");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadUserDetail(action.id, action.data);
      
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadUserDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadUserDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadUserDetailFail());
    }
  });
}



export function* loadUserSummary() {
  
  yield takeEvery(actionTypes.LOAD_USER_SUMMARY, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("USERSUMMARY");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadUserSummary(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadUserSummarySuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadUserSummaryFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadUserSummaryFail());
    }
  });
}

export function* setUserBillingAddress() {
  yield takeEvery(actionTypes.SET_USER_BILLING_ADDRESS, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETUSERBILLINGADDR");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.setUserBillingAddress(action.id, action.data);
      if (response.data.success) {
        yield put(actions.setUserBillingAddressSuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.setUserBillingAddressFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.setUserBillingAddressFail());
    }
  });
}

export function* loadUserCreditTermRequestList() {
  
  yield takeEvery(actionTypes.LOAD_USER_CREDIT_TERM_REQEUST_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("USERCREDITTERMREQUESTLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadUserCreditTermRequestList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadUserCreditTermRequestListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadUserCreditTermRequestListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadUserCreditTermRequestListFail());
    }
  });
}

export function* approveUserCreditTerm() {
  yield takeEvery(actionTypes.APPROVE_USER_CREDIT_TERM_REQEUST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("APPRUSERCREDITTERMREQUEST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.approveUserCreditTerm(action.id, action.data);
      if (response.data.success) {
        yield put(actions.approveUserCreditTermSuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.approveUserCreditTermFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.approveUserCreditTermFail());
    }
  });
}

export function* rejectUserCreditTerm() {
  yield takeEvery(actionTypes.REJECT_USER_CREDIT_TERM_REQEUST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REJECTUSERCREDITTERMREQUEST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.rejectUserCreditTerm(action.id, action.data);
      if (response.data.success) {
        yield put(actions.rejectUserCreditTermSuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.rejectUserCreditTermFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.rejectUserCreditTermFail());
    }
  });
}

export function* cancelUserCreditTerm() {
  yield takeEvery(actionTypes.CANCEL_USER_CREDIT_TERM_REQEUST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("CANCELUSERCREDITTERMREQUEST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.cancelUserCreditTerm(action.id, action.data);
      if (response.data.success) {
        yield put(actions.cancelUserCreditTermSuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.cancelUserCreditTermFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.cancelUserCreditTermFail());
    }
  });
}

export function* setUserNegoPrice() {
  yield takeEvery(actionTypes.SET_USER_NEGO_PRICE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETUSERNEGOPRICE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.setUserNegoPrice(action.id, action.data);
      if (response.data.success) {
        yield put(actions.setUserNegoPriceSuccess());
        yield put(actions.toastSuccess(response.data.message));
        
      } else {
        alert(response.data.message);
        yield put(actions.setUserNegoPriceFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.setUserNegoPriceFail());
    }
  });
}

export default function* UserSaga() {
  yield all([fork(loadUserList)]);
  yield all([fork(userCount)]);
  yield all([fork(switchUserToOrganizationType)]);  
  yield all([fork(editUserDetail)]);
  yield all([fork(removeUser)]);  
  yield all([fork(recoveryUser)]);  
  yield all([fork(editUserStatus)]);
  yield all([fork(editUserLoginShowVeriCodeStatus)]);
  yield all([fork(editUserVerifyStatus)]);
  yield all([fork(loadUserDetail)]);
  yield all([fork(loadUserSummary)]);
  yield all([fork(setUserBillingAddress)]);
  yield all([fork(loadUserCreditTermRequestList)]);
  yield all([fork(approveUserCreditTerm)]);
  yield all([fork(rejectUserCreditTerm)]);
  yield all([fork(cancelUserCreditTerm)]);
  yield all([fork(setUserNegoPrice)]);
}
