import * as actionTypes from "../actions/actionTypes";
// import { cloneDeep } from "lodash";

const initState = {
  dumpsterPriceList: [],
  isLoadingDumpsterPrice: false,
  isSetDumpsterPriceSuccess:false,
  dumpsterPriceRecyclingList: [],
  isLoadingDumpsterPriceRecycling: false,
  isSetDumpsterPriceRecyclingSuccess:false,
  dumpsterPricePDF: "",
  dumpsterPriceRecyclingPDF: "",
  dumpsterPriceEffectiveDateList: {},
  dumpsterPriceRecyclingEffectiveDateList: {},
};

export default function dumpsterPriceReducer(state = initState, action) {
 
  switch (action.type) {
    
    case actionTypes.LOAD_DUMPSTER_PRICE_EFFECTIVE_DATE_LIST: {
    
      return {
        ...state,
        dumpsterPriceEffectiveDateList: {},
      };
    }

    case actionTypes.LOAD_DUMPSTER_PRICE_EFFECTIVE_DATE_LIST_SUCCESS: {
      
      return {
        ...state,
        dumpsterPriceEffectiveDateList: action.data,
      };
    }

    case actionTypes.LOAD_DUMPSTER_PRICE_EFFECTIVE_DATE_LIST_FAIL: {
      return {
        ...state,
        dumpsterPriceEffectiveDateList: {},
      };
    }

    case actionTypes.LOAD_DUMPSTER_PRICE_LIST: {
    
      return {
        ...state,
        dumpsterPriceList: [],
        isLoadingDumpsterPrice: true,
      };
    }

    case actionTypes.LOAD_DUMPSTER_PRICE_LIST_SUCCESS: {
      
      return {
        ...state,
        dumpsterPriceList: action.data.data,
        isLoadingDumpsterPrice: false,
      };
    }

    case actionTypes.LOAD_DUMPSTER_PRICE_LIST_FAIL: {
      return {
        ...state,
        dumpsterPriceList: [],
        isLoadingDumpsterPrice: false,
      };
    }

    
    
    case actionTypes.GET_DUMPSTER_PRICE_PDF: {
      
      return {
        ...state,
        dumpsterPricePDF: ""
      };
    }

    case actionTypes.GET_DUMPSTER_PRICE_PDF_SUCCESS: {
      
      return {
        ...state,
        dumpsterPricePDF: action.data
      };
    }

    case actionTypes.GET_DUMPSTER_PRICE_PDF_FAIL: {
      return {
        ...state
      };
    }


    case actionTypes.SET_DUMPSTER_PRICE: {
      return {
        ...state,
        isSetDumpsterPriceSuccess: false
      };
    }

    case actionTypes.SET_DUMPSTER_PRICE_SUCCESS: {
      return {
        ...state,
        isSetDumpsterPriceSuccess: true
      };
    }

    case actionTypes.SET_DUMPSTER_PRICE_FAIL: {
      return {
        ...state,
        isSetDumpsterPriceSuccess: false
      };
    }

    
    case actionTypes.LOAD_DUMPSTER_PRICE_RECYCLING_EFFECTIVE_DATE_LIST: {
    
      return {
        ...state,
        dumpsterPriceRecyclingEffectiveDateList: {},
      };
    }

    case actionTypes.LOAD_DUMPSTER_PRICE_RECYCLING_EFFECTIVE_DATE_LIST_SUCCESS: {
      
      return {
        ...state,
        dumpsterPriceRecyclingEffectiveDateList: action.data,
      };
    }

    case actionTypes.LOAD_DUMPSTER_PRICE_RECYCLING_EFFECTIVE_DATE_LIST_FAIL: {
      return {
        ...state,
        dumpsterPriceRecyclingEffectiveDateList: {},
      };
    }

    
    case actionTypes.LOAD_DUMPSTER_PRICE_RECYCLING_LIST: {
    
      return {
        ...state,
        dumpsterPriceRecyclingList: [],
        isLoadingDumpsterPriceRecycling: true,
      };
    }

    case actionTypes.LOAD_DUMPSTER_PRICE_RECYCLING_LIST_SUCCESS: {
      
      return {
        ...state,
        dumpsterPriceRecyclingList: action.data.data,
        isLoadingDumpsterPriceRecycling: false,
      };
    }

    case actionTypes.LOAD_DUMPSTER_PRICE_RECYCLING_LIST_FAIL: {
      return {
        ...state,
        dumpsterPriceRecyclingList: [],
        isLoadingDumpsterPriceRecycling: false,
      };
    }
    
    case actionTypes.GET_DUMPSTER_PRICE_RECYCLING_PDF: {
      
      return {
        ...state,
        dumpsterPriceRecyclingPDF: ""
      };
    }

    case actionTypes.GET_DUMPSTER_PRICE_RECYCLING_PDF_SUCCESS: {
      
      return {
        ...state,
        dumpsterPriceRecyclingPDF: action.data
      };
    }

    case actionTypes.GET_DUMPSTER_PRICE_RECYCLING_PDF_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.SET_DUMPSTER_PRICE_RECYCLING: {
      return {
        ...state,
        isSetDumpsterPriceRecyclingSuccess: false
      };
    }

    case actionTypes.SET_DUMPSTER_PRICE_RECYCLING_SUCCESS: {
      return {
        ...state,
        isSetDumpsterPriceRecyclingSuccess: true
      };
    }

    case actionTypes.SET_DUMPSTER_PRICE_RECYCLING_FAIL: {
      return {
        ...state,
        isSetDumpsterPriceRecyclingSuccess: false
      };
    }


    default:
      return state;
  }
}
