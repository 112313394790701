import React from "react";
import { Link } from "react-router-dom";
import { SITE_CONFIG } from "../../settings";
import logo from "../../assets/img/logo.png";

export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to="/">
              <img className="logo" src={logo} width="40" alt="logo" />
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
          <Link to="/">{SITE_CONFIG.siteName}</Link>
        </h3>
      )}
    </div>
  );
};
