import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/admin/driver";
};

export const loadDriverList = data => {
  const url = `${baseURL()}/list`;
  return axios.post(url, data);
};

export const driverCount = (data) => {
  const url = `${baseURL()}/count`;
  return axios.post(url, data);
};

export const loadDriverDetail = (id, data) => {
  const url = `${baseURL()}/detail/${id}`;
  return axios.post(url, data);
};

export const editDriverDetail = (id, data) => {
  const url = `${baseURL()}/update/${id}`;
  return axios.post(url, data);
};

export const removeDriver = (id, data) => {
  const url = `${baseURL()}/remove/${id}`;
  return axios.post(url, data);
};

export const recoveryDriver = (id, data) => {
  const url = `${baseURL()}/recovery/${id}`;
  return axios.post(url, data);
};

export const uploadDriverDoc = (id, data) => {
  const url = `${baseURL()}/uploadDoc/${id}`;
  return axios.post(url, data);
};

export const loadDriverDocumentationCount = (data) => {
  const url = `${baseURL()}/documentation/count`;
  return axios.post(url, data);
};

export const loadDriverDocumentationList = (data) => {
  const url = `${baseURL()}/documentation/list`;
  return axios.post(url, data);
};

export const approveDriverDocumentation = (data) => {
  const url = `${baseURL()}/documentation/approve`;
  return axios.post(url, data);
};

export const rejectDriverDocumentation = (data) => {
  const url = `${baseURL()}/documentation/reject`;
  return axios.post(url, data);
};

export const applyScheduledWasteService = (id, data) => {
  const url = `${baseURL()}/applyScheduledWaste/${id}`;
  return axios.post(url, data);
};

export const editDriverStatus = (id, data) => {
  const url = `${baseURL()}/update/${id}/status`;
  return axios.post(url, data);
};

export const editDriverLoginShowVeriCodeStatus = (id, data) => {
  const url = `${baseURL()}/update/${id}/veriCode`;
  return axios.post(url, data);
};

export const editDriverVerifyStatus = (id, data) => {
  const url = `${baseURL()}/update/${id}/verify`;
  return axios.post(url, data);
};

export const rejectDriverVerifyStatus = (id, data) => {
  const url = `${baseURL()}/update/${id}/reject`;
  return axios.post(url, data);
};

export const driverTrackList = data => {
  const url = `${baseURL()}/track`;
  return axios.post(url, data);
};

export const driverTrack = (id, data) => {
  const url = `${baseURL()}/track/${id}`;
  return axios.post(url,data);
};


export const loadDriverSummary = data => {
  const url = `${baseURL()}/summary`;
  return axios.post(url,data);
};

export const loadDriverLorryDumpster = (id, data) => {
  const url = `${baseURL()}/lorryDumpster/${id}`;
  return axios.post(url,data);
};


/* ===== DRIVER TRANSPORT LICENSE ===== */

export const loadDriverTransportLicenseList = data => {
  const url = `${baseURL()}/transportLicense/list`;
  return axios.post(url,data);
};

export const driverTransportLicenseCount = data => {
  const url = `${baseURL()}/transportLicense/count`;
  return axios.post(url,data);
};

export const transportLicenseUpdateVerify = (id, data) => {
  const url = `${baseURL()}/transportLicense/verify/${id}`;
  return axios.post(url,data);
};

export const rejectTransportLicense = (id, data) => {
  const url = `${baseURL()}/transportLicense/reject/${id}`;
  return axios.post(url,data);
};

export const scheduledWasteSetRecoverer = (id, data) => {
  const url = `${baseURL()}/transportLicense/setRecoveryCompany/${id}`;
  return axios.post(url,data);
};
/* ===== END DRIVER TRANSPORT LICENSE ===== */