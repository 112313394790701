import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  whatsappNotificationList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  whatsappNotificationDetail:{},
  isDeleteWhatsappNotificationSuccess: false,
  isUpdateWhatsappNotificationSuccess:false,
  isCreateWhatsappNotificationSuccess:false,
  isSendWhatsappForGradingSuccess:false,
};

export default function whatsappReducer(state = initState, action) {
 
  switch (action.type) {
   
    case actionTypes.LOAD_WHATSAPP_NOTIFICATION_LIST: {
      let whatsappNotificationList = cloneDeep(state.whatsappNotificationList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        whatsappNotificationList.offset = action.data.offset;
        whatsappNotificationList.limit = action.data.limit;
      }

      return {
        ...state,
        whatsappNotificationList
      };
    }

    case actionTypes.LOAD_WHATSAPP_NOTIFICATION_LIST_SUCCESS: {
      let whatsappNotificationList = cloneDeep(state.whatsappNotificationList);
      whatsappNotificationList.data = action.data.data;
      whatsappNotificationList.meta = {
        page: 1 + state.whatsappNotificationList.offset / state.whatsappNotificationList.limit,
        pageSize: state.whatsappNotificationList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.whatsappNotificationList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        whatsappNotificationList
      };
    }

    case actionTypes.LOAD_WHATSAPP_NOTIFICATION_LIST_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.LOAD_WHATSAPP_NOTIFICATION_DETAIL: {
      
      return {
        ...state,
        whatsappNotificationDetail:{}
      };
    }

    case actionTypes.LOAD_WHATSAPP_NOTIFICATION_DETAIL_SUCCESS: {
      return {
        ...state,
        whatsappNotificationDetail: action.data
      };
    }

    case actionTypes.LOAD_WHATSAPP_NOTIFICATION_DETAIL_FAIL: {
      return {
        ...state
      };
    }
    
    
    case actionTypes.CREATE_WHATSAPP_NOTIFICATION: {
      return {
        ...state,
        isCreateWhatsappNotificationSuccess: false
      };
    }

    case actionTypes.CREATE_WHATSAPP_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        isCreateWhatsappNotificationSuccess: true
      };
    }

    case actionTypes.CREATE_WHATSAPP_NOTIFICATION_FAIL: {
      return {
        ...state,
        isCreateWhatsappNotificationSuccess: false
      };
    }

    case actionTypes.EDIT_WHATSAPP_NOTIFICATION: {
      return {
        ...state,
        isUpdateWhatsappNotificationSuccess: false
      };
    }

    case actionTypes.EDIT_WHATSAPP_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        isUpdateWhatsappNotificationSuccess: true
      };
    }

    case actionTypes.EDIT_WHATSAPP_NOTIFICATION_FAIL: {
      return {
        ...state,
        isUpdateWhatsappNotificationSuccess: false
      };
    }

    case actionTypes.DELETE_WHATSAPP_NOTIFICATION: {
      return {
        ...state,
        isDeleteWhatsappNotificationSuccess: false
      };
    }

    case actionTypes.DELETE_WHATSAPP_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        isDeleteWhatsappNotificationSuccess: true
      };
    }

    case actionTypes.DELETE_WHATSAPP_NOTIFICATION_FAIL: {
      return {
        ...state,
        isDeleteWhatsappNotificationSuccess: false
      };
    }

    case actionTypes.SEND_WHATSAPP_FOR_GRADING: {
      return {
        ...state,
        isSendWhatsappForGradingSuccess: false
      };
    }

    case actionTypes.SEND_WHATSAPP_FOR_GRADING_SUCCESS: {
      return {
        ...state,
        isSendWhatsappForGradingSuccess: true
      };
    }

    case actionTypes.SEND_WHATSAPP_FOR_GRADING_FAIL: {
      return {
        ...state,
        isSendWhatsappForGradingSuccess: false
      };
    }

    

    default:
      return state;
  }
}
