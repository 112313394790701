import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/whatsapp/notification";
};


export const loadWhatsappNotificationList = data => {
  const url = `${baseURL()}/load`;
  return axios.post(url, data);
};

export const loadWhatsappNotificationDetail = id => {
  const url = `${baseURL()}/detail/${id}`;
  return axios.get(url);
};


export const createWhatsappNotification = data => {
  const url = `${baseURL()}/create`;
  return axios.post(url, data);
};

export const editWhatsappNotification = (id, data) => {
  const url = `${baseURL()}/update/${id}`;
  return axios.post(url, data);
};

export const deleteWhatsappNotification = id => {
  const url = `${baseURL()}/delete/${id}`;
  return axios.get(url);
};

export const sendWhatsappForGrading = id => {
  const url = `${baseURL()}/grading/${id}`;
  return axios.get(url);
};

