import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  notificationList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  notificationDetail:{},
  isDeleteNotificationSuccess: false,
  isUpdateNotificationDetailSuccess:false,
  isUpdateNotificationDetailFail:false,
  isCreateNotificationSuccess:false,
  isCreateNotificationFail:false
};

export default function notificationReducer(state = initState, action) {
 
  switch (action.type) {
   
    case actionTypes.LOAD_NOTIFICATION_LIST: {
      let notificationList = cloneDeep(state.notificationList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        notificationList.offset = action.data.offset;
        notificationList.limit = action.data.limit;
      }

      return {
        ...state,
        notificationList
      };
    }

    case actionTypes.LOAD_NOTIFICATION_LIST_SUCCESS: {
      let notificationList = cloneDeep(state.notificationList);
      notificationList.data = action.data.data;
      notificationList.meta = {
        page: 1 + state.notificationList.offset / state.notificationList.limit,
        pageSize: state.notificationList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.notificationList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        notificationList
      };
    }

    case actionTypes.LOAD_NOTIFICATION_LIST_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.LOAD_NOTIFICATION_DETAIL: {
      
      return {
        ...state,
        notificationDetail:{}
      };
    }

    case actionTypes.LOAD_NOTIFICATION_DETAIL_SUCCESS: {
      return {
        ...state,
        notificationDetail: action.data
      };
    }

    case actionTypes.LOAD_NOTIFICATION_DETAIL_FAIL: {
      return {
        ...state
      };
    }
    
    
    case actionTypes.CREATE_NOTIFICATION: {
      return {
        ...state,
        isCreateNotificationSuccess: false,
        isCreateNotificationFail:false
      };
    }

    case actionTypes.CREATE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        isCreateNotificationSuccess: true,
      };
    }

    case actionTypes.CREATE_NOTIFICATION_FAIL: {
      return {
        ...state,
        isCreateNotificationFail:true
      };
    }

    case actionTypes.EDIT_NOTIFICATION: {
      return {
        ...state,
        isUpdateNotificationDetailSuccess: false,
        isUpdateNotificationDetailFail: false
      };
    }

    case actionTypes.EDIT_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        isUpdateNotificationDetailSuccess: true
      };
    }

    case actionTypes.EDIT_NOTIFICATION_FAIL: {
      return {
        ...state,
        isUpdateNotificationDetailFail: true
      };
    }

    case actionTypes.DELETE_NOTIFICATION: {
      return {
        ...state,
        isDeleteNotificationSuccess: false
      };
    }

    case actionTypes.DELETE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        isDeleteNotificationSuccess: true
      };
    }

    case actionTypes.DELETE_NOTIFICATION_FAIL: {
      return {
        ...state,
        isDeleteNotificationSuccess: false
      };
    }

    default:
      return state;
  }
}
