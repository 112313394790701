import * as actionTypes from "./actionTypes";

export const loadUserList = data => {
  return {
    type: actionTypes.LOAD_USER_LIST,
    data
  };
};

export const loadUserListSuccess = data => {
  return {
    type: actionTypes.LOAD_USER_LIST_SUCCESS,
    data
  };
};

export const loadUserListFail = () => {
  return {
    type: actionTypes.LOAD_USER_LIST_FAIL
  };
};

export const editUserDetail = (id,data) => {
  return {
    type: actionTypes.EDIT_USER_DETAIL,
    id,
    data
  };
};

export const editUserDetailSuccess = () => {
  return {
    type: actionTypes.EDIT_USER_DETAIL_SUCCESS
  };
};

export const editUserDetailFail = () => {
  return {
    type: actionTypes.EDIT_USER_DETAIL_FAIL
  };
};



export const removeUser = (id, data) => {
  return {
    type: actionTypes.REMOVE_USER,
    id,
    data
  };
};

export const removeUserSuccess = () => {
  return {
    type: actionTypes.REMOVE_USER_SUCCESS
  };
};

export const removeUserFail = () => {
  return {
    type: actionTypes.REMOVE_USER_FAIL
  };
};


export const recoveryUser = (id, data) => {
  
  return {
    type: actionTypes.RECOVERY_USER,
    id,
    data
  };
};

export const recoveryUserSuccess = () => {
  
  return {
    type: actionTypes.RECOVERY_USER_SUCCESS
  };
};

export const recoveryUserFail = () => {
  return {
    type: actionTypes.RECOVERY_USER_FAIL
  };
};


export const editUserStatus = (id,data, listParams = null) => {
  return {
    type: actionTypes.EDIT_USER_STATUS,
    id,
    data,
    listParams
  };
};

export const editUserStatusSuccess = () => {
  return {
    type: actionTypes.EDIT_USER_STATUS_SUCCESS
  };
};

export const editUserStatusFail = () => {
  return {
    type: actionTypes.EDIT_USER_STATUS_FAIL
  };
};



export const editUserLoginShowVeriCodeStatus = (id,data, listParams) => {
  return {
    type: actionTypes.EDIT_USER_SHOW_VERI_CODE_STATUS,
    id,
    data,
    listParams
  };
};

export const editUserLoginShowVeriCodeStatusSuccess = () => {
  return {
    type: actionTypes.EDIT_USER_SHOW_VERI_CODE_STATUS_SUCCESS
  };
};

export const editUserLoginShowVeriCodeStatusFail = () => {
  return {
    type: actionTypes.EDIT_USER_SHOW_VERI_CODE_STATUS_FAIL
  };
};



export const editUserVerifyStatus = (id,data, listParams) => {
  return {
    type: actionTypes.EDIT_USER_VERIFY_STATUS,
    id,
    data,
    listParams
  };
};

export const editUserVerifyStatusSuccess = () => {
  return {
    type: actionTypes.EDIT_USER_VERIFY_STATUS_SUCCESS
  };
};

export const editUserVerifyStatusFail = () => {
  return {
    type: actionTypes.EDIT_USER_VERIFY_STATUS_FAIL
  };
};

export const loadUserDetail = (id, data) => {
  
  return {
    type: actionTypes.LOAD_USER_DETAIL,
    id,
    data
  };
};

export const loadUserDetailSuccess = data => {
  
  return {
    type: actionTypes.LOAD_USER_DETAIL_SUCCESS,
    data
  };
};

export const loadUserDetailFail = () => {
  return {
    type: actionTypes.LOAD_USER_DETAIL_FAIL
  };
};

export const switchUserToOrganizationType = (id, data) => {
  
  return {
    type: actionTypes.SWITCH_USER_TO_ORGANIZATION_TYPE,
    id,
    data
  };
};

export const switchUserToOrganizationTypeSuccess = () => {
  
  return {
    type: actionTypes.SWITCH_USER_TO_ORGANIZATION_TYPE_SUCCESS
  };
};

export const switchUserToOrganizationTypeFail = () => {
  return {
    type: actionTypes.SWITCH_USER_TO_ORGANIZATION_TYPE_FAIL
  };
};



export const userCount = (data) => {
  
  return {
    type: actionTypes.USER_COUNT,
    data
  };
};

export const userCountSuccess = data => {
  return {
    type: actionTypes.USER_COUNT_SUCCESS,
    data
  };
};

export const userCountFail = () => {
  return {
    type: actionTypes.USER_COUNT_FAIL
  };
};

/* ===== USER SUMMARY ===== */
export const loadUserSummary = data => {
  return {
    type: actionTypes.LOAD_USER_SUMMARY,
    data
  };
};

export const loadUserSummarySuccess = data => {
  return {
    type: actionTypes.LOAD_USER_SUMMARY_SUCCESS,
    data
  };
};

export const loadUserSummaryFail = () => {
  return {
    type: actionTypes.LOAD_USER_SUMMARY_FAIL
  };
};

/* ===== END USER SUMMARY ===== */




/* ===== USER BILLING ADDRESS ===== */
export const setUserBillingAddress = (id,data) => {
  return {
    type: actionTypes.SET_USER_BILLING_ADDRESS,
    id,
    data
  };
};

export const setUserBillingAddressSuccess = () => {
  return {
    type: actionTypes.SET_USER_BILLING_ADDRESS_SUCCESS
  };
};

export const setUserBillingAddressFail = () => {
  return {
    type: actionTypes.SET_USER_BILLING_ADDRESS_FAIL
  };
};

/* ===== END USER BILLING ADDRESS ===== */


/* ===== USER CREDIT TERM REQEUST LIS ===== */
export const loadUserCreditTermRequestList = data => {
  return {
    type: actionTypes.LOAD_USER_CREDIT_TERM_REQEUST_LIST,
    data
  };
};

export const loadUserCreditTermRequestListSuccess = data => {
  return {
    type: actionTypes.LOAD_USER_CREDIT_TERM_REQEUST_LIST_SUCCESS,
    data
  };
};

export const loadUserCreditTermRequestListFail = () => {
  return {
    type: actionTypes.LOAD_USER_CREDIT_TERM_REQEUST_LIST_FAIL
  };
};




export const approveUserCreditTerm = (id, data) => {
  return {
    type: actionTypes.APPROVE_USER_CREDIT_TERM_REQEUST,
    id, 
    data
  };
};

export const approveUserCreditTermSuccess = () => {
  return {
    type: actionTypes.APPROVE_USER_CREDIT_TERM_REQEUST_SUCCESS
  };
};

export const approveUserCreditTermFail = () => {
  return {
    type: actionTypes.APPROVE_USER_CREDIT_TERM_REQEUST_FAIL
  };
};



export const rejectUserCreditTerm = (id,data) => {
  return {
    type: actionTypes.REJECT_USER_CREDIT_TERM_REQEUST,
    id,
    data
  };
};

export const rejectUserCreditTermSuccess = () => {
  return {
    type: actionTypes.REJECT_USER_CREDIT_TERM_REQEUST_SUCCESS
  };
};

export const rejectUserCreditTermFail = () => {
  return {
    type: actionTypes.REJECT_USER_CREDIT_TERM_REQEUST_FAIL
  };
};


export const cancelUserCreditTerm = (id,data) => {
  return {
    type: actionTypes.CANCEL_USER_CREDIT_TERM_REQEUST,
    id,
    data
  };
};

export const cancelUserCreditTermSuccess = () => {
  return {
    type: actionTypes.CANCEL_USER_CREDIT_TERM_REQEUST_SUCCESS
  };
};

export const cancelUserCreditTermFail = () => {
  return {
    type: actionTypes.CANCEL_USER_CREDIT_TERM_REQEUST_FAIL
  };
};

/* ===== END USER CREDIT TERM REQEUST LIS ===== */



export const setUserNegoPrice = (id, data) => {
  return {
    type: actionTypes.SET_USER_NEGO_PRICE,
    id,
    data
  };
};

export const setUserNegoPriceSuccess = () => {
  return {
    type: actionTypes.SET_USER_NEGO_PRICE_SUCCESS
  };
};

export const setUserNegoPriceFail = () => {
  return {
    type: actionTypes.SET_USER_NEGO_PRICE_FAIL
  };
};