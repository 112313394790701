import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
import Helper from "../../helpers/helpers";


export function* countRecyclingNewPrice() {
  
  yield takeEvery(actionTypes.COUNT_NEW_RECYCLING_PRICE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("COUNTNEWRECYCLINGPRICE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.countRecyclingNewPrice(action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.countRecyclingNewPriceSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.countRecyclingNewPriceFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.countRecyclingNewPriceFail());
    }
  });
}


export function* loadRecyclingList() {
  
  yield takeEvery(actionTypes.LOAD_RECYCLING_LIST, function*(action) {

    if(!action.data) action.data = {}
    let search = (action.data.search) ? action.data.search : ""

    let track_no = Helper.generateTrackNo(`RECYCLINGLIST${search}`);
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadRecyclingList(action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.loadRecyclingListSuccess(response.data.data));

        if(action.data.priceList && action.data.priceList === true)                 
          yield put(actions.countRecyclingNewPrice());
        
      } else {
        alert(response.data.message);
        yield put(actions.loadRecyclingListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadRecyclingListFail());
    }
  });
}

export function* loadRecyclingListAll() {
  
  yield takeEvery(actionTypes.LOAD_RECYCLING_LIST_ALL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECYCLINGLISTALL");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadRecyclingList(action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.loadRecyclingListAllSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadRecyclingListAllFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadRecyclingListAllFail());
    }
  });
}

export function* loadRecyclingDetail() {
  
  yield takeEvery(actionTypes.LOAD_RECYCLING_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECYCLINGDETAIL");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadRecyclingDetail(action.id, action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.loadRecyclingDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadRecyclingDetailFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadRecyclingDetailFail());
    }
  });
}

export function* loadRecyclingEffectiveDateList() {
  
  yield takeEvery(actionTypes.LOAD_RECYCLING_EFFECTIVE_DATE_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECYCLINGEFFECTIVEDATELIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadRecyclingEffectiveDateList(action.data);
      if (response.data.success && response.data.data) {
       
        yield put(actions.loadRecyclingEffectiveDateListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadRecyclingEffectiveDateListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadRecyclingEffectiveDateListFail());
    }
  });
}


export function* loadRecyclingMixList() {
  
  yield takeEvery(actionTypes.LOAD_RECYCLING_MIX_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECYCLINGMIXLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadRecyclingList(action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.loadRecyclingMixListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadRecyclingMixListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadRecyclingMixListFail());
    }
  });
}


export function* loadRecyclingCategory() {
  
  yield takeEvery(actionTypes.LOAD_RECYCLING_CATEGORY, function*(action) {

    if(!action.data) action.data = {}
    
    let notMixCategory = (action.data.notMixCategory) ? "notMixCategory" : ""
    let mixCategory = (action.data.mixCategory) ? "mixCategory" : ""
    let search = (action.data.search) ? action.data.search : ""
    
    let track_no = Helper.generateTrackNo(`RECYCLINGCATEGORYLIST${notMixCategory}${mixCategory}${search}`);

    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadRecyclingCategory(action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.loadRecyclingCategorySuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadRecyclingCategoryFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadRecyclingCategoryFail());
    }
  });
}

export function* loadRecyclingCategoryAll() {
  
  yield takeEvery(actionTypes.LOAD_RECYCLING_CATEGORY_ALL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECYCLINGCATEGORYALL");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadRecyclingCategory(action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.loadRecyclingCategoryAllSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadRecyclingCategoryAllFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadRecyclingCategoryAllFail());
    }
  });
}


export function* addRecyclingCategory() {
  yield takeEvery(actionTypes.ADD_RECYCLING_CATEGORY, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ADDRECYCLINGCATEGORY");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.addRecyclingCategory(action.data);
      if (response.data.success) {
        yield put(actions.addRecyclingCategorySuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.addRecyclingCategoryFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.addRecyclingCategoryFail());
    }
  });
}

export function* editRecyclingCategory() {
  yield takeEvery(actionTypes.EDIT_RECYCLING_CATEGORY, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITRECYCLINGCATEGORY");

    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editRecyclingCategory(action.id,action.data);
      if (response.data.success) {
        yield put(actions.editRecyclingCategorySuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.editRecyclingCategoryFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editRecyclingCategoryFail());
    }
  });
}

export function* deleteRecyclingCategory() {
  yield takeEvery(actionTypes.DELETE_RECYCLING_CATEGORY, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DELRECYCLINGCATEGORY");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.deleteRecyclingCategory(action.id, action.data);
      if (response.data.success) {
        yield put(actions.deleteRecyclingCategorySuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.deleteRecyclingCategoryFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.deleteRecyclingCategoryFail());
    }
  });
}

export function* setRecyclingCategorySequence() {
  yield takeEvery(actionTypes.SET_RECYCLING_CATEGORY_SEQUENCE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETRECYCLINGCATEGORYSEQUENCE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.setRecyclingCategorySequence(action.data);
      if (response.data.success) {
        yield put(actions.setRecyclingCategorySequenceSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.setRecyclingCategorySequenceFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.setRecyclingCategorySequenceFail());
    }
  });
}


export function* recyclingPriceControlLoad() {
  yield takeEvery(actionTypes.RECYCLING_PRICE_CONTROL_LOAD, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECYCLINGPRICECONTROLLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.recyclingPriceControlLoad(action.data);
      if (response.data.success) {

        yield put(actions.recyclingPriceControlLoadSuccess(response.data.data));
        
      } else {
        alert(response.data.message);
        yield put(actions.recyclingPriceControlLoadFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.recyclingPriceControlLoadFail());
    }
  });
}

export function* recyclingPriceControlSet() {
  yield takeEvery(actionTypes.RECYCLING_PRICE_CONTROL_SET, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETRECYCLINGPRICE");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.recyclingPriceControlSet(action.data,action.id);
      if (response.data.success) {
        yield put(actions.recyclingPriceControlSetSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.recyclingPriceControlSetFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.recyclingPriceControlSetFail());
    }
  });
}

export function* deleteRecycling() {
  yield takeEvery(actionTypes.DELETE_RECYCLING, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DELRECYCLING");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.deleteRecycling(action.id, action.data);
      
      if (response.data.success) {
        
        if(response.data.data)
        {
          yield put(actions.deleteRecyclingSuccess(response.data.data));

        }else{
   
          yield put(actions.deleteRecyclingSuccess());
          yield put(actions.toastSuccess(response.data.message));
        }
        
      } else {
        alert(response.data.message);
        yield put(actions.deleteRecyclingFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.deleteRecyclingFail());
    }
  });
}

export function* setRecyclingSequence() {
  yield takeEvery(actionTypes.SET_RECYCLING_SEQUENCE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETRECYCLINGSEQUENCE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.setRecyclingSequence(action.id, action.data);
      if (response.data.success) {
        yield put(actions.setRecyclingSequenceSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.setRecyclingSequenceFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.setRecyclingSequenceFail());
    }
  });
}




export function* loadRecyclingExtraFeeList() {
  
  yield takeEvery(actionTypes.LOAD_RECYCLING_EXTRA_FEE_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECYCLINGEXTRAFEELIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadRecyclingExtraFeeList(action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.loadRecyclingExtraFeeListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadRecyclingExtraFeeListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadRecyclingExtraFeeListFail());
    }
  });
}

export function* addRecyclingExtraFee() {
  yield takeEvery(actionTypes.ADD_RECYCLING_EXTRA_FEE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ADDRECYCLINGEXTRAFEE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.addRecyclingExtraFee(action.data);
      if (response.data.success) {
        yield put(actions.addRecyclingExtraFeeSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.addRecyclingExtraFeeFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.addRecyclingExtraFeeFail());
    }
  });
}

export function* editRecyclingExtraFeeItem() {
  yield takeEvery(actionTypes.EDIT_RECYCLING_EXTRA_FEE_ITEM, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITRECYCLINGEXTRAFEEITEM");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editRecyclingExtraFeeItem(action.id,action.data);
      if (response.data.success) {
        yield put(actions.editRecyclingExtraFeeItemSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.editRecyclingExtraFeeItemFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editRecyclingExtraFeeItemFail());
    }
  });
}

export function* updateRecyclingExtraFeeWasteType() {
  yield takeEvery(actionTypes.UPDATE_RECYCLING_EXTRA_FEE_WASTE_TYPE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDRECYCLINEXTRAFEEWASTETYPE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.updateRecyclingExtraFeeWasteType(action.id,action.data);
      if (response.data.success) {
        yield put(actions.updateRecyclingExtraFeeWasteTypeSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.updateRecyclingExtraFeeWasteTypeFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.updateRecyclingExtraFeeWasteTypeFail());
    }
  });
}

export function* deleteRecyclingExtraFee() {
  yield takeEvery(actionTypes.DELETE_RECYCLING_EXTRA_FEE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DELRECYCLINGEXTRAFEE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.deleteRecyclingExtraFee(action.id,action.data);
      if (response.data.success) {
        yield put(actions.deleteRecyclingExtraFeeSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.deleteRecyclingExtraFeeFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.deleteRecyclingExtraFeeFail());
    }
  });
}



export function* loadRecyclingLoadingFeeList() {
  
  yield takeEvery(actionTypes.LOAD_RECYCLING_LOADING_FEE_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECYCLINGLOADINGFEELIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadRecyclingLoadingFeeList(action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.loadRecyclingLoadingFeeListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadRecyclingLoadingFeeListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadRecyclingLoadingFeeListFail());
    }
  });
}

export function* setRecyclingLoadingFee() {
  yield takeEvery(actionTypes.SET_RECYCLING_LOADING_FEE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETLOADINGFEE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.setRecyclingLoadingFee(action.data);
      if (response.data.success) {
        yield put(actions.setRecyclingLoadingFeeSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.setRecyclingLoadingFeeFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.setRecyclingLoadingFeeFail());
    }
  });
}



export function* getRecyclingPricePDF() {
  
  yield takeEvery(actionTypes.GET_RECYCLING_PRICE_PDF, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GETRECYCLINGPRICEPDF");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.getRecyclingPricePDF(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.getRecyclingPricePDFSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getRecyclingPricePDFFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getRecyclingPricePDFFail());
    }
  });
}


export function* getRecyclingPricePostPDF() {
  
  yield takeEvery(actionTypes.GET_RECYCLING_PRICE_POST_PDF, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GETRECYCLINGPRICEPOSTPDF");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.getRecyclingPricePostPDF(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.getRecyclingPricePostPDFSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getRecyclingPricePostPDFFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getRecyclingPricePostPDFFail());
    }
  });
}


export function* loadRecyclingSalesPriceList() {
  
  yield takeEvery(actionTypes.LOAD_RECYCLING_SALES_PRICE_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECYCLINGSALESPRICELIST");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadRecyclingSalesPriceList(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadRecyclingSalesPriceListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadRecyclingSalesPriceListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadRecyclingSalesPriceListFail());
    }
  });
}

export function* getRecyclingSalesPricePDF() {
  
  yield takeEvery(actionTypes.GET_RECYCLING_SALES_PRICE_PDF, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECYCLINGSALESPRICEPDF");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.getRecyclingSalesPricePDF(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.getRecyclingSalesPricePDFSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getRecyclingSalesPricePDFFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getRecyclingSalesPricePDFFail());
    }
  });
}

export function* loadRecyclingSalesPriceUpdHistory() {
  
  yield takeEvery(actionTypes.RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let status = (action.data.status) ? action.data.status : ""
    
    let track_no = Helper.generateTrackNo(`RECYCLINGSALESUPDHISLIST${status}`);

    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadRecyclingSalesPriceUpdHistory(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadRecyclingSalesPriceUpdHistorySuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadRecyclingSalesPriceUpdHistoryFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadRecyclingSalesPriceUpdHistoryFail());
    }
  });
}


export function* countRecyclingSalesPriceUpdHistory() {
  
  yield takeEvery(actionTypes.RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECYCLINGSALESUPDHISCOUNT");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.countRecyclingSalesPriceUpdHistory(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.countRecyclingSalesPriceUpdHistorySuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.countRecyclingSalesPriceUpdHistoryFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.countRecyclingSalesPriceUpdHistoryFail());
    }
  });
}


export function* getRecyclingSalesPriceUpdHistoryDetail() {
  
  yield takeEvery(actionTypes.RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RECYCLINGSALESUPDHISDETAIL");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.getRecyclingSalesPriceUpdHistoryDetail(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.getRecyclingSalesPriceUpdHistoryDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getRecyclingSalesPriceUpdHistoryDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getRecyclingSalesPriceUpdHistoryDetailFail());
    }
  });
}

export default function* recyclingSaga() {
  
  yield all([fork(countRecyclingNewPrice)]);
  yield all([fork(loadRecyclingList)]);
  yield all([fork(loadRecyclingListAll)]);
  yield all([fork(loadRecyclingDetail)]);  
  yield all([fork(loadRecyclingEffectiveDateList)]);  
  yield all([fork(loadRecyclingMixList)]);
  
  yield all([fork(loadRecyclingCategory)]);  
  yield all([fork(loadRecyclingCategoryAll)]);    
  yield all([fork(addRecyclingCategory)]);
  yield all([fork(editRecyclingCategory)]);
  yield all([fork(deleteRecyclingCategory)]);
  yield all([fork(setRecyclingCategorySequence)]);
  
  yield all([fork(recyclingPriceControlLoad)]);
  yield all([fork(recyclingPriceControlSet)]);  
  yield all([fork(deleteRecycling)]);
  yield all([fork(setRecyclingSequence)]);
  
  yield all([fork(loadRecyclingExtraFeeList)]);
  yield all([fork(addRecyclingExtraFee)]);
  yield all([fork(editRecyclingExtraFeeItem)]);
  yield all([fork(updateRecyclingExtraFeeWasteType)]);
  yield all([fork(deleteRecyclingExtraFee)]);
  yield all([fork(loadRecyclingLoadingFeeList)]);
  yield all([fork(setRecyclingLoadingFee)]);
  
  yield all([fork(getRecyclingPricePDF)]);
  yield all([fork(getRecyclingPricePostPDF)]);
  yield all([fork(loadRecyclingSalesPriceList)]);
  yield all([fork(getRecyclingSalesPricePDF)]);
  
  yield all([fork(loadRecyclingSalesPriceUpdHistory)]);
  yield all([fork(countRecyclingSalesPriceUpdHistory)]);
  yield all([fork(getRecyclingSalesPriceUpdHistoryDetail)]);
}
