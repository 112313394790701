import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/general/admin/express";
};


export const loadExpressRecyclingList = data => {
  const url = `${baseURL()}/recycling/load`;
  return axios.post(url, data);
};

export const loadExpressRecyclingDetail = (id, data) => {
  const url = `${baseURL()}/recycling/load/${id}`;
  return axios.post(url, data);
};

export const loadExpressRecyclingEffectiveDateList = (data) => {
  const url = `${baseURL()}/price/effectiveDate`;
  return axios.post(url, data);
};


export const expressRecyclingPriceControlLoad = (data) => {
  const url = `${baseURL()}/priceControl/load`;
  return axios.post(url, data);
};

export const expressRecyclingPriceControlSet = (data, id = null) => {
  let url = `${baseURL()}/priceControl/set`;
  if(id) url += `/${id}`
  
  return axios.post(url, data);
};

export const deleteExpressRecycling = (id, data) => {
  const url = `${baseURL()}/remove/${id}`;
  return axios.post(url, data);
};

export const setExpressRecyclingSequence = (id, data) => {
  const url = `${baseURL()}/sequence/set/${id}`;
  return axios.post(url, data);
};



export const getExpressRecyclingPricePDF = data => {
  const url = `${baseURL()}/pdf `;
  return axios.post(url, data);
};

export const getExpressRecyclingPricePostPDF = data => {
  const url = `${baseURL()}/post/pdf `;
  return axios.post(url, data);
};


export const loadExpressRecyclingSalesPriceUpdHistory = data => {
  const url = `${baseURL()}/priceUpdateHistory/load`;
  return axios.post(url, data);
};

export const countExpressRecyclingSalesPriceUpdHistory = data => {
  const url = `${baseURL()}/priceUpdateHistory/count`;
  return axios.post(url, data);
};

export const getExpressRecyclingSalesPriceUpdHistoryDetail = (id, data) => {
  const url = `${baseURL()}/priceUpdateHistory/detail/${id}`;
  return axios.post(url, data);
};