import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/general/admin/dumpster";
};

export const loadDumpsterMeasurementList = data => {
  const url = `${baseURL()}/measure/load`;
  return axios.post(url, data);
};

export const addDumpsterMeasurement = data => {
  const url = `${baseURL()}/measure/create`;
  return axios.post(url, data);
};

export const editDumpsterMeasurement = (id, data) => {
  const url = `${baseURL()}/measure/update/${id}`;
  return axios.post(url, data);
};

export const deleteDumpsterMeasurement = (id, data) => {
  const url = `${baseURL()}/measure/remove/${id}`;
  return axios.post(url, data);
};


export const loadDumpsterServiceList = (data) => {
  const url = `${baseURL()}/service/load`;
  return axios.post(url, data);
};

export const updateDumpsterService = (id, data) => {
  const url = `${baseURL()}/service/update/${id}`;
  return axios.post(url, data);
};


export const loadDumpsterList = data => {
  const url = `${baseURL()}/load`;
  return axios.post(url, data);
};

export const addDumpster = data => {
  const url = `${baseURL()}/create`;
  return axios.post(url, data);
};

export const loadDumpsterDetail = (id, data) => {
  const url = `${baseURL()}/detail/${id}`;
  return axios.post(url, data);
};

export const editDumpster = (id, data) => {
  const url = `${baseURL()}/update/${id}`;
  return axios.post(url, data);
};

export const deleteDumpster = (id, data) => {
  const url = `${baseURL()}/remove/${id}`;
  return axios.post(url, data);
};


export const loadDumpsterExtraFee = (id, data) => {
  let url = `${baseURL()}/extraFee`;

  if(id)
    url += `/${id}`

  return axios.post(url, data);
};


export const updateDumpsterWasteProcessCost = (id, data) => {
  const url = `${baseURL()}/wasteProcessCost/${id}`;
  return axios.post(url, data);
};

export const updateDumpsterRecyclingProcessCost = (id, data) => {
  const url = `${baseURL()}/recyclingProcessCost/${id}`;
  return axios.post(url, data);
};


export const driverDumpsterCount = (id = null, data) => {
  var url = `${baseURL()}/driver/count`;
  if(id) url += `/${id}`
  return axios.post(url, data);
};

export const loadDriverDumpsterList = (id = null,data) => {
  var url = `${baseURL()}/driver/load`;
  if(id) url += `/${id}`
  return axios.post(url, data);
};


export const loadDriverDumpsterSummary = data => {
  const url = `${baseURL()}/driver/summary`;
  return axios.post(url,data);
};



/* ===== RECYCLING DUMPSTER ===== */
export const loadRecyclingDumpsterList = data => {
  const url = `${baseURL()}/recycling/load`;
  return axios.post(url, data);
};

export const addRecyclingDumpster = data => {
  const url = `${baseURL()}/recycling/create`;
  return axios.post(url, data);
};

export const loadRecyclingDumpsterDetail = (id, data) => {
  const url = `${baseURL()}/recycling/detail/${id}`;
  return axios.post(url, data);
};

export const editRecyclingDumpster = (id, data) => {
  const url = `${baseURL()}/recycling/update/${id}`;
  return axios.post(url, data);
};

export const deleteRecyclingDumpster = (id, data) => {
  const url = `${baseURL()}/recycling/remove/${id}`;
  return axios.post(url, data);
};
/* ===== END RECYCLING DUMPSTER ===== */