import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  newRecyclingPriceCount: {},
  recyclingList: [],
  recyclingListAll: [],
  recyclingDetail: {},
  recyclingMixList: [],
  isLoadingRecycling: false,
  isLoadingRecyclingMix: false,
  isRecyclingCategoryAddSuccess: false,
  isRecyclingCategoryAddFail: false,
  isRecyclingCategoryEditSuccess: false,
  isRecyclingCategoryEditFail: false,
  isRecyclingCategoryRemoveSuccess: false,
  isRecyclingCategoryDragSuccess: false,
  isRecyclingAddSuccess: false,
  isRecyclingEditSuccess: false,
  isRecyclingDragSuccess: false,
  isRecyclingPriceControlSetSuccess: false,
  isRecyclingPriceControlSetFail: false,
  recyclingPriceControlList: [],
  isRecyclingRemoveSuccess: false,
  recyclingRemoveConfirm: null,
  isSetRecyclingEnableSuccess:false,
  recyclingExtraFeeList: [],
  isLoadingRecyclingExtraFee: false,
  isRecyclingExtraFeeAddSuccess: false,
  isRecyclingExtraFeeAddFail: false,
  isRecyclingExtraFeeItemEditSuccess: false,
  isRecyclingExtraFeeItemEditFail: false,
  isRecyclingExtraFeeWasteTypeUpdateSuccess: false,
  isRecyclingExtraFeeWasteTypeUpdateFail: true,
  isRecyclingExtraFeeRemoveSuccess: false,

  recyclingItemList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  isRecyclingItemSetSuccess: false,
  isRecyclingItemRemoveSuccess: false,
  recyclingLoadingFeeList: [],
  isRecyclingLoadingFeeLoadDone: false,
  isRecyclingLoadingFeeSetSuccess: false,
  recyclingCategoryList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  recyclingCategoryListAll: [],
  recyclingPricePDF: "",
  recyclingPricePostPDF: "",
  recyclingEffectiveDateList: {},
  recyclingSalesPriceList: [],
  recyclingSalesPricePDF: "",  
  recyclingSalesPriceUpdateHistoryList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  recyclingSalesPriceUpdateHistoryCount: {},
  recyclingSalesPriceUpdateHistoryDetail: [],
};

export default function recyclingReducer(state = initState, action) {

  switch (action.type) {

    
    case actionTypes.COUNT_NEW_RECYCLING_PRICE: {
      
      return {
        ...state,
        newRecyclingPriceCount: cloneDeep(state.newRecyclingPriceCount),
      };
    }

    case actionTypes.COUNT_NEW_RECYCLING_PRICE_SUCCESS: {
  
      return {
        ...state,
        newRecyclingPriceCount: action.data,
      };
    }

    case actionTypes.COUNT_NEW_RECYCLING_PRICE_FAIL: {
      return {
        ...state
      };
    }
    case actionTypes.LOAD_RECYCLING_LIST: {
      
      return {
        ...state,
        recyclingList:[],
        isLoadingRecycling: true,
      };
    }

    case actionTypes.LOAD_RECYCLING_LIST_SUCCESS: {
  
      return {
        ...state,
        recyclingList: action.data,
        isLoadingRecycling: false,
      };
    }

    case actionTypes.LOAD_RECYCLING_LIST_FAIL: {
      return {
        ...state,
        isLoadingRecycling: false,
      };
    }
    
    
    case actionTypes.LOAD_RECYCLING_LIST_ALL: {
      
      return {
        ...state,
        recyclingListAll: []
      };
    }

    case actionTypes.LOAD_RECYCLING_LIST_ALL_SUCCESS: {
  
      return {
        ...state,
        recyclingListAll: action.data
      };
    }

    case actionTypes.LOAD_RECYCLING_LIST_ALL_FAIL: {
      return {
        ...state,
      };
    }
    
    case actionTypes.LOAD_RECYCLING_DETAIL: {
      
      return {
        ...state,
        recyclingDetail:{},
      };
    }

    case actionTypes.LOAD_RECYCLING_DETAIL_SUCCESS: {
  
      return {
        ...state,
        recyclingDetail: action.data,
      };
    }

    case actionTypes.LOAD_RECYCLING_DETAIL_FAIL: {
      return {
        ...state,
      };
    }

    
    case actionTypes.LOAD_RECYCLING_EFFECTIVE_DATE_LIST: {
    
      return {
        ...state,
        recyclingEffectiveDateList: {},
      };
    }

    case actionTypes.LOAD_RECYCLING_EFFECTIVE_DATE_LIST_SUCCESS: {
      
      return {
        ...state,
        recyclingEffectiveDateList: action.data,
      };
    }

    case actionTypes.LOAD_RECYCLING_EFFECTIVE_DATE_LIST_FAIL: {
      return {
        ...state,
        recyclingEffectiveDateList: {},
      };
    }
    
    
    case actionTypes.LOAD_RECYCLING_MIX_LIST: {
      
      return {
        ...state,
        recyclingMixList:[],
        isLoadingRecyclingMix: true,
      };
    }

    case actionTypes.LOAD_RECYCLING_MIX_LIST_SUCCESS: {
  
      return {
        ...state,
        recyclingMixList: action.data,
        isLoadingRecyclingMix: false,
      };
    }

    case actionTypes.LOAD_RECYCLING_MIX_LIST_FAIL: {
      return {
        ...state,
        isLoadingRecyclingMix: false,
      };
    }

    
    case actionTypes.LOAD_RECYCLING_CATEGORY: {
      
      let recyclingCategoryList = cloneDeep(state.recyclingCategoryList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        recyclingCategoryList.offset = action.data.offset;
        recyclingCategoryList.limit = action.data.limit;
      }

      return {
        ...state,
        recyclingCategoryList
      };
    }

    case actionTypes.LOAD_RECYCLING_CATEGORY_SUCCESS: {
  
      let recyclingCategoryList = cloneDeep(state.recyclingCategoryList);
      recyclingCategoryList.data = action.data.data;
      recyclingCategoryList.meta = {
        page: 1 + state.recyclingCategoryList.offset / state.recyclingCategoryList.limit,
        pageSize: state.recyclingCategoryList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.recyclingCategoryList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        recyclingCategoryList
      };
    }

    case actionTypes.LOAD_RECYCLING_CATEGORY_FAIL: {
      return {
        ...state,
      };
    }
    
    
    case actionTypes.LOAD_RECYCLING_CATEGORY_ALL: {
      
      return {
        ...state,
        recyclingCategoryListAll: []
      };
    }

    case actionTypes.LOAD_RECYCLING_CATEGORY_ALL_SUCCESS: {
  
      return {
        ...state,
        recyclingCategoryListAll: action.data
      };
    }

    case actionTypes.LOAD_RECYCLING_CATEGORY_ALL_FAIL: {
      return {
        ...state,
      };
    }

    case actionTypes.ADD_RECYCLING_CATEGORY: {
      return {
        ...state,
        isRecyclingCategoryAddSuccess: false,
        isRecyclingCategoryAddFail: false,
      };
    }

    case actionTypes.ADD_RECYCLING_CATEGORY_SUCCESS: {
      return {
        ...state,
        isRecyclingCategoryAddSuccess: true
      };
    }

    case actionTypes.ADD_RECYCLING_CATEGORY_FAIL: {
      return {
        ...state,
        isRecyclingCategoryAddFail: true
      };
    }


    case actionTypes.EDIT_RECYCLING_CATEGORY: {
      return {
        ...state,
        isRecyclingCategoryEditSuccess: false,
        isRecyclingCategoryEditFail: false,
      };
    }

    case actionTypes.EDIT_RECYCLING_CATEGORY_SUCCESS: {
      return {
        ...state,
        isRecyclingCategoryEditSuccess: true
      };
    }

    case actionTypes.EDIT_RECYCLING_CATEGORY_FAIL: {
      return {
        ...state,
        isRecyclingCategoryEditFail: true
      };
    }
    

    case actionTypes.DELETE_RECYCLING_CATEGORY: {
      return {
        ...state,
        isRecyclingCategoryRemoveSuccess: false
      };
    }

    case actionTypes.DELETE_RECYCLING_CATEGORY_SUCCESS: {
      return {
        ...state,
        isRecyclingCategoryRemoveSuccess: true
      };
    }

    case actionTypes.DELETE_RECYCLING_CATEGORY_FAIL: {
      return {
        ...state,
        isRecyclingCategoryRemoveSuccess: false
      };
    }

    case actionTypes.SET_RECYCLING_CATEGORY_SEQUENCE: {
      return {
        ...state,
        isRecyclingCategoryDragSuccess: false
      };
    }

    case actionTypes.SET_RECYCLING_CATEGORY_SEQUENCE_SUCCESS: {
      return {
        ...state,
        isRecyclingCategoryDragSuccess: true
      };
    }

    case actionTypes.SET_RECYCLING_CATEGORY_SEQUENCE_FAIL: {
      return {
        ...state,
        isRecyclingCategoryDragSuccess: false
      };
    }
    

    case actionTypes.RECYCLING_PRICE_CONTROL_LOAD: {
      return {
        ...state,
        recyclingPriceControlList:[],
      };
    }

    case actionTypes.RECYCLING_PRICE_CONTROL_LOAD_SUCCESS: {
      return {
        ...state,
        recyclingPriceControlList: action.data,
      };
    }

    case actionTypes.RECYCLING_PRICE_CONTROL_LOAD_FAIL: {
      return {
        ...state
      };
    }


    case actionTypes.RECYCLING_PRICE_CONTROL_SET: {
      return {
        ...state,
        isRecyclingPriceControlSetSuccess: false,
        isRecyclingPriceControlSetFail: false
      };
    }

    case actionTypes.RECYCLING_PRICE_CONTROL_SET_SUCCESS: {
      return {
        ...state,
        isRecyclingPriceControlSetSuccess: true
      };
    }

    case actionTypes.RECYCLING_PRICE_CONTROL_SET_FAIL: {
      return {
        ...state,
        isRecyclingPriceControlSetFail: true
      };
    }
    

    case actionTypes.DELETE_RECYCLING: {
      return {
        ...state,
        isRecyclingRemoveSuccess: false,
        recyclingRemoveConfirm: null
      };
    }

    case actionTypes.DELETE_RECYCLING_SUCCESS: {
      
      return {
        ...state,
        isRecyclingRemoveSuccess: (action.data) ? false : true,
        recyclingRemoveConfirm: (action.data) ? action.data : null
      };
    }

    case actionTypes.DELETE_RECYCLING_FAIL: {
      return {
        ...state,
        isRecyclingRemoveSuccess: false
      };
    }
    


    case actionTypes.SET_RECYCLING_SEQUENCE: {
      return {
        ...state,
        isRecyclingDragSuccess: false
      };
    }

    case actionTypes.SET_RECYCLING_SEQUENCE_SUCCESS: {
      return {
        ...state,
        isRecyclingDragSuccess: true
      };
    }

    case actionTypes.SET_RECYCLING_SEQUENCE_FAIL: {
      return {
        ...state,
        isRecyclingDragSuccess: false
      };
    }
    
    /** RECYCLING EXTRA FEE **/
    case actionTypes.LOAD_RECYCLING_EXTRA_FEE_LIST: {
      
      return {
        ...state,
        recyclingExtraFeeList:[],
        isLoadingRecyclingExtraFee: true,
      };
    }

    case actionTypes.LOAD_RECYCLING_EXTRA_FEE_LIST_SUCCESS: {
  
      return {
        ...state,
        recyclingExtraFeeList: action.data,
        isLoadingRecyclingExtraFee: false,
      };
    }

    case actionTypes.LOAD_RECYCLING_EXTRA_FEE_LIST_FAIL: {
      return {
        ...state,
        isLoadingRecyclingExtraFee: false,
      };
    }



    case actionTypes.ADD_RECYCLING_EXTRA_FEE: {
      return {
        ...state,
        isRecyclingExtraFeeAddSuccess: false,
        isRecyclingExtraFeeAddFail: false
      };
    }

    case actionTypes.ADD_RECYCLING_EXTRA_FEE_SUCCESS: {
      return {
        ...state,
        isRecyclingExtraFeeAddSuccess: true
      };
    }

    case actionTypes.ADD_RECYCLING_EXTRA_FEE_FAIL: {
      return {
        ...state,
        isRecyclingExtraFeeAddFail: true
      };
    }


    case actionTypes.EDIT_RECYCLING_EXTRA_FEE_ITEM: {
      return {
        ...state,
        isRecyclingExtraFeeItemEditSuccess: false,
        isRecyclingExtraFeeItemEditFail: false
      };
    }

    case actionTypes.EDIT_RECYCLING_EXTRA_FEE_ITEM_SUCCESS: {
      return {
        ...state,
        isRecyclingExtraFeeItemEditSuccess: true
      };
    }

    case actionTypes.EDIT_RECYCLING_EXTRA_FEE_ITEM_FAIL: {
      return {
        ...state,
        isRecyclingExtraFeeItemEditFail: true
      };
    }


    case actionTypes.UPDATE_RECYCLING_EXTRA_FEE_WASTE_TYPE: {
      return {
        ...state,
        isRecyclingExtraFeeWasteTypeUpdateSuccess: false,
        isRecyclingExtraFeeWasteTypeUpdateFail: false
      };
    }

    case actionTypes.UPDATE_RECYCLING_EXTRA_FEE_WASTE_TYPE_SUCCESS: {
      return {
        ...state,
        isRecyclingExtraFeeWasteTypeUpdateSuccess: true
      };
    }

    case actionTypes.UPDATE_RECYCLING_EXTRA_FEE_WASTE_TYPE_FAIL: {
      return {
        ...state,
        isRecyclingExtraFeeWasteTypeUpdateFail: true
      };
    }
    

    case actionTypes.DELETE_RECYCLING_EXTRA_FEE: {
      return {
        ...state,
        isRecyclingExtraFeeRemoveSuccess: false
      };
    }

    case actionTypes.DELETE_RECYCLING_EXTRA_FEE_SUCCESS: {
      return {
        ...state,
        isRecyclingExtraFeeRemoveSuccess: true
      };
    }

    case actionTypes.DELETE_RECYCLING_EXTRA_FEE_FAIL: {
      return {
        ...state,
        isRecyclingExtraFeeRemoveSuccess: false
      };
    }

    case actionTypes.LOAD_RECYCLING_LOADING_FEE_LIST: {
      
      return {
        ...state,
        recyclingLoadingFeeList:[],
        isRecyclingLoadingFeeLoadDone: false,
      };
    }

    case actionTypes.LOAD_RECYCLING_LOADING_FEE_LIST_SUCCESS: {
  
      return {
        ...state,
        recyclingLoadingFeeList: action.data,
        isRecyclingLoadingFeeLoadDone: true,
      };
    }

    case actionTypes.LOAD_RECYCLING_LOADING_FEE_LIST_FAIL: {
      return {
        ...state,
        isRecyclingLoadingFeeLoadDone: true,
      };
    }
    

    case actionTypes.SET_RECYCLING_LOADING_FEE: {
      return {
        ...state,
        isRecyclingLoadingFeeSetSuccess: false
      };
    }

    case actionTypes.SET_RECYCLING_LOADING_FEE_SUCCESS: {
      return {
        ...state,
        isRecyclingLoadingFeeSetSuccess: true
      };
    }

    case actionTypes.SET_RECYCLING_LOADING_FEE_FAIL: {
      return {
        ...state,
        isRecyclingLoadingFeeSetSuccess: false
      };
    }    
    /** END RECYCLING EXTRA FEE **/


    
    case actionTypes.GET_RECYCLING_PRICE_PDF: {
      
      return {
        ...state,
        recyclingPricePDF: ""
      };
    }

    case actionTypes.GET_RECYCLING_PRICE_PDF_SUCCESS: {
      
      return {
        ...state,
        recyclingPricePDF: action.data
      };
    }

    case actionTypes.GET_RECYCLING_PRICE_PDF_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.GET_RECYCLING_PRICE_POST_PDF: {
      
      return {
        ...state,
        recyclingPricePostPDF: ""
      };
    }

    case actionTypes.GET_RECYCLING_PRICE_POST_PDF_SUCCESS: {
      
      return {
        ...state,
        recyclingPricePostPDF: action.data
      };
    }

    case actionTypes.GET_RECYCLING_PRICE_POST_PDF_FAIL: {
      return {
        ...state
      };
    }
    
    
    case actionTypes.LOAD_RECYCLING_SALES_PRICE_LIST: {
      
      return {
        ...state,
        recyclingSalesPriceList: []
      };
    }

    case actionTypes.LOAD_RECYCLING_SALES_PRICE_LIST_SUCCESS: {
      
      return {
        ...state,
        recyclingSalesPriceList: action.data
      };
    }

    case actionTypes.LOAD_RECYCLING_SALES_PRICE_LIST_FAIL: {
      return {
        ...state
      };
    }
    
    case actionTypes.GET_RECYCLING_SALES_PRICE_PDF: {
      
      return {
        ...state,
        recyclingSalesPricePDF: ""
      };
    }

    case actionTypes.GET_RECYCLING_SALES_PRICE_PDF_SUCCESS: {
      
      return {
        ...state,
        recyclingSalesPricePDF: action.data
      };
    }

    case actionTypes.GET_RECYCLING_SALES_PRICE_PDF_FAIL: {
      return {
        ...state
      };
    }


    case actionTypes.RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST: {
      let recyclingSalesPriceUpdateHistoryList = cloneDeep(state.recyclingSalesPriceUpdateHistoryList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        recyclingSalesPriceUpdateHistoryList.offset = action.data.offset;
        recyclingSalesPriceUpdateHistoryList.limit = action.data.limit;
      }

      return {
        ...state,
        recyclingSalesPriceUpdateHistoryList
      };
    }

    case actionTypes.RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST_SUCCESS: {
     
      let recyclingSalesPriceUpdateHistoryList = cloneDeep(state.recyclingSalesPriceUpdateHistoryList);
      recyclingSalesPriceUpdateHistoryList.data = action.data.data;
      recyclingSalesPriceUpdateHistoryList.meta = {
        page: 1 + state.recyclingSalesPriceUpdateHistoryList.offset / state.recyclingSalesPriceUpdateHistoryList.limit,
        pageSize: state.recyclingSalesPriceUpdateHistoryList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.recyclingSalesPriceUpdateHistoryList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        recyclingSalesPriceUpdateHistoryList
      };
    }

    case actionTypes.RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST_FAIL: {
      return {
        ...state
      };
    }
    
    
    case actionTypes.RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT: {
      
      return {
        ...state,
        recyclingSalesPriceUpdateHistoryCount: {}
      };
    }

    case actionTypes.RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT_SUCCESS: {
      
      return {
        ...state,
        recyclingSalesPriceUpdateHistoryCount: action.data
      };
    }

    case actionTypes.RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT_FAIL: {
      return {
        ...state
      };
    }
    
    case actionTypes.RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL: {
      
      return {
        ...state,
        recyclingSalesPriceUpdateHistoryDetail: []
      };
    }

    case actionTypes.RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL_SUCCESS: {
      
      return {
        ...state,
        recyclingSalesPriceUpdateHistoryDetail: action.data
      };
    }

    case actionTypes.RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL_FAIL: {
      return {
        ...state
      };
    }

    default:
      return state;
  }
}
