import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";


export function* loadWhatsappNotificationList() {
  
  yield takeEvery(actionTypes.LOAD_WHATSAPP_NOTIFICATION_LIST, function*(action) {
    yield put(actions.enableLoading());

    try {
      const response = yield API.loadWhatsappNotificationList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadWhatsappNotificationListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadWhatsappNotificationListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadWhatsappNotificationListFail());
    }
  });
}



export function* loadWhatsappNotificationDetail() {
  
  yield takeEvery(actionTypes.LOAD_WHATSAPP_NOTIFICATION_DETAIL, function*(action) {

    yield put(actions.enableLoading());
    try {
      const response = yield API.loadWhatsappNotificationDetail(action.id);
      
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadWhatsappNotificationDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadWhatsappNotificationDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadWhatsappNotificationDetailFail());
    }
  });
}


export function* createWhatsappNotification() {
  yield takeEvery(actionTypes.CREATE_WHATSAPP_NOTIFICATION, function*(action) {
    yield put(actions.enableLoading());

    try {
      const response = yield API.createWhatsappNotification(action.data);
      if (response.data.success) {
        yield put(actions.createWhatsappNotificationSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.createWhatsappNotificationFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.createWhatsappNotificationFail());
    }
  });
}

export function* editWhatsappNotification() {
  yield takeEvery(actionTypes.EDIT_WHATSAPP_NOTIFICATION, function*(action) {
    yield put(actions.enableLoading());

    try {
      const response = yield API.editWhatsappNotification(action.id, action.data);
      if (response.data.success) {
        yield put(actions.editWhatsappNotificationSuccess());
        yield put(actions.toastSuccess(response.data.message));
        
      } else {
        alert(response.data.message);
        yield put(actions.editWhatsappNotificationFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editWhatsappNotificationFail());
    }
  });
}


export function* deleteWhatsappNotification() {
  yield takeEvery(actionTypes.DELETE_WHATSAPP_NOTIFICATION, function*(action) {
    yield put(actions.enableLoading());

    try {
      const response = yield API.deleteWhatsappNotification(action.id);
      if (response.data.success) {
        yield put(actions.deleteWhatsappNotificationSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.deleteWhatsappNotificationFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.deleteWhatsappNotificationFail());
    }
  });
}


export function* sendWhatsappForGrading() {
  yield takeEvery(actionTypes.SEND_WHATSAPP_FOR_GRADING, function*(action) {
    yield put(actions.enableLoading());

    try {
      const response = yield API.sendWhatsappForGrading(action.id);
      if (response.data.success) {
        yield put(actions.sendWhatsappForGradingSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.sendWhatsappForGradingFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.sendWhatsappForGradingFail());
    }
  });
}




export default function* whatsappSaga() {
  
  yield all([
    fork(loadWhatsappNotificationList),
    fork(createWhatsappNotification),
    fork(editWhatsappNotification),
    fork(deleteWhatsappNotification),
    fork(loadWhatsappNotificationDetail),
    fork(sendWhatsappForGrading)
    
  ]);
  
}
