import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  lorryList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  isDeleteLorrySuccess: false,
  isAddLorrySuccess: false,
  isUpdateLorrySuccess: false,
  driverLorryList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  driverLorryDetail: {},
  isProcessLoadDriverLorryDetail: false,
  isDriverLorryApproveSuccess: false,
  isDriverLorryRejectSuccess: false,
  isDriverLorryCreateSuccess: false,
  isDriverLorryRemoveSuccess: false,
  driverLorryCount: {},
  driverLorrySummary: {}
};

export default function lorryReducer(state = initState, action) {
  switch (action.type) {
    case actionTypes.LOAD_LORRY_LIST: {
      let lorryList = cloneDeep(state.lorryList);
      if (
        action.data && 
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        lorryList.offset = action.data.offset;
        lorryList.limit = action.data.limit;
      }

      return {
        ...state,
        lorryList
      };
    }

    case actionTypes.LOAD_LORRY_LIST_SUCCESS: {
      let lorryList = cloneDeep(state.lorryList);
      lorryList.data = action.data.data;
      lorryList.meta = {
        page: 1 + state.lorryList.offset / state.lorryList.limit,
        pageSize: state.lorryList.limit,
        pageTotal: Math.ceil(action.data.recordsTotal / state.lorryList.limit),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        lorryList
      };
    }

    case actionTypes.LOAD_LORRY_LIST_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.ADD_LORRY: {
      return {
        ...state,
        isAddLorrySuccess: false
      };
    }

    case actionTypes.ADD_LORRY_SUCCESS: {
      return {
        ...state,
        isAddLorrySuccess: true
      };
    }

    case actionTypes.ADD_LORRY_FAIL: {
      return {
        ...state,
        isAddLorrySuccess: false
      };
    }

    case actionTypes.EDIT_LORRY: {
      return {
        ...state,
        isUpdateLorrySuccess: false
      };
    }

    case actionTypes.EDIT_LORRY_SUCCESS: {
      return {
        ...state,
        isUpdateLorrySuccess: true
      };
    }

    case actionTypes.EDIT_LORRY_FAIL: {
      return {
        ...state,
        isUpdateLorrySuccess: false
      };
    }
    
    case actionTypes.DELETE_LORRY: {
      return {
        ...state,
        isDeleteLorrySuccess: false
      };
    }

    case actionTypes.DELETE_LORRY_SUCCESS: {
      return {
        ...state,
        isDeleteLorrySuccess: true
      };
    }

    case actionTypes.DELETE_LORRY_FAIL: {
      return {
        ...state,
        isDeleteLorrySuccess: false
      };
    }
    

    case actionTypes.DRIVER_LORRY_COUNT: {
    
      return {
        ...state,
        driverLorryCount: {}
      };
    }

    case actionTypes.DRIVER_LORRY_COUNT_SUCCESS: {
     
      return {
        ...state,
        driverLorryCount: action.data
      };
    }

    case actionTypes.DRIVER_LORRY_COUNT_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.DRIVER_LORRY_LIST: {
      let driverLorryList = cloneDeep(state.driverLorryList);
      if (
        action.data && 
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        driverLorryList.offset = action.data.offset;
        driverLorryList.limit = action.data.limit;
      }

      return {
        ...state,
        driverLorryList
      };
    }

    case actionTypes.DRIVER_LORRY_LIST_SUCCESS: {
  
      let driverLorryList = cloneDeep(state.driverLorryList);
      driverLorryList.data = action.data.data;
      driverLorryList.meta = {
        page: 1 + state.driverLorryList.offset / state.driverLorryList.limit,
        pageSize: state.driverLorryList.limit,
        pageTotal: Math.ceil(action.data.recordsTotal / state.driverLorryList.limit),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        driverLorryList
      };
    }

    case actionTypes.DRIVER_LORRY_LIST_FAIL: {
      return {
        ...state
      };
    }
    
    case actionTypes.DRIVER_LORRY_DETAIL: {
      return {
        ...state,
        driverLorryDetail: {},
        isProcessLoadDriverLorryDetail: true
      };
    }

    case actionTypes.DRIVER_LORRY_DETAIL_SUCCESS: {
      return {
        ...state,
        driverLorryDetail: action.data,
        isProcessLoadDriverLorryDetail: false
      };
    }

    case actionTypes.DRIVER_LORRY_DETAIL_FAIL: {
      return {
        ...state,
        isProcessLoadDriverLorryDetail: false
      };
    }
    
    case actionTypes.DRIVER_LORRY_APPROVE: {
      return {
        ...state,
        isDriverLorryApproveSuccess: false
      };
    }

    case actionTypes.DRIVER_LORRY_APPROVE_SUCCESS: {
      return {
        ...state,
        isDriverLorryApproveSuccess: true
      };
    }

    case actionTypes.DRIVER_LORRY_APPROVE_FAIL: {
      return {
        ...state,
        isDriverLorryApproveSuccess: false
      };
    }
    
    case actionTypes.DRIVER_LORRY_REJECT: {
      return {
        ...state,
        isDriverLorryRejectSuccess: false
      };
    }

    case actionTypes.DRIVER_LORRY_REJECT_SUCCESS: {
      return {
        ...state,
        isDriverLorryRejectSuccess: true
      };
    }

    case actionTypes.DRIVER_LORRY_REJECT_FAIL: {
      return {
        ...state,
        isDriverLorryRejectSuccess: false
      };
    }
    
    case actionTypes.DRIVER_LORRY_CREATE: {
      return {
        ...state,
        isDriverLorryCreateSuccess: false
      };
    }

    case actionTypes.DRIVER_LORRY_CREATE_SUCCESS: {
      return {
        ...state,
        isDriverLorryCreateSuccess: true
      };
    }

    case actionTypes.DRIVER_LORRY_CREATE_FAIL: {
      return {
        ...state,
        isDriverLorryCreateSuccess: false
      };
    }
    
    case actionTypes.DRIVER_LORRY_REMOVE: {
      return {
        ...state,
        isDriverLorryRemoveSuccess: false
      };
    }

    case actionTypes.DRIVER_LORRY_REMOVE_SUCCESS: {
      return {
        ...state,
        isDriverLorryRemoveSuccess: true
      };
    }

    case actionTypes.DRIVER_LORRY_REMOVE_FAIL: {
      return {
        ...state,
        isDriverLorryRemoveSuccess: false
      };
    }
    
    
    /* ===== DRIVER LORRY ===== */
    case actionTypes.LOAD_DRIVER_LORRY_SUMMARY: {
      
      return {
        ...state,
        driverLorrySummary: {}
      };
    }

    case actionTypes.LOAD_DRIVER_LORRY_SUMMARY_SUCCESS: {
      
      return {
        ...state,
        driverLorrySummary: action.data
      };
    }

    case actionTypes.LOAD_DRIVER_LORRY_SUMMARY_FAIL: {
      return {
        ...state
      };
    }
    /* ===== DRIVER LORRY END ===== */
    
    
    default:
      return state;
  }
}
