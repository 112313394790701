import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/general/admin/dumpster/price";
};


export const loadDumpsterPriceEffectiveDateList = data => {
  
  const url = `${baseURL()}/effectiveDate`;
  return axios.post(url, data);
};

export const loadDumpsterPriceList = data => {
  
  const url = `${baseURL()}/load`;
  return axios.post(url, data);
};

export const getDumpsterPricePDF = data => {
  const url = `${baseURL()}/pdf `;
  return axios.post(url, data);
};

export const setDumpsterPrice = data => {

  var url = `${baseURL()}/set`;

  return axios.post(url, data);
};

export const addDumpsterPrice = data => {

  const url = `${baseURL()}/set`;
  return axios.post(url, data);
};

export const editDumpsterPrice = (id, data) => {
  const url = `${baseURL()}/set/${id}`;
  return axios.post(url, data);
};



export const loadDumpsterPriceRecyclingEffectiveDateList = data => {
  
  const url = `${baseURL()}/recycling/effectiveDate`;
  return axios.post(url, data);
};

export const loadDumpsterPriceRecyclingList = data => {
  
  const url = `${baseURL()}/recycling/load`;
  return axios.post(url, data);
};


export const getDumpsterPriceRecyclingPDF = data => {
  const url = `${baseURL()}/recycling/pdf `;
  return axios.post(url, data);
};

export const setDumpsterPriceRecycling = data => {

  var url = `${baseURL()}/recycling/set`;

  return axios.post(url, data);
};