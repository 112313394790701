import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
import Helper from "../../helpers/helpers";

export function* loadExpressRecyclingList() {
  
  yield takeEvery(actionTypes.LOAD_EXPRESS_RECYCLING_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EXPRESSRECYCLINGLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadExpressRecyclingList(action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.loadExpressRecyclingListSuccess(response.data.data));

        if(action.data.priceList && action.data.priceList === true)                 
          yield put(actions.countRecyclingNewPrice());
          
      } else {
        alert(response.data.message);
        yield put(actions.loadExpressRecyclingListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadExpressRecyclingListFail());
    }
  });
}

export function* loadExpressRecyclingListAll() {
  
  yield takeEvery(actionTypes.LOAD_EXPRESS_RECYCLING_LIST_ALL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EXPRESSRECYCLINGLISTALL");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadExpressRecyclingList(action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.loadExpressRecyclingListAllSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadExpressRecyclingListAllFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadExpressRecyclingListAllFail());
    }
  });
}

export function* loadExpressRecyclingDetail() {
  
  yield takeEvery(actionTypes.LOAD_EXPRESS_RECYCLING_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EXPRESSRECYCLINGDETAIL");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadExpressRecyclingDetail(action.id, action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.loadExpressRecyclingDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadExpressRecyclingDetailFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadExpressRecyclingDetailFail());
    }
  });
}

export function* loadExpressRecyclingEffectiveDateList() {
  
  yield takeEvery(actionTypes.LOAD_EXPRESS_RECYCLING_EFFECTIVE_DATE_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EXPRESSRECYCLINGEFFECTIVEDATELIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadExpressRecyclingEffectiveDateList(action.data);
      if (response.data.success && response.data.data) {
       
        yield put(actions.loadExpressRecyclingEffectiveDateListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadExpressRecyclingEffectiveDateListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadExpressRecyclingEffectiveDateListFail());
    }
  });
}


export function* loadExpressRecyclingMixList() {
  
  yield takeEvery(actionTypes.LOAD_EXPRESS_RECYCLING_MIX_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EXPRESSRECYCLINGMIXLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadExpressRecyclingList(action.data);
      if (response.data.success && response.data.data) {
   
        yield put(actions.loadExpressRecyclingMixListSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadExpressRecyclingMixListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadExpressRecyclingMixListFail());
    }
  });
}


export function* expressRecyclingPriceControlLoad() {
  yield takeEvery(actionTypes.EXPRESS_RECYCLING_PRICE_CONTROL_LOAD, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EXPRESSRECYCLINGPRICECONTROLLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.expressRecyclingPriceControlLoad(action.data);
      if (response.data.success) {

        yield put(actions.expressRecyclingPriceControlLoadSuccess(response.data.data));
        
      } else {
        alert(response.data.message);
        yield put(actions.expressRecyclingPriceControlLoadFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.expressRecyclingPriceControlLoadFail());
    }
  });
}

export function* expressRecyclingPriceControlSet() {
  yield takeEvery(actionTypes.EXPRESS_RECYCLING_PRICE_CONTROL_SET, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETEXPRESSRECYCLINGPRICE");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.expressRecyclingPriceControlSet(action.data,action.id);
      if (response.data.success) {
        yield put(actions.expressRecyclingPriceControlSetSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.expressRecyclingPriceControlSetFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.expressRecyclingPriceControlSetFail());
    }
  });
}

export function* deleteExpressRecycling() {
  yield takeEvery(actionTypes.DELETE_EXPRESS_RECYCLING, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DELEXPRESSRECYCLING");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.deleteExpressRecycling(action.id, action.data);
      
      if (response.data.success) {
        
        if(response.data.data)
        {
          yield put(actions.deleteExpressRecyclingSuccess(response.data.data));

        }else{
   
          yield put(actions.deleteExpressRecyclingSuccess());
          yield put(actions.toastSuccess(response.data.message));
        }
        
      } else {
        alert(response.data.message);
        yield put(actions.deleteExpressRecyclingFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.deleteExpressRecyclingFail());
    }
  });
}

export function* setExpressRecyclingSequence() {
  yield takeEvery(actionTypes.SET_EXPRESS_RECYCLING_SEQUENCE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETEXPRESSRECYCLINGSEQUENCE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.setExpressRecyclingSequence(action.id, action.data);
      if (response.data.success) {
        yield put(actions.setExpressRecyclingSequenceSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.setExpressRecyclingSequenceFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.setExpressRecyclingSequenceFail());
    }
  });
}



export function* getExpressRecyclingPricePDF() {
  
  yield takeEvery(actionTypes.GET_EXPRESS_RECYCLING_PRICE_PDF, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GETEXPRESSRECYCLINGPRICEPDF");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.getExpressRecyclingPricePDF(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.getExpressRecyclingPricePDFSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getExpressRecyclingPricePDFFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getExpressRecyclingPricePDFFail());
    }
  });
}


export function* getExpressRecyclingPricePostPDF() {
  
  yield takeEvery(actionTypes.GET_EXPRESS_RECYCLING_PRICE_POST_PDF, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GETEXPRESSRECYCLINGPRICEPOSTPDF");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.getExpressRecyclingPricePostPDF(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.getExpressRecyclingPricePostPDFSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getExpressRecyclingPricePostPDFFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getExpressRecyclingPricePostPDFFail());
    }
  });
}

export function* loadExpressRecyclingSalesPriceUpdHistory() {
  
  yield takeEvery(actionTypes.EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST, function*(action) {

    if(!action.data) action.data = {}
    
    let status = (action.data.status) ? action.data.status : ""
    
    let track_no = Helper.generateTrackNo(`EXPRESSRECYCLINGSALESUPDHISLIST${status}`);

    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.loadExpressRecyclingSalesPriceUpdHistory(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.loadExpressRecyclingSalesPriceUpdHistorySuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadExpressRecyclingSalesPriceUpdHistoryFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadExpressRecyclingSalesPriceUpdHistoryFail());
    }
  });
}


export function* countExpressRecyclingSalesPriceUpdHistory() {
  
  yield takeEvery(actionTypes.EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EXPRESSRECYCLINGSALESUPDHISCOUNT");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.countExpressRecyclingSalesPriceUpdHistory(action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.countExpressRecyclingSalesPriceUpdHistorySuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.countExpressRecyclingSalesPriceUpdHistoryFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.countExpressRecyclingSalesPriceUpdHistoryFail());
    }
  });
}


export function* getExpressRecyclingSalesPriceUpdHistoryDetail() {
  
  yield takeEvery(actionTypes.EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EXPRESSRECYCLINGSALESUPDHISDETAIL");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.getExpressRecyclingSalesPriceUpdHistoryDetail(action.id, action.data);
  
      if (response.data.success && response.data.data) {
        
        yield put(actions.getExpressRecyclingSalesPriceUpdHistoryDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getExpressRecyclingSalesPriceUpdHistoryDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getExpressRecyclingSalesPriceUpdHistoryDetailFail());
    }
  });
}

export default function* expressSaga() {
  
  yield all([fork(loadExpressRecyclingList)]);
  yield all([fork(loadExpressRecyclingListAll)]);
  yield all([fork(loadExpressRecyclingDetail)]);  
  yield all([fork(loadExpressRecyclingEffectiveDateList)]);  
  yield all([fork(loadExpressRecyclingMixList)]);
  
  yield all([fork(expressRecyclingPriceControlLoad)]);
  yield all([fork(expressRecyclingPriceControlSet)]);  
  yield all([fork(deleteExpressRecycling)]);
  yield all([fork(setExpressRecyclingSequence)]);  
  yield all([fork(getExpressRecyclingPricePDF)]);
  yield all([fork(getExpressRecyclingPricePostPDF)]);
  
  yield all([fork(loadExpressRecyclingSalesPriceUpdHistory)]);
  yield all([fork(countExpressRecyclingSalesPriceUpdHistory)]);
  yield all([fork(getExpressRecyclingSalesPriceUpdHistoryDetail)]);
}
