import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/admin/user";
};

export const loadUserList = data => {
  const url = `${baseURL()}/list`;
  return axios.post(url, data);
};

export const userCount = data => {
  const url = `${baseURL()}/count`;
  return axios.post(url, data);
};

export const loadUserDetail = (id, data) => {
  const url = `${baseURL()}/detail/${id}`;
  return axios.post(url, data);
};

export const switchUserToOrganizationType = (id, data) => {
  const url = `${baseURL()}/switchOrg/${id}`;
  return axios.post(url, data);
};

export const editUserDetail = (id, data) => {
  const url = `${baseURL()}/update/${id}`;
  return axios.post(url, data);
};

export const removeUser = (id, data) => {
  const url = `${baseURL()}/remove/${id}`;
  return axios.post(url, data);
};

export const recoveryUser = (id, data) => {
  const url = `${baseURL()}/recovery/${id}`;
  return axios.post(url, data);
};



export const editUserStatus = (id, data) => {
  const url = `${baseURL()}/update/${id}/status`;
  return axios.post(url, data);
};

export const editUserLoginShowVeriCodeStatus = (id, data) => {
  const url = `${baseURL()}/update/${id}/veriCode`;
  return axios.post(url, data);
};

export const editUserVerifyStatus = (id, data) => {
  const url = `${baseURL()}/update/${id}/verify`;
  return axios.post(url, data);
};


export const loadUserSummary = data => {
  const url = `${baseURL()}/summary`;
  return axios.post(url,data);
};


export const setUserBillingAddress = (id, data) => {
  const url = `${baseURL()}/billing/set/${id}`;
  return axios.post(url, data);
};


export const loadUserCreditTermRequestList = data => {
  const url = `${baseURL()}/creditTerm/request/list`;
  return axios.post(url, data);
};


export const approveUserCreditTerm = (id, data) => {
  const url = `${baseURL()}/creditTerm/request/approve/${id}`;  
  return axios.post(url, data);
};

export const rejectUserCreditTerm = (id, data) => {
  const url = `${baseURL()}/creditTerm/request/reject/${id}`;
  return axios.post(url, data);
};

export const cancelUserCreditTerm = (id, data) => {
  const url = `${baseURL()}/creditTerm/request/cancel/${id}`;
  return axios.post(url, data);
};

export const setUserNegoPrice = (id, data) => {
  const url = `${baseURL()}/negoPrice/set/${id}`;
  return axios.post(url, data);
};