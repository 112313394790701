import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/general/admin/lorry";
};

export const loadLorryList = data => {
  const url = `${baseURL()}/load`;
  return axios.post(url, data);
};

export const addLorry = data => {
  const url = `${baseURL()}/create`;
  return axios.post(url, data);
};

export const editLorry = (id, data) => {
  const url = `${baseURL()}/update/${id}`;
  return axios.post(url, data);
};

export const deleteLorry = (id, data) => {
  const url = `${baseURL()}/remove/${id}`;
  return axios.post(url, data);
};

export const driverLorryCount = (id = null, data) => {
  var url = `${baseURL()}/driver/count`;
  if(id) url += `/${id}`
  return axios.post(url, data);
};

export const loadDriverLorryList = (id = null,data) => {
  var url = `${baseURL()}/driver/load`;
  if(id) url += `/${id}`
  return axios.post(url, data);
};

export const driverLorryDetail = (id, data) => {
  var url = `${baseURL()}/driver/detail/${id}`;
  return axios.post(url, data);
};

export const approveDriverLorry = (id, data) => {
  const url = `${baseURL()}/driver/approve/${id}`;
  return axios.post(url, data);
};

export const rejectDriverLorry = (id, data) => {
  const url = `${baseURL()}/driver/reject/${id}`;
  return axios.post(url, data);
};

export const createDriverLorry = (id, data) => {
  const url = `${baseURL()}/driver/create/${id}`;
  return axios.post(url, data);
};

export const removeDriverLorry = (id, data) => {
  const url = `${baseURL()}/driver/remove/${id}`;
  return axios.post(url, data);
};


export const loadDriverLorrySummary = data => {
  const url = `${baseURL()}/driver/summary`;
  return axios.post(url,data);
};