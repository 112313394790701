import React from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import { ConnectedRouter } from "react-router-redux";
import { connect } from "react-redux";

import App from "./containers/App/App";
import asyncComponent from "./helpers/AsyncFunc";

const browserHistory = createBrowserHistory();

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/consumer-verification",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

const PublicRoutes = ({ history, isLoggedIn }) => {
  let routes = (
    <Route
      exact
      path={"/"}
      component={asyncComponent(() => import("./containers/Page/SignIn"))}
    />
  );

  if (isLoggedIn) {
    routes = (
      <RestrictedRoute path="/" component={App} isLoggedIn={isLoggedIn} />
    );
  }

  return (
    <Router history={browserHistory}>
      <ConnectedRouter history={history}>
        <div>
          <Switch>
            {routes}
            <Redirect to="/" />
          </Switch>
        </div>
      </ConnectedRouter>
    </Router>
  );
};

const mapStateToProps = state => {
  let isLoggedIn = false;

  if (state.Auth.jwtToken !== null && state.Auth.profileData !== null) {
    isLoggedIn = true;
  }

  return {
    isLoggedIn
  };
};

export default connect(mapStateToProps)(PublicRoutes);
