import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  graderList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  isProcessLoadGrader: false,
  graderDetail:{},
  isProcessLoadGraderDetail: false,
  removeGraderConfirm: null,
  isRecoveryGraderSuccess: false,
  isUpdateGraderStatusSuccess: false,
  isUpdateGraderStatusFail: false,
  isUpdateGraderVerifyStatusSuccess: false,
  isUpdateGraderVerifyStatusFail: false,
  isRejectGraderVerifyStatusSuccess: false,
  isRejectGraderVerifyStatusFail: false,
  isUpdateGraderDetailSuccess: false,
  isUpdateGraderDetailFail: false,
  graderCount:{},
  proceedUpdateOrderLandfill: false,
  activeOrderList: null,
  proceedDeactivateConfirm: false,
  confirmData: null,
  isUploadGraderDocSuccess: false,
  graderDocumentationCount: {},
  graderDocumentList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  isProcessLoadGraderDocument:false,
  isApproveGraderDocumentSuccess:false,
  isRejectGraderDocumentSuccess: false,
  
  graderZoneList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  isProcessLoadGraderZone: false,
  graderZoneCount: {},
  expressPackingHistoryList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  isProcessLoadExpressPackingHistoryList: false,
  expressPackingDetail: {},
  recyclingPackingHistoryList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    },
    exportSuccess: false
  },
  isProcessLoadRecyclingPackingHistoryList: false,
  recyclingPackingDetail: {},
  recyclingPackingPDF: "",
  recyclingPackingListStack: [],
  expressPackingPDF: "",
  expressPackingListStack: [],
};
   
export default function graderReducer(state = initState, action) {

  switch (action.type) {
    case actionTypes.LOAD_GRADER_LIST: {
      let graderList = cloneDeep(state.graderList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        graderList.offset = action.data.offset;
        graderList.limit = action.data.limit;
      }

      return {
        ...state,
        graderList,
        isProcessLoadGrader: true,
      };
    }

    case actionTypes.LOAD_GRADER_LIST_SUCCESS: {
      let graderList = cloneDeep(state.graderList);
      graderList.data = action.data.data;
      graderList.meta = {
        page: 1 + state.graderList.offset / state.graderList.limit,
        pageSize: state.graderList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.graderList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        isProcessLoadGrader: false,
        graderList
      };
    }

    case actionTypes.LOAD_GRADER_LIST_FAIL: {
      return {
        ...state,
        isProcessLoadGrader: false,
      };
    }

    
    case actionTypes.GRADER_COUNT: {
      
      return {
        ...state,
        graderCount: {}
      };
    }

    case actionTypes.GRADER_COUNT_SUCCESS: {
    
      return {
        ...state,
        graderCount:action.data
      };
    }

    case actionTypes.GRADER_COUNT_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.LOAD_GRADER_DETAIL: {
      let graderDetail = cloneDeep(state.graderDetail);
      
      return {
        ...state,
        graderDetail,
        isProcessLoadGraderDetail: true,
      };
    }

    case actionTypes.LOAD_GRADER_DETAIL_SUCCESS: {
      return {
        ...state,
        graderDetail: action.data,
        isProcessLoadGraderDetail: false,
      };
    }

    case actionTypes.LOAD_GRADER_DETAIL_FAIL: {
      return {
        ...state,
        isProcessLoadGraderDetail: false,
      };
    }

    
    
    case actionTypes.REMOVE_GRADER: {
      return {
        ...state,
        removeGraderConfirm: null
      };
    }

    case actionTypes.REMOVE_GRADER_SUCCESS: {
      return {
        ...state,
        removeGraderConfirm: (action.data) ? action.data : null
      };
    }
    

    case actionTypes.RECOVERY_GRADER: {
      return {
        ...state,
        isRecoveryGraderSuccess: false
      };
    }

    case actionTypes.RECOVERY_GRADER_SUCCESS: {
      return {
        ...state,
        isRecoveryGraderSuccess: true
      };
    }

    case actionTypes.RECOVERY_GRADER_FAIL: {
      return {
        ...state,
        isRecoveryGraderSuccess: false
      };
    }


    case actionTypes.EDIT_GRADER_STATUS: {
      return {
        ...state,
        isUpdateGraderStatusSuccess: false,
        isUpdateGraderStatusFail: false,
        proceedDeactivateConfirm: false,
        confirmData: null
      };
    }

    case actionTypes.EDIT_GRADER_STATUS_SUCCESS: {
      return {
        ...state,
        isUpdateGraderStatusSuccess: true,
        proceedUpdateOrderLandfill: false,
        activeOrderList: null,
        proceedDeactivateConfirm: false,
        confirmData: null

      };
    }

    case actionTypes.PROCEED_DEACTIVATE_CONFIRM: {
      return {
        ...state,
        proceedDeactivateConfirm: true,
        confirmData: {grader_id: action.data.grader_id, status: action.data.status, full_name: action.data.full_name}
      };
    }

    case actionTypes.PROCEED_UPDATE_ORDER_LANDFILL: {
      return {
        ...state,
        proceedUpdateOrderLandfill: true,
        activeOrderList: action.data.activeOrderList,

      };
    }

    case actionTypes.DIMISS_UPDATE_ORDER_LANDFILL: {
      return {
        ...state,
        proceedUpdateOrderLandfill: false,
        activeOrderList: null,

      };
    }

    case actionTypes.EDIT_GRADER_STATUS_FAIL: {
      return {
        ...state,
        isUpdateGraderStatusFail: true
      };
    }

    case actionTypes.EDIT_GRADER_VERIFY_STATUS: {
      return {
        ...state,
        isUpdateGraderVerifyStatusSuccess: false,
        isUpdateGraderVerifyStatusFail: false
      };
    }

    case actionTypes.EDIT_GRADER_VERIFY_STATUS_SUCCESS: {
      return {
        ...state,
        isUpdateGraderVerifyStatusSuccess: true
      };
    }

    case actionTypes.EDIT_GRADER_VERIFY_STATUS_FAIL: {
      return {
        ...state,
        isUpdateGraderVerifyStatusFail: true
      };
    }


    case actionTypes.REJECT_GRADER_VERIFY_STATUS: {
      return {
        ...state,
        isRejectGraderVerifyStatusSuccess: false,
        isRejectGraderVerifyStatusFail: false
      };
    }

    case actionTypes.REJECT_GRADER_VERIFY_STATUS_SUCCESS: {
      return {
        ...state,
        isRejectGraderVerifyStatusSuccess: true
      };
    }

    case actionTypes.REJECT_GRADER_VERIFY_STATUS_FAIL: {
      return {
        ...state,
        isRejectGraderVerifyStatusFail: false
      };
    }
    
    case actionTypes.EDIT_GRADER_DETAIL: {
      return {
        ...state,
        isUpdateGraderDetailSuccess: false,
        isUpdateGraderDetailFail: false
      };
    }

    case actionTypes.EDIT_GRADER_DETAIL_SUCCESS: {
      return {
        ...state,
        isUpdateGraderDetailSuccess: true
      };
    }

    case actionTypes.EDIT_GRADER_DETAIL_FAIL: {
      return {
        ...state,
        isUpdateGraderDetailFail: true
      };
    }

    

    case actionTypes.UPLOAD_GRADER_DOC: {
      return {
        ...state,
        isUploadGraderDocSuccess: false
      };
    }

    case actionTypes.UPLOAD_GRADER_DOC_SUCCESS: {
      return {
        ...state,
        isUploadGraderDocSuccess: true
      };
    }

    case actionTypes.UPLOAD_GRADER_DOC_FAIL: {
      return {
        ...state,
        isUploadGraderDocSuccess: false
      };
    }
    

    
  
    case actionTypes.GRADER_DOCUMENTATION_COUNT: {
      
      return {
        ...state,
        graderDocumentationCount: {}
      };
    }

    case actionTypes.GRADER_DOCUMENTATION_COUNT_SUCCESS: {
    
      return {
        ...state,
        graderDocumentationCount:action.data
      };
    }

    case actionTypes.GRADER_DOCUMENTATION_COUNT_FAIL: {
      return {
        ...state
      };
    }


    case actionTypes.GRADER_DOCUMENTATION_LIST: {
      let graderDocumentList = cloneDeep(state.graderDocumentList);

      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        graderDocumentList.offset = action.data.offset;
        graderDocumentList.limit = action.data.limit;
      }

      if (action.data.offset === 0) 
        graderDocumentList.data = [];

      return {
        ...state,
        graderDocumentList,
        isProcessLoadGraderDocument: true
      };
    }

    case actionTypes.GRADER_DOCUMENTATION_LIST_SUCCESS: {
      let graderDocumentList = cloneDeep(state.graderDocumentList);
      graderDocumentList.data = action.data.data;
      graderDocumentList.meta = {
        page: 1 + state.graderDocumentList.offset / state.graderDocumentList.limit,
        pageSize: state.graderDocumentList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.graderDocumentList.limit
        ),
        total: action.data.recordsTotal
      };
      
      return {
        ...state,
        graderDocumentList,
        isProcessLoadGraderDocument:false,
      };
    }

    case actionTypes.GRADER_DOCUMENTATION_LIST_FAIL: {
      return {
        ...state,
        isProcessLoadGraderDocument:false,
      };
    }
    
    case actionTypes.RESET_GRADER_DOCUMENTATION_LIST: {

      return {
        ...state,
        graderDocumentList: {
          data: [],
          offset: 0,
          limit: 10,
          meta: {
            page: 1,
            pageSize: 0,
            pageTotal: 1,
            total: 0,
            pageSizeOptions: [5, 10, 20, 30, 50, 100]
          }
        },
        isProcessLoadGraderDocument:false,
      };
    }
    

    case actionTypes.APPROVE_GRADER_DOCUMENTATION: {
      return {
        ...state,
        isApproveGraderDocumentSuccess: false
      };
    }

    case actionTypes.APPROVE_GRADER_DOCUMENTATION_SUCCESS: {
      return {
        ...state,
        isApproveGraderDocumentSuccess: true
      };
    }

    case actionTypes.APPROVE_GRADER_DOCUMENTATION_FAIL: {
      return {
        ...state,
        isApproveGraderDocumentSuccess: false
      };
    }
    

    case actionTypes.REJECT_GRADER_DOCUMENTATION: {
      return {
        ...state,
        isRejectGraderDocumentSuccess: false
      };
    }

    case actionTypes.REJECT_GRADER_DOCUMENTATION_SUCCESS: {
      return {
        ...state,
        isRejectGraderDocumentSuccess: true
      };
    }

    case actionTypes.REJECT_GRADER_DOCUMENTATION_FAIL: {
      return {
        ...state,
        isRejectGraderDocumentSuccess: false
      };
    }



    /* ===== GRADER ZONE ===== */
    case actionTypes.LOAD_GRADER_ZONE_LIST: {
      let graderZoneList = cloneDeep(state.graderZoneList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        graderZoneList.offset = action.data.offset;
        graderZoneList.limit = action.data.limit;
      }

      return {
        ...state,
        graderZoneList,
        isProcessLoadGraderZone: true,
      };
    }

    case actionTypes.LOAD_GRADER_ZONE_LIST_SUCCESS: {
      let graderZoneList = cloneDeep(state.graderZoneList);
      graderZoneList.data = action.data.data;
      graderZoneList.meta = {
        page: 1 + state.graderZoneList.offset / state.graderZoneList.limit,
        pageSize: state.graderZoneList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.graderZoneList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        isProcessLoadGraderZone: false,
        graderZoneList
      };
    }

    case actionTypes.LOAD_GRADER_ZONE_LIST_FAIL: {
      return {
        ...state,
        isProcessLoadGraderZone: false,
      };
    }

    
    case actionTypes.GRADER_ZONE_COUNT: {
      
      return {
        ...state,
        graderZoneCount: {}
      };
    }

    case actionTypes.GRADER_ZONE_COUNT_SUCCESS: {
    
      return {
        ...state,
        graderZoneCount:action.data
      };
    }

    case actionTypes.GRADER_ZONE_COUNT_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.LOAD_EXPRESS_PACKING_HISTORY_LIST: {
      let expressPackingHistoryList = cloneDeep(state.expressPackingHistoryList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        expressPackingHistoryList.offset = action.data.offset;
        expressPackingHistoryList.limit = action.data.limit;
      }

      return {
        ...state,
        expressPackingHistoryList,
        isProcessLoadExpressPackingHistoryList: true,
      };
    }

    case actionTypes.LOAD_EXPRESS_PACKING_HISTORY_LIST_SUCCESS: {
      let expressPackingHistoryList = cloneDeep(state.expressPackingHistoryList);

      if(!action.data.data.export)
      {
        expressPackingHistoryList.exportSuccess = false
        expressPackingHistoryList.data = action.data.data;
        expressPackingHistoryList.meta = {
          page: 1 + state.expressPackingHistoryList.offset / state.expressPackingHistoryList.limit,
          pageSize: state.expressPackingHistoryList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.expressPackingHistoryList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        expressPackingHistoryList.exportSuccess = true
      }

      return {
        ...state,
        isProcessLoadExpressPackingHistoryList: false,
        expressPackingHistoryList
      };
    }

    case actionTypes.LOAD_EXPRESS_PACKING_HISTORY_LIST_FAIL: {
      return {
        ...state,
        isProcessLoadExpressPackingHistoryList: false,
      };
    }

    
    case actionTypes.LOAD_EXPRESS_PACKING_LIST_DETAIL: {
      
      return {
        ...state,
        expressPackingDetail: {}
      };
    }

    case actionTypes.LOAD_EXPRESS_PACKING_LIST_DETAIL_SUCCESS: {
    
      return {
        ...state,
        expressPackingDetail:action.data
      };
    }

    case actionTypes.LOAD_EXPRESS_PACKING_LIST_DETAIL_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.GET_EXPRESS_PACKING_LIST_PDF: {
      
      return {
        ...state,
        expressPackingPDF: ""
      };
    }

    case actionTypes.GET_EXPRESS_PACKING_LIST_PDF_SUCCESS: {
    
      return {
        ...state,
        expressPackingPDF: action.data
      };
    }

    case actionTypes.GET_EXPRESS_PACKING_LIST_PDF_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.GET_EXPRESS_PACKING_LIST_STACK: {
      
      return {
        ...state,
        expressPackingListStack: []
      };
    }

    case actionTypes.GET_EXPRESS_PACKING_LIST_STACK_SUCCESS: {
    
      return {
        ...state,
        expressPackingListStack: action.data
      };
    }

    case actionTypes.GET_EXPRESS_PACKING_LIST_STACK_FAIL: {
      return {
        ...state
      };
    }
    
    

    case actionTypes.LOAD_RECYCLING_PACKING_HISTORY_LIST: {
      let recyclingPackingHistoryList = cloneDeep(state.recyclingPackingHistoryList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        recyclingPackingHistoryList.offset = action.data.offset;
        recyclingPackingHistoryList.limit = action.data.limit;
      }

      return {
        ...state,
        recyclingPackingHistoryList,
        isProcessLoadRecyclingPackingHistoryList: true,
      };
    }

    case actionTypes.LOAD_RECYCLING_PACKING_HISTORY_LIST_SUCCESS: {
      let recyclingPackingHistoryList = cloneDeep(state.recyclingPackingHistoryList);
      
      if(!action.data.data.export)
      {
        recyclingPackingHistoryList.exportSuccess = false
        recyclingPackingHistoryList.data = action.data.data;
        recyclingPackingHistoryList.meta = {
          page: 1 + state.recyclingPackingHistoryList.offset / state.recyclingPackingHistoryList.limit,
          pageSize: state.recyclingPackingHistoryList.limit,
          pageTotal: Math.ceil(
            action.data.recordsTotal / state.recyclingPackingHistoryList.limit
          ),
          total: action.data.recordsTotal
        };
      }else{
        recyclingPackingHistoryList.exportSuccess = true
      }


      return {
        ...state,
        isProcessLoadRecyclingPackingHistoryList: false,
        recyclingPackingHistoryList
      };
    }

    case actionTypes.LOAD_RECYCLING_PACKING_HISTORY_LIST_FAIL: {
      return {
        ...state,
        isProcessLoadRecyclingPackingHistoryList: false,
      };
    }

    
    case actionTypes.LOAD_RECYCLING_PACKING_LIST_DETAIL: {
      
      return {
        ...state,
        recyclingPackingDetail: {}
      };
    }

    case actionTypes.LOAD_RECYCLING_PACKING_LIST_DETAIL_SUCCESS: {
      
      return {
        ...state,
        recyclingPackingDetail:action.data
      };
    }

    case actionTypes.LOAD_RECYCLING_PACKING_LIST_DETAIL_FAIL: {
      return {
        ...state
      };
    }
    
    
    case actionTypes.GET_RECYCLING_PACKING_LIST_PDF: {
      
      return {
        ...state,
        recyclingPackingPDF: ""
      };
    }

    case actionTypes.GET_RECYCLING_PACKING_LIST_PDF_SUCCESS: {
    
      return {
        ...state,
        recyclingPackingPDF: action.data
      };
    }

    case actionTypes.GET_RECYCLING_PACKING_LIST_PDF_FAIL: {
      return {
        ...state
      };
    }

    
    case actionTypes.GET_RECYCLING_PACKING_LIST_STACK: {
      
      return {
        ...state,
        recyclingPackingListStack: []
      };
    }

    case actionTypes.GET_RECYCLING_PACKING_LIST_STACK_SUCCESS: {
    
      return {
        ...state,
        recyclingPackingListStack: action.data
      };
    }

    case actionTypes.GET_RECYCLING_PACKING_LIST_STACK_FAIL: {
      return {
        ...state
      };
    }
    /* ===== GRADER ZONE ===== */
    

    default:
      return state;
  }
}
