import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/admin/grader";
};

export const zoneURL = () => {
  return APIHost() + "/admin/report/grader";
};

export const loadGraderList = data => {
  const url = `${baseURL()}/list`;
  return axios.post(url, data);
};

export const graderCount = (data) => {
  const url = `${baseURL()}/count`;
  return axios.post(url, data);
};

export const loadGraderDetail = (id, data) => {
  const url = `${baseURL()}/detail/${id}`;
  return axios.post(url, data);
};

export const editGraderDetail = (id, data) => {
  const url = `${baseURL()}/update/${id}`;
  return axios.post(url, data);
};

export const removeGrader = (id, data) => {
  const url = `${baseURL()}/remove/${id}`;
  return axios.post(url, data);
};

export const recoveryGrader = (id, data) => {
  const url = `${baseURL()}/recovery/${id}`;
  return axios.post(url, data);
};

export const editGraderStatus = (id, data) => {
  const url = `${baseURL()}/update/${id}/status`;
  return axios.post(url, data);
};

export const editGraderVerifyStatus = (id, data) => {
  const url = `${baseURL()}/update/${id}/verify`;
  return axios.post(url, data);
};

export const rejectGraderVerifyStatus = (id, data) => {
  const url = `${baseURL()}/update/${id}/reject`;
  return axios.post(url, data);
};


/* ===== GRADER DOCUMENTATION ===== */
export const uploadGraderDoc = (id, data) => {
  const url = `${baseURL()}/uploadDoc/${id}`;
  return axios.post(url, data);
};

export const loadGraderDocumentationCount = (data) => {
  const url = `${baseURL()}/documentation/count`;
  return axios.post(url, data);
};

export const loadGraderDocumentationList = (data) => {
  const url = `${baseURL()}/documentation/list`;
  return axios.post(url, data);
};

export const approveGraderDocumentation = (data) => {
  const url = `${baseURL()}/documentation/approve`;
  return axios.post(url, data);
};

export const rejectGraderDocumentation = (data) => {
  const url = `${baseURL()}/documentation/reject`;
  return axios.post(url, data);
};

/* ===== GRADER DOCUMENTATION ===== */



/* ===== GRADER ZONE ===== */
export const loadGraderZoneList = data => {
  const url = `${zoneURL()}/list`;
  return axios.post(url, data);
};

export const graderZoneCount = (data) => {
  const url = `${zoneURL()}/count`;
  return axios.post(url, data);
};


export const expressPackingHistoryList = data => {
  const url = `${zoneURL()}/expressPackingList/load`;
  return axios.post(url, data);
};

export const expressPackingListDetail = (id, data) => {
  const url = `${zoneURL()}/expressPackingList/detail/${id}`;
  return axios.post(url, data);
};

export const getExpressPackingListPDF = (id, data) => {
  const url = `${zoneURL()}/expressPackingList/pdf/${id}`;
  return axios.post(url, data);
};

export const getExpressPackingListStack = (data) => {
  const url = `${zoneURL()}/expressPackingList/stack`;
  return axios.post(url, data);
};


export const recyclingPackingHistoryList = data => {
  const url = `${zoneURL()}/recyclingPackingList/load`;
  return axios.post(url, data);
};

export const recyclingPackingListDetail = (id, data) => {
  const url = `${zoneURL()}/recyclingPackingList/detail/${id}`;
  return axios.post(url, data);
};

export const getRecyclingPackingListPDF = (id, data) => {
  const url = `${zoneURL()}/recyclingPackingList/pdf/${id}`;
  return axios.post(url, data);
};

export const getRecyclingPackingListStack = (data) => {
  const url = `${zoneURL()}/recyclingPackingList/stack`;
  return axios.post(url, data);
};

/* ===== GRADER ZONE ===== */