import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/admin";
};

export const login = data => {
  const url = `${baseURL()}/login`;
  return axios.post(url, data);
};

export const refreshJWTToken = (data) => {
  const url = `${baseURL()}/token`;
  return axios.post(url, data);
};

export const logout = data => {
  const url = `${baseURL()}/logout`;
  return axios.post(url, data);
};

export const getMyProfile = data => {
  const url = `${baseURL()}/detail`;
  return axios.post(url, data);
};

export const changePassword = data => {
  const url = `${baseURL()}/changePass`;
  return axios.post(url, data);
};

export const checkVersion = data => {
  const url = `${baseURL()}/version`;
  return axios.post(url, data);
};

export const versionUpdateList = data => {
  const url = `${baseURL()}/version/list`;
  return axios.post(url, data);
};
