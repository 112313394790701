import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  dumpsterList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  dumpsterDetail:{},
  dumpsterExtraFee:{},
  dumpsterMeasurement: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  dumpsterService:[],
  isAddDumpsterSuccess: false,
  isAddDumpsterFail: false,
  isEditDumpsterSuccess: false,
  isEditDumpsterFail: false,
  isUpdateWasteProcessCostSuccess: false,
  isUpdateRecyclingProcessCostSuccess: false,
  isDeleteDumpsterSuccess: false,
  isAddDumpsterMeasurementSuccess: false,
  isEditDumpsterMeasurementSuccess: false,
  isDeleteDumpsterMeasurementSuccess: false,
  driverDumpsterList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  driverDumpsterCount:{},
  driverDumpsterSummary:{},
  recyclingDumpsterList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  recyclingDumpsterDetail:{},
  isAddRecyclingDumpsterSuccess: false,
  isEditRecyclingDumpsterSuccess: false,
  isDeleteRecyclingDumpsterSuccess: false,
  isDumpsterServiceUpdateFail: false,
  isDumpsterServiceUpdateSuccess: false,
};

export default function dumpsterReducer(state = initState, action) {

  switch (action.type) {

    case actionTypes.LOAD_DUMPSTER_MEASUREMENT_LIST: {
      let dumpsterMeasurement = cloneDeep(state.dumpsterMeasurement);
      if (
        action.data && 
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        dumpsterMeasurement.offset = action.data.offset;
        dumpsterMeasurement.limit = action.data.limit;
      }

      return {
        ...state,
        dumpsterMeasurement
      };
    }

    case actionTypes.LOAD_DUMPSTER_MEASUREMENT_LIST_SUCCESS: {
      let dumpsterMeasurement = cloneDeep(state.dumpsterMeasurement);
      dumpsterMeasurement.data = action.data.data;
      dumpsterMeasurement.meta = {
        page: 1 + state.dumpsterMeasurement.offset / state.dumpsterMeasurement.limit,
        pageSize: state.dumpsterMeasurement.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.dumpsterMeasurement.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        dumpsterMeasurement
      };
    }

    case actionTypes.LOAD_DUMPSTER_MEASUREMENT_LIST_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.ADD_DUMPSTER_MEASUREMENT: {
      return {
        ...state,
        isAddDumpsterMeasurementSuccess: false
      };
    }

    case actionTypes.ADD_DUMPSTER_MEASUREMENT_SUCCESS: {
      return {
        ...state,
        isAddDumpsterMeasurementSuccess: true
      };
    }

    case actionTypes.ADD_DUMPSTER_MEASUREMENT_FAIL: {
      return {
        ...state,
        isAddDumpsterMeasurementSuccess: false
      };
    }

    case actionTypes.EDIT_DUMPSTER_MEASUREMENT: {
      return {
        ...state,
        isEditDumpsterMeasurementSuccess: false
      };
    }

    case actionTypes.EDIT_DUMPSTER_MEASUREMENT_SUCCESS: {
      return {
        ...state,
        isEditDumpsterMeasurementSuccess: true
      };
    }

    case actionTypes.EDIT_DUMPSTER_MEASUREMENT_FAIL: {
      return {
        ...state,
        isEditDumpsterMeasurementSuccess: false
      };
    }

    case actionTypes.DELETE_DUMPSTER_MEASUREMENT: {
      return {
        ...state,
        isDeleteDumpsterMeasurementSuccess: false
      };
    }

    case actionTypes.DELETE_DUMPSTER_MEASUREMENT_SUCCESS: {
      return {
        ...state,
        isDeleteDumpsterMeasurementSuccess: true
      };
    }

    case actionTypes.DELETE_DUMPSTER_MEASUREMENT_FAIL: {
      return {
        ...state,
        isDeleteDumpsterMeasurementSuccess: false
      };
    }



    
    case actionTypes.LOAD_DUMPSTER_SERVICE_LIST: {
      
      return {
        ...state,
        dumpsterService: cloneDeep(state.dumpsterService)
      };
    }

    case actionTypes.LOAD_DUMPSTER_SERVICE_LIST_SUCCESS: {
      return {
        ...state,
        dumpsterService: action.data
      };
    }

    case actionTypes.LOAD_DUMPSTER_SERVICE_LIST_FAIL: {
      return {
        ...state
      };
    }
    
    case actionTypes.UPDATE_DUMPSTER_SERVICE: {
      
      return {
        ...state,
        isDumpsterServiceUpdateSuccess: false,
        isDumpsterServiceUpdateFail: false
      };
    }

    case actionTypes.UPDATE_DUMPSTER_SERVICE_SUCCESS: {
      return {
        ...state,
        isDumpsterServiceUpdateSuccess: true,
      };
    }

    case actionTypes.UPDATE_DUMPSTER_SERVICE_FAIL: {
      return {
        ...state,
        isDumpsterServiceUpdateFail: true
      };
    }


    
    case actionTypes.LOAD_DUMPSTER_LIST: {
      let dumpsterList = cloneDeep(state.dumpsterList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        dumpsterList.offset = action.data.offset;
        dumpsterList.limit = action.data.limit;
      }

      return {
        ...state,
        dumpsterList
      };
    }

    case actionTypes.LOAD_DUMPSTER_LIST_SUCCESS: {
      let dumpsterList = cloneDeep(state.dumpsterList);
      dumpsterList.data = action.data.data;
      dumpsterList.meta = {
        page: 1 + state.dumpsterList.offset / state.dumpsterList.limit,
        pageSize: state.dumpsterList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.dumpsterList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        dumpsterList
      };
    }

    case actionTypes.LOAD_DUMPSTER_LIST_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.LOAD_DUMPSTER_DETAIL: {
      
      return {
        ...state,
        dumpsterDetail:{}
      };
    }

    case actionTypes.LOAD_DUMPSTER_DETAIL_SUCCESS: {
      return {
        ...state,
        dumpsterDetail: action.data
      };
    }

    case actionTypes.LOAD_DUMPSTER_DETAIL_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.LOAD_DUMPSTER_EXTRA_FEE: {
      
      return {
        ...state,
        dumpsterExtraFee:{}
      };
    }

    case actionTypes.LOAD_DUMPSTER_EXTRA_FEE_SUCCESS: {
      return {
        ...state,
        dumpsterExtraFee: action.data
      };
    }

    case actionTypes.LOAD_DUMPSTER_EXTRA_FEE_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.ADD_DUMPSTER: {
      return {
        ...state,
        isAddDumpsterSuccess: false,
        isAddDumpsterFail: false
      };
    }

    case actionTypes.ADD_DUMPSTER_SUCCESS: {
      return {
        ...state,
        isAddDumpsterSuccess: true
      };
    }

    case actionTypes.ADD_DUMPSTER_FAIL: {
      return {
        ...state,
        isAddDumpsterFail: true
      };
    }

    case actionTypes.EDIT_DUMPSTER: {
      return {
        ...state,
        isEditDumpsterSuccess: false,
        isEditDumpsterFail: false
      };
    }

    case actionTypes.EDIT_DUMPSTER_SUCCESS: {
      return {
        ...state,
        isEditDumpsterSuccess: true
      };
    }

    case actionTypes.EDIT_DUMPSTER_FAIL: {
      return {
        ...state,
        isEditDumpsterFail: true
      };
    }

    case actionTypes.UPDATE_DUMPSTER_WASTE_PROCESS_COST: {
      return {
        ...state,
        isUpdateWasteProcessCostSuccess: false
      };
    }

    case actionTypes.UPDATE_DUMPSTER_WASTE_PROCESS_COST_SUCCESS: {
      return {
        ...state,
        isUpdateWasteProcessCostSuccess: true
      };
    }

    case actionTypes.UPDATE_DUMPSTER_WASTE_PROCESS_COST_FAIL: {
      return {
        ...state,
        isUpdateWasteProcessCostSuccess: false
      };
    }

    case actionTypes.UPDATE_DUMPSTER_RECYCLING_PROCESS_COST: {
      return {
        ...state,
        isUpdateRecyclingProcessCostSuccess: false
      };
    }

    case actionTypes.UPDATE_DUMPSTER_RECYCLING_PROCESS_COST_SUCCESS: {
      return {
        ...state,
        isUpdateRecyclingProcessCostSuccess: true
      };
    }

    case actionTypes.UPDATE_DUMPSTER_RECYCLING_PROCESS_COST_FAIL: {
      return {
        ...state,
        isUpdateRecyclingProcessCostSuccess: false
      };
    }
    

    case actionTypes.DELETE_DUMPSTER: {
      return {
        ...state,
        isDeleteDumpsterSuccess: false
      };
    }

    case actionTypes.DELETE_DUMPSTER_SUCCESS: {
      return {
        ...state,
        isDeleteDumpsterSuccess: true
      };
    }

    case actionTypes.DELETE_DUMPSTER_FAIL: {
      return {
        ...state,
        isDeleteDumpsterSuccess: false
      };
    }



    
    

    case actionTypes.DRIVER_DUMPSTER_COUNT: {
    
      return {
        ...state,
        driverDumpsterCount: {}
      };
    }

    case actionTypes.DRIVER_DUMPSTER_COUNT_SUCCESS: {
     
      return {
        ...state,
        driverDumpsterCount: action.data
      };
    }

    case actionTypes.DRIVER_DUMPSTER_COUNT_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.DRIVER_DUMPSTER_LIST: {
      let driverDumpsterList = cloneDeep(state.driverDumpsterList);
      if (
        action.data && 
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        driverDumpsterList.offset = action.data.offset;
        driverDumpsterList.limit = action.data.limit;
      }

      return {
        ...state,
        driverDumpsterList
      };
    }

    case actionTypes.DRIVER_DUMPSTER_LIST_SUCCESS: {
  
      let driverDumpsterList = cloneDeep(state.driverDumpsterList);
      driverDumpsterList.data = action.data.data;
      driverDumpsterList.meta = {
        page: 1 + state.driverDumpsterList.offset / state.driverDumpsterList.limit,
        pageSize: state.driverDumpsterList.limit,
        pageTotal: Math.ceil(action.data.recordsTotal / state.driverDumpsterList.limit),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        driverDumpsterList
      };
    }

    case actionTypes.DRIVER_DUMPSTER_LIST_FAIL: {
      return {
        ...state
      };
    }


    /* ===== DRIVER DUMPSTER ===== */
    case actionTypes.LOAD_DRIVER_DUMPSTER_SUMMARY: {
      
      return {
        ...state,
        driverDumpsterSummary: {}
      };
    }

    case actionTypes.LOAD_DRIVER_DUMPSTER_SUMMARY_SUCCESS: {
      
      return {
        ...state,
        driverDumpsterSummary: action.data
      };
    }

    case actionTypes.LOAD_DRIVER_DUMPSTER_SUMMARY_FAIL: {
      return {
        ...state
      };
    }
    /* ===== DRIVER DUMPSTER END ===== */


    
    /* ===== RECYCLING DUMPSTER ===== */
    case actionTypes.LOAD_RECYCLING_DUMPSTER_LIST: {
      let recyclingDumpsterList = cloneDeep(state.recyclingDumpsterList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        recyclingDumpsterList.offset = action.data.offset;
        recyclingDumpsterList.limit = action.data.limit;
      }

      return {
        ...state,
        recyclingDumpsterList
      };
    }

    case actionTypes.LOAD_RECYCLING_DUMPSTER_LIST_SUCCESS: {
      let recyclingDumpsterList = cloneDeep(state.recyclingDumpsterList);
      recyclingDumpsterList.data = action.data.data;
      recyclingDumpsterList.meta = {
        page: 1 + state.recyclingDumpsterList.offset / state.recyclingDumpsterList.limit,
        pageSize: state.recyclingDumpsterList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.recyclingDumpsterList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        recyclingDumpsterList
      };
    }

    case actionTypes.LOAD_RECYCLING_DUMPSTER_LIST_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.LOAD_RECYCLING_DUMPSTER_DETAIL: {
      
      return {
        ...state,
        recyclingDumpsterDetail:{}
      };
    }

    case actionTypes.LOAD_RECYCLING_DUMPSTER_DETAIL_SUCCESS: {
      return {
        ...state,
        recyclingDumpsterDetail: action.data
      };
    }

    case actionTypes.LOAD_RECYCLING_DUMPSTER_DETAIL_FAIL: {
      return {
        ...state
      };
    }


    case actionTypes.ADD_RECYCLING_DUMPSTER: {
      return {
        ...state,
        isAddRecyclingDumpsterSuccess: false
      };
    }

    case actionTypes.ADD_RECYCLING_DUMPSTER_SUCCESS: {
      return {
        ...state,
        isAddRecyclingDumpsterSuccess: true
      };
    }

    case actionTypes.ADD_RECYCLING_DUMPSTER_FAIL: {
      return {
        ...state,
        isAddRecyclingDumpsterSuccess: false
      };
    }

    case actionTypes.EDIT_RECYCLING_DUMPSTER: {
      return {
        ...state,
        isEditRecyclingDumpsterSuccess: false
      };
    }

    case actionTypes.EDIT_RECYCLING_DUMPSTER_SUCCESS: {
      return {
        ...state,
        isEditRecyclingDumpsterSuccess: true
      };
    }

    case actionTypes.EDIT_RECYCLING_DUMPSTER_FAIL: {
      return {
        ...state,
        isEditRecyclingDumpsterSuccess: false
      };
    }
    

    case actionTypes.DELETE_RECYCLING_DUMPSTER: {
      return {
        ...state,
        isDeleteRecyclingDumpsterSuccess: false
      };
    }

    case actionTypes.DELETE_RECYCLING_DUMPSTER_SUCCESS: {
      return {
        ...state,
        isDeleteRecyclingDumpsterSuccess: true
      };
    }

    case actionTypes.DELETE_RECYCLING_DUMPSTER_FAIL: {
      return {
        ...state,
        isDeleteRecyclingDumpsterSuccess: false
      };
    }
    /* ===== RECYCLING DUMPSTER END ===== */


    default:
      return state;
  }
}
