import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
import { push } from "react-router-redux";
import Helper from "../../helpers/helpers";


export function* adminChangePassword() {
  yield takeEvery(actionTypes.ADMIN_CHANGE_PASSWORD, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("CHANGEPWD");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.adminChangePassword(action.data);
      if (response.data.success) {
        yield put(actions.adminChangePasswordSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.adminChangePasswordFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.adminChangePasswordFail());
    }
  });
}

export function* adminUpdateDetail() {
  yield takeEvery(actionTypes.ADMIN_UPDATE_DETAIL, function*(action) {

    if(!action.data) action.data = {}
    let track_no = Helper.generateTrackNo("ADMUPDDETAIL");

    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.adminUpdateDetail(action.data);
      if (response.data.success) {
        yield put(actions.adminUpdateDetailSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.adminUpdateDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.adminUpdateDetailFail());
    }
  });
}


export function* loadAdminList() {
  
  yield takeEvery(actionTypes.LOAD_ADMIN_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ADMLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadAdminList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadAdminListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadAdminListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadAdminListFail());
    }
  });
}

export function* loadAdminDetail() {
  
  yield takeEvery(actionTypes.LOAD_ADMIN_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("ADMDETAIL");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadAdminDetail(action.id, action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadAdminDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadAdminDetailFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadAdminDetailFail());
    }
  });
}

export function* createAdmin() {
  yield takeEvery(actionTypes.CREATE_ADMIN, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("CREATEADM");
    action.data.track_no = track_no

    yield put(actions.enableLoading());
    
    try {
      const response = yield API.createAdmin(action.data);
      if (response.data.success) {
        yield put(actions.createAdminSuccess());
        yield put(actions.toastSuccess(response.data.message));
        yield put(push("/admin"));
      } else {
        alert(response.data.message);
        yield put(actions.createAdminFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.createAdminFail());
    }
  });
}

export function* editAdminDetail() {
  yield takeEvery(actionTypes.EDIT_ADMIN_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITADM");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editAdminDetail(action.id, action.data);
      if (response.data.success) {
        yield put(actions.editAdminDetailSuccess());
        yield put(actions.toastSuccess(response.data.message));
        yield put(push("/admin"));
      } else {
        alert(response.data.message);
        yield put(actions.editAdminDetailFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editAdminDetailFail());
    }
  });
}

export function* editAdminStatus() {
  yield takeEvery(actionTypes.EDIT_ADMIN_STATUS, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("EDITADMSTATUS");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.editAdminStatus(action.id, action.data);
      if (response.data.success) {
        yield put(actions.editAdminStatusSuccess());
        yield put(actions.toastSuccess(response.data.message));

        if(action.listParams)
          yield put(actions.loadAdminList(action.listParams));
        else
          yield put(actions.loadAdminList());

      } else {
        alert(response.data.message);
        yield put(actions.editAdminStatusFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.editAdminStatusFail());
    }
  });
}


export function* deleteAdmin() {
  
  yield takeEvery(actionTypes.DELETE_ADMIN, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DELADM");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.deleteAdmin(action.id, action.data);
      
      if (response.data.success) {
        
        yield put(actions.deleteAdminSuccess());
        yield put(actions.toastSuccess(response.data.message));
        yield put(push("/admin"));
      } else {
        alert(response.data.message);
        yield put(actions.deleteAdminFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.deleteAdminFail());
    }
  });
}

export function* resetAdminPassword() {
  yield takeEvery(actionTypes.RESET_ADMIN_PASSWORD, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("RESETADMPWD");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.resetAdminPassword(action.id, action.data);
      if (response.data.success) {
        yield put(actions.resetAdminPasswordSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.resetAdminPasswordFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.resetAdminPasswordFail());
    }
  });
}

export function* setAdminEmergencyContact() {
  yield takeEvery(actionTypes.SER_ADMIN_EMERGENCY_CONTACT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETEMERGENCYCONTACT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.setAdminEmergencyContact(action.id, action.data);
      if (response.data.success) {
        yield put(actions.setAdminEmergencyContactSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.setAdminEmergencyContactFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.setAdminEmergencyContactFail());
    }
  });
}



/* ===== ADMIN PRIVILEGES ===== */

export function* loadAdminPrivileges() {
  
  yield takeEvery(actionTypes.LOAD_ADMIN_PRIVILEGES_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("PRIVILEGESLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadAdminPrivileges(action.data);
      if (response.data.success && response.data.data) {
  
        yield put(actions.loadAdminPrivilegesSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadAdminPrivilegesFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadAdminPrivilegesFail());
    }
  });
}

export function* createAdminRole() {
  
  yield takeEvery(actionTypes.CREATE_ADMIN_ROLE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("CREATEADMROLE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.createAdminRole(action.data);
      if (response.data.success) {
        yield put(actions.createAdminRoleSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.createAdminRoleFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.createAdminRoleFail());
    }
  });
}


export function* deleteAdminRole() {
  
  yield takeEvery(actionTypes.DELETE_ADMIN_ROLE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DELADMROLE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.deleteAdminRole(action.id, action.data);
      if (response.data.success) {
        yield put(actions.deleteAdminRoleSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.deleteAdminRoleFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.deleteAdminRoleFail());
    }
  });
}


export function* updateAdminRole() {
  
  yield takeEvery(actionTypes.UPDATE_ADMIN_ROLE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDADMROLE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.updateAdminRole(action.id, action.data);
      if (response.data.success) {
        yield put(actions.updateAdminRoleSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.updateAdminRoleFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.updateAdminRoleFail());
    }
  });
}


export function* setAdminPrivileges() {
  
  yield takeEvery(actionTypes.SET_ADMIN_PRIVILEGES, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("SETPRIVILEGES");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.setAdminPrivileges(action.data);
      if (response.data.success) {
        yield put(actions.setAdminPrivilegesSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.setAdminPrivilegesFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.setAdminPrivilegesFail());
    }
  });
}


export function* loadAdminRole() {
  
  yield takeEvery(actionTypes.LOAD_ADMIN_ROLE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("LOADADMROLE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadAdminRole(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadAdminRoleSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadAdminRoleFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadAdminRoleFail());
    }
  });
}

export default function* adminSaga() {
  
  yield all([fork(adminChangePassword)]);
  yield all([fork(adminUpdateDetail)]);
  
  yield all([fork(loadAdminList)]);
  yield all([fork(loadAdminDetail)]);
  yield all([fork(createAdmin)]);
  yield all([fork(editAdminDetail)]);
  yield all([fork(editAdminStatus)]);
  yield all([fork(deleteAdmin)]);
  yield all([fork(resetAdminPassword)]);
  yield all([fork(setAdminEmergencyContact)]);
  
  yield all([fork(loadAdminPrivileges)]);
  yield all([fork(createAdminRole)]);
  yield all([fork(deleteAdminRole)]);
  yield all([fork(updateAdminRole)]);
  yield all([fork(setAdminPrivileges)]);
  yield all([fork(loadAdminRole)]);
}
