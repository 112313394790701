import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/notification/admin";
};


export const loadNotificationList = data => {
  const url = `${baseURL()}/load`;
  return axios.post(url, data);
};

export const loadNotificationDetail = (id, data) => {
  const url = `${baseURL()}/detail/${id}`;
  return axios.post(url, data);
};


export const createNotification = data => {
  const url = `${baseURL()}/create`;
  return axios.post(url, data);
};

export const editNotification = (id, data) => {
  const url = `${baseURL()}/update/${id}`;
  return axios.post(url, data);
};

export const deleteNotification = (id, data) => {
  const url = `${baseURL()}/delete/${id}`;
  return axios.post(url, data);
};