import * as actionTypes from "./actionTypes";


export const loadWhatsappNotificationList = data => {
  return {
    type: actionTypes.LOAD_WHATSAPP_NOTIFICATION_LIST,
    data
  };
};

export const loadWhatsappNotificationListSuccess = data => {
  return {
    type: actionTypes.LOAD_WHATSAPP_NOTIFICATION_LIST_SUCCESS,
    data
  };
};

export const loadWhatsappNotificationListFail = () => {
  return {
    type: actionTypes.LOAD_WHATSAPP_NOTIFICATION_LIST_FAIL
  };
};



export const loadWhatsappNotificationDetail = id => {
  
  return {
    type: actionTypes.LOAD_WHATSAPP_NOTIFICATION_DETAIL,
    id
  };
};

export const loadWhatsappNotificationDetailSuccess = data => {
  
  return {
    type: actionTypes.LOAD_WHATSAPP_NOTIFICATION_DETAIL_SUCCESS,
    data
  };
};

export const loadWhatsappNotificationDetailFail = () => {
  return {
    type: actionTypes.LOAD_WHATSAPP_NOTIFICATION_DETAIL_FAIL
  };
};


export const createWhatsappNotification = (data) => {
  return {
    type: actionTypes.CREATE_WHATSAPP_NOTIFICATION,
    data
  };
};

export const createWhatsappNotificationSuccess = () => {
  return {
    type: actionTypes.CREATE_WHATSAPP_NOTIFICATION_SUCCESS
  };
};

export const createWhatsappNotificationFail = () => {
  return {
    type: actionTypes.CREATE_WHATSAPP_NOTIFICATION_FAIL
  };
};



export const editWhatsappNotification = (id,data) => {
  return {
    type: actionTypes.EDIT_WHATSAPP_NOTIFICATION,
    id,
    data
  };
};

export const editWhatsappNotificationSuccess = () => {
  return {
    type: actionTypes.EDIT_WHATSAPP_NOTIFICATION_SUCCESS
  };
};

export const editWhatsappNotificationFail = () => {
  return {
    type: actionTypes.EDIT_WHATSAPP_NOTIFICATION_FAIL
  };
};



export const deleteWhatsappNotification = id => {
  return {
    type: actionTypes.DELETE_WHATSAPP_NOTIFICATION,
    id
  };
};

export const deleteWhatsappNotificationSuccess = () => {
  return {
    type: actionTypes.DELETE_WHATSAPP_NOTIFICATION_SUCCESS
  };
};

export const deleteWhatsappNotificationFail = () => {
  return {
    type: actionTypes.DELETE_WHATSAPP_NOTIFICATION_FAIL
  };
};


export const sendWhatsappForGrading = id => {
  return {
    type: actionTypes.SEND_WHATSAPP_FOR_GRADING,
    id
  };
};

export const sendWhatsappForGradingSuccess = () => {
  return {
    type: actionTypes.SEND_WHATSAPP_FOR_GRADING_SUCCESS
  };
};

export const sendWhatsappForGradingFail = () => {
  return {
    type: actionTypes.SEND_WHATSAPP_FOR_GRADING_FAIL
  };
};
