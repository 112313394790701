import * as actionTypes from "./actionTypes";

export const login = data => {
  return {
    type: actionTypes.LOGIN,
    data
  };
};

export const loginSuccess = (token, refresh_token, profile) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    token,
    refresh_token, 
    profile
  };
};

export const loginError = error => {
  return {
    type: actionTypes.LOGIN_FAIL,
    error
  };
};

export const logoutConfirmation = () => {
  return {
    type: actionTypes.LOGOUT_CONFIRMATION
  };
};

export const logout = (data) => {
  return {
    type: actionTypes.LOGOUT,
    data
  };
};
export const logoutSuccess = () => {
  return {
    type: actionTypes.LOGOUT_SUCCESS
  };
};

export const refreshJWTToken = (data) => {
  return {
    type: actionTypes.REFRESH_JWT_TOKEN,
    data
  };
};

export const refreshJWTTokenSuccess = (token, refreshToken) => {
  return {
    type: actionTypes.REFRESH_JWT_TOKEN_SUCCESS,
    token,
    refreshToken
  };
};

export const refreshJWTTokenFail = () => {
  return {
    type: actionTypes.REFRESH_JWT_TOKEN_FAIL
  };
};

// Force logout
export const forceLogout = () => {
  return {
    type: actionTypes.FORCE_LOGOUT
  };
};

export const forceLogoutSuccess = data => {
  return {
    type: actionTypes.FORCE_LOGOUT_SUCCESS,
    data
  };
};

export const getProfile = (data) => {
  return {
    type: actionTypes.GET_PROFILE,
    data
  };
};

export const getProfileSuccess = data => {
  return {
    type: actionTypes.GET_PROFILE_SUCCESS,
    data
  };
};

export const changePassword = data => {
  return {
    type: actionTypes.CHANGE_PASSWORD,
    data
  };
};

export const changePasswordSuccess = data => {
  return {
    type: actionTypes.CHANGE_PASSWORD_SUCCESS,
    data
  };
};



export const checkVersion = (data) => {
  return {
    type: actionTypes.CHECK_VERSION,
    data
  };
};

export const checkVersionSuccess = (data) => {
  return {
    type: actionTypes.CHECK_VERSION_SUCCESS,
    data
  };
};

export const checkVersionFail = () => {
  return {
    type: actionTypes.CHECK_VERSION_FAIL
  };
};


export const versionUpdateList = (data) => {
  return {
    type: actionTypes.VERSION_UPDATE_LIST,
    data
  };
};

export const versionUpdateListSuccess = (data) => {
  return {
    type: actionTypes.VERSION_UPDATE_LIST_SUCCESS,
    data
  };
};

export const versionUpdateListFail = () => {
  return {
    type: actionTypes.VERSION_UPDATE_LIST_FAIL
  };
};

