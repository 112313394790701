import * as actionTypes from "./actionTypes";

export const loadDumpsterPriceEffectiveDateList = data => {

  return {
    type: actionTypes.LOAD_DUMPSTER_PRICE_EFFECTIVE_DATE_LIST,
    data
  };
};

export const loadDumpsterPriceEffectiveDateListSuccess = data => {
  
  return {
    type: actionTypes.LOAD_DUMPSTER_PRICE_EFFECTIVE_DATE_LIST_SUCCESS,
    data
  };
};

export const loadDumpsterPriceEffectiveDateListFail = () => {
  return {
    type: actionTypes.LOAD_DUMPSTER_PRICE_EFFECTIVE_DATE_LIST_FAIL
  };
};

export const loadDumpsterPriceList = data => {

  return {
    type: actionTypes.LOAD_DUMPSTER_PRICE_LIST,
    data
  };
};

export const loadDumpsterPriceListSuccess = data => {
  
  return {
    type: actionTypes.LOAD_DUMPSTER_PRICE_LIST_SUCCESS,
    data
  };
};

export const loadDumpsterPriceListFail = () => {
  return {
    type: actionTypes.LOAD_DUMPSTER_PRICE_LIST_FAIL
  };
};


export const getDumpsterPricePDF = (data) => {
  return {
    type: actionTypes.GET_DUMPSTER_PRICE_PDF,
    data,
  };
};

export const getDumpsterPricePDFSuccess = data => {
  return {
    type: actionTypes.GET_DUMPSTER_PRICE_PDF_SUCCESS,
    data
  };
};

export const getDumpsterPricePDFFail = () => {
  return {
    type: actionTypes.GET_DUMPSTER_PRICE_PDF_FAIL
  };
};


export const loadStateDistrictList = (data) => {
  return {
    type: actionTypes.LOAD_STATE_DISTRICT_LIST,
    data
  };
};


export const setDumpsterPrice = data => {
  return {
    type: actionTypes.SET_DUMPSTER_PRICE,
    data
  };
};

export const setDumpsterPriceSuccess = () => {
  return {
    type: actionTypes.SET_DUMPSTER_PRICE_SUCCESS
  };
};

export const setDumpsterPriceFail = () => {
  return {
    type: actionTypes.SET_DUMPSTER_PRICE_FAIL
  };
};


export const addDumpsterPrice = data => {
  return {
    type: actionTypes.ADD_DUMPSTER_PRICE,
    data
  };
};

export const addDumpsterPriceSuccess = () => {
  return {
    type: actionTypes.ADD_DUMPSTER_PRICE_SUCCESS
  };
};

export const addDumpsterPriceFail = () => {
  return {
    type: actionTypes.ADD_DUMPSTER_PRICE_FAIL
  };
};

export const editDumpsterPrice = (id,data) => {
  return {
    type: actionTypes.EDIT_DUMPSTER_PRICE,
    id,
    data
  };
};

export const editDumpsterPriceSuccess = () => {
  return {
    type: actionTypes.EDIT_DUMPSTER_PRICE_SUCCESS
  };
};

export const editDumpsterPriceFail = () => {
  return {
    type: actionTypes.EDIT_DUMPSTER_PRICE_FAIL
  };
};


export const loadDumpsterPriceRecyclingEffectiveDateList = data => {

  return {
    type: actionTypes.LOAD_DUMPSTER_PRICE_RECYCLING_EFFECTIVE_DATE_LIST,
    data
  };
};

export const loadDumpsterPriceRecyclingEffectiveDateListSuccess = data => {
  
  return {
    type: actionTypes.LOAD_DUMPSTER_PRICE_RECYCLING_EFFECTIVE_DATE_LIST_SUCCESS,
    data
  };
};

export const loadDumpsterPriceRecyclingEffectiveDateListFail = () => {
  return {
    type: actionTypes.LOAD_DUMPSTER_PRICE_RECYCLING_EFFECTIVE_DATE_LIST_FAIL
  };
};



export const loadDumpsterPriceRecyclingList = data => {

  return {
    type: actionTypes.LOAD_DUMPSTER_PRICE_RECYCLING_LIST,
    data
  };
};

export const loadDumpsterPriceRecyclingListSuccess = data => {
  
  return {
    type: actionTypes.LOAD_DUMPSTER_PRICE_RECYCLING_LIST_SUCCESS,
    data
  };
};

export const loadDumpsterPriceRecyclingListFail = () => {
  return {
    type: actionTypes.LOAD_DUMPSTER_PRICE_RECYCLING_LIST_FAIL
  };
};


export const getDumpsterPriceRecyclingPDF = (data) => {
  return {
    type: actionTypes.GET_DUMPSTER_PRICE_RECYCLING_PDF,
    data,
  };
};

export const getDumpsterPriceRecyclingPDFSuccess = data => {
  return {
    type: actionTypes.GET_DUMPSTER_PRICE_RECYCLING_PDF_SUCCESS,
    data
  };
};

export const getDumpsterPriceRecyclingPDFFail = () => {
  return {
    type: actionTypes.GET_DUMPSTER_PRICE_RECYCLING_PDF_FAIL
  };
};


export const setDumpsterPriceRecycling = data => {
  return {
    type: actionTypes.SET_DUMPSTER_PRICE_RECYCLING,
    data
  };
};

export const setDumpsterPriceRecyclingSuccess = () => {
  return {
    type: actionTypes.SET_DUMPSTER_PRICE_RECYCLING_SUCCESS
  };
};

export const setDumpsterPriceRecyclingFail = () => {
  return {
    type: actionTypes.SET_DUMPSTER_PRICE_RECYCLING_FAIL
  };
};
