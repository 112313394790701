import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions";
import * as API from "../api";
import Helper from "../../helpers/helpers";


export function* loadDriverCutOff() {
  
  yield takeEvery(actionTypes.DRIVER_CUT_OFF_LOAD, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DRIVERCUTOFFLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDriverCutOff(action.data);
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.loadDriverCutOffSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDriverCutOffFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDriverCutOffFail());
    }
  });
}


export function* driverCutOffDetail() {
  
  yield takeEvery(actionTypes.DRIVER_CUT_OFF_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DRIVERCUTOFFDETAIL");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.driverCutOffDetail(action.id, action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.driverCutOffDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.driverCutOffDetailFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.driverCutOffDetailFail());
    }
  });
}

export function* getReimburseReport() {
  
  yield takeEvery(actionTypes.GET_REIMBURSE_REPORT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("REIMBURSEREPORT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.getReimburseReport(action.id, action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.getReimburseReportSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.getReimburseReportFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getReimburseReportFail());
    }
  });
}

export function* driverCutOffApprove() {
  
  yield takeEvery(actionTypes.DRIVER_CUT_OFF_APPROVE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("APPRDRIVERCUTOFF");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.driverCutOffApprove(action.id, action.data);
      
      if (response.data.success) {
        yield put(actions.driverCutOffApproveSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.driverCutOffApproveFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.driverCutOffApproveFail());
    }
  });
}


export function* driverCutOffCount() {
  
  yield takeEvery(actionTypes.DRIVER_CUT_OFF_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DRIVERCUTOFFCOUNT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.driverCutOffCount(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.driverCutOffCountSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.driverCutOffCountFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.driverCutOffCountFail());
    }
  });
}



export function* driverCutOffUpdateReimburse() {
  
  yield takeEvery(actionTypes.DRIVER_CUT_OFF_UPDATE_REIMBURSE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DRIVERCUTOFFUPDREIMBURSE");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.driverCutOffUpdateReimburse(action.id, action.trans, action.data);
      
      if (response.data.success) {
        yield put(actions.driverCutOffUpdateReimburseSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.driverCutOffUpdateReimburseFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.driverCutOffUpdateReimburseFail());
    }
  });
}

/* ===== USER WITHDRAWAL  ===== */

export function* loadWithdrawRequest() {
  
  yield takeEvery(actionTypes.WITHDRAW_REQUEST_LOAD, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WITHDRAWREQUESTLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadWithdrawRequest(action.data);
      if (response.data.success && response.data.data) {
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }
        
        yield put(actions.loadWithdrawRequestSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadWithdrawRequestFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadWithdrawRequestFail());
    }
  });
}



export function* withdrawRequestDetail() {
  
  yield takeEvery(actionTypes.WITHDRAW_REQUEST_DETAIL, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WITHDRAWREQUESTDETAIL");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.withdrawRequestDetail(action.id, action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.withdrawRequestDetailSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.withdrawRequestDetailFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.withdrawRequestDetailFail());
    }
  });
}

export function* withdrawRequestUpdateStatus() {
  
  yield takeEvery(actionTypes.WITHDRAW_REQUEST_UPDATE_STATUS, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WITHDRAWREQUESTUPDSTATUS");
    action.data.track_no = track_no


    yield put(actions.enableLoading());
    try {
      const response = yield API.withdrawRequestUpdateStatus(action.id,action.data);
      
      if (response.data.success) {
        yield put(actions.withdrawRequestUpdateStatusSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.withdrawRequestUpdateStatusFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.withdrawRequestUpdateStatusFail());
    }
  });
}


export function* withdrawRequestCount() {
  
  yield takeEvery(actionTypes.WITHDRAW_REQUEST_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WITHDRAWREQEUSTCOUNT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.withdrawRequestCount(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.withdrawRequestCountSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.withdrawRequestCountFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.withdrawRequestCountFail());
    }
  });
}


export function* withdrawRequestGenerateWDBatchFile() {
  yield takeEvery(actionTypes.WITHDRAW_REQUEST_GENERATE_WD_BATCH_FILE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WRGENERATEBATCHFILE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.withdrawRequestGenerateWDBatchFile(action.id, action.data);
      
      if (response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        let contentType = response.headers["content-type"];
        let filename = new Date().toString();
        let strArr = contentType.split(";");
        if (strArr && strArr.length > 0) {
          let fileArr = strArr[2].split("=");
          filename = fileArr[1];
        }
        link.href = url;
        link.setAttribute("Download", `${filename}.txt`);
        document.body.appendChild(link);
        link.click();
        yield put(actions.toastSuccess("Batch File Generated Successfully"));
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link);

        yield put(actions.withdrawRequestGenerateWDBatchFileSuccess());

      } else {
        yield put(actions.toastError("Batch File Failed to generate!"));
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.withdrawRequestGenerateWDBatchFileFail());
    }
  });
}

export function* withdrawRequestSetPendingWDToProcessing() {
  yield takeEvery(actionTypes.WITHDRAW_REQUEST_UPDATE_TO_PROCESSING, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WRSETPROCESSING");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.withdrawRequestSetPendingWDToProcessing(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.withdrawRequestSetPendingWDToProcessingSuccess(response.data.data));
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.withdrawRequestSetPendingWDToProcessingFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.withdrawRequestSetPendingWDToProcessingFail());
    }
  });
}

export function* withdrawRequestUploadRespondBatchFile() {
  yield takeEvery(actionTypes.WITHDRAW_REQUEST_RESPOND_BATCH_FILE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WRRESPONDBATCHFILE");
    
    if(action.data instanceof FormData)
      action.data.append("track_no", track_no);
      
    else
      action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.withdrawRequestUploadRespondBatchFile(action.data);
      if (response.data.success) {
        yield put(actions.withdrawRequestUploadRespondBatchFileSuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.withdrawRequestUploadRespondBatchFileFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.withdrawRequestUploadRespondBatchFileFail());
    }
  });
}


export function* getBatchFile() {
  yield takeEvery(actionTypes.GET_BATCH_FILE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WDGETBATCHFILE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.getBatchFile(action.data);      
      if (response.data.success && response.data.data) {        
        
          Helper.loadSecureFile(response.data.data.path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.filename;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
      } else {
        
        alert(response.data.message);
        yield put(actions.getBatchFileFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getBatchFileFail());
    }
  });
}


export function* getResponseFile() {
  yield takeEvery(actionTypes.GET_BANK_RESPONSE_FILE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GETBANKRESPONSEFILE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.getResponseFile(action.data);
      if (response.data.success && response.data.data) {        
        
          Helper.loadSecureFile(response.data.data.path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.filename;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
      } else {
        
        alert(response.data.message);
        yield put(actions.getResponseFileFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.getResponseFileFail());
    }
  });
}


export function* loadWalletTransactionCount() {
  
  yield takeEvery(actionTypes.WALLET_TRANSACTION_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WALLETTRANSACTIONCOUNT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadWalletTransactionCount(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadWalletTransactionCountSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadWalletTransactionCountFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadWalletTransactionCountFail());
    }
  });
}


export function* loadWalletBalance() {
  
  yield takeEvery(actionTypes.LOAD_WALLET_BALANCE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WALLETBALLANCE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadWalletBalance(action.id, action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadWalletBalanceSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadWalletBalanceFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadWalletBalanceFail());
    }
  });
}



export function* loadWalletTransaction() {
  
  yield takeEvery(actionTypes.LOAD_WALLET_TRANSACTION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WALLETTRANSACTIONLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadWalletTransaction(action.data);
      if (response.data.success && response.data.data) {        
        
        if(response.data.data.export)
        {
          Helper.loadSecureFile(response.data.data.file_path, file => {
            
            var downloadLink = document.createElement("a");
            downloadLink.href = file;
            downloadLink.download = response.data.data.file_name;
  
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
          });
        }

        yield put(actions.loadWalletTransactionSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadWalletTransactionFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadWalletTransactionFail());
    }
  });
}



export function* loadWalletAccountList() {
  
  yield takeEvery(actionTypes.LOAD_WALLET_ACCOUNT_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WALLETACCLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadWalletAccountList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadWalletAccountListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadWalletAccountListFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadWalletAccountListFail());
    }
  });
}


export function* loadWalletAccountCount() {
  
  yield takeEvery(actionTypes.LOAD_WALLET_ACCOUNT_COUNT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("WALLETACCOUNTCOUNT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadWalletAccountCount(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadWalletAccountCountSuccess(response.data.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadWalletAccountCountFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadWalletAccountCountFail());
    }
  });
}

export function* updateWalletVerifyStatus() {
  yield takeEvery(actionTypes.UPDATE_WALLET_VERIFY_STATUS, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDWALLETVERIFYSTATUS");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.updateWalletVerifyStatus(action.id, action.data);
      if (response.data.success) {
        yield put(actions.updateWalletVerifyStatusSuccess());
        yield put(actions.toastSuccess(response.data.message));

      } else {
        alert(response.data.message);
        yield put(actions.updateWalletVerifyStatusFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.updateWalletVerifyStatusFail());
    }
  });
}

export function* updateWalletAllowCredited() {
  yield takeEvery(actionTypes.UPDATE_WALLET_ALLOW_CREDITED, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("UPDWALLETALLOWCREDITED");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.updateWalletAllowCredited(action.id, action.data);
      if (response.data.success) {
        
        if(response.data.data)
        {
          yield put(actions.updateWalletAllowCreditedSuccess(response.data.data));
        }else{

          yield put(actions.updateWalletAllowCreditedSuccess());
          yield put(actions.toastSuccess(response.data.message));
        }

      } else {
        alert(response.data.message);
        yield put(actions.updateWalletAllowCreditedFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.updateWalletAllowCreditedFail());
    }
  });
}

export function* loadDriverPendingPayment() {
  
  yield takeEvery(actionTypes.LOAD_DRIVER_PENDING_PAYMENT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("DRIVERPENDINGPAYMENTLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.loadDriverPendingPayment(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.loadDriverPendingPaymentSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.loadDriverPendingPaymentFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.loadDriverPendingPaymentFail());
    }
  });
}


export function* approveDriverPayment() {
  
  yield takeEvery(actionTypes.APPROVE_DRIVER_PAYMENT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("APPRDRIVERPAYMENT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.approveDriverPayment(action.id, action.data);
      if (response.data.success) {
        yield put(actions.approveDriverPaymentSuccess());
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
        yield put(actions.approveDriverPaymentFail());
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.approveDriverPaymentFail());
    }
  });
}


export default function* orderSaga() {
  yield all([fork(loadDriverCutOff)]);
  yield all([fork(driverCutOffDetail)]);
  yield all([fork(getReimburseReport)]);
  yield all([fork(driverCutOffApprove)]);
  yield all([fork(driverCutOffCount)]);
  yield all([fork(driverCutOffUpdateReimburse)]);
  
  yield all([fork(loadWithdrawRequest)]);
  yield all([fork(withdrawRequestDetail)]);
  yield all([fork(withdrawRequestUpdateStatus)]);
  yield all([fork(withdrawRequestCount)]);
  yield all([fork(withdrawRequestGenerateWDBatchFile)]);
  yield all([fork(withdrawRequestSetPendingWDToProcessing)]);
  yield all([fork(withdrawRequestUploadRespondBatchFile)]);
  yield all([fork(getBatchFile)]);
  yield all([fork(getResponseFile)]);
  yield all([fork(loadWalletTransactionCount)]);
  yield all([fork(loadWalletBalance)]);
  yield all([fork(loadWalletTransaction)]);
  yield all([fork(loadWalletAccountList)]);
  yield all([fork(loadWalletAccountCount)]);
  yield all([fork(updateWalletVerifyStatus)]);  
  yield all([fork(updateWalletAllowCredited)]); 
  yield all([fork(loadDriverPendingPayment)]);
  yield all([fork(approveDriverPayment)]);
  
}
