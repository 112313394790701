import * as actionTypes from "./actionTypes";

export const loadDriverList = data => {
  
  return {
    type: actionTypes.LOAD_DRIVER_LIST,
    data
  };
};

export const loadDriverListSuccess = data => {
  return {
    type: actionTypes.LOAD_DRIVER_LIST_SUCCESS,
    data
  };
};

export const loadDriverListFail = () => {
  return {
    type: actionTypes.LOAD_DRIVER_LIST_FAIL
  };
};



export const driverCount = (data) => {
  
  return {
    type: actionTypes.DRIVER_COUNT,
    data
  };
};

export const driverCountSuccess = data => {
  return {
    type: actionTypes.DRIVER_COUNT_SUCCESS,
    data
  };
};

export const driverCountFail = () => {
  return {
    type: actionTypes.DRIVER_COUNT_FAIL
  };
};


export const editDriverDetail = (id,data) => {
  return {
    type: actionTypes.EDIT_DRIVER_DETAIL,
    id,
    data
  };
};

export const editDriverDetailSuccess = () => {
  return {
    type: actionTypes.EDIT_DRIVER_DETAIL_SUCCESS
  };
};

export const editDriverDetailFail = () => {
  return {
    type: actionTypes.EDIT_DRIVER_DETAIL_FAIL
  };
};


export const removeDriver = (id,data) => {
  return {
    type: actionTypes.REMOVE_DRIVER,
    id,
    data
  };
};

export const removeDriverSuccess = (data) => {
  return {
    type: actionTypes.REMOVE_DRIVER_SUCCESS,
    data
  };
};

export const removeDriverFail = () => {
  return {
    type: actionTypes.REMOVE_DRIVER_FAIL
  };
};


export const editDriverStatus = (id,data, listParams) => {
  return {
    type: actionTypes.EDIT_DRIVER_STATUS,
    id,
    data,
    listParams
  };
};

export const editDriverStatusSuccess = () => {
  return {
    type: actionTypes.EDIT_DRIVER_STATUS_SUCCESS
  };
};

export const editDriverStatusFail = () => {
  return {
    type: actionTypes.EDIT_DRIVER_STATUS_FAIL
  };
};



export const editDriverLoginShowVeriCodeStatus = (id,data, listParams) => {
  return {
    type: actionTypes.EDIT_DRIVER_SHOW_VERI_CODE_STATUS,
    id,
    data,
    listParams
  };
};

export const editDriverLoginShowVeriCodeStatusSuccess = () => {
  return {
    type: actionTypes.EDIT_DRIVER_SHOW_VERI_CODE_STATUS_SUCCESS
  };
};

export const editDriverLoginShowVeriCodeStatusFail = () => {
  return {
    type: actionTypes.EDIT_DRIVER_SHOW_VERI_CODE_STATUS_FAIL
  };
};



export const editDriverVerifyStatus = (id,data, listParams) => {
  return {
    type: actionTypes.EDIT_DRIVER_VERIFY_STATUS,
    id,
    data,
    listParams
  };
};

export const editDriverVerifyStatusSuccess = () => {
  return {
    type: actionTypes.EDIT_DRIVER_VERIFY_STATUS_SUCCESS
  };
};

export const editDriverVerifyStatusFail = () => {
  return {
    type: actionTypes.EDIT_DRIVER_VERIFY_STATUS_FAIL
  };
};


export const rejectDriverVerifyStatus = (id,data, listParams) => {
  return {
    type: actionTypes.REJECT_DRIVER_VERIFY_STATUS,
    id,
    data,
    listParams
  };
};

export const rejectDriverVerifyStatusSuccess = () => {
  return {
    type: actionTypes.REJECT_DRIVER_VERIFY_STATUS_SUCCESS
  };
};

export const rejectDriverVerifyStatusFail = () => {
  return {
    type: actionTypes.REJECT_DRIVER_VERIFY_STATUS_FAIL
  };
};


export const loadDriverDetail = (id, data) => {
  
  return {
    type: actionTypes.LOAD_DRIVER_DETAIL,
    id,
    data
  };
};

export const loadDriverDetailSuccess = data => {
  
  return {
    type: actionTypes.LOAD_DRIVER_DETAIL_SUCCESS,
    data
  };
};

export const loadDriverDetailFail = () => {
  return {
    type: actionTypes.LOAD_DRIVER_DETAIL_FAIL
  };
};


export const recoveryDriver = (id, data) => {
  
  return {
    type: actionTypes.RECOVERY_DRIVER,
    id,
    data
  };
};

export const recoveryDriverSuccess = () => {
  
  return {
    type: actionTypes.RECOVERY_DRIVER_SUCCESS
  };
};

export const recoveryDriverFail = () => {
  return {
    type: actionTypes.RECOVERY_DRIVER_FAIL
  };
};

export const uploadDriverDoc = (id, data) => {
  
  return {
    type: actionTypes.UPLOAD_DRIVER_DOC,
    id,
    data
  };
};

export const uploadDriverDocSuccess = () => {
  
  return {
    type: actionTypes.UPLOAD_DRIVER_DOC_SUCCESS
  };
};

export const uploadDriverDocFail = () => {
  return {
    type: actionTypes.UPLOAD_DRIVER_DOC_FAIL
  };
};


export const loadDriverDocumentationCount = (data) => {
  
  return {
    type: actionTypes.DRIVER_DOCUMENTATION_COUNT,
    data
  };
};

export const loadDriverDocumentationCountSuccess = (data) => {
  
  return {
    type: actionTypes.DRIVER_DOCUMENTATION_COUNT_SUCCESS,
    data
  };
};

export const loadDriverDocumentationCountFail = () => {
  return {
    type: actionTypes.DRIVER_DOCUMENTATION_COUNT_FAIL
  };
};

export const loadDriverDocumentationList = (data) => {
  
  return {
    type: actionTypes.DRIVER_DOCUMENTATION_LIST,
    data
  };
};

export const loadDriverDocumentationListSuccess = (data) => {
  
  return {
    type: actionTypes.DRIVER_DOCUMENTATION_LIST_SUCCESS,
    data
  };
};

export const loadDriverDocumentationListFail = () => {
  return {
    type: actionTypes.DRIVER_DOCUMENTATION_LIST_FAIL
  };
};

export const resetDriverDocumentationList = () => {
  return {
    type: actionTypes.RESET_DRIVER_DOCUMENTATION_LIST
  };
};

export const approveDriverDocumentation = (data) => {
  
  return {
    type: actionTypes.APPROVE_DRIVER_DOCUMENTATION,
    data
  };
};

export const approveDriverDocumentationSuccess = (data) => {
  
  return {
    type: actionTypes.APPROVE_DRIVER_DOCUMENTATION_SUCCESS,
    data
  };
};

export const approveDriverDocumentationFail = () => {
  return {
    type: actionTypes.APPROVE_DRIVER_DOCUMENTATION_FAIL
  };
};


export const rejectDriverDocumentation = (data) => {
  
  return {
    type: actionTypes.REJECT_DRIVER_DOCUMENTATION,
    data
  };
};

export const rejectDriverDocumentationSuccess = (data) => {
  
  return {
    type: actionTypes.REJECT_DRIVER_DOCUMENTATION_SUCCESS,
    data
  };
};

export const rejectDriverDocumentationFail = () => {
  return {
    type: actionTypes.REJECT_DRIVER_DOCUMENTATION_FAIL
  };
};

export const applyScheduledWasteService = (id, data) => {
  
  return {
    type: actionTypes.APPLY_SCHEDULED_WASTE_SERVICE,
    id,
    data
  };
};

export const applyScheduledWasteServiceSuccess = () => {
  
  return {
    type: actionTypes.APPLY_SCHEDULED_WASTE_SERVICE_SUCCESS
  };
};

export const applyScheduledWasteServiceFail = () => {
  return {
    type: actionTypes.APPLY_SCHEDULED_WASTE_SERVICE_FAIL
  };
};


export const driverTrackList = data => {
  
  return {
    type: actionTypes.DRIVER_TRACK_LIST,
    data
  };
};

export const driverTrackListSuccess = data => {
  
  return {
    type: actionTypes.DRIVER_TRACK_LIST_SUCCESS,
    data
  };
};

export const driverTrackListFail = () => {
  return {
    type: actionTypes.DRIVER_TRACK_LIST_FAIL
  };
};


export const driverTrack = (id, data) => {
  
  return {
    type: actionTypes.DRIVER_TRACK,
    id,
    data
  };
};

export const driverTrackSuccess = data => {
  
  return {
    type: actionTypes.DRIVER_TRACK_SUCCESS,
    data
  };
};

export const driverTrackFail = () => {
  return {
    type: actionTypes.DRIVER_TRACK_FAIL
  };
};


/* ===== DRIVER SUMMARY ===== */
export const loadDriverSummary = data => {
  return {
    type: actionTypes.LOAD_DRIVER_SUMMARY,
    data
  };
};

export const loadDriverSummarySuccess = data => {
  return {
    type: actionTypes.LOAD_DRIVER_SUMMARY_SUCCESS,
    data
  };
};

export const loadDriverSummaryFail = () => {
  return {
    type: actionTypes.LOAD_DRIVER_SUMMARY_FAIL
  };
};

/* ===== END DRIVER SUMMARY ===== */



/* ===== DRIVER LORRY_DUMPSTER ===== */
export const loadDriverLorryDumpster = (id,data) => {
  return {
    type: actionTypes.LOAD_DRIVER_LORRY_DUMPSTER,
    data,
    id
  };
};

export const loadDriverLorryDumpsterSuccess = data => {
  return {
    type: actionTypes.LOAD_DRIVER_LORRY_DUMPSTER_SUCCESS,
    data
  };
};

export const loadDriverLorryDumpsterFail = () => {
  return {
    type: actionTypes.LOAD_DRIVER_LORRY_DUMPSTER_FAIL
  };
};

/* ===== END DRIVER LORRY_DUMPSTER ===== */


/* ===== DRIVER TRANSPORT LICENSE ===== */
export const loadDriverTransportLicenseList = data => {
  
  return {
    type: actionTypes.LOAD_DRIVER_TRANSPORT_LICENSE_LIST,
    data
  };
};

export const loadDriverTransportLicenseListSuccess = data => {
  return {
    type: actionTypes.LOAD_DRIVER_TRANSPORT_LICENSE_LIST_SUCCESS,
    data
  };
};

export const loadDriverTransportLicenseListFail = () => {
  return {
    type: actionTypes.LOAD_DRIVER_TRANSPORT_LICENSE_LIST_FAIL
  };
};



export const driverTransportLicenseCount = (data) => {
  
  return {
    type: actionTypes.DRIVER_TRANSPORT_LICENSE_COUNT,
    data
  };
};

export const driverTransportLicenseCountSuccess = data => {
  return {
    type: actionTypes.DRIVER_TRANSPORT_LICENSE_COUNT_SUCCESS,
    data
  };
};

export const driverTransportLicenseCountFail = () => {
  return {
    type: actionTypes.DRIVER_TRANSPORT_LICENSE_COUNT_FAIL
  };
};


export const transportLicenseUpdateVerify = (id, data) => {
  
  return {
    type: actionTypes.DRIVER_TRANSPORT_LICENSE_UPDATE_VERIFIED,
    data,
    id
  };
};

export const transportLicenseUpdateVerifySuccess = () => {
  return {
    type: actionTypes.DRIVER_TRANSPORT_LICENSE_UPDATE_VERIFIED_SUCCESS
  };
};

export const transportLicenseUpdateVerifyFail = () => {
  return {
    type: actionTypes.DRIVER_TRANSPORT_LICENSE_UPDATE_VERIFIED_FAIL
  };
};


export const rejectTransportLicense = (id, data) => {
  
  return {
    type: actionTypes.REJECT_DRIVER_TRANSPORT_LICENSE,
    data,
    id
  };
};

export const rejectTransportLicenseSuccess = () => {
  return {
    type: actionTypes.REJECT_DRIVER_TRANSPORT_LICENSE_SUCCESS
  };
};

export const rejectTransportLicenseFail = () => {
  return {
    type: actionTypes.REJECT_DRIVER_TRANSPORT_LICENSE_FAIL
  };
};


export const scheduledWasteSetRecoverer = (id, data) => {
  
  return {
    type: actionTypes.DRIVER_SCHEDULED_WASTE_SET_RECOVERER,
    id,
    data
  };
};

export const scheduledWasteSetRecovererSuccess = () => {
  return {
    type: actionTypes.DRIVER_SCHEDULED_WASTE_SET_RECOVERER_SUCCESS
  };
};

export const scheduledWasteSetRecovererFail = () => {
  return {
    type: actionTypes.DRIVER_SCHEDULED_WASTE_SET_RECOVERER_FAIL
  };
};
/* ===== END DRIVER TRANSPORT LICENSE ===== */