import * as actionTypes from "./actionTypes";

export const loadDumpsterMeasurementList = data => {
  
  return {
    type: actionTypes.LOAD_DUMPSTER_MEASUREMENT_LIST,
    data
  };
};

export const loadDumpsterMeasurementListSuccess = data => {
  return {
    type: actionTypes.LOAD_DUMPSTER_MEASUREMENT_LIST_SUCCESS,
    data
  };
};

export const loadDumpsterMeasurementListFail = () => {
  return {
    type: actionTypes.LOAD_DUMPSTER_MEASUREMENT_LIST_FAIL
  };
};

export const addDumpsterMeasurement = data => {
  return {
    type: actionTypes.ADD_DUMPSTER_MEASUREMENT,
    data
  };
};

export const addDumpsterMeasurementSuccess = () => {
  return {
    type: actionTypes.ADD_DUMPSTER_MEASUREMENT_SUCCESS
  };
};

export const addDumpsterMeasurementFail = () => {
  return {
    type: actionTypes.ADD_DUMPSTER_MEASUREMENT_FAIL
  };
};

export const editDumpsterMeasurement = (id,data) => {
  return {
    type: actionTypes.EDIT_DUMPSTER_MEASUREMENT,
    id,
    data
  };
};

export const editDumpsterMeasurementSuccess = () => {
  return {
    type: actionTypes.EDIT_DUMPSTER_MEASUREMENT_SUCCESS
  };
};

export const editDumpsterMeasurementFail = () => {
  return {
    type: actionTypes.EDIT_DUMPSTER_MEASUREMENT_FAIL
  };
};

export const deleteDumpsterMeasurement = (id, data) => {
  return {
    type: actionTypes.DELETE_DUMPSTER_MEASUREMENT,
    id,
    data
  };
};

export const deleteDumpsterMeasurementSuccess = () => {
  return {
    type: actionTypes.DELETE_DUMPSTER_MEASUREMENT_SUCCESS
  };
};

export const deleteDumpsterMeasurementFail = () => {
  return {
    type: actionTypes.DELETE_DUMPSTER_MEASUREMENT_FAIL
  };
};




export const loadDumpsterServiceList = (data) => {
  
  return {
    type: actionTypes.LOAD_DUMPSTER_SERVICE_LIST,
    data
  };
};

export const loadDumpsterServiceListSuccess = data => {
  return {
    type: actionTypes.LOAD_DUMPSTER_SERVICE_LIST_SUCCESS,
    data
  };
};

export const loadDumpsterServiceListFail = () => {
  return {
    type: actionTypes.LOAD_DUMPSTER_SERVICE_LIST_FAIL
  };
};


export const updateDumpsterService = (id, data) => {
  
  return {
    type: actionTypes.UPDATE_DUMPSTER_SERVICE,
    id,
    data
  };
};

export const updateDumpsterServiceSuccess = () => {
  return {
    type: actionTypes.UPDATE_DUMPSTER_SERVICE_SUCCESS
  };
};

export const updateDumpsterServiceFail = () => {
  return {
    type: actionTypes.UPDATE_DUMPSTER_SERVICE_FAIL
  };
};



export const loadDumpsterList = data => {
  
  return {
    type: actionTypes.LOAD_DUMPSTER_LIST,
    data
  };
};

export const loadDumpsterListSuccess = data => {
  return {
    type: actionTypes.LOAD_DUMPSTER_LIST_SUCCESS,
    data
  };
};

export const loadDumpsterListFail = () => {
  return {
    type: actionTypes.LOAD_DUMPSTER_LIST_FAIL
  };
};

export const addDumpster = data => {
  return {
    type: actionTypes.ADD_DUMPSTER,
    data
  };
};

export const addDumpsterSuccess = () => {
  return {
    type: actionTypes.ADD_DUMPSTER_SUCCESS
  };
};

export const addDumpsterFail = () => {
  return {
    type: actionTypes.ADD_DUMPSTER_FAIL
  };
};

export const editDumpster = (id,data) => {
  return {
    type: actionTypes.EDIT_DUMPSTER,
    id,
    data
  };
};

export const editDumpsterSuccess = () => {
  return {
    type: actionTypes.EDIT_DUMPSTER_SUCCESS
  };
};

export const editDumpsterFail = () => {
  return {
    type: actionTypes.EDIT_DUMPSTER_FAIL
  };
};


export const deleteDumpster = (id, data) => {
  return {
    type: actionTypes.DELETE_DUMPSTER,
    id,
    data
  };
};

export const deleteDumpsterSuccess = () => {
  return {
    type: actionTypes.DELETE_DUMPSTER_SUCCESS
  };
};

export const deleteDumpsterFail = () => {
  return {
    type: actionTypes.DELETE_DUMPSTER_FAIL
  };
};



export const updateDumpsterWasteProcessCost = (id,data) => {
  return {
    type: actionTypes.UPDATE_DUMPSTER_WASTE_PROCESS_COST,
    id,
    data
  };
};

export const updateDumpsterWasteProcessCostSuccess = () => {
  return {
    type: actionTypes.UPDATE_DUMPSTER_WASTE_PROCESS_COST_SUCCESS
  };
};

export const updateDumpsterWasteProcessCostFail = () => {
  return {
    type: actionTypes.UPDATE_DUMPSTER_WASTE_PROCESS_COST_FAIL
  };
};



export const updateDumpsterRecyclingProcessCost = (id,data) => {
  return {
    type: actionTypes.UPDATE_DUMPSTER_RECYCLING_PROCESS_COST,
    id,
    data
  };
};

export const updateDumpsterRecyclingProcessCostSuccess = () => {
  return {
    type: actionTypes.UPDATE_DUMPSTER_RECYCLING_PROCESS_COST_SUCCESS
  };
};

export const updateDumpsterRecyclingProcessCostFail = () => {
  return {
    type: actionTypes.UPDATE_DUMPSTER_RECYCLING_PROCESS_COST_FAIL
  };
};



export const loadDumpsterDetail = (id, data) => {
  
  return {
    type: actionTypes.LOAD_DUMPSTER_DETAIL,
    id,
    data
  };
};

export const loadDumpsterDetailSuccess = data => {
  
  return {
    type: actionTypes.LOAD_DUMPSTER_DETAIL_SUCCESS,
    data
  };
};

export const loadDumpsterDetailFail = () => {
  return {
    type: actionTypes.LOAD_DUMPSTER_DETAIL_FAIL
  };
};




export const loadDumpsterExtraFee = (id,data) => {
  
  return {
    type: actionTypes.LOAD_DUMPSTER_EXTRA_FEE,
    id,
    data
  };
};

export const loadDumpsterExtraFeeSuccess = data => {
  
  return {
    type: actionTypes.LOAD_DUMPSTER_EXTRA_FEE_SUCCESS,
    data
  };
};

export const loadDumpsterExtraFeeFail = () => {
  return {
    type: actionTypes.LOAD_DUMPSTER_EXTRA_FEE_FAIL
  };
};




export const driverDumpsterCount = (id, data) => {
  return {
    type: actionTypes.DRIVER_DUMPSTER_COUNT,
    id,
    data
  };
};

export const driverDumpsterCountSuccess = data => {
  return {
    type: actionTypes.DRIVER_DUMPSTER_COUNT_SUCCESS,
    data
  };
};

export const driverDumpsterCountFail = () => {
  return {
    type: actionTypes.DRIVER_DUMPSTER_COUNT_FAIL
  };
};

export const loadDriverDumpsterList = (id = null,data) => {
  return {
    type: actionTypes.DRIVER_DUMPSTER_LIST,
    data,
    id
  };
};

export const loadDriverDumpsterListSuccess = data => {
  return {
    type: actionTypes.DRIVER_DUMPSTER_LIST_SUCCESS,
    data
  };
};

export const loadDriverDumpsterListFail = () => {
  return {
    type: actionTypes.DRIVER_DUMPSTER_LIST_FAIL
  };
};


/* ===== DRIVER DUMPSTER ===== */
export const loadDriverDumpsterSummary = data => {
  return {
    type: actionTypes.LOAD_DRIVER_DUMPSTER_SUMMARY,
    data
  };
};

export const loadDriverDumpsterSummarySuccess = data => {
  return {
    type: actionTypes.LOAD_DRIVER_DUMPSTER_SUMMARY_SUCCESS,
    data
  };
};

export const loadDriverDumpsterSummaryFail = () => {
  return {
    type: actionTypes.LOAD_DRIVER_DUMPSTER_SUMMARY_FAIL
  };
};

/* ===== END DRIVER DUMPSTER SUMMARY ===== */



/* ===== RECYCLING DUMPSTER ===== */
export const loadRecyclingDumpsterList = data => {
  
  return {
    type: actionTypes.LOAD_RECYCLING_DUMPSTER_LIST,
    data
  };
};

export const loadRecyclingDumpsterListSuccess = data => {
  return {
    type: actionTypes.LOAD_RECYCLING_DUMPSTER_LIST_SUCCESS,
    data
  };
};

export const loadRecyclingDumpsterListFail = () => {
  return {
    type: actionTypes.LOAD_RECYCLING_DUMPSTER_LIST_FAIL
  };
};


export const loadRecyclingDumpsterDetail = (id, data) => {
  
  return {
    type: actionTypes.LOAD_RECYCLING_DUMPSTER_DETAIL,
    id,
    data
  };
};

export const loadRecyclingDumpsterDetailSuccess = data => {
  
  return {
    type: actionTypes.LOAD_RECYCLING_DUMPSTER_DETAIL_SUCCESS,
    data
  };
};

export const loadRecyclingDumpsterDetailFail = () => {
  return {
    type: actionTypes.LOAD_RECYCLING_DUMPSTER_DETAIL_FAIL
  };
};


export const addRecyclingDumpster = data => {
  return {
    type: actionTypes.ADD_RECYCLING_DUMPSTER,
    data
  };
};

export const addRecyclingDumpsterSuccess = () => {
  return {
    type: actionTypes.ADD_RECYCLING_DUMPSTER_SUCCESS
  };
};

export const addRecyclingDumpsterFail = () => {
  return {
    type: actionTypes.ADD_RECYCLING_DUMPSTER_FAIL
  };
};

export const editRecyclingDumpster = (id,data) => {
  return {
    type: actionTypes.EDIT_RECYCLING_DUMPSTER,
    id,
    data
  };
};

export const editRecyclingDumpsterSuccess = () => {
  return {
    type: actionTypes.EDIT_RECYCLING_DUMPSTER_SUCCESS
  };
};

export const editRecyclingDumpsterFail = () => {
  return {
    type: actionTypes.EDIT_RECYCLING_DUMPSTER_FAIL
  };
};


export const deleteRecyclingDumpster = (id, data) => {
  return {
    type: actionTypes.DELETE_RECYCLING_DUMPSTER,
    id,
    data
  };
};

export const deleteRecyclingDumpsterSuccess = () => {
  return {
    type: actionTypes.DELETE_RECYCLING_DUMPSTER_SUCCESS
  };
};

export const deleteRecyclingDumpsterFail = () => {
  return {
    type: actionTypes.DELETE_RECYCLING_DUMPSTER_FAIL
  };
};
/* ===== END RECYCLING DUMPSTER ===== */