import { all, fork, takeEvery, put } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions/index";
//import * as API from "../api";
//import { string } from "prop-types";

export function* handleAPIError() {
  yield takeEvery(actionTypes.HANDLE_API_ERROR, function*(action) {
    let error = action.error;

    if (error.response && error.response.status) {

      if(error.response.data.duplicate){
        console.log("Handle api error: Duplicated");

      }else if (error.response.status !== 401) {

        if(typeof error.response.data.message !== "string")
          yield put(actions.fieldErrorHandle( error.response.data.message ));

        else{

          // alert(
          //   "Error: " +
          //     (action.message !== "" ? action.message + "\n" : "") +
          //     API.constructErrMsg(action.error)
          // );
          yield put(actions.toastError(error.response.data.message));
          
        }
      }else{
        
        yield put(actions.toastError(error.response.data.message));
      }
    } else {
      console.log("Handle api error:", error);
    }
    yield put(actions.disableLoading());
  });
}
// Combine all sage
export default function* appSaga() {
  yield all([fork(handleAPIError)]);
}
