import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  adminList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  adminDetail:{},
  privilegesList: [],
  adminRoles: [],
  isUpdateAdminStatusSuccess: false,
  isDeleteAdminSuccess: false,
  isSetPrivilegesSuccess: false,
  isCreateRoleSuccess: false,
  isRemoveRoleSuccess: false,
  isUpdateRoleSuccess: false,
  isChangePassSuccess: false,
  isUpdateDetailSuccess: false,
  isAdminEmergencyContactSetSuccess: false
};

export default function AdminReducer(state = initState, action) {

  switch (action.type) {
    case actionTypes.LOAD_ADMIN_LIST: {
      let adminList = cloneDeep(state.adminList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        adminList.offset = action.data.offset;
        adminList.limit = action.data.limit;
      }

      return {
        ...state,
        adminList
      };
    }

    case actionTypes.LOAD_ADMIN_LIST_SUCCESS: {
      let adminList = cloneDeep(state.adminList);
      adminList.data = action.data.data;
      adminList.meta = {
        page: 1 + state.adminList.offset / state.adminList.limit,
        pageSize: state.adminList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.adminList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        adminList
      };
    }

    case actionTypes.LOAD_ADMIN_LIST_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.LOAD_ADMIN_DETAIL: {
      
      return {
        ...state,
        adminDetail: {}
      };
    }

    case actionTypes.LOAD_ADMIN_DETAIL_SUCCESS: {
      return {
        ...state,
        adminDetail: action.data
      };
    }

    case actionTypes.LOAD_ADMIN_DETAIL_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.EDIT_ADMIN_STATUS: {
      return {
        ...state,
        isUpdateAdminStatusSuccess: false
      };
    }

    case actionTypes.EDIT_ADMIN_STATUS_SUCCESS: {
      return {
        ...state,
        isUpdateAdminStatusSuccess: true
      };
    }

    case actionTypes.EDIT_ADMIN_STATUS_FAIL: {
      return {
        ...state,
        isUpdateAdminStatusSuccess: false
      };
    }

    case actionTypes.DELETE_ADMIN: {
      return {
        ...state,
        isDeleteAdminSuccess: false
      };
    }

    case actionTypes.DELETE_ADMIN_SUCCESS: {
      return {
        ...state,
        isDeleteAdminSuccess: true
      };
    }

    case actionTypes.DELETE_ADMIN_FAIL: {
      return {
        ...state,
        isDeleteAdminSuccess: false
      };
    }

    

    case actionTypes.ADMIN_CHANGE_PASSWORD: {
      return {
        ...state,
        isChangePassSuccess: false
      };
    }

    case actionTypes.ADMIN_CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        isChangePassSuccess: true
      };
    }

    case actionTypes.ADMIN_CHANGE_PASSWORD_FAIL: {
      return {
        ...state,
        isChangePassSuccess: false
      };
    }
    

    case actionTypes.ADMIN_UPDATE_DETAIL: {
      return {
        ...state,
        isUpdateDetailSuccess: false
      };
    }

    case actionTypes.ADMIN_UPDATE_DETAIL_SUCCESS: {
      return {
        ...state,
        isUpdateDetailSuccess: true
      };
    }

    case actionTypes.ADMIN_UPDATE_DETAIL_FAIL: {
      return {
        ...state,
        isUpdateDetailSuccess: false
      };
    }
    
    

    case actionTypes.SER_ADMIN_EMERGENCY_CONTACT: {
      return {
        ...state,
        isAdminEmergencyContactSetSuccess: false
      };
    }

    case actionTypes.SER_ADMIN_EMERGENCY_CONTACT_SUCCESS: {
      return {
        ...state,
        isAdminEmergencyContactSetSuccess: true
      };
    }

    case actionTypes.SER_ADMIN_EMERGENCY_CONTACT_FAIL: {
      return {
        ...state,
        isAdminEmergencyContactSetSuccess: false
      };
    }
    
    
    
    /* ===== ADMIN PRIVILEGES ===== */
    
    case actionTypes.LOAD_ADMIN_PRIVILEGES_LIST: {
      let privilegesList = cloneDeep(state.privilegesList);
     
      return {
        ...state,
        privilegesList
      };
    }

    case actionTypes.LOAD_ADMIN_PRIVILEGES_LIST_SUCCESS: {
   
      return {
        ...state,
        privilegesList: action.data
      };
    }

    case actionTypes.LOAD_ADMIN_PRIVILEGES_LIST_FAIL: {
      return {
        ...state
      };
    }

    

    case actionTypes.CREATE_ADMIN_ROLE: {
      return {
        ...state,
        isCreateRoleSuccess: false
      };
    }

    case actionTypes.CREATE_ADMIN_ROLE_SUCCESS: {
      return {
        ...state,
        isCreateRoleSuccess: true
      };
    }

    case actionTypes.CREATE_ADMIN_ROLE_FAIL: {
      return {
        ...state,
        isCreateRoleSuccess: false
      };
    }

    

    case actionTypes.DELETE_ADMIN_ROLE: {
      return {
        ...state,
        isRemoveRoleSuccess: false
      };
    }

    case actionTypes.DELETE_ADMIN_ROLE_SUCCESS: {
      return {
        ...state,
        isRemoveRoleSuccess: true
      };
    }

    case actionTypes.DELETE_ADMIN_ROLE_FAIL: {
      return {
        ...state,
        isRemoveRoleSuccess: false
      };
    }

    

    case actionTypes.UPDATE_ADMIN_ROLE: {
      return {
        ...state,
        isUpdateRoleSuccess: false
      };
    }

    case actionTypes.UPDATE_ADMIN_ROLE_SUCCESS: {
      return {
        ...state,
        isUpdateRoleSuccess: true
      };
    }

    case actionTypes.UPDATE_ADMIN_ROLE_FAIL: {
      return {
        ...state,
        isUpdateRoleSuccess: false
      };
    }

    


    case actionTypes.SET_ADMIN_PRIVILEGES: {
      return {
        ...state,
        isSetPrivilegesSuccess: false
      };
    }

    case actionTypes.SET_ADMIN_PRIVILEGES_SUCCESS: {
      return {
        ...state,
        isSetPrivilegesSuccess: true
      };
    }

    case actionTypes.SET_ADMIN_PRIVILEGES_FAIL: {
      return {
        ...state,
        isSetPrivilegesSuccess: false
      };
    }


    case actionTypes.LOAD_ADMIN_ROLE: {
      let adminRoles = cloneDeep(state.adminRoles);
     
      return {
        ...state,
        adminRoles
      };
    }

    case actionTypes.LOAD_ADMIN_ROLE_SUCCESS: {
   
      return {
        ...state,
        adminRoles: action.data
      };
    }

    case actionTypes.LOAD_ADMIN_ROLE_FAIL: {
      return {
        ...state
      };
    }

    

    default:
      return state;
  }
}
