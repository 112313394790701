import * as actionTypes from "./actionTypes";


export const loadExpressRecyclingList = data => {
  
  return {
    type: actionTypes.LOAD_EXPRESS_RECYCLING_LIST,
    data
  };
};

export const loadExpressRecyclingListSuccess = data => {
  return {
    type: actionTypes.LOAD_EXPRESS_RECYCLING_LIST_SUCCESS,
    data
  };
};

export const loadExpressRecyclingListFail = () => {
  return {
    type: actionTypes.LOAD_EXPRESS_RECYCLING_LIST_FAIL
  };
};


export const loadExpressRecyclingListAll = data => {
  
  return {
    type: actionTypes.LOAD_EXPRESS_RECYCLING_LIST_ALL,
    data
  };
};

export const loadExpressRecyclingListAllSuccess = data => {
  return {
    type: actionTypes.LOAD_EXPRESS_RECYCLING_LIST_ALL_SUCCESS,
    data
  };
};

export const loadExpressRecyclingListAllFail = () => {
  return {
    type: actionTypes.LOAD_EXPRESS_RECYCLING_LIST_ALL_FAIL
  };
};

export const setExpressRecyclingSequence = (id, data) => {
  return {
    type: actionTypes.SET_EXPRESS_RECYCLING_SEQUENCE,
    id,
    data
  };
};

export const setExpressRecyclingSequenceSuccess = () => {
  return {
    type: actionTypes.SET_EXPRESS_RECYCLING_SEQUENCE_SUCCESS
  };
};

export const setExpressRecyclingSequenceFail = () => {
  return {
    type: actionTypes.SET_EXPRESS_RECYCLING_SEQUENCE_FAIL
  };
};


export const loadExpressRecyclingDetail = (id, data) => {
  
  return {
    type: actionTypes.LOAD_EXPRESS_RECYCLING_DETAIL,
    id,
    data
  };
};

export const loadExpressRecyclingDetailSuccess = data => {
  return {
    type: actionTypes.LOAD_EXPRESS_RECYCLING_DETAIL_SUCCESS,
    data
  };
};

export const loadExpressRecyclingDetailFail = () => {
  return {
    type: actionTypes.LOAD_EXPRESS_RECYCLING_DETAIL_FAIL
  };
};

export const loadExpressRecyclingMixList = data => {
  
  return {
    type: actionTypes.LOAD_EXPRESS_RECYCLING_MIX_LIST,
    data
  };
};

export const loadExpressRecyclingMixListSuccess = data => {
  return {
    type: actionTypes.LOAD_EXPRESS_RECYCLING_MIX_LIST_SUCCESS,
    data
  };
};

export const loadExpressRecyclingMixListFail = () => {
  return {
    type: actionTypes.LOAD_EXPRESS_RECYCLING_MIX_LIST_FAIL
  };
};



export const loadExpressRecyclingEffectiveDateList = data => {

  return {
    type: actionTypes.LOAD_EXPRESS_RECYCLING_EFFECTIVE_DATE_LIST,
    data
  };
};

export const loadExpressRecyclingEffectiveDateListSuccess = data => {
  
  return {
    type: actionTypes.LOAD_EXPRESS_RECYCLING_EFFECTIVE_DATE_LIST_SUCCESS,
    data
  };
};

export const loadExpressRecyclingEffectiveDateListFail = () => {
  return {
    type: actionTypes.LOAD_EXPRESS_RECYCLING_EFFECTIVE_DATE_LIST_FAIL
  };
};




export const expressRecyclingPriceControlLoad = (data) => {
  return {
    type: actionTypes.EXPRESS_RECYCLING_PRICE_CONTROL_LOAD,
    data
  };
};

export const expressRecyclingPriceControlLoadSuccess = (data) => {
  return {
    type: actionTypes.EXPRESS_RECYCLING_PRICE_CONTROL_LOAD_SUCCESS,
    data
  };
};

export const expressRecyclingPriceControlLoadFail = () => {
  return {
    type: actionTypes.EXPRESS_RECYCLING_PRICE_CONTROL_LOAD_FAIL
  };
};

export const expressRecyclingPriceControlSet = (data,id) => {
  return {
    type: actionTypes.EXPRESS_RECYCLING_PRICE_CONTROL_SET,
    id,
    data
  };
};

export const expressRecyclingPriceControlSetSuccess = () => {
  return {
    type: actionTypes.EXPRESS_RECYCLING_PRICE_CONTROL_SET_SUCCESS
  };
};

export const expressRecyclingPriceControlSetFail = () => {
  return {
    type: actionTypes.EXPRESS_RECYCLING_PRICE_CONTROL_SET_FAIL
  };
};


export const deleteExpressRecycling = (id,data) => {
  return {
    type: actionTypes.DELETE_EXPRESS_RECYCLING,
    id,
    data
  };
};

export const deleteExpressRecyclingSuccess = (data) => {
  return {
    type: actionTypes.DELETE_EXPRESS_RECYCLING_SUCCESS,
    data
  };
};

export const deleteExpressRecyclingFail = () => {
  return {
    type: actionTypes.DELETE_EXPRESS_RECYCLING_FAIL
  };
};






export const getExpressRecyclingPricePDF = (data) => {
  return {
    type: actionTypes.GET_EXPRESS_RECYCLING_PRICE_PDF,
    data,
  };
};

export const getExpressRecyclingPricePDFSuccess = data => {
  return {
    type: actionTypes.GET_EXPRESS_RECYCLING_PRICE_PDF_SUCCESS,
    data
  };
};

export const getExpressRecyclingPricePDFFail = () => {
  return {
    type: actionTypes.GET_EXPRESS_RECYCLING_PRICE_PDF_FAIL
  };
};



export const getExpressRecyclingPricePostPDF = (data) => {
  return {
    type: actionTypes.GET_EXPRESS_RECYCLING_PRICE_POST_PDF,
    data,
  };
};

export const getExpressRecyclingPricePostPDFSuccess = data => {
  return {
    type: actionTypes.GET_EXPRESS_RECYCLING_PRICE_POST_PDF_SUCCESS,
    data
  };
};

export const getExpressRecyclingPricePostPDFFail = () => {
  return {
    type: actionTypes.GET_EXPRESS_RECYCLING_PRICE_POST_PDF_FAIL
  };
};


export const loadExpressRecyclingSalesPriceUpdHistory = (data) => {
  return {
    type: actionTypes.EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST,
    data,
  };
};

export const loadExpressRecyclingSalesPriceUpdHistorySuccess = data => {
  return {
    type: actionTypes.EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST_SUCCESS,
    data
  };
};

export const loadExpressRecyclingSalesPriceUpdHistoryFail = () => {
  return {
    type: actionTypes.EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST_FAIL
  };
};


export const countExpressRecyclingSalesPriceUpdHistory = (data) => {
  return {
    type: actionTypes.EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT,
    data,
  };
};

export const countExpressRecyclingSalesPriceUpdHistorySuccess = data => {
  return {
    type: actionTypes.EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT_SUCCESS,
    data
  };
};

export const countExpressRecyclingSalesPriceUpdHistoryFail = () => {
  return {
    type: actionTypes.EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT_FAIL
  };
};

export const getExpressRecyclingSalesPriceUpdHistoryDetail = (id, data) => {
  return {
    type: actionTypes.EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL,
    id,
    data,
  };
};

export const getExpressRecyclingSalesPriceUpdHistoryDetailSuccess = data => {
  return {
    type: actionTypes.EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL_SUCCESS,
    data
  };
};

export const getExpressRecyclingSalesPriceUpdHistoryDetailFail = () => {
  return {
    type: actionTypes.EXPRESS_RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL_FAIL
  };
};
