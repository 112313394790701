import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  scheduledWasteList: [],
  isScheduledWasteCategoryCreateSuccess: false,
  isScheduledWasteCategoryCreateFail: false,
  isScheduledWasteCategoryUpdateSuccess: false,
  isScheduledWasteCategoryUpdateFail: false,
  isScheduledWasteCategoryDeleteSuccess: false,
  isScheduledWasteCategorySetItemSuccess: false,
  isScheduledWasteCategorySetItemFail: false,
  scheduledWastePriceEffectiveList: [],
  scheduledWastePriceList: [],
  scheduledWastePricePDF: null,
  isScheduledWastePriceSetSuccess: false,
  
  scheduledWasteLorryPriceEffectiveList: [],
  scheduledWasteLorryPriceList: [],
  scheduledWasteLorryPricePDF: null,
  isScheduledWasteLorryPriceSetSuccess: false,

  recoveryCompanyList: [],
  recoveryCompanyDetail: {}, 
  isRecoveryCompanyCreateSuccess: false,
  isRecoveryCompanyUpdateDetailSuccess: false,
  isRecoveryCompanyUpdateLicenseSuccess: false,
  isRecoveryCompanyRemoveSuccess: false,
  DOERegistrationList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  DOERegistrationCount: {},
  DOERegistrationDetail: {},
  isRegistrationApproveSuccess: false,
  isRegistrationRejectSuccess: false,
  containerList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  isContainerCreateSuccess: false,
  isContainerCreateFail: false,
  isContainerUpdateSuccess: false,
  isContainerUpdateFail: false,
  isContainerRemoveSuccess: false,
  isScheduledWasteProcessCostSetSuccess: false,
};

export default function scheduledWasteReducer(state = initState, action) {

  switch (action.type) {

    
    case actionTypes.LOAD_SCHEDULED_WASTE_LIST: {
      
      return {
        ...state,
        scheduledWasteList:[],
      };
    }

    case actionTypes.LOAD_SCHEDULED_WASTE_LIST_SUCCESS: {
  
      return {
        ...state,
        scheduledWasteList: action.data,
      };
    }

    case actionTypes.LOAD_SCHEDULED_WASTE_LIST_FAIL: {
      return {
        ...state,
      };
    }


    /** WASTE CATEGORY */
        
    case actionTypes.SCHEDULED_WASTE_CATEGORY_CREATE: {
      return {
        ...state,
        isScheduledWasteCategoryCreateSuccess: false,
        isScheduledWasteCategoryCreateFail: false
      };
    }

    case actionTypes.SCHEDULED_WASTE_CATEGORY_CREATE_SUCCESS: {
      return {
        ...state,
        isScheduledWasteCategoryCreateSuccess: true
      };
    }

    case actionTypes.SCHEDULED_WASTE_CATEGORY_CREATE_FAIL: {
      return {
        ...state,
        isScheduledWasteCategoryCreateFail: true
      };
    }


    case actionTypes.SCHEDULED_WASTE_CATEGORY_UPDATE: {
      return {
        ...state,
        isScheduledWasteCategoryUpdateSuccess: false,
        isScheduledWasteCategoryUpdateFail: false,
      };
    }

    case actionTypes.SCHEDULED_WASTE_CATEGORY_UPDATE_SUCCESS: {
      return {
        ...state,
        isScheduledWasteCategoryUpdateSuccess: true
      };
    }

    case actionTypes.SCHEDULED_WASTE_CATEGORY_UPDATE_FAIL: {
      return {
        ...state,
        isScheduledWasteCategoryUpdateFail: true
      };
    }


    case actionTypes.SCHEDULED_WASTE_CATEGORY_DELETE: {
      return {
        ...state,
        isScheduledWasteCategoryDeleteSuccess: false
      };
    }

    case actionTypes.SCHEDULED_WASTE_CATEGORY_DELETE_SUCCESS: {
      return {
        ...state,
        isScheduledWasteCategoryDeleteSuccess: true
      };
    }

    case actionTypes.SCHEDULED_WASTE_CATEGORY_DELETE_FAIL: {
      return {
        ...state,
        isScheduledWasteCategoryDeleteSuccess: false
      };
    }


    case actionTypes.SCHEDULED_WASTE_CATEGORY_SET_ITEM: {
      return {
        ...state,
        isScheduledWasteCategorySetItemSuccess: false,
        isScheduledWasteCategorySetItemFail: false
      };
    }

    case actionTypes.SCHEDULED_WASTE_CATEGORY_SET_ITEM_SUCCESS: {
      return {
        ...state,
        isScheduledWasteCategorySetItemSuccess: true
      };
    }

    case actionTypes.SCHEDULED_WASTE_CATEGORY_SET_ITEM_FAIL: {
      return {
        ...state,
        isScheduledWasteCategorySetItemFail: true
      };
    }

    /** WASTE CATEGORY END*/


    /** WASTE PRICE */
    
    case actionTypes.SCHEDULED_WASTE_PRICE_EFFECTIVE_LIST: {
      
      return {
        ...state,
        scheduledWastePriceEffectiveList:[],
      };
    }

    case actionTypes.SCHEDULED_WASTE_PRICE_EFFECTIVE_LIST_SUCCESS: {
  
      return {
        ...state,
        scheduledWastePriceEffectiveList: action.data,
      };
    }

    case actionTypes.SCHEDULED_WASTE_PRICE_EFFECTIVE_LIST_FAIL: {
      return {
        ...state,
      };
    }
    
    case actionTypes.SCHEDULED_WASTE_PRICE_LIST: {
      
      return {
        ...state,
        scheduledWastePriceList:[],
      };
    }

    case actionTypes.SCHEDULED_WASTE_PRICE_LIST_SUCCESS: {
  
      return {
        ...state,
        scheduledWastePriceList: action.data,
      };
    }

    case actionTypes.SCHEDULED_WASTE_PRICE_LIST_FAIL: {
      return {
        ...state,
      };
    }
    
    case actionTypes.SCHEDULED_WASTE_PRICE_LIST_PDF: {
      
      return {
        ...state,
        scheduledWastePricePDF: "",
      };
    }

    case actionTypes.SCHEDULED_WASTE_PRICE_LIST_PDF_SUCCESS: {
  
      return {
        ...state,
        scheduledWastePricePDF: action.data,
      };
    }

    case actionTypes.SCHEDULED_WASTE_PRICE_LIST_PDF_FAIL: {
      return {
        ...state,
      };
    }


    case actionTypes.SCHEDULED_WASTE_PRICE_SET: {
      return {
        ...state,
        isScheduledWastePriceSetSuccess: false
      };
    }

    case actionTypes.SCHEDULED_WASTE_PRICE_SET_SUCCESS: {
      return {
        ...state,
        isScheduledWastePriceSetSuccess: true
      };
    }

    case actionTypes.SCHEDULED_WASTE_PRICE_SET_FAIL: {
      return {
        ...state,
        isScheduledWastePriceSetSuccess: false
      };
    }

    /** WASTE PRICE END*/


    /** WASTE PRICE */
    
    case actionTypes.SCHEDULED_WASTE_LORRY_PRICE_EFFECTIVE_LIST: {
      
      return {
        ...state,
        scheduledWasteLorryPriceEffectiveList:[],
      };
    }

    case actionTypes.SCHEDULED_WASTE_LORRY_PRICE_EFFECTIVE_LIST_SUCCESS: {
  
      return {
        ...state,
        scheduledWasteLorryPriceEffectiveList: action.data,
      };
    }

    case actionTypes.SCHEDULED_WASTE_LORRY_PRICE_EFFECTIVE_LIST_FAIL: {
      return {
        ...state,
      };
    }
    
    case actionTypes.SCHEDULED_WASTE_LORRY_PRICE_LIST: {
      
      return {
        ...state,
        scheduledWasteLorryPriceList:[],
      };
    }

    case actionTypes.SCHEDULED_WASTE_LORRY_PRICE_LIST_SUCCESS: {
  
      return {
        ...state,
        scheduledWasteLorryPriceList: action.data,
      };
    }

    case actionTypes.SCHEDULED_WASTE_LORRY_PRICE_LIST_FAIL: {
      return {
        ...state,
      };
    }
    
    case actionTypes.SCHEDULED_WASTE_LORRY_PRICE_LIST_PDF: {
      
      return {
        ...state,
        scheduledWasteLorryPricePDF: "",
      };
    }

    case actionTypes.SCHEDULED_WASTE_LORRY_PRICE_LIST_PDF_SUCCESS: {
  
      return {
        ...state,
        scheduledWasteLorryPricePDF: action.data,
      };
    }

    case actionTypes.SCHEDULED_WASTE_LORRY_PRICE_LIST_PDF_FAIL: {
      return {
        ...state,
      };
    }


    case actionTypes.SCHEDULED_WASTE_LORRY_PRICE_SET: {
      return {
        ...state,
        isScheduledWasteLorryPriceSetSuccess: false
      };
    }

    case actionTypes.SCHEDULED_WASTE_LORRY_PRICE_SET_SUCCESS: {
      return {
        ...state,
        isScheduledWasteLorryPriceSetSuccess: true
      };
    }

    case actionTypes.SCHEDULED_WASTE_LORRY_PRICE_SET_FAIL: {
      return {
        ...state,
        isScheduledWasteLorryPriceSetSuccess: false
      };
    }

    

    case actionTypes.SCHEDULED_PROCESS_COST_SET: {
      return {
        ...state,
        isScheduledWasteProcessCostSetSuccess: false
      };
    }

    case actionTypes.SCHEDULED_PROCESS_COST_SET_SUCCESS: {
      return {
        ...state,
        isScheduledWasteProcessCostSetSuccess: true
      };
    }

    case actionTypes.SCHEDULED_PROCESS_COST_SET_FAIL: {
      return {
        ...state,
        isScheduledWasteProcessCostSetSuccess: false
      };
    }
    
    /** WASTE PRICE END*/

        
    /** RECOVERY COMPANY */
    
    
    case actionTypes.LOAD_RECOVERY_COMPANY_LIST: {
      
      return {
        ...state,
        recoveryCompanyList:[],
      };
    }

    case actionTypes.LOAD_RECOVERY_COMPANY_LIST_SUCCESS: {
  
      return {
        ...state,
        recoveryCompanyList: action.data,
      };
    }

    case actionTypes.LOAD_RECOVERY_COMPANY_LIST_FAIL: {
      return {
        ...state,
      };
    }

    case actionTypes.LOAD_RECOVERY_COMPANY_DETAIL: {
      
      return {
        ...state,
        recoveryCompanyDetail:{},
      };
    }

    case actionTypes.LOAD_RECOVERY_COMPANY_DETAIL_SUCCESS: {
  
      return {
        ...state,
        recoveryCompanyDetail: action.data,
      };
    }

    case actionTypes.LOAD_RECOVERY_COMPANY_DETAIL_FAIL: {
      return {
        ...state,
      };
    }
    

    case actionTypes.RECOVERY_COMPANY_CREATE: {
      
      return {
        ...state,
        isRecoveryCompanyCreateSuccess: false,
      };
    }

    case actionTypes.RECOVERY_COMPANY_CREATE_SUCCESS: {
  
      return {
        ...state,
        isRecoveryCompanyCreateSuccess: true,
      };
    }

    case actionTypes.RECOVERY_COMPANY_CREATE_FAIL: {
      return {
        ...state,
        isRecoveryCompanyCreateSuccess: false,
      };
    }

    case actionTypes.RECOVERY_COMPANY_UPDATE_DETAIL: {
      
      return {
        ...state,
        isRecoveryCompanyUpdateDetailSuccess: false,
      };
    }

    case actionTypes.RECOVERY_COMPANY_UPDATE_DETAIL_SUCCESS: {
  
      return {
        ...state,
        isRecoveryCompanyUpdateDetailSuccess: true,
      };
    }

    case actionTypes.RECOVERY_COMPANY_UPDATE_DETAIL_FAIL: {
      return {
        ...state,
        isRecoveryCompanyUpdateDetailSuccess: false,
      };
    }

    case actionTypes.RECOVERY_COMPANY_UPDATE_LICENSE: {
      
      return {
        ...state,
        isRecoveryCompanyUpdateLicenseSuccess: false,
      };
    }

    case actionTypes.RECOVERY_COMPANY_UPDATE_LICENSE_SUCCESS: {
  
      return {
        ...state,
        isRecoveryCompanyUpdateLicenseSuccess: true,
      };
    }

    case actionTypes.RECOVERY_COMPANY_UPDATE_LICENSE_FAIL: {
      return {
        ...state,
        isRecoveryCompanyUpdateLicenseSuccess: false,
      };
    }


    case actionTypes.RECOVERY_COMPANY_DELETE: {
      
      return {
        ...state,
        isRecoveryCompanyRemoveSuccess: false,
      };
    }

    case actionTypes.RECOVERY_COMPANY_DELETE_SUCCESS: {
  
      return {
        ...state,
        isRecoveryCompanyRemoveSuccess: true,
      };
    }

    case actionTypes.RECOVERY_COMPANY_DELETE_FAIL: {
      return {
        ...state,
        isRecoveryCompanyRemoveSuccess: false,
      };
    }
    /** RECOVERY COMPANY END*/

    /* ===== SCHEDULED DOE REGISTRATION ===== */
    case actionTypes.LOAD_DOE_REGISTRATION_LIST: {
      let DOERegistrationList = cloneDeep(state.DOERegistrationList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        DOERegistrationList.offset = action.data.offset;
        DOERegistrationList.limit = action.data.limit;
      }

      return {
        ...state,
        DOERegistrationList
      };
    }

    case actionTypes.LOAD_DOE_REGISTRATION_LIST_SUCCESS: {
      let DOERegistrationList = cloneDeep(state.DOERegistrationList);
      DOERegistrationList.data = action.data.data;
      DOERegistrationList.meta = {
        page: 1 + state.DOERegistrationList.offset / state.DOERegistrationList.limit,
        pageSize: state.DOERegistrationList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.DOERegistrationList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        DOERegistrationList
      };
    }

    case actionTypes.LOAD_DOE_REGISTRATION_LIST_FAIL: {
      return {
        ...state
      };
    }
    

    case actionTypes.LOAD_DOE_REGISTRATION_COUNT: {
      let DOERegistrationCount = {};
      
      return {
        ...state,
        DOERegistrationCount
      };
    }

    case actionTypes.LOAD_DOE_REGISTRATION_COUNT_SUCCESS: {
      return {
        ...state,
        DOERegistrationCount: action.data
      };
    }

    case actionTypes.LOAD_DOE_REGISTRATION_COUNT_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.LOAD_DOE_REGISTRATION_DETAIL: {
      let DOERegistrationDetail = {};
      
      return {
        ...state,
        DOERegistrationDetail
      };
    }

    case actionTypes.LOAD_DOE_REGISTRATION_DETAIL_SUCCESS: {
      return {
        ...state,
        DOERegistrationDetail: action.data
      };
    }

    case actionTypes.LOAD_DOE_REGISTRATION_DETAIL_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.DOE_REGISTRATION_APPROVE: {
      
      return {
        ...state,
        isRegistrationApproveSuccess: false,
      };
    }

    case actionTypes.DOE_REGISTRATION_APPROVE_SUCCESS: {
  
      return {
        ...state,
        isRegistrationApproveSuccess: true,
      };
    }

    case actionTypes.DOE_REGISTRATION_APPROVE_FAIL: {
      return {
        ...state,
        isRegistrationApproveSuccess: false,
      };
    }

    case actionTypes.DOE_REGISTRATION_REJECT: {
      
      return {
        ...state,
        isRegistrationRejectSuccess: false,
      };
    }

    case actionTypes.DOE_REGISTRATION_REJECT_SUCCESS: {
  
      return {
        ...state,
        isRegistrationRejectSuccess: true,
      };
    }

    case actionTypes.DOE_REGISTRATION_REJECT_FAIL: {
      return {
        ...state,
        isRegistrationRejectSuccess: false,
      };
    }
    /* ===== END SCHEDULED DOE REGISTRATION ===== */


        
    /* ===== SCHEDULED WASTE CONTAINER ===== */

    case actionTypes.LOAD_SCHEDULED_WASTE_CONTAINER_LIST: {
      let containerList = cloneDeep(state.containerList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        containerList.offset = action.data.offset;
        containerList.limit = action.data.limit;
      }

      return {
        ...state,
        containerList
      };
    }

    case actionTypes.LOAD_SCHEDULED_WASTE_CONTAINER_LIST_SUCCESS: {
      let containerList = cloneDeep(state.containerList);
      containerList.data = action.data.data;
      containerList.meta = {
        page: 1 + state.containerList.offset / state.containerList.limit,
        pageSize: state.containerList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.containerList.limit
        ),
        total: action.data.recordsTotal
      };
      
      containerList.extra = {
        total_waiting_accept: action.data.total_waiting_accept,
        total_waiting_payment: action.data.total_waiting_payment,
        total_confirm: action.data.total_confirm,
        total_complete: action.data.total_complete,
        total_cancel_user: action.data.total_cancel_user,
        total_cancel_system: action.data.total_cancel_system
      };

      return {
        ...state,
        containerList
      };
    }

    case actionTypes.LOAD_SCHEDULED_WASTE_CONTAINER_LIST_FAIL: {
      return {
        ...state
      };
    }


    case actionTypes.CREATE_SCHEDULED_WASTE_CONTAINER: {
      
      return {
        ...state,
        isContainerCreateSuccess: false,
        isContainerCreateFail: false
      };
    }

    case actionTypes.CREATE_SCHEDULED_WASTE_CONTAINER_SUCCESS: {
  
      return {
        ...state,
        isContainerCreateSuccess: true,
      };
    }

    case actionTypes.CREATE_SCHEDULED_WASTE_CONTAINER_FAIL: {
      return {
        ...state,
        isContainerCreateFail: true,
      };
    }


    case actionTypes.UPDATE_SCHEDULED_WASTE_CONTAINER: {
      
      return {
        ...state,
        isContainerUpdateSuccess: false,
        isContainerUpdateFail: false
      };
    }

    case actionTypes.UPDATE_SCHEDULED_WASTE_CONTAINER_SUCCESS: {
  
      return {
        ...state,
        isContainerUpdateSuccess: true,
      };
    }

    case actionTypes.UPDATE_SCHEDULED_WASTE_CONTAINER_FAIL: {
      return {
        ...state,
        isContainerUpdateFail: true,
      };
    }

    case actionTypes.REMOVE_SCHEDULED_WASTE_CONTAINER: {
      
      return {
        ...state,
        isContainerRemoveSuccess: false,
      };
    }

    case actionTypes.REMOVE_SCHEDULED_WASTE_CONTAINER_SUCCESS: {
  
      return {
        ...state,
        isContainerRemoveSuccess: true,
      };
    }

    case actionTypes.REMOVE_SCHEDULED_WASTE_CONTAINER_FAIL: {
      return {
        ...state,
        isContainerRemoveSuccess: false,
      };
    }
    /* ===== END SCHEDULED WASTE CONTAINER ===== */


    default:
      return state;
  }
}
