import * as actionTypes from "./actionTypes";


export const loadScheduledWasteList = data => {
  
  return {
    type: actionTypes.LOAD_SCHEDULED_WASTE_LIST,
    data
  };
};

export const loadScheduledWasteListSuccess = data => {
  return {
    type: actionTypes.LOAD_SCHEDULED_WASTE_LIST_SUCCESS,
    data
  };
};

export const loadScheduledWasteListFail = () => {
  return {
    type: actionTypes.LOAD_SCHEDULED_WASTE_LIST_FAIL
  };
};

/** WASTE CATEGORY */
export const addScheduledWasteCategory = data => {
  return {
    type: actionTypes.SCHEDULED_WASTE_CATEGORY_CREATE,
    data
  };
};

export const addScheduledWasteCategorySuccess = () => {
  return {
    type: actionTypes.SCHEDULED_WASTE_CATEGORY_CREATE_SUCCESS
  };
};

export const addScheduledWasteCategoryFail = () => {
  return {
    type: actionTypes.SCHEDULED_WASTE_CATEGORY_CREATE_FAIL
  };
};


export const editScheduledWasteCategory = (id,data) => {
  return {
    type: actionTypes.SCHEDULED_WASTE_CATEGORY_UPDATE,
    id,
    data
  };
};

export const editScheduledWasteCategorySuccess = () => {
  return {
    type: actionTypes.SCHEDULED_WASTE_CATEGORY_UPDATE_SUCCESS
  };
};

export const editScheduledWasteCategoryFail = () => {
  return {
    type: actionTypes.SCHEDULED_WASTE_CATEGORY_UPDATE_FAIL
  };
};

export const deleteScheduledWasteCategory = (id, data) => {
  return {
    type: actionTypes.SCHEDULED_WASTE_CATEGORY_DELETE,
    id,
    data
  };
};

export const deleteScheduledWasteCategorySuccess = () => {
  return {
    type: actionTypes.SCHEDULED_WASTE_CATEGORY_DELETE_SUCCESS
  };
};

export const deleteScheduledWasteCategoryFail = () => {
  return {
    type: actionTypes.SCHEDULED_WASTE_CATEGORY_DELETE_FAIL
  };
};


export const scheduledWasteCategorySetItem = (id,data) => {
  return {
    type: actionTypes.SCHEDULED_WASTE_CATEGORY_SET_ITEM,
    id,
    data
  };
};

export const scheduledWasteCategorySetItemSuccess = () => {
  return {
    type: actionTypes.SCHEDULED_WASTE_CATEGORY_SET_ITEM_SUCCESS
  };
};

export const scheduledWasteCategorySetItemFail = () => {
  return {
    type: actionTypes.SCHEDULED_WASTE_CATEGORY_SET_ITEM_FAIL
  };
};

/** WASTE CATEGORY END*/


/** WASTE PRICE */
export const loadScheduledWastePriceEffectiveDateList = data => {

  return {
    type: actionTypes.SCHEDULED_WASTE_PRICE_EFFECTIVE_LIST,
    data
  };
};

export const loadScheduledWastePriceEffectiveDateListSuccess = data => {
  
  return {
    type: actionTypes.SCHEDULED_WASTE_PRICE_EFFECTIVE_LIST_SUCCESS,
    data
  };
};

export const loadScheduledWastePriceEffectiveDateListFail = () => {
  return {
    type: actionTypes.SCHEDULED_WASTE_PRICE_EFFECTIVE_LIST_FAIL
  };
};


export const loadScheduledWastePriceList = data => {
  
  return {
    type: actionTypes.SCHEDULED_WASTE_PRICE_LIST,
    data
  };
};

export const loadScheduledWastePriceListSuccess = data => {
  return {
    type: actionTypes.SCHEDULED_WASTE_PRICE_LIST_SUCCESS,
    data
  };
};

export const loadScheduledWastePriceListFail = () => {
  return {
    type: actionTypes.SCHEDULED_WASTE_PRICE_LIST_FAIL
  };
};


export const loadScheduledWastePricePDF = (data) => {
  return {
    type: actionTypes.SCHEDULED_WASTE_PRICE_LIST_PDF,
    data
  };
};

export const loadScheduledWastePricePDFSuccess = (data) => {
  return {
    type: actionTypes.SCHEDULED_WASTE_PRICE_LIST_PDF_SUCCESS,
    data
  };
};

export const loadScheduledWastePricePDFFail = () => {
  return {
    type: actionTypes.SCHEDULED_WASTE_PRICE_LIST_PDF_FAIL
  };
};


export const scheduledWastePriceSet = (data) => {
  return {
    type: actionTypes.SCHEDULED_WASTE_PRICE_SET,
    data
  };
};

export const scheduledWastePriceSetSuccess = () => {
  return {
    type: actionTypes.SCHEDULED_WASTE_PRICE_SET_SUCCESS
  };
};

export const scheduledWastePriceSetFail = () => {
  return {
    type: actionTypes.SCHEDULED_WASTE_PRICE_SET_FAIL
  };
};

/** WASTE PRICE END*/


/** WASTE LORRY PRICE */
export const loadScheduledWasteLorryPriceEffectiveDateList = data => {

  return {
    type: actionTypes.SCHEDULED_WASTE_LORRY_PRICE_EFFECTIVE_LIST,
    data
  };
};

export const loadScheduledWasteLorryPriceEffectiveDateListSuccess = data => {
  
  return {
    type: actionTypes.SCHEDULED_WASTE_LORRY_PRICE_EFFECTIVE_LIST_SUCCESS,
    data
  };
};

export const loadScheduledWasteLorryPriceEffectiveDateListFail = () => {
  return {
    type: actionTypes.SCHEDULED_WASTE_LORRY_PRICE_EFFECTIVE_LIST_FAIL
  };
};


export const loadScheduledWasteLorryPriceList = data => {
  
  return {
    type: actionTypes.SCHEDULED_WASTE_LORRY_PRICE_LIST,
    data
  };
};

export const loadScheduledWasteLorryPriceListSuccess = data => {
  return {
    type: actionTypes.SCHEDULED_WASTE_LORRY_PRICE_LIST_SUCCESS,
    data
  };
};

export const loadScheduledWasteLorryPriceListFail = () => {
  return {
    type: actionTypes.SCHEDULED_WASTE_LORRY_PRICE_LIST_FAIL
  };
};


export const loadScheduledWasteLorryPricePDF = (data) => {
  return {
    type: actionTypes.SCHEDULED_WASTE_LORRY_PRICE_LIST_PDF,
    data
  };
};

export const loadScheduledWasteLorryPricePDFSuccess = (data) => {
  return {
    type: actionTypes.SCHEDULED_WASTE_LORRY_PRICE_LIST_PDF_SUCCESS,
    data
  };
};

export const loadScheduledWasteLorryPricePDFFail = () => {
  return {
    type: actionTypes.SCHEDULED_WASTE_LORRY_PRICE_LIST_PDF_FAIL
  };
};


export const scheduledWasteLorryPriceSet = (data) => {
  return {
    type: actionTypes.SCHEDULED_WASTE_LORRY_PRICE_SET,
    data
  };
};

export const scheduledWasteLorryPriceSetSuccess = () => {
  return {
    type: actionTypes.SCHEDULED_WASTE_LORRY_PRICE_SET_SUCCESS
  };
};

export const scheduledWasteLorryPriceSetFail = () => {
  return {
    type: actionTypes.SCHEDULED_WASTE_LORRY_PRICE_SET_FAIL
  };
};


export const scheduledWasteProcessCostSet = (id, data) => {
  return {
    type: actionTypes.SCHEDULED_PROCESS_COST_SET,
    id,
    data
  };
};

export const scheduledWasteProcessCostSetSuccess = () => {
  return {
    type: actionTypes.SCHEDULED_PROCESS_COST_SET_SUCCESS
  };
};

export const scheduledWasteProcessCostSetFail = () => {
  return {
    type: actionTypes.SCHEDULED_PROCESS_COST_SET_FAIL
  };
};

/** WASTE LORRY PRICE END*/


/** RECOVERY COMPANY */
export const loadRecoveryCompanyList = data => {
  
  return {
    type: actionTypes.LOAD_RECOVERY_COMPANY_LIST,
    data
  };
};

export const loadRecoveryCompanyListSuccess = data => {
  return {
    type: actionTypes.LOAD_RECOVERY_COMPANY_LIST_SUCCESS,
    data
  };
};

export const loadRecoveryCompanyListFail = () => {
  return {
    type: actionTypes.LOAD_RECOVERY_COMPANY_LIST_FAIL
  };
};


export const loadRecoveryCompanyDetail = (id, data) => {
  
  return {
    type: actionTypes.LOAD_RECOVERY_COMPANY_DETAIL,
    id,
    data
  };
};

export const loadRecoveryCompanyDetailSuccess = data => {
  return {
    type: actionTypes.LOAD_RECOVERY_COMPANY_DETAIL_SUCCESS,
    data
  };
};

export const loadRecoveryCompanyDetailFail = () => {
  return {
    type: actionTypes.LOAD_RECOVERY_COMPANY_DETAIL_FAIL
  };
};


export const addRecoveryCompany = data => {
  return {
    type: actionTypes.RECOVERY_COMPANY_CREATE,
    data
  };
};

export const addRecoveryCompanySuccess = () => {
  return {
    type: actionTypes.RECOVERY_COMPANY_CREATE_SUCCESS
  };
};

export const addRecoveryCompanyFail = () => {
  return {
    type: actionTypes.RECOVERY_COMPANY_CREATE_FAIL
  };
};


export const editRecoveryCompanyDetail = (id,data) => {
  return {
    type: actionTypes.RECOVERY_COMPANY_UPDATE_DETAIL,
    id,
    data
  };
};

export const editRecoveryCompanyDetailSuccess = () => {
  return {
    type: actionTypes.RECOVERY_COMPANY_UPDATE_DETAIL_SUCCESS
  };
};

export const editRecoveryCompanyDetailFail = () => {
  return {
    type: actionTypes.RECOVERY_COMPANY_UPDATE_DETAIL_FAIL
  };
};


export const editRecoveryCompanyLicense = (id,data) => {
  return {
    type: actionTypes.RECOVERY_COMPANY_UPDATE_LICENSE,
    id,
    data
  };
};

export const editRecoveryCompanyLicenseSuccess = () => {
  return {
    type: actionTypes.RECOVERY_COMPANY_UPDATE_LICENSE_SUCCESS
  };
};

export const editRecoveryCompanyLicenseFail = () => {
  return {
    type: actionTypes.RECOVERY_COMPANY_UPDATE_LICENSE_FAIL
  };
};

export const deleteRecoveryCompany = (id, data) => {
  return {
    type: actionTypes.RECOVERY_COMPANY_DELETE,
    id,
    data
  };
};

export const deleteRecoveryCompanySuccess = () => {
  return {
    type: actionTypes.RECOVERY_COMPANY_DELETE_SUCCESS
  };
};

export const deleteRecoveryCompanyFail = () => {
  return {
    type: actionTypes.RECOVERY_COMPANY_DELETE_FAIL
  };
};
/** RECOVERY COMPANY END*/



/* ===== SCHEDULED DOE REGISTRATION ===== */
export const loadDOERegistrationList = data => {
  return {
    type: actionTypes.LOAD_DOE_REGISTRATION_LIST,
    data
  };
};

export const loadDOERegistrationListSuccess = data => {
  return {
    type: actionTypes.LOAD_DOE_REGISTRATION_LIST_SUCCESS,
    data
  };
};

export const loadDOERegistrationListFail = () => {
  return {
    type: actionTypes.LOAD_DOE_REGISTRATION_LIST_FAIL
  };
};


export const DOERegistrationCount = (data) => {
  return {
    type: actionTypes.LOAD_DOE_REGISTRATION_COUNT,
    data
  };
};

export const DOERegistrationCountSuccess = data => {
  return {
    type: actionTypes.LOAD_DOE_REGISTRATION_COUNT_SUCCESS,
    data
  };
};

export const DOERegistrationCountFail = () => {
  return {
    type: actionTypes.LOAD_DOE_REGISTRATION_COUNT_FAIL
  };
};

export const loadDOERegistrationDetail = (id, data) => {
  return {
    type: actionTypes.LOAD_DOE_REGISTRATION_DETAIL,
    id,
    data
  };
};

export const loadDOERegistrationDetailSuccess = data => {
  return {
    type: actionTypes.LOAD_DOE_REGISTRATION_DETAIL_SUCCESS,
    data
  };
};

export const loadDOERegistrationDetailFail = () => {
  return {
    type: actionTypes.LOAD_DOE_REGISTRATION_DETAIL_FAIL
  };
};


export const DOERegistrationApprove = (id, data) => {
  return {
    type: actionTypes.DOE_REGISTRATION_APPROVE,
    id,
    data
  };
};

export const DOERegistrationApproveSuccess = () => {
  return {
    type: actionTypes.DOE_REGISTRATION_APPROVE_SUCCESS
  };
};

export const DOERegistrationApproveFail = () => {
  return {
    type: actionTypes.DOE_REGISTRATION_APPROVE_FAIL
  };
};


export const DOERegistrationReject = (id,data) => {
  return {
    type: actionTypes.DOE_REGISTRATION_REJECT,
    id,
    data
  };
};

export const DOERegistrationRejectSuccess = () => {
  return {
    type: actionTypes.DOE_REGISTRATION_REJECT_SUCCESS
  };
};

export const DOERegistrationRejectFail = () => {
  return {
    type: actionTypes.DOE_REGISTRATION_REJECT_FAIL
  };
};
/* ===== END SCHEDULED DOE REGISTRATION ===== */


/* ===== SCHEDULED WASTE CONTAINER ===== */
export const loadScheduledWasteContainerList = (data) => {
  return {
    type: actionTypes.LOAD_SCHEDULED_WASTE_CONTAINER_LIST,
    data
  };
};

export const loadScheduledWasteContainerListSuccess = (data) => {
  return {
    type: actionTypes.LOAD_SCHEDULED_WASTE_CONTAINER_LIST_SUCCESS,
    data
  };
};

export const loadScheduledWasteContainerListFail = () => {
  return {
    type: actionTypes.LOAD_SCHEDULED_WASTE_CONTAINER_LIST_FAIL
  };
};


export const createScheduledWasteContainer = (data) => {
  return {
    type: actionTypes.CREATE_SCHEDULED_WASTE_CONTAINER,
    data
  };
};

export const createScheduledWasteContainerSuccess = () => {
  return {
    type: actionTypes.CREATE_SCHEDULED_WASTE_CONTAINER_SUCCESS
  };
};

export const createScheduledWasteContainerFail = () => {
  return {
    type: actionTypes.CREATE_SCHEDULED_WASTE_CONTAINER_FAIL
  };
};


export const updateScheduledWasteContainer = (id,data) => {
  return {
    type: actionTypes.UPDATE_SCHEDULED_WASTE_CONTAINER,
    id,
    data
  };
};

export const updateScheduledWasteContainerSuccess = () => {
  return {
    type: actionTypes.UPDATE_SCHEDULED_WASTE_CONTAINER_SUCCESS
  };
};

export const updateScheduledWasteContainerFail = () => {
  return {
    type: actionTypes.UPDATE_SCHEDULED_WASTE_CONTAINER_FAIL
  };
};


export const removeScheduledWasteContainer = (id, data) => {
  return {
    type: actionTypes.REMOVE_SCHEDULED_WASTE_CONTAINER,
    id,
    data
  };
};

export const removeScheduledWasteContainerSuccess = () => {
  return {
    type: actionTypes.REMOVE_SCHEDULED_WASTE_CONTAINER_SUCCESS
  };
};

export const removeScheduledWasteContainerFail = () => {
  return {
    type: actionTypes.REMOVE_SCHEDULED_WASTE_CONTAINER_FAIL
  };
};
/* ===== END SCHEDULED WASTE CONTAINER ===== */