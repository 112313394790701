import * as actionTypes from "./actionTypes";


export const countRecyclingNewPrice = data => {
  
  return {
    type: actionTypes.COUNT_NEW_RECYCLING_PRICE,
    data
  };
};

export const countRecyclingNewPriceSuccess = data => {
  return {
    type: actionTypes.COUNT_NEW_RECYCLING_PRICE_SUCCESS,
    data
  };
};

export const countRecyclingNewPriceFail = () => {
  return {
    type: actionTypes.COUNT_NEW_RECYCLING_PRICE_FAIL
  };
};


export const loadRecyclingList = data => {
  
  return {
    type: actionTypes.LOAD_RECYCLING_LIST,
    data
  };
};

export const loadRecyclingListSuccess = data => {
  return {
    type: actionTypes.LOAD_RECYCLING_LIST_SUCCESS,
    data
  };
};

export const loadRecyclingListFail = () => {
  return {
    type: actionTypes.LOAD_RECYCLING_LIST_FAIL
  };
};


export const loadRecyclingListAll = data => {
  
  return {
    type: actionTypes.LOAD_RECYCLING_LIST_ALL,
    data
  };
};

export const loadRecyclingListAllSuccess = data => {
  return {
    type: actionTypes.LOAD_RECYCLING_LIST_ALL_SUCCESS,
    data
  };
};

export const loadRecyclingListAllFail = () => {
  return {
    type: actionTypes.LOAD_RECYCLING_LIST_ALL_FAIL
  };
};

export const setRecyclingSequence = (id, data) => {
  return {
    type: actionTypes.SET_RECYCLING_SEQUENCE,
    id,
    data
  };
};

export const setRecyclingSequenceSuccess = () => {
  return {
    type: actionTypes.SET_RECYCLING_SEQUENCE_SUCCESS
  };
};

export const setRecyclingSequenceFail = () => {
  return {
    type: actionTypes.SET_RECYCLING_SEQUENCE_FAIL
  };
};


export const loadRecyclingDetail = (id, data) => {
  
  return {
    type: actionTypes.LOAD_RECYCLING_DETAIL,
    id,
    data
  };
};

export const loadRecyclingDetailSuccess = data => {
  return {
    type: actionTypes.LOAD_RECYCLING_DETAIL_SUCCESS,
    data
  };
};

export const loadRecyclingDetailFail = () => {
  return {
    type: actionTypes.LOAD_RECYCLING_DETAIL_FAIL
  };
};

export const loadRecyclingMixList = data => {
  
  return {
    type: actionTypes.LOAD_RECYCLING_MIX_LIST,
    data
  };
};

export const loadRecyclingMixListSuccess = data => {
  return {
    type: actionTypes.LOAD_RECYCLING_MIX_LIST_SUCCESS,
    data
  };
};

export const loadRecyclingMixListFail = () => {
  return {
    type: actionTypes.LOAD_RECYCLING_MIX_LIST_FAIL
  };
};


export const loadRecyclingCategory = data => {
  
  return {
    type: actionTypes.LOAD_RECYCLING_CATEGORY,
    data
  };
};

export const loadRecyclingCategorySuccess = data => {
  return {
    type: actionTypes.LOAD_RECYCLING_CATEGORY_SUCCESS,
    data
  };
};

export const loadRecyclingCategoryFail = () => {
  return {
    type: actionTypes.LOAD_RECYCLING_CATEGORY_FAIL
  };
};

export const loadRecyclingCategoryAll = data => {
  
  return {
    type: actionTypes.LOAD_RECYCLING_CATEGORY_ALL,
    data
  };
};

export const loadRecyclingCategoryAllSuccess = data => {
  return {
    type: actionTypes.LOAD_RECYCLING_CATEGORY_ALL_SUCCESS,
    data
  };
};

export const loadRecyclingCategoryAllFail = () => {
  return {
    type: actionTypes.LOAD_RECYCLING_CATEGORY_ALL_FAIL
  };
};


export const addRecyclingCategory = data => {
  return {
    type: actionTypes.ADD_RECYCLING_CATEGORY,
    data
  };
};

export const addRecyclingCategorySuccess = () => {
  return {
    type: actionTypes.ADD_RECYCLING_CATEGORY_SUCCESS
  };
};

export const addRecyclingCategoryFail = () => {
  return {
    type: actionTypes.ADD_RECYCLING_CATEGORY_FAIL
  };
};

export const editRecyclingCategory = (id,data) => {
  return {
    type: actionTypes.EDIT_RECYCLING_CATEGORY,
    id,
    data
  };
};

export const editRecyclingCategorySuccess = () => {
  return {
    type: actionTypes.EDIT_RECYCLING_CATEGORY_SUCCESS
  };
};

export const editRecyclingCategoryFail = () => {
  return {
    type: actionTypes.EDIT_RECYCLING_CATEGORY_FAIL
  };
};

export const deleteRecyclingCategory = (id, data) => {
  return {
    type: actionTypes.DELETE_RECYCLING_CATEGORY,
    id,
    data
  };
};

export const deleteRecyclingCategorySuccess = () => {
  return {
    type: actionTypes.DELETE_RECYCLING_CATEGORY_SUCCESS
  };
};

export const deleteRecyclingCategoryFail = () => {
  return {
    type: actionTypes.DELETE_RECYCLING_CATEGORY_FAIL
  };
};


export const setRecyclingCategorySequence = data => {
  return {
    type: actionTypes.SET_RECYCLING_CATEGORY_SEQUENCE,
    data
  };
};

export const setRecyclingCategorySequenceSuccess = () => {
  return {
    type: actionTypes.SET_RECYCLING_CATEGORY_SEQUENCE_SUCCESS
  };
};

export const setRecyclingCategorySequenceFail = () => {
  return {
    type: actionTypes.SET_RECYCLING_CATEGORY_SEQUENCE_FAIL
  };
};


export const loadRecyclingEffectiveDateList = data => {

  return {
    type: actionTypes.LOAD_RECYCLING_EFFECTIVE_DATE_LIST,
    data
  };
};

export const loadRecyclingEffectiveDateListSuccess = data => {
  
  return {
    type: actionTypes.LOAD_RECYCLING_EFFECTIVE_DATE_LIST_SUCCESS,
    data
  };
};

export const loadRecyclingEffectiveDateListFail = () => {
  return {
    type: actionTypes.LOAD_RECYCLING_EFFECTIVE_DATE_LIST_FAIL
  };
};




export const recyclingPriceControlLoad = (data) => {
  return {
    type: actionTypes.RECYCLING_PRICE_CONTROL_LOAD,
    data
  };
};

export const recyclingPriceControlLoadSuccess = (data) => {
  return {
    type: actionTypes.RECYCLING_PRICE_CONTROL_LOAD_SUCCESS,
    data
  };
};

export const recyclingPriceControlLoadFail = () => {
  return {
    type: actionTypes.RECYCLING_PRICE_CONTROL_LOAD_FAIL
  };
};

export const recyclingPriceControlSet = (data,id) => {
  return {
    type: actionTypes.RECYCLING_PRICE_CONTROL_SET,
    id,
    data
  };
};

export const recyclingPriceControlSetSuccess = () => {
  return {
    type: actionTypes.RECYCLING_PRICE_CONTROL_SET_SUCCESS
  };
};

export const recyclingPriceControlSetFail = () => {
  return {
    type: actionTypes.RECYCLING_PRICE_CONTROL_SET_FAIL
  };
};


export const deleteRecycling = (id,data) => {
  return {
    type: actionTypes.DELETE_RECYCLING,
    id,
    data
  };
};

export const deleteRecyclingSuccess = (data) => {
  return {
    type: actionTypes.DELETE_RECYCLING_SUCCESS,
    data
  };
};

export const deleteRecyclingFail = () => {
  return {
    type: actionTypes.DELETE_RECYCLING_FAIL
  };
};




/** RECYCLING EXTRA FEE **/

export const loadRecyclingExtraFeeList = data => {
  
  return {
    type: actionTypes.LOAD_RECYCLING_EXTRA_FEE_LIST,
    data
  };
};

export const loadRecyclingExtraFeeListSuccess = data => {
  return {
    type: actionTypes.LOAD_RECYCLING_EXTRA_FEE_LIST_SUCCESS,
    data
  };
};

export const loadRecyclingExtraFeeListFail = () => {
  return {
    type: actionTypes.LOAD_RECYCLING_EXTRA_FEE_LIST_FAIL
  };
};


export const addRecyclingExtraFee = data => {
  return {
    type: actionTypes.ADD_RECYCLING_EXTRA_FEE,
    data
  };
};

export const addRecyclingExtraFeeSuccess = () => {
  return {
    type: actionTypes.ADD_RECYCLING_EXTRA_FEE_SUCCESS
  };
};

export const addRecyclingExtraFeeFail = () => {
  return {
    type: actionTypes.ADD_RECYCLING_EXTRA_FEE_FAIL
  };
};

export const editRecyclingExtraFeeItem = (id,data) => {
  return {
    type: actionTypes.EDIT_RECYCLING_EXTRA_FEE_ITEM,
    id,
    data
  };
};

export const editRecyclingExtraFeeItemSuccess = () => {
  return {
    type: actionTypes.EDIT_RECYCLING_EXTRA_FEE_ITEM_SUCCESS
  };
};

export const editRecyclingExtraFeeItemFail = () => {
  return {
    type: actionTypes.EDIT_RECYCLING_EXTRA_FEE_ITEM_FAIL
  };
};



export const updateRecyclingExtraFeeWasteType = (id,data) => {
  return {
    type: actionTypes.UPDATE_RECYCLING_EXTRA_FEE_WASTE_TYPE,
    id,
    data
  };
};

export const updateRecyclingExtraFeeWasteTypeSuccess = () => {
  return {
    type: actionTypes.UPDATE_RECYCLING_EXTRA_FEE_WASTE_TYPE_SUCCESS
  };
};

export const updateRecyclingExtraFeeWasteTypeFail = () => {
  return {
    type: actionTypes.UPDATE_RECYCLING_EXTRA_FEE_WASTE_TYPE_FAIL
  };
};

export const deleteRecyclingExtraFee = (id,data) => {
  return {
    type: actionTypes.DELETE_RECYCLING_EXTRA_FEE,
    id,
    data
  };
};

export const deleteRecyclingExtraFeeSuccess = () => {
  return {
    type: actionTypes.DELETE_RECYCLING_EXTRA_FEE_SUCCESS
  };
};

export const deleteRecyclingExtraFeeFail = () => {
  return {
    type: actionTypes.DELETE_RECYCLING_EXTRA_FEE_FAIL
  };
};


export const loadRecyclingLoadingFeeList = data => {
  
  return {
    type: actionTypes.LOAD_RECYCLING_LOADING_FEE_LIST,
    data
  };
};

export const loadRecyclingLoadingFeeListSuccess = data => {
  return {
    type: actionTypes.LOAD_RECYCLING_LOADING_FEE_LIST_SUCCESS,
    data
  };
};

export const loadRecyclingLoadingFeeListFail = () => {
  return {
    type: actionTypes.LOAD_RECYCLING_LOADING_FEE_LIST_FAIL
  };
};


export const setRecyclingLoadingFee = data => {
  
  return {
    type: actionTypes.SET_RECYCLING_LOADING_FEE,
    data
  };
};

export const setRecyclingLoadingFeeSuccess = () => {
  return {
    type: actionTypes.SET_RECYCLING_LOADING_FEE_SUCCESS
  };
};

export const setRecyclingLoadingFeeFail = () => {
  return {
    type: actionTypes.SET_RECYCLING_LOADING_FEE_FAIL
  };
};
/** END RECYCLING EXTRA FEE **/



export const getRecyclingPricePDF = (data) => {
  return {
    type: actionTypes.GET_RECYCLING_PRICE_PDF,
    data,
  };
};

export const getRecyclingPricePDFSuccess = data => {
  return {
    type: actionTypes.GET_RECYCLING_PRICE_PDF_SUCCESS,
    data
  };
};

export const getRecyclingPricePDFFail = () => {
  return {
    type: actionTypes.GET_RECYCLING_PRICE_PDF_FAIL
  };
};


export const getRecyclingPricePostPDF = (data) => {
  return {
    type: actionTypes.GET_RECYCLING_PRICE_POST_PDF,
    data,
  };
};

export const getRecyclingPricePostPDFSuccess = data => {
  return {
    type: actionTypes.GET_RECYCLING_PRICE_POST_PDF_SUCCESS,
    data
  };
};

export const getRecyclingPricePostPDFFail = () => {
  return {
    type: actionTypes.GET_RECYCLING_PRICE_POST_PDF_FAIL
  };
};



export const loadRecyclingSalesPriceList = (data) => {
  return {
    type: actionTypes.LOAD_RECYCLING_SALES_PRICE_LIST,
    data,
  };
};

export const loadRecyclingSalesPriceListSuccess = data => {
  return {
    type: actionTypes.LOAD_RECYCLING_SALES_PRICE_LIST_SUCCESS,
    data
  };
};

export const loadRecyclingSalesPriceListFail = () => {
  return {
    type: actionTypes.LOAD_RECYCLING_SALES_PRICE_LIST_FAIL
  };
};


export const getRecyclingSalesPricePDF = (data) => {
  return {
    type: actionTypes.GET_RECYCLING_SALES_PRICE_PDF,
    data,
  };
};

export const getRecyclingSalesPricePDFSuccess = data => {
  return {
    type: actionTypes.GET_RECYCLING_SALES_PRICE_PDF_SUCCESS,
    data
  };
};

export const getRecyclingSalesPricePDFFail = () => {
  return {
    type: actionTypes.GET_RECYCLING_SALES_PRICE_PDF_FAIL
  };
};


export const loadRecyclingSalesPriceUpdHistory = (data) => {
  return {
    type: actionTypes.RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST,
    data,
  };
};

export const loadRecyclingSalesPriceUpdHistorySuccess = data => {
  return {
    type: actionTypes.RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST_SUCCESS,
    data
  };
};

export const loadRecyclingSalesPriceUpdHistoryFail = () => {
  return {
    type: actionTypes.RECYCLING_SALES_PRICE_UPDATE_HISTORY_LIST_FAIL
  };
};


export const countRecyclingSalesPriceUpdHistory = (data) => {
  return {
    type: actionTypes.RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT,
    data,
  };
};

export const countRecyclingSalesPriceUpdHistorySuccess = data => {
  return {
    type: actionTypes.RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT_SUCCESS,
    data
  };
};

export const countRecyclingSalesPriceUpdHistoryFail = () => {
  return {
    type: actionTypes.RECYCLING_SALES_PRICE_UPDATE_HISTORY_COUNT_FAIL
  };
};

export const getRecyclingSalesPriceUpdHistoryDetail = (id, data) => {
  return {
    type: actionTypes.RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL,
    id,
    data,
  };
};

export const getRecyclingSalesPriceUpdHistoryDetailSuccess = data => {
  return {
    type: actionTypes.RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL_SUCCESS,
    data
  };
};

export const getRecyclingSalesPriceUpdHistoryDetailFail = () => {
  return {
    type: actionTypes.RECYCLING_SALES_PRICE_UPDATE_HISTORY_DETAIL_FAIL
  };
};

