import * as actionTypes from "./actionTypes";

export const loadGooglePlaceAutocomplete = data => {
  return {
    type: actionTypes.LOAD_GOOGLE_PLACE_AUTOCOMPLETE,
    data
  };
};

export const loadGooglePlaceAutocompleteSuccess = data => {
  return {
    type: actionTypes.LOAD_GOOGLE_PLACE_AUTOCOMPLETE_SUCCESS,
    data
  };
};

export const loadGooglePlaceAutocompleteFail = () => {
  return {
    type: actionTypes.LOAD_GOOGLE_PLACE_AUTOCOMPLETE_FAIL
  };
};
