import { all, takeEvery, put, fork } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import * as actions from "../actions/index";
import * as API from "../api";
// import { push } from "react-router-redux";
import Helper from "../../helpers/helpers";

export function* login() {
  yield takeEvery(actionTypes.LOGIN, function*(action) {
    try {
      const response = yield API.login(action.data);

      if (response.data.success && response.data.token && response.data.refresh_token) {
        yield API.SetAxiosAuthBearer(response.data.token);
        yield put(
          actions.loginSuccess(response.data.token, response.data.refresh_token, response.data.data)
        );
      } else {
        yield put(actions.loginError(response.data.message));
      }
    } catch (error) {
      yield put(actions.loginError(API.constructErrMsg(error)));
    }
  });
}

export function* loginError() {
  yield takeEvery(actionTypes.LOGIN_FAIL, function*(action) {
    yield alert(action.error);
  });
}

export function* logout() {
  yield takeEvery(actionTypes.LOGOUT, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("LOGOUT");
    action.data.track_no = track_no

    yield put(actions.enableLoading());
    try {
      const response = yield API.logout(action.data);
      
      if (response.data.success) {
        yield put(actions.logoutSuccess());
        // yield put(push("/"));
      } else {
        yield alert("Failed to logout!" + response.data.message);
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error, "Failed to logout!"));
      yield put(actions.disableLoading());
    }
  });
}


export function* logoutRequest() {
  yield takeEvery(actionTypes.LOGOUT_CONFIRMATION, function*(action) {
    const confirm = window.confirm("Are you sure want to log out?");

    if (confirm) {
      yield put(actions.logout());
    }
  });
}

// Relogin
export function* refreshJWTToken() {
  
  yield takeEvery(actionTypes.REFRESH_JWT_TOKEN, function*() {
    try {
      const response = yield API.refreshJWTToken();
      if (response.data.success && response.data.token && response.data.refresh_token) {
        yield API.SetAxiosAuthBearer(response.data.token);
        yield put(
          actions.refreshJWTTokenSuccess(
            response.data.token,
            response.data.data
          )
        );
      } else {
        yield alert("Failed to refresh JWT Token!" + response.data.message);
        yield put(actions.forceLogout());
      }
    } catch (error) {
      yield put(actions.handleAPIError(error, "Failed to refresh JWT Token!"));
      yield put(actions.forceLogout());
    }
  });
}

export function* forceLogout() {
  yield takeEvery(actionTypes.FORCE_LOGOUT, function*() {
    yield put(actions.enableLoading());
    yield put(actions.forceLogoutSuccess());
    yield put(actions.disableLoading());
  });
}

export function* getProfileRequest() {
  yield takeEvery(actionTypes.GET_PROFILE, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("GETPROFILE");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.getMyProfile(action.data);

      if (response.data.success && response.data.data) {
        yield put(actions.getProfileSuccess(response.data.data));
      } else {
        alert(response.data.message);
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
    }
  });
}

export function* changePassword() {
  yield takeEvery(actionTypes.CHANGE_PASSWORD, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("CHANGEPWD");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.changePassword(action.data);

      if (response.data.success) {
        yield put(actions.changePasswordSuccess(response.data));
        yield put(actions.toastSuccess(response.data.message));
      } else {
        alert(response.data.message);
      }

      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
    }
  });
}


export function* checkVersion() {
  yield takeEvery(actionTypes.CHECK_VERSION, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("CHECKVERSION");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.checkVersion(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.checkVersionSuccess(response.data.data));
      } else {
        // alert("New content is available. Please refresh (Shift + F5).");
        yield put(actions.checkVersionFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.checkVersionFail());
    }
  });
}


export function* versionUpdateList() {
  yield takeEvery(actionTypes.VERSION_UPDATE_LIST, function*(action) {

    if(!action.data) action.data = {}

    let track_no = Helper.generateTrackNo("VERSIONUPDLIST");
    action.data.track_no = track_no

    yield put(actions.enableLoading());

    try {
      const response = yield API.versionUpdateList(action.data);
      if (response.data.success && response.data.data) {
        yield put(actions.versionUpdateListSuccess(response.data));
      } else {
        alert(response.data.message);
        yield put(actions.versionUpdateListFail());
      }
      yield put(actions.disableLoading());
    } catch (error) {
      yield put(actions.handleAPIError(error));
      yield put(actions.disableLoading());
      yield put(actions.versionUpdateListFail());
    }
  });
}



export default function* authSaga() {
  yield all([
    fork(login),
    fork(loginError),
    fork(refreshJWTToken),
    fork(logout),
    fork(logoutRequest),
    fork(forceLogout),
    fork(getProfileRequest),
    fork(changePassword),
    fork(checkVersion),
    fork(versionUpdateList)
  ]);
}
