import * as actionTypes from "./actionTypes";



export const adminChangePassword = (data) => {
  return {
    type: actionTypes.ADMIN_CHANGE_PASSWORD,
    data
  };
};

export const adminChangePasswordSuccess = () => {
  return {
    type: actionTypes.ADMIN_CHANGE_PASSWORD_SUCCESS
  };
};

export const adminChangePasswordFail = () => {
  return {
    type: actionTypes.ADMIN_CHANGE_PASSWORD_FAIL
  };
};


export const adminUpdateDetail = (data) => {
  return {
    type: actionTypes.ADMIN_UPDATE_DETAIL,
    data
  };
};

export const adminUpdateDetailSuccess = () => {
  return {
    type: actionTypes.ADMIN_UPDATE_DETAIL_SUCCESS
  };
};

export const adminUpdateDetailFail = () => {
  return {
    type: actionTypes.ADMIN_UPDATE_DETAIL_FAIL
  };
};

export const loadAdminList = data => {
  
  return {
    type: actionTypes.LOAD_ADMIN_LIST,
    data
  };
};

export const loadAdminListSuccess = data => {
  return {
    type: actionTypes.LOAD_ADMIN_LIST_SUCCESS,
    data
  };
};

export const loadAdminListFail = () => {
  return {
    type: actionTypes.LOAD_ADMIN_LIST_FAIL
  };
};




export const loadAdminDetail = (id,data) => {
  
  return {
    type: actionTypes.LOAD_ADMIN_DETAIL,
    id,
    data
  };
};

export const loadAdminDetailSuccess = data => {
  
  return {
    type: actionTypes.LOAD_ADMIN_DETAIL_SUCCESS,
    data
  };
};

export const loadAdminDetailFail = () => {
  return {
    type: actionTypes.LOAD_ADMIN_DETAIL_FAIL
  };
};


export const createAdmin = (data) => {
  return {
    type: actionTypes.CREATE_ADMIN,
    data
  };
};

export const createAdminSuccess = () => {
  return {
    type: actionTypes.CREATE_ADMIN_SUCCESS
  };
};

export const createAdminFail = () => {
  return {
    type: actionTypes.CREATE_ADMIN_FAIL
  };
};


export const editAdminDetail = (id,data) => {
  return {
    type: actionTypes.EDIT_ADMIN_DETAIL,
    id,
    data
  };
};

export const editAdminDetailSuccess = () => {
  return {
    type: actionTypes.EDIT_ADMIN_DETAIL_SUCCESS
  };
};

export const editAdminDetailFail = () => {
  return {
    type: actionTypes.EDIT_ADMIN_DETAIL_FAIL
  };
};


export const editAdminStatus = (id,data, listParams) => {
  return {
    type: actionTypes.EDIT_ADMIN_STATUS,
    id,
    data,
    listParams
  };
};

export const editAdminStatusSuccess = () => {
  return {
    type: actionTypes.EDIT_ADMIN_STATUS_SUCCESS
  };
};

export const editAdminStatusFail = () => {
  return {
    type: actionTypes.EDIT_ADMIN_STATUS_FAIL
  };
};


export const deleteAdmin = (id,data) => {
  return {
    type: actionTypes.DELETE_ADMIN,
    id,
    data
  };
};

export const deleteAdminSuccess = () => {
  return {
    type: actionTypes.DELETE_ADMIN_SUCCESS
  };
};

export const deleteAdminFail = () => {
  return {
    type: actionTypes.DELETE_ADMIN_FAIL
  };
};


export const resetAdminPassword = (id,data) => {
  return {
    type: actionTypes.RESET_ADMIN_PASSWORD,
    id,
    data
  };
};

export const resetAdminPasswordSuccess = () => {
  return {
    type: actionTypes.RESET_ADMIN_PASSWORD_SUCCESS
  };
};

export const resetAdminPasswordFail = () => {
  return {
    type: actionTypes.RESET_ADMIN_PASSWORD_FAIL
  };
};


export const setAdminEmergencyContact = (id,data) => {
  return {
    type: actionTypes.SER_ADMIN_EMERGENCY_CONTACT,
    id,
    data
  };
};

export const setAdminEmergencyContactSuccess = () => {
  return {
    type: actionTypes.SER_ADMIN_EMERGENCY_CONTACT_SUCCESS
  };
};

export const setAdminEmergencyContactFail = () => {
  return {
    type: actionTypes.SER_ADMIN_EMERGENCY_CONTACT_FAIL
  };
};


/* ===== ADMIN PRIVILEGES ===== */


export const loadAdminPrivileges = (data) => {
  return {
    type: actionTypes.LOAD_ADMIN_PRIVILEGES_LIST,
    data
  };
};

export const loadAdminPrivilegesSuccess = data => {
  return {
    type: actionTypes.LOAD_ADMIN_PRIVILEGES_LIST_SUCCESS,
    data
  };
};

export const loadAdminPrivilegesFail = () => {
  return {
    type: actionTypes.LOAD_ADMIN_PRIVILEGES_LIST_FAIL
  };
};

export const createAdminRole = (data) => {
  return {
    type: actionTypes.CREATE_ADMIN_ROLE,
    data
  };
};

export const createAdminRoleSuccess = () => {
  return {
    type: actionTypes.CREATE_ADMIN_ROLE_SUCCESS
  };
};

export const createAdminRoleFail = () => {
  return {
    type: actionTypes.CREATE_ADMIN_ROLE_FAIL
  };
};


export const deleteAdminRole = (id,data) => {
  return {
    type: actionTypes.DELETE_ADMIN_ROLE,
    id,
    data
  };
};

export const deleteAdminRoleSuccess = () => {
  return {
    type: actionTypes.DELETE_ADMIN_ROLE_SUCCESS
  };
};

export const deleteAdminRoleFail = () => {
  return {
    type: actionTypes.DELETE_ADMIN_ROLE_FAIL
  };
};


export const updateAdminRole = (id,data) => {
  return {
    type: actionTypes.UPDATE_ADMIN_ROLE,
    id,
    data
  };
};

export const updateAdminRoleSuccess = () => {
  return {
    type: actionTypes.UPDATE_ADMIN_ROLE_SUCCESS
  };
};

export const updateAdminRoleFail = () => {
  return {
    type: actionTypes.UPDATE_ADMIN_ROLE_FAIL
  };
};



export const setAdminPrivileges = (data) => {
  return {
    type: actionTypes.SET_ADMIN_PRIVILEGES,
    data
  };
};

export const setAdminPrivilegesSuccess = () => {
  return {
    type: actionTypes.SET_ADMIN_PRIVILEGES_SUCCESS
  };
};

export const setAdminPrivilegesFail = () => {
  return {
    type: actionTypes.SET_ADMIN_PRIVILEGES_FAIL
  };
};



export const loadAdminRole = (data) => {
  return {
    type: actionTypes.LOAD_ADMIN_ROLE,
    data
  };
};

export const loadAdminRoleSuccess = data => {
  return {
    type: actionTypes.LOAD_ADMIN_ROLE_SUCCESS,
    data
  };
};

export const loadAdminRoleFail = () => {
  return {
    type: actionTypes.LOAD_ADMIN_ROLE_FAIL
  };
};


