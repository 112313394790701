import axios from "axios";
import { APIHost } from "./helpers";

// Base URL
export const baseURL = () => {
  return APIHost() + "/general/admin/scheduledWaste";
};


export const loadScheduledWasteList = data => {
  const url = `${baseURL()}/load`;
  return axios.post(url, data);
};

/** WASTE CATEGORY */
export const addScheduledWasteCategory = data => {
  const url = `${baseURL()}/category/create`;
  return axios.post(url, data);
};

export const editScheduledWasteCategory = (id,data) => {
  const url = `${baseURL()}/category/update/${id}`;
  return axios.post(url, data);
};

export const deleteScheduledWasteCategory = (id,data) => {
  const url = `${baseURL()}/category/remove/${id}`;
  return axios.post(url, data);
};

export const scheduledWasteCategorySetItem = (id,data) => {
  const url = `${baseURL()}/category/addItem/${id}`;
  return axios.post(url, data);
};

/** WASTE CATEGORY END*/


/** WASTE PRICE */

export const loadScheduledWastePriceEffectiveDateList = data => {
  const url = `${baseURL()}/price/effectiveDate`;
  return axios.post(url, data);
};


export const loadScheduledWastePriceList = data => {
  const url = `${baseURL()}/price/load`;
  return axios.post(url, data);
};


export const loadScheduledWastePricePDF = data => {
  const url = `${baseURL()}/price/pdf`;
  return axios.post(url, data);
};


export const scheduledWastePriceSet = data => {
  const url = `${baseURL()}/price/set`;
  return axios.post(url, data);
};

/** WASTE PRICE END*/


/** WASTE LORRY PRICE */

export const loadScheduledWasteLorryPriceEffectiveDateList = data => {
  const url = `${baseURL()}/lorry/price/effectiveDate`;
  return axios.post(url, data);
};


export const loadScheduledWasteLorryPriceList = data => {
  const url = `${baseURL()}/lorry/price/load`;
  return axios.post(url, data);
};


export const loadScheduledWasteLorryPricePDF = data => {
  const url = `${baseURL()}/lorry/price/pdf`;
  return axios.post(url, data);
};


export const scheduledWasteLorryPriceSet = data => {
  const url = `${baseURL()}/lorry/price/set`;
  return axios.post(url, data);
};

export const scheduledWasteProcessCostSet = (id, data) => {
  const url = `${baseURL()}/processCost/${id}`;
  return axios.post(url, data);
};

/** WASTE LORRY PRICE END*/



/** RECOVERY COMPANY */
export const loadRecoveryCompanyList = data => {
  const url = `${baseURL()}/recoverCompany/load`;
  return axios.post(url, data);
};

export const loadRecoveryCompanyDetail = (id,data) => {
  const url = `${baseURL()}/recoverCompany/load/${id}`;
  return axios.post(url, data);
};

export const addRecoveryCompany = data => {
  const url = `${baseURL()}/recoverCompany/create`;
  return axios.post(url, data);
};

export const editRecoveryCompanyDetail = (id,data) => {
  const url = `${baseURL()}/recoverCompany/update/${id}/detail`;
  return axios.post(url, data);
};

export const editRecoveryCompanyLicense = (id,data) => {
  const url = `${baseURL()}/recoverCompany/update/${id}/license`;
  return axios.post(url, data);
};

export const deleteRecoveryCompany = (id,data) => {
  const url = `${baseURL()}/recoverCompany/remove/${id}`;
  return axios.post(url, data);
};
/** RECOVERY COMPANY END*/

/* ===== SCHEDULED DOE REGISTRATION ===== */
export const loadDOERegistrationList = data => {
  const url = `${baseURL()}/DOE_Registration/load`;
  return axios.post(url, data);
};

export const DOERegistrationCount = (data) => {
  const url = `${baseURL()}/DOE_Registration/count`;
  return axios.post(url, data);
};

export const loadDOERegistrationDetail = (id,data) => {
  const url = `${baseURL()}/DOE_Registration/load/${id}`;
  return axios.post(url, data);
};

export const DOERegistrationApprove = (id,data) => {
  const url = `${baseURL()}/DOE_Registration/approve/${id}`;
  return axios.post(url, data);
};

export const DOERegistrationReject = (id,data) => {
  const url = `${baseURL()}/DOE_Registration/reject/${id}`;
  return axios.post(url, data);
};
/* ===== END SCHEDULED DOE REGISTRATION ===== */


/* ===== SCHEDULED WASTE CONTAINER ===== */
export const loadScheduledWasteContainerList = data => {
  const url = `${baseURL()}/container/load`;
  return axios.post(url, data);
};

export const createScheduledWasteContainer = data => {
  const url = `${baseURL()}/container/create`;
  return axios.post(url, data);
};

export const updateScheduledWasteContainer = (id,data) => {
  const url = `${baseURL()}/container/update/${id}`;
  return axios.post(url, data);
};

export const removeScheduledWasteContainer = (id,data) => {
  const url = `${baseURL()}/container/remove/${id}`;
  return axios.post(url, data);
};
/* ===== END SCHEDULED WASTE CONTAINER ===== */