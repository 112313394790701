import firebase from "firebase/app";
import "firebase/auth";
//import "firebase/messaging";
import "firebase/database";
//import ReduxSagaFirebase from 'redux-saga-firebase';
import 'firebase/firestore';
import { userFirebaseConfig } from '../../settings';

const validLive = userFirebaseConfig.Live && userFirebaseConfig.Live.apiKey && userFirebaseConfig.Live.projectId;
const firebaseAppLive = validLive && firebase.initializeApp(userFirebaseConfig.Live, "firebaseAppLive");
const firebaseDBLive = validLive && firebase.database(firebaseAppLive);


const validStage = userFirebaseConfig.Stage && userFirebaseConfig.Stage.apiKey && userFirebaseConfig.Stage.projectId;
const firebaseAppStage = validStage && firebase.initializeApp(userFirebaseConfig.Stage, "firebaseAppStage");
const firebaseDBStage = validStage && firebase.database(firebaseAppStage);

//const messaging = firebase.messaging();

class FirebaseHelper {
  // isValid = valid;
  EMAIL = 'email';
  FACEBOOK = 'facebook';
  GOOGLE = 'google';
  GITHUB = 'github';
  TWITTER = 'twitter';
  // constructor() {
    // this.login = this.login.bind(this);
    // this.logout = this.logout.bind(this);
    // this.isAuthenticated = this.isAuthenticated.bind(this);
    // this.database = this.isValid && firebase.firestore();
    // if (this.database) {
    //   const settings = {};
    //   this.database.settings(settings);
    // }
    // this.rsf =
    //   this.isValid && new ReduxSagaFirebase(firebaseApp, firebase.firestore());
    // this.rsfFirestore = this.isValid && this.rsf.firestore;
  // }

  // order_log = (isLive) => {

  //   console.log("order-log")
  //   if(isLive)
  //     return firebaseDBLive.ref("admin-message/order-log")
  //   else
  //     return firebaseDBStage.ref("admin-message/order-log")

  //   // firebaseDB.ref("admin-message/order-log").on("value", snap =>{
  //   //   console.log(snap.val())
      
  //   //   return "hhh"
  //   // })
  // };
  
  // new_order = (isLive) => {

  //   if(isLive)
  //     return firebaseDBLive.ref("admin-message/new-order");
  //   else
  //     return firebaseDBStage.ref("admin-message/new-order");
  // };

  // waiting_accept_order = (isLive) => {

  //   if(isLive)
  //     return firebaseDBLive.ref("admin-message/waiting-accept-order");
  //   else
  //     return firebaseDBStage.ref("admin-message/waiting-accept-order");
  // }; 
  
  
  // latest_time = (admin_id, isLive) => {

  //   if(isLive)
  //     return firebaseDBLive.ref(`admin/${admin_id}`);
  //   else
  //     return firebaseDBStage.ref(`admin/${admin_id}`);
  // };
  

  // trip_log = (isLive) => {

  //   if(isLive)
  //     return firebaseDBLive.ref("admin-message/trip-log");
  //   else
  //     return firebaseDBStage.ref("admin-message/trip-log");
  // };  
  
  // upcoming_trip = (isLive) => {

  //   if(isLive)
  //     return firebaseDBLive.ref("admin-message/upcoming-trip");
  //   else
  //     return firebaseDBStage.ref("admin-message/upcoming-trip");
  // };  
  
  // waiting_going_now = (isLive) => {

  //   if(isLive)
  //     return firebaseDBLive.ref("admin-message/waiting-going-now");
  //   else
  //     return firebaseDBStage.ref("admin-message/waiting-going-now");
  // }; 
  
  individual_driver_waiting_approve = (isLive) => {

    if(isLive)
      return firebaseDBLive.ref("admin-message/individual-driver-waiting-approve");
    else
      return firebaseDBStage.ref("admin-message/individual-driver-waiting-approve");
  }; 

  
  organization_driver_waiting_approve = (isLive) => {

    if(isLive)
      return firebaseDBLive.ref("admin-message/organization-driver-waiting-approve");
    else
      return firebaseDBStage.ref("admin-message/organization-driver-waiting-approve");
  }; 

  
  user_branch_waiting_approve = (isLive) => {

    if(isLive)
      return firebaseDBLive.ref("admin-message/user_branch-waiting-approve");
    else
      return firebaseDBStage.ref("admin-message/user_branch-waiting-approve");
  }; 

  
  lorry_waiting_approve = (isLive) => {

    if(isLive)
      return firebaseDBLive.ref("admin-message/lorry-waiting-approve");
    else
      return firebaseDBStage.ref("admin-message/lorry-waiting-approve");
  };

  
  user_credit_term_waiting_approve = (isLive) => {

    if(isLive)
      return firebaseDBLive.ref("admin-message/user-credit-term-waiting-approve");
    else
      return firebaseDBStage.ref("admin-message/user-credit-term-waiting-approve");
  };

  
  order_pending_accept = (isLive) => {

    if(isLive)
      return firebaseDBLive.ref("admin-message/order_pending_accept");
    else
      return firebaseDBStage.ref("admin-message/order_pending_accept");
  };

  
  grading_waiting_approve = (isLive) => {

    if(isLive)
      return firebaseDBLive.ref("admin-message/grading-waiting-approve");
    else
      return firebaseDBStage.ref("admin-message/grading-waiting-approve");
  };

  
  withdraw_waiting_approve = (isLive) => {

    if(isLive)
      return firebaseDBLive.ref("admin-message/withdraw-waiting-approve");
    else
      return firebaseDBStage.ref("admin-message/withdraw-waiting-approve");
  };

  
  order_refund_waiting_approve = (isLive) => {

    if(isLive)
      return firebaseDBLive.ref("admin-message/order-refund-waiting-approve");
    else
      return firebaseDBStage.ref("admin-message/order-refund-waiting-approve");
  };

  
  driver_payment_waiting_approve = (isLive) => {

    if(isLive)
      return firebaseDBLive.ref("admin-message/driver-payment-waiting-approve");
    else
      return firebaseDBStage.ref("admin-message/driver-payment-waiting-approve");
  };

  
  reimburse_cut_off_waiting_approve = (isLive) => {

    if(isLive)
      return firebaseDBLive.ref("admin-message/reimburse-cut-off-waiting-approve");
    else
      return firebaseDBStage.ref("admin-message/reimburse-cut-off-waiting-approve");
  };

  
  feeback_waiting_resolve = (isLive) => {

    if(isLive)
      return firebaseDBLive.ref("admin-message/feeback-waiting-resolve");
    else
      return firebaseDBStage.ref("admin-message/feeback-waiting-resolve");
  };

  
  partner_manager_waiting_verify = (isLive) => {

    if(isLive)
      return firebaseDBLive.ref("admin-message/partner-manager-waiting-verify");
    else
      return firebaseDBStage.ref("admin-message/partner-manager-waiting-verify");
  };

  
  transport_license_waiting_verify = (isLive) => {

    if(isLive)
      return firebaseDBLive.ref("admin-message/transport-license-waiting-verify");
    else
      return firebaseDBStage.ref("admin-message/transport-license-waiting-verify");
  };

  
  waiting_set_recovery_company = (isLive) => {

    if(isLive)
      return firebaseDBLive.ref("admin-message/waiting-set-recovery-company");
    else
      return firebaseDBStage.ref("admin-message/waiting-set-recovery-company");
  };
  
  DOE_Registration_waiting_approve = (isLive) => {

    if(isLive)
      return firebaseDBLive.ref("admin-message/DOE-registration-waiting-approve");
    else
      return firebaseDBStage.ref("admin-message/DOE-registration-waiting-approve");
  };

  
  // createBatch = () => {
  //   return this.database.batch();
  // };

  // login(provider, info) {
  //   if (!this.isValid) {
  //     return;
  //   }
  //   switch (provider) {
  //     case this.EMAIL:
  //       return firebaseAuth().signInWithEmailAndPassword(
  //         info.email,
  //         info.password
  //       );
  //     case this.FACEBOOK:
  //       return firebaseAuth().FacebookAuthProvider();
  //     case this.GOOGLE:
  //       return firebaseAuth().GoogleAuthProvider();
  //     case this.GITHUB:
  //       return firebaseAuth().GithubAuthProvider();
  //     case this.TWITTER:
  //       return firebaseAuth().TwitterAuthProvider();
  //     default:
  //   }
  // }
  // logout() {
  //   return firebaseAuth().signOut();
  // }

  // isAuthenticated() {
  //   firebaseAuth().onAuthStateChanged(user => {
  //     return user ? true : false;
  //   });
  // }
  // resetPassword(email) {
  //   return firebaseAuth().sendPasswordResetEmail(email);
  // }
  // createNewRef() {
  //   return firebase
  //     .database()
  //     .ref()
  //     .push().key;
  // }

  // requestFirebaseNotificationPermission() {
  //   console.log("requestFirebaseNotificationPermission")
  //   return messaging
  //       .requestPermission()
  //       .then(() => messaging.getToken())
  //       .then((firebaseToken) => {
  //         console.log(firebaseToken)
  //         //resolve(firebaseToken);
  //       })
  //       .catch((err) => {
  //         console.log(err)
  //         //reject(err);
  //       });
   
  // }

  // onMessageListener() {
  //   return messaging.onMessage((payload) => {
  //     console.log(payload)
  //   });
  // }
}

export default new FirebaseHelper();
