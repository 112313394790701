import React from "react";
import Tags from "../../../components/uielements/tag";
import Color from "../../Color";

/*
  props.type = custom (optional)
  props.size
  props.color
  props.style
*/

const CustomTags = props => {
  let color = "";
  switch (props.type) {
    
    case "default":
      color = Color.Boulderapprox;
      break;

    case "success":
      color = Color.Caribbean;
      break;

    case "danger":
      color = Color.Carnation;
      break;

    case "warning":
      color = Color.Amber;
      break;

    case "primary":
      color = Color.DarkBlue;
      break;

    case "info":
      color = Color.Lochmara;
      break;

    case "purple":
      color = Color.PurpleBlue;
      break;
      
    case "greypink":
      color = Color.GreyPink;
      break;

    case "pink":
      color = Color.BrinkPink;
      break;

    case "lightblue":
      color = Color.LinghtBlue;
      break;

    case "orange":
      color = Color.FlushOrange;
      break;

    case "brown":
      color = Color.GreyOrange;
      break;

    case "greyyellow":
      color = Color.GreyYellow;
      break;

    case "fun-green":
      color = Color.FunGreen;
      break;

    case "greygreen":
      color = Color.GreyGreen;
      break;

    case "grey":
      color = Color.Boulderapprox;
      break;

    case "nobelGrey":
      color = Color.Nobel;
      break;
      
      
    default:
      color = Color.Primary;
  }
  return (
    <Tags {...props} color={color}>
      {props.children !== undefined ? props.children : null}
    </Tags>
  );
};

export default CustomTags;
