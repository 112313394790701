import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initState = {
  userOrganizationList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  isProcessLoadBranch: false,
  userOrganizationDetail:{},
  userOrganizationCount:{},
  isUserOrganizationApprovedSuccess: false,
  isUserOrganizationRejectedSuccess: false,
  isDeleteUserOrganizationSuccess: false,
  isUpdateUserOrganizationDetailSuccess:false,
  isCreateUserOrganizationSuccess:false,
  isSetUserOrganizationNegoPriceSuccess: false,
  isSetUserOrganizationMonthlyPriceReducingSuccess: false,
  organization_landfill_distance:null,
  quotationCount: {},
  negoPriceEffectiveDateList: {},
  negoDumpWastePriceList: [],
  negoDumpWastePricePDF: "",
  negoDumpRecyclingPriceList: [],
  negoDumpRecyclingPricePDF: "",
  negoDumpExpressPriceList: [],
  negoDumpExpressPricePDF: "",
  negoRecyclingPriceList: [],
  negoRecyclingPricePDF: "",
  negoDumpWastePriceAdj: [],
  negoDumpRecyclingPriceAdj: [],
  negoDumpExpressPriceAdj: [],
  DumpWasteQuotationList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  DumpRecyclingQuotationList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  DumpExpressQuotationList: {
    data: [],
    offset: 0,
    limit: 10,
    meta: {
      page: 1,
      pageSize: 0,
      pageTotal: 1,
      total: 0,
      pageSizeOptions: [5, 10, 20, 30, 50, 100]
    }
  },
  isQuotationTerminateSuccess: false,
};

export default function userOrganizationReducer(state = initState, action) {
 
  switch (action.type) {
   
    case actionTypes.LOAD_USER_ORGANIZATION_LIST: {
      let userOrganizationList = cloneDeep(state.userOrganizationList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        userOrganizationList.offset = action.data.offset;
        userOrganizationList.limit = action.data.limit;
      }

      return {
        ...state,
        userOrganizationList,
        isProcessLoadBranch: true,
      };
    }

    case actionTypes.LOAD_USER_ORGANIZATION_LIST_SUCCESS: {
      let userOrganizationList = cloneDeep(state.userOrganizationList);
      userOrganizationList.data = action.data.data;
      userOrganizationList.meta = {
        page: 1 + state.userOrganizationList.offset / state.userOrganizationList.limit,
        pageSize: state.userOrganizationList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.userOrganizationList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        userOrganizationList,
        isProcessLoadBranch: false,
      };
    }

    case actionTypes.LOAD_USER_ORGANIZATION_LIST_FAIL: {
      return {
        ...state,
        isProcessLoadBranch: false,
      };
    }
        

    case actionTypes.RESET_USER_ORGANIZATION_LIST_SUCCESS: {
      return {
        ...state,
        userOrganizationList: {
          data: [],
          offset: 0,
          limit: 10,
          meta: {
            page: 1,
            pageSize: 0,
            pageTotal: 1,
            total: 0,
            pageSizeOptions: [5, 10, 20, 30, 50, 100]
          }
        },
      };
    }
    
    case actionTypes.USER_ORGANIZATION_COUNT: {
      
      return {
        ...state,
        userOrganizationCount:{}
      };
    }

    case actionTypes.USER_ORGANIZATION_COUNT_SUCCESS: {
      return {
        ...state,
        userOrganizationCount: action.data
      };
    }

    case actionTypes.USER_ORGANIZATION_COUNT_FAIL: {
      return {
        ...state
      };
    }
    
    
    case actionTypes.APPROVE_USER_ORGANIZATION: {
      
      return {
        ...state,
        isUserOrganizationApprovedSuccess:false
      };
    }

    case actionTypes.APPROVE_USER_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        isUserOrganizationApprovedSuccess: true
      };
    }

    case actionTypes.APPROVE_USER_ORGANIZATION_FAIL: {
      return {
        ...state,
        isUserOrganizationApprovedSuccess:false
      };
    }
    
    
    case actionTypes.REJECT_USER_ORGANIZATION: {
      
      return {
        ...state,
        isUserOrganizationRejectedSuccess:false
      };
    }

    case actionTypes.REJECT_USER_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        isUserOrganizationRejectedSuccess: true
      };
    }

    case actionTypes.REJECT_USER_ORGANIZATION_FAIL: {
      return {
        ...state,
        isUserOrganizationRejectedSuccess:false
      };
    }
    
    
    case actionTypes.LOAD_USER_ORGANIZATION_DETAIL: {
      
      return {
        ...state,
        userOrganizationDetail:{}
      };
    }

    case actionTypes.LOAD_USER_ORGANIZATION_DETAIL_SUCCESS: {
      return {
        ...state,
        userOrganizationDetail: action.data
      };
    }

    case actionTypes.LOAD_USER_ORGANIZATION_DETAIL_FAIL: {
      return {
        ...state
      };
    }
    
    
    case actionTypes.ADD_USER_ORGANIZATION: {
      return {
        ...state,
        isCreateUserOrganizationSuccess: false
      };
    }

    case actionTypes.ADD_USER_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        isCreateUserOrganizationSuccess: true
      };
    }

    case actionTypes.ADD_USER_ORGANIZATION_FAIL: {
      return {
        ...state,
        isCreateUserOrganizationSuccess: false
      };
    }

    case actionTypes.EDIT_USER_ORGANIZATION: {
      return {
        ...state,
        isUpdateUserOrganizationDetailSuccess: false
      };
    }

    case actionTypes.EDIT_USER_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        isUpdateUserOrganizationDetailSuccess: true
      };
    }

    case actionTypes.EDIT_USER_ORGANIZATION_FAIL: {
      return {
        ...state,
        isUpdateUserOrganizationDetailSuccess: false
      };
    }

    case actionTypes.DELETE_USER_ORGANIZATION: {
      return {
        ...state,
        isDeleteUserOrganizationSuccess: false
      };
    }

    case actionTypes.DELETE_USER_ORGANIZATION_SUCCESS: {
      return {
        ...state,
        isDeleteUserOrganizationSuccess: true
      };
    }

    case actionTypes.DELETE_USER_ORGANIZATION_FAIL: {
      return {
        ...state,
        isDeleteUserOrganizationSuccess: false
      };
    }
    

    case actionTypes.USER_ORGANIZATION_LANDFILL_DISTANCE: {
      return {
        ...state,
        organization_landfill_distance: null
      };
    }

    case actionTypes.USER_ORGANIZATION_LANDFILL_DISTANCE_SUCCESS: {
      return {
        ...state,
        organization_landfill_distance: action.data
      };
    }

    case actionTypes.USER_ORGANIZATION_LANDFILL_DISTANCE_FAIL: {
      return {
        ...state
      };
    }

    case actionTypes.SET_USER_ORGANIZATION_NEGO_PRICE: {
      return {
        ...state,
        isSetUserOrganizationNegoPriceSuccess: false
      };
    }

    case actionTypes.SET_USER_ORGANIZATION_NEGO_PRICE_SUCCESS: {
      return {
        ...state,
        isSetUserOrganizationNegoPriceSuccess: true
      };
    }

    case actionTypes.SET_USER_ORGANIZATION_NEGO_PRICE_FAIL: {
      return {
        ...state,
        isSetUserOrganizationNegoPriceSuccess: false
      };
    }

    case actionTypes.SET_USER_ORGANIZATION_MONTHLY_PRICE_REDUCING: {
      return {
        ...state,
        isSetUserOrganizationMonthlyPriceReducingSuccess: false
      };
    }

    case actionTypes.SET_USER_ORGANIZATION_MONTHLY_PRICE_REDUCING_SUCCESS: {
      return {
        ...state,
        isSetUserOrganizationMonthlyPriceReducingSuccess: true
      };
    }

    case actionTypes.SET_USER_ORGANIZATION_MONTHLY_PRICE_REDUCING_FAIL: {
      return {
        ...state,
        isSetUserOrganizationMonthlyPriceReducingSuccess: false
      };
    }

    
    case actionTypes.LOAD_NEGO_PRICE_EFFECTIVE_DATE_LIST: {
      return {
        ...state,
        negoPriceEffectiveDateList: {},
      };
    }

    case actionTypes.LOAD_NEGO_PRICE_EFFECTIVE_DATE_LIST_SUCCESS: {
      return {
        ...state,
        negoPriceEffectiveDateList: action.data,
      };
    }

    case actionTypes.LOAD_NEGO_PRICE_EFFECTIVE_DATE_LIST_FAIL: {
      return {
        ...state,
        negoPriceEffectiveDateList: {},
      };
    }


    case actionTypes.LOAD_NEGO_DUMP_WASTE_PRICE_LIST: {
      return {
        ...state,
        negoDumpWastePriceList: [],
      };
    }

    case actionTypes.LOAD_NEGO_DUMP_WASTE_PRICE_LIST_SUCCESS: {
      return {
        ...state,
        negoDumpWastePriceList: action.data,
      };
    }

    case actionTypes.LOAD_NEGO_DUMP_WASTE_PRICE_LIST_FAIL: {
      return {
        ...state,
        negoDumpWastePriceList: [],
      };
    }
    

    case actionTypes.LOAD_NEGO_DUMP_WASTE_PRICE_ADJUSTMENT: {
      return {
        ...state,
        negoDumpWastePriceAdj: [],
      };
    }

    case actionTypes.LOAD_NEGO_DUMP_WASTE_PRICE_ADJUSTMENT_SUCCESS: {
      return {
        ...state,
        negoDumpWastePriceAdj: action.data,
      };
    }

    case actionTypes.LOAD_NEGO_DUMP_WASTE_PRICE_ADJUSTMENT_FAIL: {
      return {
        ...state,
        negoDumpWastePriceAdj: [],
      };
    }
    

    case actionTypes.LOAD_NEGO_DUMP_WASTE_PRICE_PDF: {
      return {
        ...state,
        negoDumpWastePricePDF: "",
      };
    }

    case actionTypes.LOAD_NEGO_DUMP_WASTE_PRICE_PDF_SUCCESS: {
      return {
        ...state,
        negoDumpWastePricePDF: action.data
      };
    }

    case actionTypes.LOAD_NEGO_DUMP_WASTE_PRICE_PDF_FAIL: {
      return {
        ...state,
        negoDumpWastePricePDF: "",
      };
    }


    case actionTypes.LOAD_NEGO_DUMP_RECYCLING_PRICE_LIST: {
      return {
        ...state,
        negoDumpRecyclingPriceList: [],
      };
    }

    case actionTypes.LOAD_NEGO_DUMP_RECYCLING_PRICE_LIST_SUCCESS: {
      return {
        ...state,
        negoDumpRecyclingPriceList: action.data,
      };
    }

    case actionTypes.LOAD_NEGO_DUMP_RECYCLING_PRICE_LIST_FAIL: {
      return {
        ...state,
        negoDumpRecyclingPriceList: [],
      };
    }

    case actionTypes.LOAD_NEGO_DUMP_RECYCLING_PRICE_ADJUSTMENT: {
      return {
        ...state,
        negoDumpRecyclingPriceAdj: [],
      };
    }

    case actionTypes.LOAD_NEGO_DUMP_RECYCLING_PRICE_ADJUSTMENT_SUCCESS: {
      return {
        ...state,
        negoDumpRecyclingPriceAdj: action.data,
      };
    }

    case actionTypes.LOAD_NEGO_DUMP_RECYCLING_PRICE_ADJUSTMENT_FAIL: {
      return {
        ...state,
        negoDumpRecyclingPriceAdj: [],
      };
    }

    
    case actionTypes.LOAD_NEGO_DUMP_RECYCLING_PRICE_PDF: {
      return {
        ...state,
        negoDumpRecyclingPricePDF: "",
      };
    }

    case actionTypes.LOAD_NEGO_DUMP_RECYCLING_PRICE_PDF_SUCCESS: {
      return {
        ...state,
        negoDumpRecyclingPricePDF: action.data
      };
    }

    case actionTypes.LOAD_NEGO_DUMP_RECYCLING_PRICE_PDF_FAIL: {
      return {
        ...state,
        negoDumpRecyclingPricePDF: "",
      };
    }

      
    case actionTypes.LOAD_NEGO_RECYCLING_PRICE_LIST: {
      return {
        ...state,
        negoRecyclingPriceList: [],
      };
    }

    case actionTypes.LOAD_NEGO_RECYCLING_PRICE_LIST_SUCCESS: {
      return {
        ...state,
        negoRecyclingPriceList: action.data,
      };
    }

    case actionTypes.LOAD_NEGO_RECYCLING_PRICE_LIST_FAIL: {
      return {
        ...state,
        negoRecyclingPriceList: [],
      };
    }


    case actionTypes.LOAD_NEGO_RECYCLING_PRICE_PDF: {
      return {
        ...state,
        negoRecyclingPricePDF: "",
      };
    }

    case actionTypes.LOAD_NEGO_RECYCLING_PRICE_PDF_SUCCESS: {
      return {
        ...state,
        negoRecyclingPricePDF: action.data
      };
    }

    case actionTypes.LOAD_NEGO_RECYCLING_PRICE_PDF_FAIL: {
      return {
        ...state,
        negoRecyclingPricePDF: "",
      };
    }



    case actionTypes.LOAD_NEGO_DUMP_EXPRESS_PRICE_LIST: {
      return {
        ...state,
        negoDumpExpressPriceList: [],
      };
    }

    case actionTypes.LOAD_NEGO_DUMP_EXPRESS_PRICE_LIST_SUCCESS: {
      return {
        ...state,
        negoDumpExpressPriceList: action.data,
      };
    }

    case actionTypes.LOAD_NEGO_DUMP_EXPRESS_PRICE_LIST_FAIL: {
      return {
        ...state,
        negoDumpExpressPriceList: [],
      };
    }
        

    case actionTypes.LOAD_NEGO_DUMP_EXPRESS_PRICE_ADJUSTMENT: {
      return {
        ...state,
        negoDumpExpressPriceAdj: [],
      };
    }

    case actionTypes.LOAD_NEGO_DUMP_EXPRESS_PRICE_ADJUSTMENT_SUCCESS: {
      return {
        ...state,
        negoDumpExpressPriceAdj: action.data,
      };
    }

    case actionTypes.LOAD_NEGO_DUMP_EXPRESS_PRICE_ADJUSTMENT_FAIL: {
      return {
        ...state,
        negoDumpExpressPriceAdj: [],
      };
    }

    
    case actionTypes.LOAD_NEGO_DUMP_EXPRESS_PRICE_PDF: {
      return {
        ...state,
        negoDumpExpressPricePDF: "",
      };
    }

    case actionTypes.LOAD_NEGO_DUMP_EXPRESS_PRICE_PDF_SUCCESS: {
      return {
        ...state,
        negoDumpExpressPricePDF: action.data
      };
    }

    case actionTypes.LOAD_NEGO_DUMP_EXPRESS_PRICE_PDF_FAIL: {
      return {
        ...state,
        negoDumpExpressPricePDF: "",
      };
    }


    case actionTypes.RESET_NEGO_DUMP_WASTE_PRICE_LIST: {
      return {
        ...state,
        negoDumpWastePriceList: [],
      };
    }


    case actionTypes.RESET_NEGO_DUMP_RECYCLING_PRICE_LIST: {
      return {
        ...state,
        negoDumpRecyclingPriceList: [],
      };
    }


    case actionTypes.RESET_NEGO_RECYCLING_PRICE_LIST: {
      return {
        ...state,
        negoRecyclingPriceList: [],
      };
    }

    case actionTypes.NEW_QUOTATION_COUNT: {
      return {
        ...state,
        quotationCount: cloneDeep(state.quotationCount),
      };
    }


    case actionTypes.NEW_QUOTATION_COUNT_SUCCESS: {
      return {
        ...state,
        quotationCount: action.data,
      };
    }


    case actionTypes.NEW_QUOTATION_COUNT_FAIL: {
      return {
        ...state
      };
    }


    case actionTypes.DUMPWASTE_QUOTATION_LIST: {
      let DumpWasteQuotationList = cloneDeep(state.DumpWasteQuotationList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        DumpWasteQuotationList.offset = action.data.offset;
        DumpWasteQuotationList.limit = action.data.limit;
      }

      return {
        ...state,
        DumpWasteQuotationList
      };
    }

    case actionTypes.DUMPWASTE_QUOTATION_LIST_SUCCESS: {
      let DumpWasteQuotationList = cloneDeep(state.DumpWasteQuotationList);
      DumpWasteQuotationList.data = action.data.data;
      DumpWasteQuotationList.meta = {
        page: 1 + state.DumpWasteQuotationList.offset / state.DumpWasteQuotationList.limit,
        pageSize: state.DumpWasteQuotationList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.DumpWasteQuotationList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        DumpWasteQuotationList
      };
    }

    case actionTypes.DUMPWASTE_QUOTATION_LIST_FAIL: {
      return {
        ...state
      };
    }
    
    
    case actionTypes.DUMPRECYCLING_QUOTATION_LIST: {
      let DumpRecyclingQuotationList = cloneDeep(state.DumpRecyclingQuotationList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        DumpRecyclingQuotationList.offset = action.data.offset;
        DumpRecyclingQuotationList.limit = action.data.limit;
      }

      return {
        ...state,
        DumpRecyclingQuotationList
      };
    }

    case actionTypes.DUMPRECYCLING_QUOTATION_LIST_SUCCESS: {
      let DumpRecyclingQuotationList = cloneDeep(state.DumpRecyclingQuotationList);
      DumpRecyclingQuotationList.data = action.data.data;
      DumpRecyclingQuotationList.meta = {
        page: 1 + state.DumpRecyclingQuotationList.offset / state.DumpRecyclingQuotationList.limit,
        pageSize: state.DumpRecyclingQuotationList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.DumpRecyclingQuotationList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        DumpRecyclingQuotationList
      };
    }

    case actionTypes.DUMPRECYCLING_QUOTATION_LIST_FAIL: {
      return {
        ...state
      };
    }
    
    
    
    case actionTypes.DUMPEXPRESS_QUOTATION_LIST: {
      let DumpExpressQuotationList = cloneDeep(state.DumpExpressQuotationList);
      if (
        Object.keys(action.data).includes("offset") &&
        Object.keys(action.data).includes("limit")
      ) {
        DumpExpressQuotationList.offset = action.data.offset;
        DumpExpressQuotationList.limit = action.data.limit;
      }

      return {
        ...state,
        DumpExpressQuotationList
      };
    }

    case actionTypes.DUMPEXPRESS_QUOTATION_LIST_SUCCESS: {
      let DumpExpressQuotationList = cloneDeep(state.DumpExpressQuotationList);
      DumpExpressQuotationList.data = action.data.data;
      DumpExpressQuotationList.meta = {
        page: 1 + state.DumpExpressQuotationList.offset / state.DumpExpressQuotationList.limit,
        pageSize: state.DumpExpressQuotationList.limit,
        pageTotal: Math.ceil(
          action.data.recordsTotal / state.DumpExpressQuotationList.limit
        ),
        total: action.data.recordsTotal
      };

      return {
        ...state,
        DumpExpressQuotationList
      };
    }

    case actionTypes.DUMPEXPRESS_QUOTATION_LIST_FAIL: {
      return {
        ...state
      };
    }
    
    
    case actionTypes.TERMINATE_QUOTATION: {
      return {
        ...state,
        isQuotationTerminateSuccess: false,
      };
    }


    case actionTypes.TERMINATE_QUOTATION_SUCCESS: {
      return {
        ...state,
        isQuotationTerminateSuccess: true,
      };
    }


    case actionTypes.TERMINATE_QUOTATION_FAIL: {
      return {
        ...state,
        isQuotationTerminateSuccess: false,
      };
    }
    
    default:
      return state;
  }
}
